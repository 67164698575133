/*!
 * Krajee Font Awesome 5.x Theme styling for bootstrap-star-rating.
 * This file must be loaded after 'star-rating.css'.
 *
 * bootstrap-star-rating v4.1.2
 * http://plugins.krajee.com/star-rating
 *
 * Author: Kartik Visweswaran
 * Copyright: 2013 - 2021, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-star-rating/blob/master/LICENSE.md
 */
 .theme-krajee-fas.rating-rtl.is-star .filled-stars{right:0}
.fa-shopping-cart:before, .fa-mobile:before, .fa-desktop:before
{
  font-size: 42px !important;
}
.fa-shopping-cart:hover
{
  color: #fff;
}
.custom-icon-style-1:hover
{
  color: #fff;
  /* transform: rotate(45deg); */
}
.zoom-image 
{
  overflow: hidden;
}
.zoom-image img
{
  width: 450px;
  transition: transform 0.3s;
}
.zoom-image img:hover
{
  transform: scale(1.5);
}
.light-color
{
  color:#888;
}
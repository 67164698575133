/*!
 * bootstrap-star-rating v4.1.2
 * http://plugins.krajee.com/star-rating
 *
 * Author: Kartik Visweswaran
 * Copyright: 2013 - 2021, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-star-rating/blob/master/LICENSE.md
 */
 .rating-loading {
	width: 25px;
	height: 25px;
	font-size: 0;
	color: #fff;
	/* background: url(../img/loading.gif) top left no-repeat; */
	border: none
}

.rating-container .rating-stars {
	position: relative;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
	overflow: hidden;
	white-space: nowrap
}

.rating-container .rating-stars:focus {
	outline: dotted 1px
}

.rating-input {
	display: absolute;
	cursor: pointer;
	width: 100%;
	height: 1px;
	bottom: 0;
	left: 0;
	font-size: 1px;
	border: none;
	background: 0 0;
	opacity: 0;
	padding: 0;
	margin: 0
}

.caption-badge,
.rating-container .caption .label {
	line-height: 1;
	text-align: center;
	border-radius: .25rem
}

.rating-container.is-display-only .rating-stars {
	cursor: default
}

.rating-disabled .rating-stars {
	cursor: not-allowed
}

.rating-container .star {
	display: inline-block;
	margin: 0 2px;
	text-align: center
}

.rating-container .empty-stars {
	color: #aaa
}

.rating-container .filled-stars {
	position: absolute;
	left: 0;
	top: 0;
	margin: auto;
	color: #fde16d;
	white-space: nowrap;
	overflow: hidden;
	-webkit-text-stroke: 1px #777;
	text-shadow: 1px 1px #999
}

.rating-rtl {
	float: right
}

.rating-animate .filled-stars {
	transition: width .25s ease
}

.rating-rtl .filled-stars {
	left: auto;
	right: 0;
	transition: none;
	-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
	transform: matrix(-1, 0, 0, 1, 0, 0)
}

.rating-rtl.is-star .filled-stars {
	right: .06em
}

.rating-rtl.is-heart .empty-stars {
	margin-right: .07em
}

.rating-container .clear-rating {
	color: #aaa;
	cursor: not-allowed;
	display: inline-block;
	vertical-align: middle;
	font-size: 60%;
	padding-right: 5px
}

.clear-rating-active {
	cursor: pointer !important
}

.clear-rating-active:hover {
	color: #843534
}

.rating-container .caption .label {
	display: inline-block;
	padding: .25em .4em;
	vertical-align: baseline
}

.rating-container .caption {
	color: #999;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	margin-left: 5px;
	margin-right: 0
}

.rating-rtl .caption {
	margin-right: 5px;
	margin-left: 0
}

@media print {
	.rating-container .clear-rating {
		display: none
	}
}

.rating-xl {
	font-size: 48px
}

.rating-lg {
	font-size: 40px
}

.rating-md {
	font-size: 32px
}

.rating-sm {
	font-size: 24px
}

.rating-xs {
	font-size: 16px
}

.rating-xl .caption {
	font-size: 20px
}

.rating-lg .caption {
	font-size: 18px
}

.rating-md .caption {
	font-size: 16px
}

.rating-sm .caption {
	font-size: 14px
}

.rating-xs .caption {
	font-size: 12px
}

.caption-badge {
	font-family: Arial, Helvetica, sans-serif;
	display: inline-block;
	padding: .35em .65em;
	font-size: .75em;
	font-weight: 700;
	color: #fff;
	white-space: nowrap;
	vertical-align: baseline
}

.caption-secondary {
	background-color: #6c757d
}

.caption-danger {
	background-color: #dc3545
}

.caption-warning {
	background-color: #ffc107;
	color: #212529
}

.caption-info {
	background-color: #0dcaf0;
	color: #212529
}

.caption-primary {
	background-color: #0d6efd
}

.caption-success {
	background-color: #198754
}
::-moz-selection {
	color: #fff;
	background: #1c5fa8
}

::selection {
	color: #fff;
	background: #1c5fa8
}

:root {
	--primary: #1c5fa8;
	--secondary: #e36159;
	--tertiary: #2baab1;
	--quaternary: #383f48
}

a {
	color: #1c5fa8
}

a:hover {
	color: #206bbe
}

a:focus {
	color: #206bbe
}

a:active {
	color: #185392
}

html .text-color-primary,
html .text-primary {
	color: #1c5fa8 !important
}

html .text-color-hover-primary:hover,
html .text-hover-primary:hover {
	color: #1c5fa8 !important
}

html .text-color-secondary,
html .text-secondary {
	color: #e36159 !important
}

html .text-color-hover-secondary:hover,
html .text-hover-secondary:hover {
	color: #e36159 !important
}

html .text-color-tertiary,
html .text-tertiary {
	color: #2baab1 !important
}

html .text-color-hover-tertiary:hover,
html .text-hover-tertiary:hover {
	color: #2baab1 !important
}

html .text-color-quaternary,
html .text-quaternary {
	color: #383f48 !important
}

html .text-color-hover-quaternary:hover,
html .text-hover-quaternary:hover {
	color: #383f48 !important
}

html .text-color-dark,
html .text-dark {
	color: #212529 !important
}

html .text-color-hover-dark:hover,
html .text-hover-dark:hover {
	color: #212529 !important
}

html .text-color-light,
html .text-light {
	color: #fff !important
}

.header-nav-top ul li span, .header-nav-top ul li span a
{
    color: #fff !important;
}

html .text-color-hover-light:hover,
html .text-hover-light:hover {
	color: #fff !important
}

html .svg-fill-color-primary {
	fill: #1c5fa8 !important
}

html .svg-fill-color-primary svg path,
html .svg-fill-color-primary svg rect,
html .svg-fill-color-primary svg line,
html .svg-fill-color-primary svg polyline,
html .svg-fill-color-primary svg polygon {
	fill: #1c5fa8 !important
}

html .svg-fill-color-hover-primary:hover {
	fill: #1c5fa8 !important
}

html .svg-fill-color-hover-primary svg:hover path,
html .svg-fill-color-hover-primary svg:hover rect,
html .svg-fill-color-hover-primary svg:hover line,
html .svg-fill-color-hover-primary svg:hover polyline,
html .svg-fill-color-hover-primary svg:hover polygon {
	fill: #1c5fa8 !important
}

html .svg-stroke-color-primary {
	stroke: #1c5fa8 !important
}

html .svg-stroke-color-primary svg path,
html .svg-stroke-color-primary svg rect,
html .svg-stroke-color-primary svg line,
html .svg-stroke-color-primary svg polyline,
html .svg-stroke-color-primary svg polygon {
	stroke: #1c5fa8 !important
}

html .svg-stroke-color-hover-primary:hover {
	stroke: #1c5fa8 !important
}

html .svg-stroke-color-hover-primary svg:hover path,
html .svg-stroke-color-hover-primary svg:hover rect,
html .svg-stroke-color-hover-primary svg:hover line,
html .svg-stroke-color-hover-primary svg:hover polyline,
html .svg-stroke-color-hover-primary svg:hover polygon {
	stroke: #1c5fa8 !important
}

html .svg-fill-color-secondary {
	fill: #e36159 !important
}

html .svg-fill-color-secondary svg path,
html .svg-fill-color-secondary svg rect,
html .svg-fill-color-secondary svg line,
html .svg-fill-color-secondary svg polyline,
html .svg-fill-color-secondary svg polygon {
	fill: #e36159 !important
}

html .svg-fill-color-hover-secondary:hover {
	fill: #e36159 !important
}

html .svg-fill-color-hover-secondary svg:hover path,
html .svg-fill-color-hover-secondary svg:hover rect,
html .svg-fill-color-hover-secondary svg:hover line,
html .svg-fill-color-hover-secondary svg:hover polyline,
html .svg-fill-color-hover-secondary svg:hover polygon {
	fill: #e36159 !important
}

html .svg-stroke-color-secondary {
	stroke: #e36159 !important
}

html .svg-stroke-color-secondary svg path,
html .svg-stroke-color-secondary svg rect,
html .svg-stroke-color-secondary svg line,
html .svg-stroke-color-secondary svg polyline,
html .svg-stroke-color-secondary svg polygon {
	stroke: #e36159 !important
}

html .svg-stroke-color-hover-secondary:hover {
	stroke: #e36159 !important
}

html .svg-stroke-color-hover-secondary svg:hover path,
html .svg-stroke-color-hover-secondary svg:hover rect,
html .svg-stroke-color-hover-secondary svg:hover line,
html .svg-stroke-color-hover-secondary svg:hover polyline,
html .svg-stroke-color-hover-secondary svg:hover polygon {
	stroke: #e36159 !important
}

html .svg-fill-color-tertiary {
	fill: #2baab1 !important
}

html .svg-fill-color-tertiary svg path,
html .svg-fill-color-tertiary svg rect,
html .svg-fill-color-tertiary svg line,
html .svg-fill-color-tertiary svg polyline,
html .svg-fill-color-tertiary svg polygon {
	fill: #2baab1 !important
}

html .svg-fill-color-hover-tertiary:hover {
	fill: #2baab1 !important
}

html .svg-fill-color-hover-tertiary svg:hover path,
html .svg-fill-color-hover-tertiary svg:hover rect,
html .svg-fill-color-hover-tertiary svg:hover line,
html .svg-fill-color-hover-tertiary svg:hover polyline,
html .svg-fill-color-hover-tertiary svg:hover polygon {
	fill: #2baab1 !important
}

html .svg-stroke-color-tertiary {
	stroke: #2baab1 !important
}

html .svg-stroke-color-tertiary svg path,
html .svg-stroke-color-tertiary svg rect,
html .svg-stroke-color-tertiary svg line,
html .svg-stroke-color-tertiary svg polyline,
html .svg-stroke-color-tertiary svg polygon {
	stroke: #2baab1 !important
}

html .svg-stroke-color-hover-tertiary:hover {
	stroke: #2baab1 !important
}

html .svg-stroke-color-hover-tertiary svg:hover path,
html .svg-stroke-color-hover-tertiary svg:hover rect,
html .svg-stroke-color-hover-tertiary svg:hover line,
html .svg-stroke-color-hover-tertiary svg:hover polyline,
html .svg-stroke-color-hover-tertiary svg:hover polygon {
	stroke: #2baab1 !important
}

html .svg-fill-color-quaternary {
	fill: #383f48 !important
}

html .svg-fill-color-quaternary svg path,
html .svg-fill-color-quaternary svg rect,
html .svg-fill-color-quaternary svg line,
html .svg-fill-color-quaternary svg polyline,
html .svg-fill-color-quaternary svg polygon {
	fill: #383f48 !important
}

html .svg-fill-color-hover-quaternary:hover {
	fill: #383f48 !important
}

html .svg-fill-color-hover-quaternary svg:hover path,
html .svg-fill-color-hover-quaternary svg:hover rect,
html .svg-fill-color-hover-quaternary svg:hover line,
html .svg-fill-color-hover-quaternary svg:hover polyline,
html .svg-fill-color-hover-quaternary svg:hover polygon {
	fill: #383f48 !important
}

html .svg-stroke-color-quaternary {
	stroke: #383f48 !important
}

html .svg-stroke-color-quaternary svg path,
html .svg-stroke-color-quaternary svg rect,
html .svg-stroke-color-quaternary svg line,
html .svg-stroke-color-quaternary svg polyline,
html .svg-stroke-color-quaternary svg polygon {
	stroke: #383f48 !important
}

html .svg-stroke-color-hover-quaternary:hover {
	stroke: #383f48 !important
}

html .svg-stroke-color-hover-quaternary svg:hover path,
html .svg-stroke-color-hover-quaternary svg:hover rect,
html .svg-stroke-color-hover-quaternary svg:hover line,
html .svg-stroke-color-hover-quaternary svg:hover polyline,
html .svg-stroke-color-hover-quaternary svg:hover polygon {
	stroke: #383f48 !important
}

html .svg-fill-color-dark {
	fill: #212529 !important
}

html .svg-fill-color-dark svg path,
html .svg-fill-color-dark svg rect,
html .svg-fill-color-dark svg line,
html .svg-fill-color-dark svg polyline,
html .svg-fill-color-dark svg polygon {
	fill: #212529 !important
}

html .svg-fill-color-hover-dark:hover {
	fill: #212529 !important
}

html .svg-fill-color-hover-dark svg:hover path,
html .svg-fill-color-hover-dark svg:hover rect,
html .svg-fill-color-hover-dark svg:hover line,
html .svg-fill-color-hover-dark svg:hover polyline,
html .svg-fill-color-hover-dark svg:hover polygon {
	fill: #212529 !important
}

html .svg-stroke-color-dark {
	stroke: #212529 !important
}

html .svg-stroke-color-dark svg path,
html .svg-stroke-color-dark svg rect,
html .svg-stroke-color-dark svg line,
html .svg-stroke-color-dark svg polyline,
html .svg-stroke-color-dark svg polygon {
	stroke: #212529 !important
}

html .svg-stroke-color-hover-dark:hover {
	stroke: #212529 !important
}

html .svg-stroke-color-hover-dark svg:hover path,
html .svg-stroke-color-hover-dark svg:hover rect,
html .svg-stroke-color-hover-dark svg:hover line,
html .svg-stroke-color-hover-dark svg:hover polyline,
html .svg-stroke-color-hover-dark svg:hover polygon {
	stroke: #212529 !important
}

html .svg-fill-color-light {
	fill: #fff !important
}

html .svg-fill-color-light svg path,
html .svg-fill-color-light svg rect,
html .svg-fill-color-light svg line,
html .svg-fill-color-light svg polyline,
html .svg-fill-color-light svg polygon {
	fill: #fff !important
}

html .svg-fill-color-hover-light:hover {
	fill: #fff !important
}

html .svg-fill-color-hover-light svg:hover path,
html .svg-fill-color-hover-light svg:hover rect,
html .svg-fill-color-hover-light svg:hover line,
html .svg-fill-color-hover-light svg:hover polyline,
html .svg-fill-color-hover-light svg:hover polygon {
	fill: #fff !important
}

html .svg-stroke-color-light {
	stroke: #fff !important
}

html .svg-stroke-color-light svg path,
html .svg-stroke-color-light svg rect,
html .svg-stroke-color-light svg line,
html .svg-stroke-color-light svg polyline,
html .svg-stroke-color-light svg polygon {
	stroke: #fff !important
}

html .svg-stroke-color-hover-light:hover {
	stroke: #fff !important
}

html .svg-stroke-color-hover-light svg:hover path,
html .svg-stroke-color-hover-light svg:hover rect,
html .svg-stroke-color-hover-light svg:hover line,
html .svg-stroke-color-hover-light svg:hover polyline,
html .svg-stroke-color-hover-light svg:hover polygon {
	stroke: #fff !important
}

.svg-animation-effect-1-hover:hover svg path,
.svg-animation-effect-1-hover:hover svg polygon,
.svg-animation-effect-1-hover:hover svg polyline,
.svg-animation-effect-1-hover:hover svg rect {
	stroke: #1c5fa8
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-primary:hover svg rect {
	stroke: #1c5fa8
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-secondary:hover svg rect {
	stroke: #e36159
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-tertiary:hover svg rect {
	stroke: #2baab1
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-quaternary:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-quaternary:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-quaternary:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-quaternary:hover svg rect {
	stroke: #383f48
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg rect {
	stroke: #212529
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg rect {
	stroke: #fff
}

.gradient-text-color {
	color: #1c5fa8;
	background: linear-gradient(to bottom right, #1c5fa8, #e36159);
	background-image: linear-gradient(to right, #1c5fa8, #e36159)
}

html .bg-color-primary,
html .bg-primary {
	background-color: #1c5fa8 !important
}

html .bg-color-hover-primary:hover,
html .bg-hover-primary:hover {
	background-color: #1c5fa8 !important
}

html .bg-color-after-primary:after {
	background-color: #1c5fa8 !important
}

html .bg-color-hover-after-primary:after:hover {
	background-color: #1c5fa8 !important
}

html .bg-color-before-primary:before {
	background-color: #1c5fa8 !important
}

html .bg-color-hover-before-primary:before:hover {
	background-color: #1c5fa8 !important
}

html .bg-color-secondary,
html .bg-secondary {
	background-color: #e36159 !important
}

html .bg-color-hover-secondary:hover,
html .bg-hover-secondary:hover {
	background-color: #e36159 !important
}

html .bg-color-after-secondary:after {
	background-color: #e36159 !important
}

html .bg-color-hover-after-secondary:after:hover {
	background-color: #e36159 !important
}

html .bg-color-before-secondary:before {
	background-color: #e36159 !important
}

html .bg-color-hover-before-secondary:before:hover {
	background-color: #e36159 !important
}

html .bg-color-tertiary,
html .bg-tertiary {
	background-color: #2baab1 !important
}

html .bg-color-hover-tertiary:hover,
html .bg-hover-tertiary:hover {
	background-color: #2baab1 !important
}

html .bg-color-after-tertiary:after {
	background-color: #2baab1 !important
}

html .bg-color-hover-after-tertiary:after:hover {
	background-color: #2baab1 !important
}

html .bg-color-before-tertiary:before {
	background-color: #2baab1 !important
}

html .bg-color-hover-before-tertiary:before:hover {
	background-color: #2baab1 !important
}

html .bg-color-quaternary,
html .bg-quaternary {
	background-color: #383f48 !important
}

html .bg-color-hover-quaternary:hover,
html .bg-hover-quaternary:hover {
	background-color: #383f48 !important
}

html .bg-color-after-quaternary:after {
	background-color: #383f48 !important
}

html .bg-color-hover-after-quaternary:after:hover {
	background-color: #383f48 !important
}

html .bg-color-before-quaternary:before {
	background-color: #383f48 !important
}

html .bg-color-hover-before-quaternary:before:hover {
	background-color: #383f48 !important
}

html .bg-color-dark,
html .bg-dark {
	background-color: #212529 !important
}

html .bg-color-hover-dark:hover,
html .bg-hover-dark:hover {
	background-color: #212529 !important
}

html .bg-color-after-dark:after {
	background-color: #212529 !important
}

html .bg-color-hover-after-dark:after:hover {
	background-color: #212529 !important
}

html .bg-color-before-dark:before {
	background-color: #212529 !important
}

html .bg-color-hover-before-dark:before:hover {
	background-color: #212529 !important
}

html .bg-color-light,
html .bg-light {
	background-color: #fff !important
}

html .bg-color-hover-light:hover,
html .bg-hover-light:hover {
	background-color: #fff !important
}

html .bg-color-after-light:after {
	background-color: #fff !important
}

html .bg-color-hover-after-light:after:hover {
	background-color: #fff !important
}

html .bg-color-before-light:before {
	background-color: #fff !important
}

html .bg-color-hover-before-light:before:hover {
	background-color: #fff !important
}

.bg-gradient {
	background-color: #1c5fa8 !important;
	background-image: linear-gradient(to right, #1c5fa8 0%, #e36159 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1c5fa8', endColorstr='#e36159', GradientType=1)
}

.bg-gradient-to-top {
	background-color: #1c5fa8 !important;
	background-image: linear-gradient(to top, #1c5fa8 0%, #e36159 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1c5fa8', endColorstr='#e36159', GradientType=1)
}

.bg-gradient-to-bottom {
	background-color: #1c5fa8 !important;
	background-image: linear-gradient(to bottom, #1c5fa8 0%, #e36159 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1c5fa8', endColorstr='#e36159', GradientType=1)
}

@keyframes colorTransition {
	0% {
		background-color: #1c5fa8
	}

	33% {
		background-color: #e36159
	}

	66% {
		background-color: #2baab1
	}

	100% {
		background-color: #383f48
	}
}

html .border-color-primary {
	border-color: #1c5fa8 !important
}

html .border-color-hover-primary:hover {
	border-color: #1c5fa8 !important
}

html .border-color-secondary {
	border-color: #e36159 !important
}

html .border-color-hover-secondary:hover {
	border-color: #e36159 !important
}

html .border-color-tertiary {
	border-color: #2baab1 !important
}

html .border-color-hover-tertiary:hover {
	border-color: #2baab1 !important
}

html .border-color-quaternary {
	border-color: #383f48 !important
}

html .border-color-hover-quaternary:hover {
	border-color: #383f48 !important
}

html .border-color-dark {
	border-color: #212529 !important
}

html .border-color-hover-dark:hover {
	border-color: #212529 !important
}

html .border-color-light {
	border-color: #fff !important
}

html .border-color-hover-light:hover {
	border-color: #fff !important
}

.alternative-font {
	color: #1c5fa8
}

html .box-shadow-1-primary:before {
	box-shadow: 0 30px 90px #1c5fa8 !important
}

html .box-shadow-1-secondary:before {
	box-shadow: 0 30px 90px #e36159 !important
}

html .box-shadow-1-tertiary:before {
	box-shadow: 0 30px 90px #2baab1 !important
}

html .box-shadow-1-quaternary:before {
	box-shadow: 0 30px 90px #383f48 !important
}

html .box-shadow-1-dark:before {
	box-shadow: 0 30px 90px #212529 !important
}

html .box-shadow-1-light:before {
	box-shadow: 0 30px 90px #fff !important
}

html .blockquote-primary {
	border-color: #1c5fa8 !important
}

html .blockquote-secondary {
	border-color: #e36159 !important
}

html .blockquote-tertiary {
	border-color: #2baab1 !important
}

html .blockquote-quaternary {
	border-color: #383f48 !important
}

html .blockquote-dark {
	border-color: #212529 !important
}

html .blockquote-light {
	border-color: #fff !important
}

p.drop-caps:first-letter {
	color: #1c5fa8
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #1c5fa8
}

html .nav-color-primary nav>ul>li>a {
	color: #1c5fa8 !important
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #1c5fa8 !important
}

html .nav-color-primary:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #1c5fa8 !important
}

html .nav-color-secondary nav>ul>li>a {
	color: #e36159 !important
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #e36159 !important
}

html .nav-color-secondary:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #e36159 !important
}

html .nav-color-tertiary nav>ul>li>a {
	color: #2baab1 !important
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #2baab1 !important
}

html .nav-color-tertiary:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #2baab1 !important
}

html .nav-color-quaternary nav>ul>li>a {
	color: #383f48 !important
}

html .nav-color-quaternary:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #383f48 !important
}

html .nav-color-quaternary:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #383f48 !important
}

html .nav-color-dark nav>ul>li>a {
	color: #212529 !important
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #212529 !important
}

html .nav-color-dark:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #212529 !important
}

html .nav-color-light nav>ul>li>a {
	color: #fff !important
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav>ul>li>a:before {
	background-color: #fff !important
}

html .nav-color-light:not(.header-nav-main-dropdown-arrow) nav>ul>li ul {
	border-top-color: #fff !important
}

.nav-pills>li.active>a,
.nav-pills .nav-link.active {
	background-color: #1c5fa8
}

.nav-pills>li.active>a:hover,
.nav-pills .nav-link.active:hover,
.nav-pills>li.active>a:focus,
.nav-pills .nav-link.active:focus {
	background-color: #1c5fa8
}

.nav-active-style-1>li>a:hover,
.nav-active-style-1>li>a:focus,
.nav-active-style-1>li>a.active {
	border-bottom-color: #1c5fa8
}

html .nav-pills-primary a {
	color: #1c5fa8
}

html .nav-pills-primary a:hover {
	color: #206bbe
}

html .nav-pills-primary a:focus {
	color: #206bbe
}

html .nav-pills-primary a:active {
	color: #185392
}

html .nav-pills-primary .nav-link.active,
html .nav-pills-primary>li.active>a {
	background-color: #1c5fa8
}

html .nav-pills-primary .nav-link.active:hover,
html .nav-pills-primary>li.active>a:hover,
html .nav-pills-primary .nav-link.active:focus,
html .nav-pills-primary>li.active>a:focus {
	background-color: #1c5fa8
}

html .nav-pills-secondary a {
	color: #e36159
}

html .nav-pills-secondary a:hover {
	color: #e7766f
}

html .nav-pills-secondary a:focus {
	color: #e7766f
}

html .nav-pills-secondary a:active {
	color: #df4c43
}

html .nav-pills-secondary .nav-link.active,
html .nav-pills-secondary>li.active>a {
	background-color: #e36159
}

html .nav-pills-secondary .nav-link.active:hover,
html .nav-pills-secondary>li.active>a:hover,
html .nav-pills-secondary .nav-link.active:focus,
html .nav-pills-secondary>li.active>a:focus {
	background-color: #e36159
}

html .nav-pills-tertiary a {
	color: #2baab1
}

html .nav-pills-tertiary a:hover {
	color: #30bec6
}

html .nav-pills-tertiary a:focus {
	color: #30bec6
}

html .nav-pills-tertiary a:active {
	color: #26969c
}

html .nav-pills-tertiary .nav-link.active,
html .nav-pills-tertiary>li.active>a {
	background-color: #2baab1
}

html .nav-pills-tertiary .nav-link.active:hover,
html .nav-pills-tertiary>li.active>a:hover,
html .nav-pills-tertiary .nav-link.active:focus,
html .nav-pills-tertiary>li.active>a:focus {
	background-color: #2baab1
}

html .nav-pills-quaternary a {
	color: #383f48
}

html .nav-pills-quaternary a:hover {
	color: #434c56
}

html .nav-pills-quaternary a:focus {
	color: #434c56
}

html .nav-pills-quaternary a:active {
	color: #2d323a
}

html .nav-pills-quaternary .nav-link.active,
html .nav-pills-quaternary>li.active>a {
	background-color: #383f48
}

html .nav-pills-quaternary .nav-link.active:hover,
html .nav-pills-quaternary>li.active>a:hover,
html .nav-pills-quaternary .nav-link.active:focus,
html .nav-pills-quaternary>li.active>a:focus {
	background-color: #383f48
}

html .nav-pills-dark a {
	color: #212529
}

html .nav-pills-dark a:hover {
	color: #2c3237
}

html .nav-pills-dark a:focus {
	color: #2c3237
}

html .nav-pills-dark a:active {
	color: #16181b
}

html .nav-pills-dark .nav-link.active,
html .nav-pills-dark>li.active>a {
	background-color: #212529
}

html .nav-pills-dark .nav-link.active:hover,
html .nav-pills-dark>li.active>a:hover,
html .nav-pills-dark .nav-link.active:focus,
html .nav-pills-dark>li.active>a:focus {
	background-color: #212529
}

html .nav-pills-light a {
	color: #fff
}

html .nav-pills-light a:hover {
	color: #fff
}

html .nav-pills-light a:focus {
	color: #fff
}

html .nav-pills-light a:active {
	color: #f2f2f2
}

html .nav-pills-light .nav-link.active,
html .nav-pills-light>li.active>a {
	background-color: #fff
}

html .nav-pills-light .nav-link.active:hover,
html .nav-pills-light>li.active>a:hover,
html .nav-pills-light .nav-link.active:focus,
html .nav-pills-light>li.active>a:focus {
	background-color: #fff
}

.nav-link {
	color: #1c5fa8
}

.nav-link:hover {
	color: #206bbe
}

.nav-link:focus {
	color: #206bbe
}

.nav-link:active {
	color: #185392
}

.section-scroll-dots-navigation-colored>ul>li.active>a:before {
	background: #1c5fa8
}

.sort-source-wrapper .nav>li.active>a {
	color: #1c5fa8
}

.sort-source-wrapper .nav>li.active>a:hover,
.sort-source-wrapper .nav>li.active>a:focus {
	color: #1c5fa8
}

.sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #1c5fa8
}

.sort-source.sort-source-style-3>li.active>a {
	border-bottom-color: #1c5fa8 !important;
	color: #1c5fa8 !important
}

html .badge-primary {
	background-color: #1c5fa8
}

html .badge-secondary {
	background-color: #e36159
}

html .badge-tertiary {
	background-color: #2baab1
}

html .badge-quaternary {
	background-color: #383f48
}

html .badge-dark {
	background-color: #212529
}

html .badge-light {
	background-color: #fff
}

html .overlay-color-primary:not(.no-skin):before {
	background-color: #1c5fa8 !important
}

html .overlay-color-secondary:not(.no-skin):before {
	background-color: #e36159 !important
}

html .overlay-color-tertiary:not(.no-skin):before {
	background-color: #2baab1 !important
}

html .overlay-color-quaternary:not(.no-skin):before {
	background-color: #383f48 !important
}

html .overlay-color-dark:not(.no-skin):before {
	background-color: #212529 !important
}

html .overlay-color-light:not(.no-skin):before {
	background-color: #fff !important
}

.overlay-gradient:before {
	background-color: #2baab1 !important;
	background-image: linear-gradient(to right, #2baab1 0%, #383f48 100%) !important
}

.btn-link {
	color: #1c5fa8
}

.btn-link:hover {
	color: #206bbe
}

.btn-link:active {
	color: #185392
}

html .btn-primary {
	background-color: #1c5fa8;
	border-color: #1c5fa8 #1c5fa8 #15467c;
	color: #fff;
	--color: #1c5fa8;
	--hover: #2172c9;
	--disabled: #1c5fa8;
	--active: #174c87
}

html .btn-primary:hover,
html .btn-primary.hover {
	border-color: #2378d4 #2378d4 #1c5fa8;
	color: #fff
}

html .btn-primary:hover:not(.bg-transparent),
html .btn-primary.hover:not(.bg-transparent) {
	background-color: #2172c9
}

html .btn-primary:focus,
html .btn-primary.focus {
	border-color: #15467c #15467c #0d2e51;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

html .btn-primary:focus:not(.bg-transparent),
html .btn-primary.focus:not(.bg-transparent) {
	background-color: #174c87
}

html .btn-primary.disabled,
html .btn-primary:disabled {
	border-color: #1c5fa8 #1c5fa8 #15467c
}

html .btn-primary.disabled:not(.bg-transparent),
html .btn-primary:disabled:not(.bg-transparent) {
	background-color: #1c5fa8
}

html .btn-primary:active,
html .btn-primary.active {
	border-color: #15467c #15467c #0d2e51 !important
}

html .btn-primary:active:not(.bg-transparent),
html .btn-primary.active:not(.bg-transparent) {
	background-color: #174c87 !important;
	background-image: none !important
}

html .btn-primary-scale-2 {
	background-color: #15467c;
	border-color: #15467c #15467c #0d2e51;
	color: #fff
}

html .btn-primary-scale-2:hover,
html .btn-primary-scale-2.hover {
	border-color: #1c5fa8 #1c5fa8 #15467c;
	color: #fff
}

html .btn-primary-scale-2:hover:not(.bg-transparent),
html .btn-primary-scale-2.hover:not(.bg-transparent) {
	background-color: #1a599d
}

html .btn-primary-scale-2:focus,
html .btn-primary-scale-2.focus {
	border-color: #0d2e51 #0d2e51 #061525;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(21, 70, 124, .5)
}

html .btn-primary-scale-2:focus:not(.bg-transparent),
html .btn-primary-scale-2.focus:not(.bg-transparent) {
	background-color: #0f345c
}

html .btn-primary-scale-2.disabled,
html .btn-primary-scale-2:disabled {
	border-color: #15467c #15467c #0d2e51
}

html .btn-primary-scale-2.disabled:not(.bg-transparent),
html .btn-primary-scale-2:disabled:not(.bg-transparent) {
	background-color: #15467c
}

html .btn-primary-scale-2:active,
html .btn-primary-scale-2.active {
	border-color: #0d2e51 #0d2e51 #061525 !important
}

html .btn-primary-scale-2:active:not(.bg-transparent),
html .btn-primary-scale-2.active:not(.bg-transparent) {
	background-color: #0f345c !important;
	background-image: none !important
}

html .show>.btn-primary.dropdown-toggle,
html .show>.btn-primary-scale-2.dropdown-toggle {
	background-color: #174c87 !important;
	background-image: none !important;
	border-color: #15467c #15467c #0d2e51 !important
}

html .btn-check:active+.btn-primary,
html .btn-check:checked+.btn-primary,
html .btn-check:focus+.btn-primary {
	background-color: #185392;
	border-color: #1c5fa8 #1c5fa8 #185392;
	color: #fff
}

html .btn-secondary {
	background-color: #e36159;
	border-color: #e36159 #e36159 #dc372d;
	color: #fff;
	--color: #e36159;
	--hover: #e9807a;
	--disabled: #e36159;
	--active: #dd4238
}

html .btn-secondary:hover,
html .btn-secondary.hover {
	border-color: #ea8b85 #ea8b85 #e36159;
	color: #fff
}

html .btn-secondary:hover:not(.bg-transparent),
html .btn-secondary.hover:not(.bg-transparent) {
	background-color: #e9807a
}

html .btn-secondary:focus,
html .btn-secondary.focus {
	border-color: #dc372d #dc372d #b7281f;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(227, 97, 89, .5)
}

html .btn-secondary:focus:not(.bg-transparent),
html .btn-secondary.focus:not(.bg-transparent) {
	background-color: #dd4238
}

html .btn-secondary.disabled,
html .btn-secondary:disabled {
	border-color: #e36159 #e36159 #dc372d
}

html .btn-secondary.disabled:not(.bg-transparent),
html .btn-secondary:disabled:not(.bg-transparent) {
	background-color: #e36159
}

html .btn-secondary:active,
html .btn-secondary.active {
	border-color: #dc372d #dc372d #b7281f !important
}

html .btn-secondary:active:not(.bg-transparent),
html .btn-secondary.active:not(.bg-transparent) {
	background-color: #dd4238 !important;
	background-image: none !important
}

html .btn-secondary-scale-2 {
	background-color: #dc372d;
	border-color: #dc372d #dc372d #b7281f;
	color: #fff
}

html .btn-secondary-scale-2:hover,
html .btn-secondary-scale-2.hover {
	border-color: #e36159 #e36159 #dc372d;
	color: #fff
}

html .btn-secondary-scale-2:hover:not(.bg-transparent),
html .btn-secondary-scale-2.hover:not(.bg-transparent) {
	background-color: #e1574e
}

html .btn-secondary-scale-2:focus,
html .btn-secondary-scale-2.focus {
	border-color: #b7281f #b7281f #8b1e18;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(220, 55, 45, .5)
}

html .btn-secondary-scale-2:focus:not(.bg-transparent),
html .btn-secondary-scale-2.focus:not(.bg-transparent) {
	background-color: #c22a21
}

html .btn-secondary-scale-2.disabled,
html .btn-secondary-scale-2:disabled {
	border-color: #dc372d #dc372d #b7281f
}

html .btn-secondary-scale-2.disabled:not(.bg-transparent),
html .btn-secondary-scale-2:disabled:not(.bg-transparent) {
	background-color: #dc372d
}

html .btn-secondary-scale-2:active,
html .btn-secondary-scale-2.active {
	border-color: #b7281f #b7281f #8b1e18 !important
}

html .btn-secondary-scale-2:active:not(.bg-transparent),
html .btn-secondary-scale-2.active:not(.bg-transparent) {
	background-color: #c22a21 !important;
	background-image: none !important
}

html .show>.btn-secondary.dropdown-toggle,
html .show>.btn-secondary-scale-2.dropdown-toggle {
	background-color: #dd4238 !important;
	background-image: none !important;
	border-color: #dc372d #dc372d #b7281f !important
}

html .btn-check:active+.btn-secondary,
html .btn-check:checked+.btn-secondary,
html .btn-check:focus+.btn-secondary {
	background-color: #df4c43;
	border-color: #e36159 #e36159 #df4c43;
	color: #fff
}

html .btn-tertiary {
	background-color: #2baab1;
	border-color: #2baab1 #2baab1 #218388;
	color: #fff;
	--color: #2baab1;
	--hover: #34c6ce;
	--disabled: #2baab1;
	--active: #248c92
}

html .btn-tertiary:hover,
html .btn-tertiary.hover {
	border-color: #3fc9d0 #3fc9d0 #2baab1;
	color: #fff
}

html .btn-tertiary:hover:not(.bg-transparent),
html .btn-tertiary.hover:not(.bg-transparent) {
	background-color: #34c6ce
}

html .btn-tertiary:focus,
html .btn-tertiary.focus {
	border-color: #218388 #218388 #175b5f;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(43, 170, 177, .5)
}

html .btn-tertiary:focus:not(.bg-transparent),
html .btn-tertiary.focus:not(.bg-transparent) {
	background-color: #248c92
}

html .btn-tertiary.disabled,
html .btn-tertiary:disabled {
	border-color: #2baab1 #2baab1 #218388
}

html .btn-tertiary.disabled:not(.bg-transparent),
html .btn-tertiary:disabled:not(.bg-transparent) {
	background-color: #2baab1
}

html .btn-tertiary:active,
html .btn-tertiary.active {
	border-color: #218388 #218388 #175b5f !important
}

html .btn-tertiary:active:not(.bg-transparent),
html .btn-tertiary.active:not(.bg-transparent) {
	background-color: #248c92 !important;
	background-image: none !important
}

html .btn-tertiary-scale-2 {
	background-color: #218388;
	border-color: #218388 #218388 #175b5f;
	color: #fff
}

html .btn-tertiary-scale-2:hover,
html .btn-tertiary-scale-2.hover {
	border-color: #2baab1 #2baab1 #218388;
	color: #fff
}

html .btn-tertiary-scale-2:hover:not(.bg-transparent),
html .btn-tertiary-scale-2.hover:not(.bg-transparent) {
	background-color: #29a0a7
}

html .btn-tertiary-scale-2:focus,
html .btn-tertiary-scale-2.focus {
	border-color: #175b5f #175b5f #0d3436;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(33, 131, 136, .5)
}

html .btn-tertiary-scale-2:focus:not(.bg-transparent),
html .btn-tertiary-scale-2.focus:not(.bg-transparent) {
	background-color: #1a6569
}

html .btn-tertiary-scale-2.disabled,
html .btn-tertiary-scale-2:disabled {
	border-color: #218388 #218388 #175b5f
}

html .btn-tertiary-scale-2.disabled:not(.bg-transparent),
html .btn-tertiary-scale-2:disabled:not(.bg-transparent) {
	background-color: #218388
}

html .btn-tertiary-scale-2:active,
html .btn-tertiary-scale-2.active {
	border-color: #175b5f #175b5f #0d3436 !important
}

html .btn-tertiary-scale-2:active:not(.bg-transparent),
html .btn-tertiary-scale-2.active:not(.bg-transparent) {
	background-color: #1a6569 !important;
	background-image: none !important
}

html .show>.btn-tertiary.dropdown-toggle,
html .show>.btn-tertiary-scale-2.dropdown-toggle {
	background-color: #248c92 !important;
	background-image: none !important;
	border-color: #218388 #218388 #175b5f !important
}

html .btn-check:active+.btn-tertiary,
html .btn-check:checked+.btn-tertiary,
html .btn-check:focus+.btn-tertiary {
	background-color: #26969c;
	border-color: #2baab1 #2baab1 #26969c;
	color: #fff
}

html .btn-quaternary {
	background-color: #383f48;
	border-color: #383f48 #383f48 #22262b;
	color: #fff;
	--color: #383f48;
	--hover: #49525e;
	--disabled: #383f48;
	--active: #272c32
}

html .btn-quaternary:hover,
html .btn-quaternary.hover {
	border-color: #4e5865 #4e5865 #383f48;
	color: #fff
}

html .btn-quaternary:hover:not(.bg-transparent),
html .btn-quaternary.hover:not(.bg-transparent) {
	background-color: #49525e
}

html .btn-quaternary:focus,
html .btn-quaternary.focus {
	border-color: #22262b #22262b #0b0d0f;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(56, 63, 72, .5)
}

html .btn-quaternary:focus:not(.bg-transparent),
html .btn-quaternary.focus:not(.bg-transparent) {
	background-color: #272c32
}

html .btn-quaternary.disabled,
html .btn-quaternary:disabled {
	border-color: #383f48 #383f48 #22262b
}

html .btn-quaternary.disabled:not(.bg-transparent),
html .btn-quaternary:disabled:not(.bg-transparent) {
	background-color: #383f48
}

html .btn-quaternary:active,
html .btn-quaternary.active {
	border-color: #22262b #22262b #0b0d0f !important
}

html .btn-quaternary:active:not(.bg-transparent),
html .btn-quaternary.active:not(.bg-transparent) {
	background-color: #272c32 !important;
	background-image: none !important
}

html .btn-quaternary-scale-2 {
	background-color: #22262b;
	border-color: #22262b #22262b #0b0d0f;
	color: #fff
}

html .btn-quaternary-scale-2:hover,
html .btn-quaternary-scale-2.hover {
	border-color: #383f48 #383f48 #22262b;
	color: #fff
}

html .btn-quaternary-scale-2:hover:not(.bg-transparent),
html .btn-quaternary-scale-2.hover:not(.bg-transparent) {
	background-color: #323941
}

html .btn-quaternary-scale-2:focus,
html .btn-quaternary-scale-2.focus {
	border-color: #0b0d0f #0b0d0f #000;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(34, 38, 43, .5)
}

html .btn-quaternary-scale-2:focus:not(.bg-transparent),
html .btn-quaternary-scale-2.focus:not(.bg-transparent) {
	background-color: #111316
}

html .btn-quaternary-scale-2.disabled,
html .btn-quaternary-scale-2:disabled {
	border-color: #22262b #22262b #0b0d0f
}

html .btn-quaternary-scale-2.disabled:not(.bg-transparent),
html .btn-quaternary-scale-2:disabled:not(.bg-transparent) {
	background-color: #22262b
}

html .btn-quaternary-scale-2:active,
html .btn-quaternary-scale-2.active {
	border-color: #0b0d0f #0b0d0f #000 !important
}

html .btn-quaternary-scale-2:active:not(.bg-transparent),
html .btn-quaternary-scale-2.active:not(.bg-transparent) {
	background-color: #111316 !important;
	background-image: none !important
}

html .show>.btn-quaternary.dropdown-toggle,
html .show>.btn-quaternary-scale-2.dropdown-toggle {
	background-color: #272c32 !important;
	background-image: none !important;
	border-color: #22262b #22262b #0b0d0f !important
}

html .btn-check:active+.btn-quaternary,
html .btn-check:checked+.btn-quaternary,
html .btn-check:focus+.btn-quaternary {
	background-color: #2d323a;
	border-color: #383f48 #383f48 #2d323a;
	color: #fff
}

html .btn-dark {
	background-color: #212529;
	border-color: #212529 #212529 #0a0c0d;
	color: #fff;
	--color: #212529;
	--hover: #32383e;
	--disabled: #212529;
	--active: #101214
}

html .btn-dark:hover,
html .btn-dark.hover {
	border-color: #383f45 #383f45 #212529;
	color: #fff
}

html .btn-dark:hover:not(.bg-transparent),
html .btn-dark.hover:not(.bg-transparent) {
	background-color: #32383e
}

html .btn-dark:focus,
html .btn-dark.focus {
	border-color: #0a0c0d #0a0c0d #000;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(33, 37, 41, .5)
}

html .btn-dark:focus:not(.bg-transparent),
html .btn-dark.focus:not(.bg-transparent) {
	background-color: #101214
}

html .btn-dark.disabled,
html .btn-dark:disabled {
	border-color: #212529 #212529 #0a0c0d
}

html .btn-dark.disabled:not(.bg-transparent),
html .btn-dark:disabled:not(.bg-transparent) {
	background-color: #212529
}

html .btn-dark:active,
html .btn-dark.active {
	border-color: #0a0c0d #0a0c0d #000 !important
}

html .btn-dark:active:not(.bg-transparent),
html .btn-dark.active:not(.bg-transparent) {
	background-color: #101214 !important;
	background-image: none !important
}

html .btn-dark-scale-2 {
	background-color: #0a0c0d;
	border-color: #0a0c0d #0a0c0d #000;
	color: #fff
}

html .btn-dark-scale-2:hover,
html .btn-dark-scale-2.hover {
	border-color: #212529 #212529 #0a0c0d;
	color: #fff
}

html .btn-dark-scale-2:hover:not(.bg-transparent),
html .btn-dark-scale-2.hover:not(.bg-transparent) {
	background-color: #1b1f22
}

html .btn-dark-scale-2:focus,
html .btn-dark-scale-2.focus {
	border-color: #000 #000 #000;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(10, 11, 13, .5)
}

html .btn-dark-scale-2:focus:not(.bg-transparent),
html .btn-dark-scale-2.focus:not(.bg-transparent) {
	background-color: #000
}

html .btn-dark-scale-2.disabled,
html .btn-dark-scale-2:disabled {
	border-color: #0a0c0d #0a0c0d #000
}

html .btn-dark-scale-2.disabled:not(.bg-transparent),
html .btn-dark-scale-2:disabled:not(.bg-transparent) {
	background-color: #0a0c0d
}

html .btn-dark-scale-2:active,
html .btn-dark-scale-2.active {
	border-color: #000 #000 #000 !important
}

html .btn-dark-scale-2:active:not(.bg-transparent),
html .btn-dark-scale-2.active:not(.bg-transparent) {
	background-color: #000 !important;
	background-image: none !important
}

html .show>.btn-dark.dropdown-toggle,
html .show>.btn-dark-scale-2.dropdown-toggle {
	background-color: #101214 !important;
	background-image: none !important;
	border-color: #0a0c0d #0a0c0d #000 !important
}

html .btn-check:active+.btn-dark,
html .btn-check:checked+.btn-dark,
html .btn-check:focus+.btn-dark {
	background-color: #16181b;
	border-color: #212529 #212529 #16181b;
	color: #fff
}

html .btn-light {
	background-color: #fff;
	border-color: #fff #fff #e6e6e6;
	color: #777;
	--color: #FFF;
	--hover: #ffffff;
	--disabled: #FFF;
	--active: #ececec
}

html .btn-light:hover,
html .btn-light.hover {
	border-color: #fff #fff #fff;
	color: #777
}

html .btn-light:hover:not(.bg-transparent),
html .btn-light.hover:not(.bg-transparent) {
	background-color: #fff
}

html .btn-light:focus,
html .btn-light.focus {
	border-color: #e6e6e6 #e6e6e6 #ccc;
	color: #777;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, .5)
}

html .btn-light:focus:not(.bg-transparent),
html .btn-light.focus:not(.bg-transparent) {
	background-color: #ececec
}

html .btn-light.disabled,
html .btn-light:disabled {
	border-color: #fff #fff #e6e6e6
}

html .btn-light.disabled:not(.bg-transparent),
html .btn-light:disabled:not(.bg-transparent) {
	background-color: #fff
}

html .btn-light:active,
html .btn-light.active {
	border-color: #e6e6e6 #e6e6e6 #ccc !important
}

html .btn-light:active:not(.bg-transparent),
html .btn-light.active:not(.bg-transparent) {
	background-color: #ececec !important;
	background-image: none !important
}

html .btn-light-scale-2 {
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #ccc;
	color: #777
}

html .btn-light-scale-2:hover,
html .btn-light-scale-2.hover {
	border-color: #fff #fff #e6e6e6;
	color: #777
}

html .btn-light-scale-2:hover:not(.bg-transparent),
html .btn-light-scale-2.hover:not(.bg-transparent) {
	background-color: #f9f9f9
}

html .btn-light-scale-2:focus,
html .btn-light-scale-2.focus {
	border-color: #ccc #ccc #b3b3b3;
	color: #777;
	box-shadow: 0 0 0 3px rgba(230, 230, 230, .5)
}

html .btn-light-scale-2:focus:not(.bg-transparent),
html .btn-light-scale-2.focus:not(.bg-transparent) {
	background-color: #d2d2d2
}

html .btn-light-scale-2.disabled,
html .btn-light-scale-2:disabled {
	border-color: #e6e6e6 #e6e6e6 #ccc
}

html .btn-light-scale-2.disabled:not(.bg-transparent),
html .btn-light-scale-2:disabled:not(.bg-transparent) {
	background-color: #e6e6e6
}

html .btn-light-scale-2:active,
html .btn-light-scale-2.active {
	border-color: #ccc #ccc #b3b3b3 !important
}

html .btn-light-scale-2:active:not(.bg-transparent),
html .btn-light-scale-2.active:not(.bg-transparent) {
	background-color: #d2d2d2 !important;
	background-image: none !important
}

html .show>.btn-light.dropdown-toggle,
html .show>.btn-light-scale-2.dropdown-toggle {
	background-color: #ececec !important;
	background-image: none !important;
	border-color: #e6e6e6 #e6e6e6 #ccc !important
}

html .btn-check:active+.btn-light,
html .btn-check:checked+.btn-light,
html .btn-check:focus+.btn-light {
	background-color: #f2f2f2;
	border-color: #fff #fff #f2f2f2;
	color: #777
}

html .btn-outline.btn-primary {
	color: #1c5fa8;
	background-color: transparent;
	background-image: none;
	border-color: #1c5fa8
}

html .btn-outline.btn-primary:hover,
html .btn-outline.btn-primary.hover {
	color: #fff;
	background-color: #1c5fa8;
	border-color: #1c5fa8
}

html .btn-outline.btn-primary:focus,
html .btn-outline.btn-primary.focus {
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

html .btn-outline.btn-primary.disabled,
html .btn-outline.btn-primary:disabled {
	color: #1c5fa8;
	background-color: transparent
}

html .btn-outline.btn-primary:active,
html .btn-outline.btn-primary.active {
	color: #fff !important;
	background-color: #1c5fa8 !important;
	border-color: #1c5fa8 !important
}

html .show>.btn-outline.btn-primary.dropdown-toggle {
	color: #fff !important;
	background-color: #1c5fa8 !important;
	border-color: #1c5fa8 !important
}

html .btn-outline.btn-secondary {
	color: #e36159;
	background-color: transparent;
	background-image: none;
	border-color: #e36159
}

html .btn-outline.btn-secondary:hover,
html .btn-outline.btn-secondary.hover {
	color: #fff;
	background-color: #e36159;
	border-color: #e36159
}

html .btn-outline.btn-secondary:focus,
html .btn-outline.btn-secondary.focus {
	box-shadow: 0 0 0 3px rgba(227, 97, 89, .5)
}

html .btn-outline.btn-secondary.disabled,
html .btn-outline.btn-secondary:disabled {
	color: #e36159;
	background-color: transparent
}

html .btn-outline.btn-secondary:active,
html .btn-outline.btn-secondary.active {
	color: #fff !important;
	background-color: #e36159 !important;
	border-color: #e36159 !important
}

html .show>.btn-outline.btn-secondary.dropdown-toggle {
	color: #fff !important;
	background-color: #e36159 !important;
	border-color: #e36159 !important
}

html .btn-outline.btn-tertiary {
	color: #2baab1;
	background-color: transparent;
	background-image: none;
	border-color: #2baab1
}

html .btn-outline.btn-tertiary:hover,
html .btn-outline.btn-tertiary.hover {
	color: #fff;
	background-color: #2baab1;
	border-color: #2baab1
}

html .btn-outline.btn-tertiary:focus,
html .btn-outline.btn-tertiary.focus {
	box-shadow: 0 0 0 3px rgba(43, 170, 177, .5)
}

html .btn-outline.btn-tertiary.disabled,
html .btn-outline.btn-tertiary:disabled {
	color: #2baab1;
	background-color: transparent
}

html .btn-outline.btn-tertiary:active,
html .btn-outline.btn-tertiary.active {
	color: #fff !important;
	background-color: #2baab1 !important;
	border-color: #2baab1 !important
}

html .show>.btn-outline.btn-tertiary.dropdown-toggle {
	color: #fff !important;
	background-color: #2baab1 !important;
	border-color: #2baab1 !important
}

html .btn-outline.btn-quaternary {
	color: #383f48;
	background-color: transparent;
	background-image: none;
	border-color: #383f48
}

html .btn-outline.btn-quaternary:hover,
html .btn-outline.btn-quaternary.hover {
	color: #fff;
	background-color: #383f48;
	border-color: #383f48
}

html .btn-outline.btn-quaternary:focus,
html .btn-outline.btn-quaternary.focus {
	box-shadow: 0 0 0 3px rgba(56, 63, 72, .5)
}

html .btn-outline.btn-quaternary.disabled,
html .btn-outline.btn-quaternary:disabled {
	color: #383f48;
	background-color: transparent
}

html .btn-outline.btn-quaternary:active,
html .btn-outline.btn-quaternary.active {
	color: #fff !important;
	background-color: #383f48 !important;
	border-color: #383f48 !important
}

html .show>.btn-outline.btn-quaternary.dropdown-toggle {
	color: #fff !important;
	background-color: #383f48 !important;
	border-color: #383f48 !important
}

html .btn-outline.btn-dark {
	color: #212529;
	background-color: transparent;
	background-image: none;
	border-color: #212529
}

html .btn-outline.btn-dark:hover,
html .btn-outline.btn-dark.hover {
	color: #fff;
	background-color: #212529;
	border-color: #212529
}

html .btn-outline.btn-dark:focus,
html .btn-outline.btn-dark.focus {
	box-shadow: 0 0 0 3px rgba(33, 37, 41, .5)
}

html .btn-outline.btn-dark.disabled,
html .btn-outline.btn-dark:disabled {
	color: #212529;
	background-color: transparent
}

html .btn-outline.btn-dark:active,
html .btn-outline.btn-dark.active {
	color: #fff !important;
	background-color: #212529 !important;
	border-color: #212529 !important
}

html .show>.btn-outline.btn-dark.dropdown-toggle {
	color: #fff !important;
	background-color: #212529 !important;
	border-color: #212529 !important
}

html .btn-outline.btn-light {
	color: #fff;
	background-color: transparent;
	background-image: none;
	border-color: #fff
}

html .btn-outline.btn-light:hover,
html .btn-outline.btn-light.hover {
	color: #777;
	background-color: #fff;
	border-color: #fff
}

html .btn-outline.btn-light:focus,
html .btn-outline.btn-light.focus {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, .5)
}

html .btn-outline.btn-light.disabled,
html .btn-outline.btn-light:disabled {
	color: #fff;
	background-color: transparent
}

html .btn-outline.btn-light:active,
html .btn-outline.btn-light.active {
	color: #777 !important;
	background-color: #fff !important;
	border-color: #fff !important
}

html .show>.btn-outline.btn-light.dropdown-toggle {
	color: #777 !important;
	background-color: #fff !important;
	border-color: #fff !important
}

html .btn-with-arrow.btn-primary {
	background-color: transparent;
	border-color: transparent;
	color: #1c5fa8
}

html .btn-with-arrow.btn-primary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #1c5fa8 !important
}

html .btn-with-arrow.btn-primary span {
	background-color: #1c5fa8;
	box-shadow: 2px 3px 18px -3px #1c5fa8
}

html .btn-with-arrow.btn-primary.btn-outline {
	border-color: #1c5fa8 !important
}

html .btn-with-arrow.btn-primary.btn-outline:hover span {
	background-color: #206bbe !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-primary span {
	background-color: #185392
}

html .btn-with-arrow.btn-secondary {
	background-color: transparent;
	border-color: transparent;
	color: #e36159
}

html .btn-with-arrow.btn-secondary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #e36159 !important
}

html .btn-with-arrow.btn-secondary span {
	background-color: #e36159;
	box-shadow: 2px 3px 18px -3px #e36159
}

html .btn-with-arrow.btn-secondary.btn-outline {
	border-color: #e36159 !important
}

html .btn-with-arrow.btn-secondary.btn-outline:hover span {
	background-color: #e7766f !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-secondary span {
	background-color: #df4c43
}

html .btn-with-arrow.btn-tertiary {
	background-color: transparent;
	border-color: transparent;
	color: #2baab1
}

html .btn-with-arrow.btn-tertiary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #2baab1 !important
}

html .btn-with-arrow.btn-tertiary span {
	background-color: #2baab1;
	box-shadow: 2px 3px 18px -3px #2baab1
}

html .btn-with-arrow.btn-tertiary.btn-outline {
	border-color: #2baab1 !important
}

html .btn-with-arrow.btn-tertiary.btn-outline:hover span {
	background-color: #30bec6 !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-tertiary span {
	background-color: #26969c
}

html .btn-with-arrow.btn-quaternary {
	background-color: transparent;
	border-color: transparent;
	color: #383f48
}

html .btn-with-arrow.btn-quaternary:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #383f48 !important
}

html .btn-with-arrow.btn-quaternary span {
	background-color: #383f48;
	box-shadow: 2px 3px 18px -3px #383f48
}

html .btn-with-arrow.btn-quaternary.btn-outline {
	border-color: #383f48 !important
}

html .btn-with-arrow.btn-quaternary.btn-outline:hover span {
	background-color: #434c56 !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-quaternary span {
	background-color: #2d323a
}

html .btn-with-arrow.btn-dark {
	background-color: transparent;
	border-color: transparent;
	color: #212529
}

html .btn-with-arrow.btn-dark:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #212529 !important
}

html .btn-with-arrow.btn-dark span {
	background-color: #212529;
	box-shadow: 2px 3px 18px -3px #212529
}

html .btn-with-arrow.btn-dark.btn-outline {
	border-color: #212529 !important
}

html .btn-with-arrow.btn-dark.btn-outline:hover span {
	background-color: #2c3237 !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-dark span {
	background-color: #16181b
}

html .btn-with-arrow.btn-light {
	background-color: transparent;
	border-color: transparent;
	color: #fff
}

html .btn-with-arrow.btn-light:active {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #fff !important
}

html .btn-with-arrow.btn-light span {
	background-color: #fff;
	box-shadow: 2px 3px 18px -3px #fff
}

html .btn-with-arrow.btn-light.btn-outline {
	border-color: #fff !important
}

html .btn-with-arrow.btn-light.btn-outline:hover span {
	background-color: #fff !important;
	box-shadow: none
}

html .btn-with-arrow-solid.btn-light span {
	background-color: #f2f2f2
}

.btn-gradient:not(.btn-outline) {
	background: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%) !important;
	color: #fff
}

.btn-gradient:not(.btn-outline):hover,
.btn-gradient:not(.btn-outline).hover {
	background: linear-gradient(135deg, #2172c9 0%, #e9807a 80%) !important;
	color: #fff
}

.btn-gradient:not(.btn-outline):focus,
.btn-gradient:not(.btn-outline).focus {
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.btn-gradient:not(.btn-outline).disabled,
.btn-gradient:not(.btn-outline):disabled {
	background: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%) !important
}

.btn-gradient:not(.btn-outline):active,
.btn-gradient:not(.btn-outline).active {
	background: linear-gradient(135deg, #174c87 0%, #dd4238 80%) !important;
	color: #fff !important
}

.btn-gradient.btn-outline {
	-o-border-image: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%);
	border-image: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%);
	color: #1c5fa8
}

.btn-gradient.btn-outline:hover,
.btn-gradient.btn-outline.hover {
	-o-border-image: linear-gradient(135deg, #2172c9 0%, #e9807a 80%);
	border-image: linear-gradient(135deg, #2172c9 0%, #e9807a 80%);
	color: #2172c9
}

.btn-gradient.btn-outline:focus,
.btn-gradient.btn-outline.focus {
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.btn-gradient.btn-outline.disabled,
.btn-gradient.btn-outline:disabled {
	-o-border-image: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%);
	border-image: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%)
}

.btn-gradient.btn-outline:active,
.btn-gradient.btn-outline.active {
	-o-border-image: linear-gradient(135deg, #174c87 0%, #dd4238 80%);
	border-image: linear-gradient(135deg, #174c87 0%, #dd4238 80%);
	color: #174c87
}

.btn-gradient-primary:not(.btn-outline) {
	background: linear-gradient(135deg, #1f69b9 0%, #1b5a9f 80%) !important;
	color: #fff
}

.btn-gradient-primary:not(.btn-outline):hover,
.btn-gradient-primary:not(.btn-outline).hover {
	background: linear-gradient(135deg, #1e66b5 0%, #2172c9 80%) !important;
	color: #fff
}

.btn-gradient-primary:not(.btn-outline):focus,
.btn-gradient-primary:not(.btn-outline).focus {
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.btn-gradient-primary:not(.btn-outline).disabled,
.btn-gradient-primary:not(.btn-outline):disabled {
	background: linear-gradient(135deg, #1f69b9 80%, #1b5a9f 0%) !important
}

.btn-gradient-primary:not(.btn-outline):active,
.btn-gradient-primary:not(.btn-outline).active {
	background: linear-gradient(135deg, #1e66b5 0%, #2172c9 80%) !important;
	color: #fff !important
}

.btn-gradient-primary.btn-outline {
	-o-border-image: linear-gradient(135deg, #1c5fa8 0%, #2273cb 80%);
	border-image: linear-gradient(135deg, #1c5fa8 0%, #2273cb 80%);
	color: #1c5fa8
}

.btn-gradient-primary.btn-outline:hover,
.btn-gradient-primary.btn-outline.hover {
	-o-border-image: linear-gradient(135deg, #2172c9 0%, #2172c9 80%);
	border-image: linear-gradient(135deg, #2172c9 0%, #2172c9 80%);
	color: #2172c9
}

.btn-gradient-primary.btn-outline:focus,
.btn-gradient-primary.btn-outline.focus {
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.btn-gradient-primary.btn-outline.disabled,
.btn-gradient-primary.btn-outline:disabled {
	-o-border-image: linear-gradient(135deg, #1c5fa8 0%, #2273cb 80%);
	border-image: linear-gradient(135deg, #1c5fa8 0%, #2273cb 80%)
}

.btn-gradient-primary.btn-outline:active,
.btn-gradient-primary.btn-outline.active {
	-o-border-image: linear-gradient(135deg, #2172c9 0%, #2172c9 80%);
	border-image: linear-gradient(135deg, #2172c9 0%, #2172c9 80%);
	color: #2172c9
}

.pagination>a,
.pagination>a:hover,
.pagination>a:focus,
.pagination>li>a,
.pagination>li>span,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
	color: #1c5fa8
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.pagination .page-item.active .page-link {
	background-color: #1c5fa8 !important;
	border-color: #1c5fa8
}

body.dark .pagination>li>a,
body.dark .pagination>li>span,
body.dark .pagination>li>a:hover,
body.dark .pagination>li>span:hover,
body.dark .pagination>li>a:focus,
body.dark .pagination>li>span:focus {
	color: #1c5fa8
}

body.dark .pagination>.active>a,
body.dark .pagination>.active>span,
body.dark .pagination>.active>a:hover,
body.dark .pagination>.active>span:hover,
body.dark .pagination>.active>a:focus,
body.dark .pagination>.active>span:focus {
	background-color: #1c5fa8;
	border-color: #1c5fa8
}

.pagination>.active>a,
body.dark .pagination>.active>a {
	background-color: #1c5fa8;
	border-color: #1c5fa8 #1c5fa8 #15467c;
	color: #fff
}

.pagination>.active>a:hover,
body.dark .pagination>.active>a:hover,
.pagination>.active>a.hover,
body.dark .pagination>.active>a.hover {
	border-color: #2378d4 #2378d4 #1c5fa8;
	color: #fff
}

.pagination>.active>a:hover:not(.bg-transparent),
body.dark .pagination>.active>a:hover:not(.bg-transparent),
.pagination>.active>a.hover:not(.bg-transparent),
body.dark .pagination>.active>a.hover:not(.bg-transparent) {
	background-color: #2172c9
}

.pagination>.active>a:focus,
body.dark .pagination>.active>a:focus,
.pagination>.active>a.focus,
body.dark .pagination>.active>a.focus {
	border-color: #15467c #15467c #0d2e51;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.pagination>.active>a:focus:not(.bg-transparent),
body.dark .pagination>.active>a:focus:not(.bg-transparent),
.pagination>.active>a.focus:not(.bg-transparent),
body.dark .pagination>.active>a.focus:not(.bg-transparent) {
	background-color: #174c87
}

.pagination>.active>a.disabled,
body.dark .pagination>.active>a.disabled,
.pagination>.active>a:disabled,
body.dark .pagination>.active>a:disabled {
	border-color: #1c5fa8 #1c5fa8 #15467c
}

.pagination>.active>a.disabled:not(.bg-transparent),
body.dark .pagination>.active>a.disabled:not(.bg-transparent),
.pagination>.active>a:disabled:not(.bg-transparent),
body.dark .pagination>.active>a:disabled:not(.bg-transparent) {
	background-color: #1c5fa8
}

.pagination>.active>a:active,
body.dark .pagination>.active>a:active,
.pagination>.active>a.active,
body.dark .pagination>.active>a.active {
	border-color: #15467c #15467c #0d2e51 !important
}

.pagination>.active>a:active:not(.bg-transparent),
body.dark .pagination>.active>a:active:not(.bg-transparent),
.pagination>.active>a.active:not(.bg-transparent),
body.dark .pagination>.active>a.active:not(.bg-transparent) {
	background-color: #174c87 !important;
	background-image: none !important
}

.custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: #1c5fa8
}

.custom-checkbox-switch:checked {
	background-color: #1c5fa8;
	border-color: #1c5fa8
}

html .alert-primary {
	background-color: #1c5fa8;
	border-color: #1a589b;
	color: #fff
}

html .alert-primary .alert-link {
	color: #fff
}

html .alert-secondary {
	background-color: #e36159;
	border-color: #e1554c;
	color: #fff
}

html .alert-secondary .alert-link {
	color: #fff
}

html .alert-tertiary {
	background-color: #2baab1;
	border-color: #289ea5;
	color: #fff
}

html .alert-tertiary .alert-link {
	color: #fff
}

html .alert-quaternary {
	background-color: #383f48;
	border-color: #31373f;
	color: #fff
}

html .alert-quaternary .alert-link {
	color: #fff
}

html .alert-dark {
	background-color: #212529;
	border-color: #1a1d21;
	color: #fff
}

html .alert-dark .alert-link {
	color: #fff
}

html .alert-light {
	background-color: #fff;
	border-color: #f7f7f7;
	color: #777
}

html .alert-light .alert-link {
	color: #777
}

html .progress-bar-primary {
	background-color: #1c5fa8
}

html .progress-bar-secondary {
	background-color: #e36159
}

html .progress-bar-tertiary {
	background-color: #2baab1
}

html .progress-bar-quaternary {
	background-color: #383f48
}

html .progress-bar-dark {
	background-color: #212529
}

html .progress-bar-light {
	background-color: #fff
}

html .word-rotator.loading-bar-primary .word-rotator-words::after {
	background-color: #1c5fa8
}

html .word-rotator.loading-bar-secondary .word-rotator-words::after {
	background-color: #e36159
}

html .word-rotator.loading-bar-tertiary .word-rotator-words::after {
	background-color: #2baab1
}

html .word-rotator.loading-bar-quaternary .word-rotator-words::after {
	background-color: #383f48
}

html .word-rotator.loading-bar-dark .word-rotator-words::after {
	background-color: #212529
}

html .word-rotator.loading-bar-light .word-rotator-words::after {
	background-color: #fff
}

html .mark.mark-gradient.mark-gradient-primary::before {
	background: #1c5fa8;
	background: linear-gradient(90deg, #1c5fa8 0%, rgba(28, 95, 168, 0) 100%)
}

html .mark.mark-gradient.mark-gradient-secondary::before {
	background: #e36159;
	background: linear-gradient(90deg, #e36159 0%, rgba(227, 97, 89, 0) 100%)
}

html .mark.mark-gradient.mark-gradient-tertiary::before {
	background: #2baab1;
	background: linear-gradient(90deg, #2baab1 0%, rgba(43, 170, 177, 0) 100%)
}

html .mark.mark-gradient.mark-gradient-quaternary::before {
	background: #383f48;
	background: linear-gradient(90deg, #383f48 0%, rgba(56, 63, 72, 0) 100%)
}

html .mark.mark-gradient.mark-gradient-dark::before {
	background: #212529;
	background: linear-gradient(90deg, #212529 0%, rgba(33, 37, 41, 0) 100%)
}

html .mark.mark-gradient.mark-gradient-light::before {
	background: #fff;
	background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)
}

.process .process-step-circle {
	border-color: #1c5fa8
}

.process .process-step:hover .process-step-circle {
	background-color: #1c5fa8
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
	color: #fff !important
}

.process.process-shapes .process-step-circle .process-step-circle-content,
.process.process-shapes .process-step-circle:before,
.process.process-shapes .process-step-circle:after {
	background-color: #1c5fa8
}

.process-connecting-line .connecting-line {
	background: #1c5fa8
}

html .rating-primary .filled-stars {
	color: #1c5fa8
}

html .rating-secondary .filled-stars {
	color: #e36159
}

html .rating-tertiary .filled-stars {
	color: #2baab1
}

html .rating-quaternary .filled-stars {
	color: #383f48
}

html .rating-dark .filled-stars {
	color: #212529
}

html .rating-light .filled-stars {
	color: #fff
}

html section.section-primary {
	background-color: #1c5fa8 !important;
	border-color: #185392 !important
}

html section.section-primary h1,
html section.section-primary h2,
html section.section-primary h3,
html section.section-primary h4,
html section.section-primary h5,
html section.section-primary h6 {
	color: #fff
}

html section.section-primary p {
	color: #e6e6e6
}

html section.section-primary-scale-2 {
	background-color: #15467c !important;
	border-color: #113a66 !important
}

html section.section-primary-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #15467c
}

html section.section-secondary {
	background-color: #e36159 !important;
	border-color: #df4c43 !important
}

html section.section-secondary h1,
html section.section-secondary h2,
html section.section-secondary h3,
html section.section-secondary h4,
html section.section-secondary h5,
html section.section-secondary h6 {
	color: #fff
}

html section.section-secondary p {
	color: #e6e6e6
}

html section.section-secondary-scale-2 {
	background-color: #dc372d !important;
	border-color: #cd2c23 !important
}

html section.section-secondary-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #dc372d
}

html section.section-tertiary {
	background-color: #2baab1 !important;
	border-color: #26969c !important
}

html section.section-tertiary h1,
html section.section-tertiary h2,
html section.section-tertiary h3,
html section.section-tertiary h4,
html section.section-tertiary h5,
html section.section-tertiary h6 {
	color: #fff
}

html section.section-tertiary p {
	color: #e6e6e6
}

html section.section-tertiary-scale-2 {
	background-color: #218388 !important;
	border-color: #1c6f73 !important
}

html section.section-tertiary-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #218388
}

html section.section-quaternary {
	background-color: #383f48 !important;
	border-color: #2d323a !important
}

html section.section-quaternary h1,
html section.section-quaternary h2,
html section.section-quaternary h3,
html section.section-quaternary h4,
html section.section-quaternary h5,
html section.section-quaternary h6 {
	color: #fff
}

html section.section-quaternary p {
	color: #e6e6e6
}

html section.section-quaternary-scale-2 {
	background-color: #22262b !important;
	border-color: #17191d !important
}

html section.section-quaternary-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #22262b
}

html section.section-dark {
	background-color: #212529 !important;
	border-color: #16181b !important
}

html section.section-dark h1,
html section.section-dark h2,
html section.section-dark h3,
html section.section-dark h4,
html section.section-dark h5,
html section.section-dark h6 {
	color: #fff
}

html section.section-dark p {
	color: #e6e6e6
}

html section.section-dark-scale-2 {
	background-color: #0a0c0d !important;
	border-color: #000 !important
}

html section.section-dark-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #0a0c0d
}

html section.section-light {
	background-color: #fff !important;
	border-color: #f2f2f2 !important
}

html section.section-light h1,
html section.section-light h2,
html section.section-light h3,
html section.section-light h4,
html section.section-light h5,
html section.section-light h6 {
	color: #777
}

html section.section-light p {
	color: #5e5e5e
}

html section.section-light-scale-2 {
	background-color: #e6e6e6 !important;
	border-color: #d9d9d9 !important
}

html section.section-light-scale-2 .sort-source.sort-source-style-2>li.active>a:after {
	border-top-color: #e6e6e6
}

section.section.section-background-half-primary-half-secondary {
	background: linear-gradient(to right, #1c5fa8 50%, #e36159 50%)
}

section.section.section-overlay-opacity-gradient:before {
	background: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%)
}

html .bg-color-light-scale-1 {
	background-color: #f7f7f7 !important
}

html .section.bg-color-light-scale-1,
html .bg-color-light-scale-border-top-1 {
	border-top-color: #efefef !important
}

html .bg-color-light-scale-2 {
	background-color: #e8e8e8 !important
}

html .section.bg-color-light-scale-2,
html .bg-color-light-scale-border-top-2 {
	border-top-color: #e0e0e0 !important
}

html .bg-color-light-scale-3 {
	background-color: #e0e0e0 !important
}

html .section.bg-color-light-scale-3,
html .bg-color-light-scale-border-top-3 {
	border-top-color: #d8d8d8 !important
}

html .bg-color-light-scale-4 {
	background-color: #d8d8d8 !important
}

html .section.bg-color-light-scale-4,
html .bg-color-light-scale-border-top-4 {
	border-top-color: #d1d1d1 !important
}

html .bg-color-light-scale-5 {
	background-color: #d1d1d1 !important
}

html .section.bg-color-light-scale-5,
html .bg-color-light-scale-border-top-5 {
	border-top-color: #c9c9c9 !important
}

html .bg-color-light-scale-6 {
	background-color: #c9c9c9 !important
}

html .section.bg-color-light-scale-6,
html .bg-color-light-scale-border-top-6 {
	border-top-color: #c1c1c1 !important
}

html .bg-color-light-scale-7 {
	background-color: #c1c1c1 !important
}

html .section.bg-color-light-scale-7,
html .bg-color-light-scale-border-top-7 {
	border-top-color: #bababa !important
}

html .bg-color-light-scale-8 {
	background-color: #bababa !important
}

html .section.bg-color-light-scale-8,
html .bg-color-light-scale-border-top-8 {
	border-top-color: #b2b2b2 !important
}

html .bg-color-light-scale-9 {
	background-color: #b2b2b2 !important
}

html .section.bg-color-light-scale-9,
html .bg-color-light-scale-border-top-9 {
	border-top-color: #ababab !important
}

html .bg-color-dark-scale-1 {
	background-color: #212529 !important
}

html .section.bg-color-dark-scale-1,
html .bg-color-dark-scale-border-top-1 {
	border-top-color: #1a1d21 !important
}

html .bg-color-dark-scale-2 {
	background-color: #1c2023 !important
}

html .section.bg-color-dark-scale-2,
html .bg-color-dark-scale-border-top-2 {
	border-top-color: #16181b !important
}

html .bg-color-dark-scale-3 {
	background-color: #181b1e !important
}

html .section.bg-color-dark-scale-3,
html .bg-color-dark-scale-border-top-3 {
	border-top-color: #111315 !important
}

html .bg-color-dark-scale-4 {
	background-color: #131618 !important
}

html .section.bg-color-dark-scale-4,
html .bg-color-dark-scale-border-top-4 {
	border-top-color: #0d0e10 !important
}

html .bg-color-dark-scale-5 {
	background-color: #0f1112 !important
}

html .section.bg-color-dark-scale-5,
html .bg-color-dark-scale-border-top-5 {
	border-top-color: #08090a !important
}

html .bg-color-dark-scale-6 {
	background-color: #0a0c0d !important
}

html .section.bg-color-dark-scale-6,
html .bg-color-dark-scale-border-top-6 {
	border-top-color: #030404 !important
}

html .bg-color-dark-scale-7 {
	background-color: #060607 !important
}

html .section.bg-color-dark-scale-7,
html .bg-color-dark-scale-border-top-7 {
	border-top-color: #000 !important
}

html .bg-color-dark-scale-8 {
	background-color: #010101 !important
}

html .section.bg-color-dark-scale-8,
html .bg-color-dark-scale-border-top-8 {
	border-top-color: #000 !important
}

html .bg-color-dark-scale-9 {
	background-color: #000 !important
}

html .section.bg-color-dark-scale-9,
html .bg-color-dark-scale-border-top-9 {
	border-top-color: #000 !important
}

section.page-header .page-header-title-border {
	background-color: #1c5fa8 !important
}

section.page-header.custom-product {
	background-color: #185392;
	border-top-color: #1b5a9f
}

html .page-header-color.page-header-primary {
	background-color: #1c5fa8;
	border-bottom-color: #1c5fa8;
	color: #fff
}

html .page-header-color.page-header-primary h1 {
	color: #fff
}

html .page-header-color.page-header-primary h1 span {
	color: #fff
}

html .page-header-color.page-header-primary a {
	color: #fff
}

html .page-header-color.page-header-primary .breadcrumb>.active {
	color: #fff
}

html .page-header-color.page-header-secondary {
	background-color: #e36159;
	border-bottom-color: #e36159;
	color: #fff
}

html .page-header-color.page-header-secondary h1 {
	color: #fff
}

html .page-header-color.page-header-secondary h1 span {
	color: #fff
}

html .page-header-color.page-header-secondary a {
	color: #fff
}

html .page-header-color.page-header-secondary .breadcrumb>.active {
	color: #fff
}

html .page-header-color.page-header-tertiary {
	background-color: #2baab1;
	border-bottom-color: #2baab1;
	color: #fff
}

html .page-header-color.page-header-tertiary h1 {
	color: #fff
}

html .page-header-color.page-header-tertiary h1 span {
	color: #fff
}

html .page-header-color.page-header-tertiary a {
	color: #fff
}

html .page-header-color.page-header-tertiary .breadcrumb>.active {
	color: #fff
}

html .page-header-color.page-header-quaternary {
	background-color: #383f48;
	border-bottom-color: #383f48;
	color: #fff
}

html .page-header-color.page-header-quaternary h1 {
	color: #fff
}

html .page-header-color.page-header-quaternary h1 span {
	color: #fff
}

html .page-header-color.page-header-quaternary a {
	color: #fff
}

html .page-header-color.page-header-quaternary .breadcrumb>.active {
	color: #fff
}

html .page-header-color.page-header-dark {
	background-color: #212529;
	border-bottom-color: #212529;
	color: #fff
}

html .page-header-color.page-header-dark h1 {
	color: #fff
}

html .page-header-color.page-header-dark h1 span {
	color: #fff
}

html .page-header-color.page-header-dark a {
	color: #fff
}

html .page-header-color.page-header-dark .breadcrumb>.active {
	color: #fff
}

html .page-header-color.page-header-light {
	background-color: #fff;
	border-bottom-color: #fff;
	color: #777
}

html .page-header-color.page-header-light h1 {
	color: #777
}

html .page-header-color.page-header-light h1 span {
	color: #777
}

html .page-header-color.page-header-light a {
	color: #777
}

html .page-header-color.page-header-light .breadcrumb>.active {
	color: #777
}

html .toggle-primary .toggle label,
html .toggle-primary .toggle .toggle-title {
	color: #1c5fa8;
	border-left-color: #1c5fa8;
	border-right-color: #1c5fa8
}

html .toggle-primary .toggle.active>label,
html .toggle-primary .toggle.active>.toggle-title {
	background-color: #1c5fa8;
	border-color: #1c5fa8;
	color: #fff
}

html .toggle-primary.toggle-simple .toggle>label:after,
html .toggle-primary.toggle-simple .toggle>.toggle-title:after {
	background-color: #1c5fa8
}

html .toggle-primary.toggle-minimal .toggle.active>label,
html .toggle-primary.toggle-minimal .toggle.active>.toggle-title {
	color: #1c5fa8
}

html .toggle-secondary .toggle label,
html .toggle-secondary .toggle .toggle-title {
	color: #e36159;
	border-left-color: #e36159;
	border-right-color: #e36159
}

html .toggle-secondary .toggle.active>label,
html .toggle-secondary .toggle.active>.toggle-title {
	background-color: #e36159;
	border-color: #e36159;
	color: #fff
}

html .toggle-secondary.toggle-simple .toggle>label:after,
html .toggle-secondary.toggle-simple .toggle>.toggle-title:after {
	background-color: #e36159
}

html .toggle-secondary.toggle-minimal .toggle.active>label,
html .toggle-secondary.toggle-minimal .toggle.active>.toggle-title {
	color: #e36159
}

html .toggle-tertiary .toggle label,
html .toggle-tertiary .toggle .toggle-title {
	color: #2baab1;
	border-left-color: #2baab1;
	border-right-color: #2baab1
}

html .toggle-tertiary .toggle.active>label,
html .toggle-tertiary .toggle.active>.toggle-title {
	background-color: #2baab1;
	border-color: #2baab1;
	color: #fff
}

html .toggle-tertiary.toggle-simple .toggle>label:after,
html .toggle-tertiary.toggle-simple .toggle>.toggle-title:after {
	background-color: #2baab1
}

html .toggle-tertiary.toggle-minimal .toggle.active>label,
html .toggle-tertiary.toggle-minimal .toggle.active>.toggle-title {
	color: #2baab1
}

html .toggle-quaternary .toggle label,
html .toggle-quaternary .toggle .toggle-title {
	color: #383f48;
	border-left-color: #383f48;
	border-right-color: #383f48
}

html .toggle-quaternary .toggle.active>label,
html .toggle-quaternary .toggle.active>.toggle-title {
	background-color: #383f48;
	border-color: #383f48;
	color: #fff
}

html .toggle-quaternary.toggle-simple .toggle>label:after,
html .toggle-quaternary.toggle-simple .toggle>.toggle-title:after {
	background-color: #383f48
}

html .toggle-quaternary.toggle-minimal .toggle.active>label,
html .toggle-quaternary.toggle-minimal .toggle.active>.toggle-title {
	color: #383f48
}

html .toggle-dark .toggle label,
html .toggle-dark .toggle .toggle-title {
	color: #212529;
	border-left-color: #212529;
	border-right-color: #212529
}

html .toggle-dark .toggle.active>label,
html .toggle-dark .toggle.active>.toggle-title {
	background-color: #212529;
	border-color: #212529;
	color: #fff
}

html .toggle-dark.toggle-simple .toggle>label:after,
html .toggle-dark.toggle-simple .toggle>.toggle-title:after {
	background-color: #212529
}

html .toggle-dark.toggle-minimal .toggle.active>label,
html .toggle-dark.toggle-minimal .toggle.active>.toggle-title {
	color: #212529
}

html .toggle-light .toggle label,
html .toggle-light .toggle .toggle-title {
	color: #fff;
	border-left-color: #fff;
	border-right-color: #fff
}

html .toggle-light .toggle.active>label,
html .toggle-light .toggle.active>.toggle-title {
	background-color: #fff;
	border-color: #fff;
	color: #777
}

html .toggle-light.toggle-simple .toggle>label:after,
html .toggle-light.toggle-simple .toggle>.toggle-title:after {
	background-color: #fff
}

html .toggle-light.toggle-minimal .toggle.active>label,
html .toggle-light.toggle-minimal .toggle.active>.toggle-title {
	color: #fff
}

.thumb-info .thumb-info-type,
.thumb-info .thumb-info-action-icon,
.thumb-info-social-icons a,
.thumbnail .zoom,
.img-thumbnail .zoom,
.thumb-info-ribbon {
	background-color: #1c5fa8
}

html .thumb-info .thumb-info-action-icon-primary {
	background-color: #1c5fa8
}

html .thumb-info .thumb-info-action-icon-primary i {
	color: #fff
}

html .thumb-info .thumb-info-action-icon-secondary {
	background-color: #e36159
}

html .thumb-info .thumb-info-action-icon-secondary i {
	color: #fff
}

html .thumb-info .thumb-info-action-icon-tertiary {
	background-color: #2baab1
}

html .thumb-info .thumb-info-action-icon-tertiary i {
	color: #fff
}

html .thumb-info .thumb-info-action-icon-quaternary {
	background-color: #383f48
}

html .thumb-info .thumb-info-action-icon-quaternary i {
	color: #fff
}

html .thumb-info .thumb-info-action-icon-dark {
	background-color: #212529
}

html .thumb-info .thumb-info-action-icon-dark i {
	color: #fff
}

html .thumb-info .thumb-info-action-icon-light {
	background-color: #fff
}

html .thumb-info .thumb-info-action-icon-light i {
	color: #777
}

.thumb-info-ribbon:before {
	border-right-color: #113a66;
	border-left-color: #113a66
}

.thumb-info.thumb-info-block-primary .thumb-info-wrapper:before {
	background: rgba(28, 95, 168, .9)
}

.thumb-info-floating-caption-title .thumb-info-floating-caption-type {
	background-color: #1c5fa8
}

.inverted {
	background-color: #1c5fa8
}

html .inverted-primary {
	background-color: #1c5fa8
}

html .inverted-secondary {
	background-color: #e36159
}

html .inverted-tertiary {
	background-color: #2baab1
}

html .inverted-quaternary {
	background-color: #383f48
}

html .inverted-dark {
	background-color: #212529
}

html .inverted-light {
	background-color: #fff
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background-color: #18508e
}

.owl-carousel.show-nav-title .owl-nav button[class*=owl-],
.owl-carousel.show-nav-title .owl-nav button[class*=owl-]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*=owl-].hover {
	color: #1c5fa8
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*=owl-] {
	background-color: #1c5fa8;
	border-color: #1c5fa8 #1c5fa8 #15467c;
	color: #fff
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*=owl-]:hover,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*=owl-].hover {
	background-color: #2172c9;
	border-color: #2378d4 #2378d4 #1c5fa8
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*=owl-]:active,
.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*=owl-].active {
	background-color: #174c87;
	background-image: none;
	border-color: #15467c #15467c #0d2e51
}

.owl-carousel.nav-with-transparency:not(.nav-style-1):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*=owl-] {
	background-color: rgba(28, 95, 168, .4)
}

.owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
	color: #1c5fa8 !important
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
	border-color: #1c5fa8
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg polygon {
	fill: #1c5fa8;
	stroke: #1c5fa8
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-prev,
.owl-carousel.nav-arrows-1 .owl-nav .owl-next {
	color: #1c5fa8
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active>div {
	background: #1c5fa8
}

.owl-carousel.carousel-bottom-inside-shadow .owl-stage-outer:after {
	background-image: linear-gradient(360deg, #1c5fa8 0%, transparent 100%)
}

html body .tabs .nav-tabs .nav-link,
html.dark body .tabs .nav-tabs .nav-link,
html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs .nav-link.active,
html.dark body .tabs .nav-tabs .nav-link.active {
	color: #1c5fa8
}

html body .tabs .nav-tabs .nav-link:hover,
html.dark body .tabs .nav-tabs .nav-link:hover,
html body .tabs .nav-tabs .nav-link:focus,
html.dark body .tabs .nav-tabs .nav-link:focus,
html body .tabs .nav-tabs .nav-link.active,
html.dark body .tabs .nav-tabs .nav-link.active,
html body .tabs .nav-tabs.nav-justified .nav-link.active,
html.dark body .tabs .nav-tabs.nav-justified .nav-link.active,
html body .tabs .nav-tabs.nav-justified .nav-link:hover,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:hover,
html body .tabs .nav-tabs.nav-justified .nav-link:focus,
html.dark body .tabs .nav-tabs.nav-justified .nav-link:focus {
	border-top-color: #1c5fa8
}

html body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-bottom .nav-tabs .nav-link.active:focus {
	border-bottom-color: #1c5fa8
}

html body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-vertical.tabs-left .nav-tabs .nav-link.active:focus {
	border-left-color: #1c5fa8
}

html body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:hover,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:hover,
html body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:focus,
html.dark body .tabs.tabs-vertical.tabs-right .nav-tabs .nav-link.active:focus {
	border-right-color: #1c5fa8
}

html body .tabs.tabs-simple .nav-tabs>li .nav-link.active,
html.dark body .tabs.tabs-simple .nav-tabs>li .nav-link.active,
html body .tabs.tabs-simple .nav-tabs>li .nav-link.active:focus,
html.dark body .tabs.tabs-simple .nav-tabs>li .nav-link.active:focus,
html body .tabs.tabs-simple .nav-tabs>li .nav-link:hover,
html.dark body .tabs.tabs-simple .nav-tabs>li .nav-link:hover,
html body .tabs.tabs-simple .nav-tabs>li .nav-link.active:hover,
html.dark body .tabs.tabs-simple .nav-tabs>li .nav-link.active:hover {
	border-top-color: #1c5fa8;
	color: #1c5fa8
}

html body .tabs-primary .nav-tabs li .nav-link,
html.dark body .tabs-primary .nav-tabs li .nav-link,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link,
html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
	color: #1c5fa8
}

html body .tabs-primary .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #1c5fa8
}

html body .tabs-primary .nav-tabs li .nav-link.active,
html.dark body .tabs-primary .nav-tabs li .nav-link.active,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary .nav-tabs li .nav-link.active:hover,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary .nav-tabs li .nav-link.active:focus,
html body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #1c5fa8;
	color: #1c5fa8
}

html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #1c5fa8
}

html body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #1c5fa8 !important;
	border-bottom-color: #1c5fa8
}

html body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #1c5fa8
}

html body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-primary.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #1c5fa8
}

html body .tabs-secondary .nav-tabs li .nav-link,
html.dark body .tabs-secondary .nav-tabs li .nav-link,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link,
html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
	color: #e36159
}

html body .tabs-secondary .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #e36159
}

html body .tabs-secondary .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #e36159;
	color: #e36159
}

html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #e36159
}

html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #e36159 !important;
	border-bottom-color: #e36159
}

html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #e36159
}

html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-secondary.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #e36159
}

html body .tabs-tertiary .nav-tabs li .nav-link,
html.dark body .tabs-tertiary .nav-tabs li .nav-link,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link,
html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
	color: #2baab1
}

html body .tabs-tertiary .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #2baab1
}

html body .tabs-tertiary .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #2baab1;
	color: #2baab1
}

html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #2baab1
}

html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #2baab1 !important;
	border-bottom-color: #2baab1
}

html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #2baab1
}

html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-tertiary.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #2baab1
}

html body .tabs-quaternary .nav-tabs li .nav-link,
html.dark body .tabs-quaternary .nav-tabs li .nav-link,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link,
html body .tabs-quaternary .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
	color: #383f48
}

html body .tabs-quaternary .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs li .nav-link:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #383f48
}

html body .tabs-quaternary .nav-tabs li .nav-link.active,
html.dark body .tabs-quaternary .nav-tabs li .nav-link.active,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-quaternary .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-quaternary .nav-tabs li .nav-link.active:hover,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-quaternary .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-quaternary .nav-tabs li .nav-link.active:focus,
html body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-quaternary .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #383f48;
	color: #383f48
}

html body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #383f48
}

html body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #383f48 !important;
	border-bottom-color: #383f48
}

html body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #383f48
}

html body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-quaternary.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #383f48
}

html body .tabs-dark .nav-tabs li .nav-link,
html.dark body .tabs-dark .nav-tabs li .nav-link,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link,
html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
	color: #212529
}

html body .tabs-dark .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #212529
}

html body .tabs-dark .nav-tabs li .nav-link.active,
html.dark body .tabs-dark .nav-tabs li .nav-link.active,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark .nav-tabs li .nav-link.active:hover,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark .nav-tabs li .nav-link.active:focus,
html body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #212529;
	color: #212529
}

html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #212529
}

html body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #212529 !important;
	border-bottom-color: #212529
}

html body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #212529
}

html body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-dark.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #212529
}

html body .tabs-light .nav-tabs li .nav-link,
html.dark body .tabs-light .nav-tabs li .nav-link,
html body .tabs-light .nav-tabs.nav-justified li .nav-link,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link,
html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
	color: #fff
}

html body .tabs-light .nav-tabs li .nav-link:hover,
html.dark body .tabs-light .nav-tabs li .nav-link:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link:hover {
	border-top-color: #fff
}

html body .tabs-light .nav-tabs li .nav-link.active,
html.dark body .tabs-light .nav-tabs li .nav-link.active,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light .nav-tabs li .nav-link.active:hover,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light .nav-tabs li .nav-link.active:focus,
html body .tabs-light .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light .nav-tabs.nav-justified li .nav-link.active:focus {
	border-top-color: #fff;
	color: #fff
}

html body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:hover,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs li .nav-link.active:focus,
html body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light.tabs-bottom .nav-tabs.nav-justified li .nav-link.active:focus {
	border-bottom-color: #fff
}

html body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link:hover,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link:hover,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:hover,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:hover,
html body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:focus,
html.dark body .tabs-light.tabs-simple .nav-tabs li .nav-link.active:focus,
html body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus,
html.dark body .tabs-light.tabs-simple .nav-tabs.nav-justified li .nav-link.active:focus {
	color: #fff !important;
	border-bottom-color: #fff
}

html body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:hover,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:hover,
html body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:focus,
html.dark body .tabs-light.tabs-vertical.tabs-left li .nav-link.active:focus {
	border-left-color: #fff
}

html body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link:hover,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:hover,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:hover,
html body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:focus,
html.dark body .tabs-light.tabs-vertical.tabs-right li .nav-link.active:focus {
	border-right-color: #fff
}

html[dir=rtl] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link:hover html[dir=rtl] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active,
html[dir=rtl] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active:hover,
html[dir=rtl] .tabs.tabs-vertical.tabs-left .nav-tabs li .nav-link.active:focus {
	border-right-color: #1c5fa8;
	border-left-color: transparent
}

html[dir=rtl] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link:hover html[dir=rtl] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active,
html[dir=rtl] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active:hover,
html[dir=rtl] .tabs.tabs-vertical.tabs-right .nav-tabs li .nav-link.active:focus {
	border-right-color: transparent;
	border-left-color: #1c5fa8
}

.list.list-icons li>[class*=fa-]:first-child,
.list.list-icons li a:first-child>[class*=fa-]:first-child,
.list.list-icons li>.icons:first-child,
.list.list-icons li a:first-child>.icons:first-child {
	color: #1c5fa8;
	border-color: #1c5fa8
}

.list.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li>.icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #1c5fa8
}

.list.list-ordened li:before {
	color: #1c5fa8;
	border-color: #1c5fa8
}

.list.list-ordened.list-ordened-style-3 li:before {
	background-color: #1c5fa8
}

html .list-primary.list-icons li>[class*=fa-]:first-child,
html .list-primary.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-primary.list-icons li>.icons:first-child,
html .list-primary.list-icons li a:first-child>.icons:first-child {
	color: #1c5fa8;
	border-color: #1c5fa8
}

html .list-primary.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-primary.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-primary.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #1c5fa8;
	color: #fff
}

html .list-primary.list-ordened li:before {
	color: #1c5fa8
}

html .list-primary.list-ordened.list-ordened-style-3 li:before {
	background-color: #1c5fa8;
	color: #fff
}

html .list-secondary.list-icons li>[class*=fa-]:first-child,
html .list-secondary.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-secondary.list-icons li>.icons:first-child,
html .list-secondary.list-icons li a:first-child>.icons:first-child {
	color: #e36159;
	border-color: #e36159
}

html .list-secondary.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-secondary.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-secondary.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #e36159;
	color: #fff
}

html .list-secondary.list-ordened li:before {
	color: #e36159
}

html .list-secondary.list-ordened.list-ordened-style-3 li:before {
	background-color: #e36159;
	color: #fff
}

html .list-tertiary.list-icons li>[class*=fa-]:first-child,
html .list-tertiary.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-tertiary.list-icons li>.icons:first-child,
html .list-tertiary.list-icons li a:first-child>.icons:first-child {
	color: #2baab1;
	border-color: #2baab1
}

html .list-tertiary.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-tertiary.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #2baab1;
	color: #fff
}

html .list-tertiary.list-ordened li:before {
	color: #2baab1
}

html .list-tertiary.list-ordened.list-ordened-style-3 li:before {
	background-color: #2baab1;
	color: #fff
}

html .list-quaternary.list-icons li>[class*=fa-]:first-child,
html .list-quaternary.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-quaternary.list-icons li>.icons:first-child,
html .list-quaternary.list-icons li a:first-child>.icons:first-child {
	color: #383f48;
	border-color: #383f48
}

html .list-quaternary.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-quaternary.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #383f48;
	color: #fff
}

html .list-quaternary.list-ordened li:before {
	color: #383f48
}

html .list-quaternary.list-ordened.list-ordened-style-3 li:before {
	background-color: #383f48;
	color: #fff
}

html .list-dark.list-icons li>[class*=fa-]:first-child,
html .list-dark.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-dark.list-icons li>.icons:first-child,
html .list-dark.list-icons li a:first-child>.icons:first-child {
	color: #212529;
	border-color: #212529
}

html .list-dark.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-dark.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-dark.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #212529;
	color: #fff
}

html .list-dark.list-ordened li:before {
	color: #212529
}

html .list-dark.list-ordened.list-ordened-style-3 li:before {
	background-color: #212529;
	color: #fff
}

html .list-light.list-icons li>[class*=fa-]:first-child,
html .list-light.list-icons li a:first-child>[class*=fa-]:first-child,
html .list-light.list-icons li>.icons:first-child,
html .list-light.list-icons li a:first-child>.icons:first-child {
	color: #fff;
	border-color: #fff
}

html .list-light.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
html .list-light.list-icons.list-icons-style-3 li>.icons:first-child,
html .list-light.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
	background-color: #fff;
	color: #777
}

html .list-light.list-ordened li:before {
	color: #fff
}

html .list-light.list-ordened.list-ordened-style-3 li:before {
	background-color: #fff;
	color: #777
}

html .highlight-primary {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #1c5fa8 0)
}

html .highlight-primary.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(28, 95, 168, 0.2) 0)
}

html .highlight-primary.highlight-change-text-color {
	color: #fff
}

html .highlight-secondary {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #e36159 0)
}

html .highlight-secondary.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(227, 97, 89, 0.2) 0)
}

html .highlight-secondary.highlight-change-text-color {
	color: #fff
}

html .highlight-tertiary {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #2baab1 0)
}

html .highlight-tertiary.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(43, 170, 177, 0.2) 0)
}

html .highlight-tertiary.highlight-change-text-color {
	color: #fff
}

html .highlight-quaternary {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #383f48 0)
}

html .highlight-quaternary.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(56, 63, 72, 0.2) 0)
}

html .highlight-quaternary.highlight-change-text-color {
	color: #fff
}

html .highlight-dark {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #212529 0)
}

html .highlight-dark.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(33, 37, 41, 0.2) 0)
}

html .highlight-dark.highlight-change-text-color {
	color: #fff
}

html .highlight-light {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #FFF 0)
}

html .highlight-light.highlight-bg-opacity {
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 0)
}

html .highlight-light.highlight-change-text-color {
	color: #777
}

.parallax blockquote i.fa-quote-left {
	color: #1c5fa8
}

section.video blockquote i.fa-quote-left {
	color: #1c5fa8
}

.accordion .card-header a {
	color: #1c5fa8
}

html .accordion.accordion-primary .card-header {
	background-color: #1c5fa8 !important
}

html .accordion.accordion-primary .card-header a {
	color: #fff
}

html .accordion-modern-status-primary.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #1c5fa8 !important;
	color: #fff !important
}

html .accordion-modern-status-primary.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #1c5fa8 !important
}

html .accordion.accordion-secondary .card-header {
	background-color: #e36159 !important
}

html .accordion.accordion-secondary .card-header a {
	color: #fff
}

html .accordion-modern-status-secondary.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #e36159 !important;
	color: #fff !important
}

html .accordion-modern-status-secondary.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #e36159 !important
}

html .accordion.accordion-tertiary .card-header {
	background-color: #2baab1 !important
}

html .accordion.accordion-tertiary .card-header a {
	color: #fff
}

html .accordion-modern-status-tertiary.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #2baab1 !important;
	color: #fff !important
}

html .accordion-modern-status-tertiary.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #2baab1 !important
}

html .accordion.accordion-quaternary .card-header {
	background-color: #383f48 !important
}

html .accordion.accordion-quaternary .card-header a {
	color: #fff
}

html .accordion-modern-status-quaternary.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #383f48 !important;
	color: #fff !important
}

html .accordion-modern-status-quaternary.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #383f48 !important
}

html .accordion.accordion-dark .card-header {
	background-color: #212529 !important
}

html .accordion.accordion-dark .card-header a {
	color: #fff
}

html .accordion-modern-status-dark.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #212529 !important;
	color: #fff !important
}

html .accordion-modern-status-dark.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #212529 !important
}

html .accordion.accordion-light .card-header {
	background-color: #fff !important
}

html .accordion.accordion-light .card-header a {
	color: #777
}

html .accordion-modern-status-light.accordion-modern-status>.card .card-header a:not(.collapsed) {
	background-color: #fff !important;
	color: #777 !important
}

html .accordion-modern-status-light.accordion-modern-status>.card:hover .card-header a.collapsed {
	color: #fff !important
}

html .divider.divider-primary [class*=fa-],
html .divider.divider-primary .icons {
	color: #1c5fa8
}

html .divider.divider-primary.divider-small hr {
	background: #1c5fa8
}

html .divider.divider-secondary [class*=fa-],
html .divider.divider-secondary .icons {
	color: #e36159
}

html .divider.divider-secondary.divider-small hr {
	background: #e36159
}

html .divider.divider-tertiary [class*=fa-],
html .divider.divider-tertiary .icons {
	color: #2baab1
}

html .divider.divider-tertiary.divider-small hr {
	background: #2baab1
}

html .divider.divider-quaternary [class*=fa-],
html .divider.divider-quaternary .icons {
	color: #383f48
}

html .divider.divider-quaternary.divider-small hr {
	background: #383f48
}

html .divider.divider-dark [class*=fa-],
html .divider.divider-dark .icons {
	color: #212529
}

html .divider.divider-dark.divider-small hr {
	background: #212529
}

html .divider.divider-light [class*=fa-],
html .divider.divider-light .icons {
	color: #fff
}

html .divider.divider-light.divider-small hr {
	background: #fff
}

html .divider.divider-style-2.divider-primary [class*=fa-],
html .divider.divider-style-2.divider-primary .icons {
	background: #1c5fa8;
	color: #fff
}

html .divider.divider-style-2.divider-secondary [class*=fa-],
html .divider.divider-style-2.divider-secondary .icons {
	background: #e36159;
	color: #fff
}

html .divider.divider-style-2.divider-tertiary [class*=fa-],
html .divider.divider-style-2.divider-tertiary .icons {
	background: #2baab1;
	color: #fff
}

html .divider.divider-style-2.divider-quaternary [class*=fa-],
html .divider.divider-style-2.divider-quaternary .icons {
	background: #383f48;
	color: #fff
}

html .divider.divider-style-2.divider-dark [class*=fa-],
html .divider.divider-style-2.divider-dark .icons {
	background: #212529;
	color: #fff
}

html .divider.divider-style-2.divider-light [class*=fa-],
html .divider.divider-style-2.divider-light .icons {
	background: #fff;
	color: #777
}

html .divider.divider-style-3.divider-primary [class*=fa-],
html .divider.divider-style-3.divider-primary .icons {
	border-color: #1c5fa8
}

html .divider.divider-style-3.divider-secondary [class*=fa-],
html .divider.divider-style-3.divider-secondary .icons {
	border-color: #e36159
}

html .divider.divider-style-3.divider-tertiary [class*=fa-],
html .divider.divider-style-3.divider-tertiary .icons {
	border-color: #2baab1
}

html .divider.divider-style-3.divider-quaternary [class*=fa-],
html .divider.divider-style-3.divider-quaternary .icons {
	border-color: #383f48
}

html .divider.divider-style-3.divider-dark [class*=fa-],
html .divider.divider-style-3.divider-dark .icons {
	border-color: #212529
}

html .divider.divider-style-3.divider-light [class*=fa-],
html .divider.divider-style-3.divider-light .icons {
	border-color: #fff
}

#header .tip {
	background: #1c5fa8;
	color: #fff
}

#header .tip:before {
	border-right-color: #1c5fa8;
	border-left-color: transparent
}

html #header .tip-primary {
	background: #1c5fa8 !important;
	color: #fff !important
}

html #header .tip-primary:before {
	border-right-color: #1c5fa8 !important
}

html #header .tip-secondary {
	background: #e36159 !important;
	color: #fff !important
}

html #header .tip-secondary:before {
	border-right-color: #e36159 !important
}

html #header .tip-tertiary {
	background: #2baab1 !important;
	color: #fff !important
}

html #header .tip-tertiary:before {
	border-right-color: #2baab1 !important
}

html #header .tip-quaternary {
	background: #383f48 !important;
	color: #fff !important
}

html #header .tip-quaternary:before {
	border-right-color: #383f48 !important
}

html #header .tip-dark {
	background: #212529 !important;
	color: #fff !important
}

html #header .tip-dark:before {
	border-right-color: #212529 !important
}

html #header .tip-light {
	background: #fff !important;
	color: #777 !important
}

html #header .tip-light:before {
	border-right-color: #fff !important
}

html[dir=rtl] #header .tip:before {
	border-left-color: #1c5fa8
}

html[dir=rtl] #header .tip.skin:before {
	border-left-color: #1c5fa8
}

html[dir=rtl] #header .tip-primary:before {
	border-left-color: #1c5fa8 !important;
	border-right-color: transparent !important
}

html[dir=rtl] #header .tip-secondary:before {
	border-left-color: #e36159 !important;
	border-right-color: transparent !important
}

html[dir=rtl] #header .tip-tertiary:before {
	border-left-color: #2baab1 !important;
	border-right-color: transparent !important
}

html[dir=rtl] #header .tip-quaternary:before {
	border-left-color: #383f48 !important;
	border-right-color: transparent !important
}

html[dir=rtl] #header .tip-dark:before {
	border-left-color: #212529 !important;
	border-right-color: transparent !important
}

html[dir=rtl] #header .tip-light:before {
	border-left-color: #fff !important;
	border-right-color: transparent !important
}

#header .header-btn-collapse-nav {
	background: #1c5fa8
}

@media(min-width:992px) {
	#header .header-nav-main nav>ul>li>a {
		color: #1c5fa8
	}

	#header .header-nav-main nav>ul>li.open>a,
	#header .header-nav-main nav>ul>li:hover>a {
		background: #1c5fa8
	}

	#header .header-nav-main nav>ul>li.dropdown .dropdown-menu {
		border-top-color: #1c5fa8
	}

	#header .header-nav-main nav>ul>li.dropdown.open>a:before,
	#header .header-nav-main nav>ul>li.dropdown:hover>a:before {
		border-bottom-color: #1c5fa8
	}

	#header .header-nav-main nav>ul>li.dropdown-mega-signin .dropdown-menu {
		border-top-color: #1c5fa8
	}

	#header .header-nav-main nav>ul>li.dropdown-mega-signin .dropdown-menu li a {
		color: #1c5fa8
	}

	#header .header-nav-main nav>ul:not(:hover)>li>a.active {
		background-color: #1c5fa8;
		color: #fff
	}

	#header .header-nav.header-nav-stripe nav>ul>li:hover>a,
	#header .header-nav.header-nav-stripe nav>ul>li.open>a {
		background-color: #1c5fa8
	}

	#header .header-nav.header-nav-stripe nav>ul:not(:hover)>li>a.active {
		background-color: #1c5fa8
	}

	#header .header-nav.header-nav-stripe nav>ul:hover>li>a.active {
		color: #1c5fa8
	}

	#header .header-nav.header-nav-links nav>ul li:hover>a {
		color: #1c5fa8
	}

	#header .header-nav.header-nav-links nav>ul:not(:hover)>li>a.active {
		color: #1c5fa8
	}

	#header .header-nav.header-nav-links.header-nav-links-vertical-slide nav ul li.dropdown .dropdown-menu::-webkit-scrollbar-thumb {
		background: #1c5fa8 !important
	}

	#header .header-nav.header-nav-line nav>ul li:hover>a {
		color: #1c5fa8
	}

	#header .header-nav.header-nav-line nav>ul li:hover>a:before {
		background: #1c5fa8
	}

	#header .header-nav.header-nav-line nav>ul:not(:hover) li>a.active {
		color: #1c5fa8
	}

	#header .header-nav.header-nav-line nav>ul:not(:hover) li>a.active:before {
		background: #1c5fa8
	}

	#header .header-nav-main.header-nav-main-slide nav>ul>li.open>span>a,
	#header .header-nav-main.header-nav-main-slide nav>ul>li:hover>span>a {
		color: #1c5fa8
	}

	#header .header-nav-main.header-nav-main-slide nav>ul:not(:hover)>li>span>a.active {
		color: #1c5fa8
	}

	html.side-header #header.side-header .header-nav-main nav>ul li.dropdown.open>.dropdown-menu,
	html.side-header #header.side-header .header-nav-main nav>ul li.dropdown:hover>.dropdown-menu {
		border-left-color: #1c5fa8
	}

	html.side-header-right #header.side-header .header-nav-main nav>ul li.dropdown.open>.dropdown-menu,
	html.side-header-right #header.side-header .header-nav-main nav>ul li.dropdown:hover>.dropdown-menu {
		border-right-color: #1c5fa8
	}
}

@media(min-width:992px) {
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary .dropdown-menu {
		background-color: #1c5fa8
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
		color: #fff;
		border-bottom-color: #1a589b
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary .dropdown-menu li:hover>a {
		background: #1e66b5
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary:hover>a:before {
		border-bottom-color: #1c5fa8;
		background: #1c5fa8
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-primary .dropdown-mega-sub-title {
		color: #fff;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary:hover>a {
		background: #1c5fa8;
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary .dropdown-menu li a {
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-primary>a.active {
		background: #1c5fa8;
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:hover {
		color: #1c5fa8
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:hover:before {
		background-color: #1c5fa8
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary:hover>a {
		color: #1c5fa8
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary:hover>a:before {
		background: #1c5fa8
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-primary>a.active {
		color: #1c5fa8
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-primary>a.active:before {
		background-color: #1c5fa8
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a {
		color: #1c5fa8
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary a:hover {
		color: #1c5fa8
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary:hover>a {
		color: #1c5fa8
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-primary>a.active {
		color: #1c5fa8
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary .dropdown-menu {
		background-color: #e36159
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
		color: #fff;
		border-bottom-color: #e1554c
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary .dropdown-menu li:hover>a {
		background: #e56d66
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary:hover>a:before {
		border-bottom-color: #e36159;
		background: #e36159
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-secondary .dropdown-mega-sub-title {
		color: #fff;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary:hover>a {
		background: #e36159;
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary .dropdown-menu li a {
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-secondary>a.active {
		background: #e36159;
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:hover {
		color: #e36159
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:hover:before {
		background-color: #e36159
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary:hover>a {
		color: #e36159
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary:hover>a:before {
		background: #e36159
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-secondary>a.active {
		color: #e36159
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-secondary>a.active:before {
		background-color: #e36159
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a {
		color: #e36159
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary a:hover {
		color: #e36159
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary:hover>a {
		color: #e36159
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-secondary>a.active {
		color: #e36159
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary .dropdown-menu {
		background-color: #2baab1
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
		color: #fff;
		border-bottom-color: #289ea5
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary .dropdown-menu li:hover>a {
		background: #2eb6bd
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary:hover>a:before {
		border-bottom-color: #2baab1;
		background: #2baab1
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-tertiary .dropdown-mega-sub-title {
		color: #fff;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary:hover>a {
		background: #2baab1;
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary .dropdown-menu li a {
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-tertiary>a.active {
		background: #2baab1;
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:hover {
		color: #2baab1
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:hover:before {
		background-color: #2baab1
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary:hover>a {
		color: #2baab1
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary:hover>a:before {
		background: #2baab1
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-tertiary>a.active {
		color: #2baab1
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-tertiary>a.active:before {
		background-color: #2baab1
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a {
		color: #2baab1
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary a:hover {
		color: #2baab1
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary:hover>a {
		color: #2baab1
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-tertiary>a.active {
		color: #2baab1
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary .dropdown-menu {
		background-color: #383f48
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary .dropdown-menu li a {
		color: #fff;
		border-bottom-color: #31373f
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary .dropdown-menu li:hover>a {
		background: #3f4751
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary:hover>a:before {
		border-bottom-color: #383f48;
		background: #383f48
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-quaternary .dropdown-mega-sub-title {
		color: #fff;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary:hover>a {
		background: #383f48;
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary .dropdown-menu li a {
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-quaternary>a.active {
		background: #383f48;
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:hover {
		color: #383f48
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:hover:before {
		background-color: #383f48
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary:hover>a {
		color: #383f48
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary:hover>a:before {
		background: #383f48
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-quaternary>a.active {
		color: #383f48
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-quaternary>a.active:before {
		background-color: #383f48
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a {
		color: #383f48
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary a:hover {
		color: #383f48
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary:hover>a {
		color: #383f48
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-quaternary>a.active {
		color: #383f48
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark .dropdown-menu {
		background-color: #212529
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
		color: #fff;
		border-bottom-color: #1a1d21
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark .dropdown-menu li:hover>a {
		background: #282d31
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark:hover>a:before {
		border-bottom-color: #212529;
		background: #212529
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-dark .dropdown-mega-sub-title {
		color: #fff;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark:hover>a {
		background: #212529;
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark .dropdown-menu li a {
		color: #fff
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-dark>a.active {
		background: #212529;
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:hover {
		color: #212529
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:hover:before {
		background-color: #212529
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark:hover>a {
		color: #212529
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark:hover>a:before {
		background: #212529
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-dark>a.active {
		color: #212529
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-dark>a.active:before {
		background-color: #212529
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a {
		color: #212529
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark a:hover {
		color: #212529
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-dark:hover>a {
		color: #212529
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-dark>a.active {
		color: #212529
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light .dropdown-menu {
		background-color: #fff
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light .dropdown-menu li a {
		color: #777;
		border-bottom-color: #f7f7f7
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light .dropdown-menu li:hover>a {
		background: #fff
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light.open>a:before,
	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a:before {
		border-bottom-color: #fff;
		background: #fff
	}

	html #header .header-nav .header-nav-main nav>ul>li.dropdown-full-color.dropdown-mega.dropdown-light .dropdown-mega-sub-title {
		color: #777;
		opacity: .7
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a {
		background: #fff;
		color: #777
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light .dropdown-menu li a {
		color: #777
	}

	html #header .header-nav:not(.header-nav-line):not(.header-nav-links) .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-light>a.active {
		background: #fff;
		color: #777
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:focus,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:hover {
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:focus:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:hover:before {
		background-color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a {
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light.open>a:before,
	html #header .header-nav-line .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a:before {
		background: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-light>a.active {
		color: #fff
	}

	html #header .header-nav-line .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-light>a.active:before {
		background-color: #fff
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a {
		color: #fff
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:focus,
	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light a:hover {
		color: #fff
	}

	html #header .header-nav-links .header-nav-main nav>ul>li.dropdown-full-color.dropdown-light:hover>a {
		color: #fff
	}

	html #header .header-nav-links .header-nav-main nav>ul:not(:hover)>li.dropdown-full-color.dropdown-light>a.active {
		color: #fff
	}
}

@media(min-width:992px) {

	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav>ul>li:hover>a,
	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav>ul>li .wrapper-items-cloned:hover>a {
		color: #1c5fa8 !important
	}

	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav>ul:not(:hover)>li>a.active,
	html #header .header-nav-force-light-text-active-skin-color .header-nav-main nav>ul:not(:hover)>li .wrapper-items-cloned>a.active {
		color: #1c5fa8 !important
	}
}

@media(max-width:991px) {
	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav>ul>li>a {
		color: #1c5fa8
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav>ul>li>a:active {
		color: #1c5fa8
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav>ul>li>a.active {
		background: #1c5fa8
	}

	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav>ul>li>a.active:focus,
	#header .header-nav-main:not(.header-nav-main-mobile-dark) nav>ul>li>a.active:hover {
		background: #1c5fa8
	}

	#header .header-nav-main.header-nav-main-mobile-dark nav>ul>li>a.active {
		background: #1c5fa8
	}

	#header .header-nav-main.header-nav-main-mobile-dark nav>ul>li>a.active:focus,
	#header .header-nav-main.header-nav-main-mobile-dark nav>ul>li>a.active:hover {
		background: #1c5fa8
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links .header-nav-main.header-nav-main-mobile-dark nav>ul>li>a.active {
		color: #1c5fa8 !important
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav::-webkit-scrollbar-thumb {
		background-color: #1c5fa8
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav>ul>li>a.active {
		color: #1c5fa8 !important
	}

	html.side-header-hamburguer-sidebar #header.side-header .header-nav.header-nav-links nav>ul>li:hover>a {
		color: #1c5fa8 !important
	}
}

@media(max-width:991px) {
	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary>a {
		color: #1c5fa8
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-primary>a.active {
		background: #1c5fa8 !important;
		color: #fff !important
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary>a {
		color: #e36159
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-secondary>a.active {
		background: #e36159 !important;
		color: #fff !important
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary>a {
		color: #2baab1
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-tertiary>a.active {
		background: #2baab1 !important;
		color: #fff !important
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary>a {
		color: #383f48
	}

	html #header .header-nav-main nav>ul>li.dropdown-full-color.dropdown-quaternary>a.active {
		background: #383f48 !important;
		color: #fff !important
	}
}

html #header .header-top.header-top-primary {
	background: #1c5fa8;
	border-top-color: #185392
}

html #header .header-top.header-top-primary .header-nav-top ul>li.open>a {
	background: #206bbe
}

html #header .header-top.header-top-primary .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-primary .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-primary .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-primary .header-nav-top ul>li.open>a:focus {
	background: #206bbe
}

html #header .header-top.header-top-primary .dropdown-menu {
	background: #206bbe;
	border-color: #206bbe
}

html #header .header-top.header-top-primary .dropdown-menu a:hover,
html #header .header-top.header-top-primary .dropdown-menu a:focus {
	background: #2378d4 !important
}

html #header .header-top.header-top-secondary {
	background: #e36159;
	border-top-color: #df4c43
}

html #header .header-top.header-top-secondary .header-nav-top ul>li.open>a {
	background: #e7766f
}

html #header .header-top.header-top-secondary .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-secondary .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-secondary .header-nav-top ul>li.open>a:focus {
	background: #e7766f
}

html #header .header-top.header-top-secondary .dropdown-menu {
	background: #e7766f;
	border-color: #e7766f
}

html #header .header-top.header-top-secondary .dropdown-menu a:hover,
html #header .header-top.header-top-secondary .dropdown-menu a:focus {
	background: #ea8b85 !important
}

html #header .header-top.header-top-tertiary {
	background: #2baab1;
	border-top-color: #26969c
}

html #header .header-top.header-top-tertiary .header-nav-top ul>li.open>a {
	background: #30bec6
}

html #header .header-top.header-top-tertiary .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-tertiary .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-tertiary .header-nav-top ul>li.open>a:focus {
	background: #30bec6
}

html #header .header-top.header-top-tertiary .dropdown-menu {
	background: #30bec6;
	border-color: #30bec6
}

html #header .header-top.header-top-tertiary .dropdown-menu a:hover,
html #header .header-top.header-top-tertiary .dropdown-menu a:focus {
	background: #3fc9d0 !important
}

html #header .header-top.header-top-quaternary {
	background: #383f48;
	border-top-color: #2d323a
}

html #header .header-top.header-top-quaternary .header-nav-top ul>li.open>a {
	background: #434c56
}

html #header .header-top.header-top-quaternary .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-quaternary .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-quaternary .header-nav-top ul>li.open>a:focus {
	background: #434c56
}

html #header .header-top.header-top-quaternary .dropdown-menu {
	background: #434c56;
	border-color: #434c56
}

html #header .header-top.header-top-quaternary .dropdown-menu a:hover,
html #header .header-top.header-top-quaternary .dropdown-menu a:focus {
	background: #4e5865 !important
}

html #header .header-top.header-top-dark {
	background: #212529;
	border-top-color: #16181b
}

html #header .header-top.header-top-dark .header-nav-top ul>li.open>a {
	background: #2c3237
}

html #header .header-top.header-top-dark .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-dark .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-dark .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-dark .header-nav-top ul>li.open>a:focus {
	background: #2c3237
}

html #header .header-top.header-top-dark .dropdown-menu {
	background: #2c3237;
	border-color: #2c3237
}

html #header .header-top.header-top-dark .dropdown-menu a:hover,
html #header .header-top.header-top-dark .dropdown-menu a:focus {
	background: #383f45 !important
}

html #header .header-top.header-top-light {
	background: #fff;
	border-top-color: #f2f2f2
}

html #header .header-top.header-top-light .header-nav-top ul>li.open>a {
	background: #fff
}

html #header .header-top.header-top-light .header-nav-top ul>li>a:hover,
html #header .header-top.header-top-light .header-nav-top ul>li.open>a:hover,
html #header .header-top.header-top-light .header-nav-top ul>li>a:focus,
html #header .header-top.header-top-light .header-nav-top ul>li.open>a:focus {
	background: #fff
}

html #header .header-top.header-top-light .dropdown-menu {
	background: #fff;
	border-color: #fff
}

html #header .header-top.header-top-light .dropdown-menu a:hover,
html #header .header-top.header-top-light .dropdown-menu a:focus {
	background: #fff !important
}

@media(min-width:992px) {
	html #header .header-nav-bar-primary {
		background: #1c5fa8 !important;
		color: #fff !important
	}

	html #header .header-nav-bar-secondary {
		background: #e36159 !important;
		color: #fff !important
	}

	html #header .header-nav-bar-tertiary {
		background: #2baab1 !important;
		color: #fff !important
	}

	html #header .header-nav-bar-quaternary {
		background: #383f48 !important;
		color: #fff !important
	}

	html #header .header-nav-bar-dark {
		background: #212529 !important;
		color: #fff !important
	}

	html #header .header-nav-bar-light {
		background: #fff !important;
		color: #777 !important
	}
}

@media(min-width:992px) {

	#header .header-nav-main.header-nav-main-light nav>ul>li.open>a,
	#header .header-nav-main.header-nav-main-light nav>ul>li:hover>a {
		color: #1c5fa8
	}

	#header .header-nav-main.header-nav-main-light nav>ul>li>a.active {
		color: #1c5fa8
	}
}

#header .header-nav-features .header-nav-features-search-reveal-big-search .form-control {
	border-bottom-color: #1c5fa8
}

.testimonial blockquote {
	background: #206bbe
}

.testimonial .testimonial-arrow-down {
	border-top-color: #206bbe
}

html .testimonial-primary blockquote {
	background: #206bbe
}

html .testimonial-primary .testimonial-arrow-down {
	border-top-color: #206bbe
}

html .testimonial-primary.testimonial-style-7 {
	background: #206bbe
}

html .testimonial-quotes-primary blockquote:before,
html .testimonial-quotes-primary blockquote:after {
	color: #1c5fa8 !important
}

html .testimonial-secondary blockquote {
	background: #e7766f
}

html .testimonial-secondary .testimonial-arrow-down {
	border-top-color: #e7766f
}

html .testimonial-secondary.testimonial-style-7 {
	background: #e7766f
}

html .testimonial-quotes-secondary blockquote:before,
html .testimonial-quotes-secondary blockquote:after {
	color: #1c5fa8 !important
}

html .testimonial-tertiary blockquote {
	background: #30bec6
}

html .testimonial-tertiary .testimonial-arrow-down {
	border-top-color: #30bec6
}

html .testimonial-tertiary.testimonial-style-7 {
	background: #30bec6
}

html .testimonial-quotes-tertiary blockquote:before,
html .testimonial-quotes-tertiary blockquote:after {
	color: #1c5fa8 !important
}

html .testimonial-quaternary blockquote {
	background: #434c56
}

html .testimonial-quaternary .testimonial-arrow-down {
	border-top-color: #434c56
}

html .testimonial-quaternary.testimonial-style-7 {
	background: #434c56
}

html .testimonial-quotes-quaternary blockquote:before,
html .testimonial-quotes-quaternary blockquote:after {
	color: #1c5fa8 !important
}

html .testimonial-dark blockquote {
	background: #2c3237
}

html .testimonial-dark .testimonial-arrow-down {
	border-top-color: #2c3237
}

html .testimonial-dark.testimonial-style-7 {
	background: #2c3237
}

html .testimonial-quotes-dark blockquote:before,
html .testimonial-quotes-dark blockquote:after {
	color: #1c5fa8 !important
}

html .testimonial-light blockquote {
	background: #fff
}

html .testimonial-light .testimonial-arrow-down {
	border-top-color: #fff
}

html .testimonial-light.testimonial-style-7 {
	background: #fff
}

html .testimonial-quotes-light blockquote:before,
html .testimonial-quotes-light blockquote:after {
	color: #1c5fa8 !important
}

.circular-bar.only-icon [class*=fa-],
.circular-bar.only-icon .icons {
	color: #1c5fa8
}

.form-control.bg-primary,
.form-control.bg-color-primary {
	color: #fff;
	border-color: #1c5fa8
}

.form-control.bg-primary::-webkit-input-placeholder,
.form-control.bg-color-primary::-webkit-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-primary::-moz-placeholder,
.form-control.bg-color-primary::-moz-placeholder {
	color: #e6e6e6
}

.form-control.bg-primary:-ms-input-placeholder,
.form-control.bg-color-primary:-ms-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-primary:-moz-placeholder,
.form-control.bg-color-primary:-moz-placeholder {
	color: #e6e6e6
}

.form-control .testimonial-primary blockquote {
	background: #206bbe
}

.form-control .testimonial-primary .testimonial-arrow-down {
	border-top-color: #206bbe
}

.form-control .testimonial-primary.testimonial-style-7 {
	background: #206bbe
}

.form-control .testimonial-quotes-primary blockquote:before,
.form-control .testimonial-quotes-primary blockquote:after {
	color: #1c5fa8 !important
}

.form-control.bg-secondary,
.form-control.bg-color-secondary {
	color: #fff;
	border-color: #e36159
}

.form-control.bg-secondary::-webkit-input-placeholder,
.form-control.bg-color-secondary::-webkit-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-secondary::-moz-placeholder,
.form-control.bg-color-secondary::-moz-placeholder {
	color: #e6e6e6
}

.form-control.bg-secondary:-ms-input-placeholder,
.form-control.bg-color-secondary:-ms-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-secondary:-moz-placeholder,
.form-control.bg-color-secondary:-moz-placeholder {
	color: #e6e6e6
}

.form-control .testimonial-secondary blockquote {
	background: #e7766f
}

.form-control .testimonial-secondary .testimonial-arrow-down {
	border-top-color: #e7766f
}

.form-control .testimonial-secondary.testimonial-style-7 {
	background: #e7766f
}

.form-control .testimonial-quotes-secondary blockquote:before,
.form-control .testimonial-quotes-secondary blockquote:after {
	color: #1c5fa8 !important
}

.form-control.bg-tertiary,
.form-control.bg-color-tertiary {
	color: #fff;
	border-color: #2baab1
}

.form-control.bg-tertiary::-webkit-input-placeholder,
.form-control.bg-color-tertiary::-webkit-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-tertiary::-moz-placeholder,
.form-control.bg-color-tertiary::-moz-placeholder {
	color: #e6e6e6
}

.form-control.bg-tertiary:-ms-input-placeholder,
.form-control.bg-color-tertiary:-ms-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-tertiary:-moz-placeholder,
.form-control.bg-color-tertiary:-moz-placeholder {
	color: #e6e6e6
}

.form-control .testimonial-tertiary blockquote {
	background: #30bec6
}

.form-control .testimonial-tertiary .testimonial-arrow-down {
	border-top-color: #30bec6
}

.form-control .testimonial-tertiary.testimonial-style-7 {
	background: #30bec6
}

.form-control .testimonial-quotes-tertiary blockquote:before,
.form-control .testimonial-quotes-tertiary blockquote:after {
	color: #1c5fa8 !important
}

.form-control.bg-quaternary,
.form-control.bg-color-quaternary {
	color: #fff;
	border-color: #383f48
}

.form-control.bg-quaternary::-webkit-input-placeholder,
.form-control.bg-color-quaternary::-webkit-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-quaternary::-moz-placeholder,
.form-control.bg-color-quaternary::-moz-placeholder {
	color: #e6e6e6
}

.form-control.bg-quaternary:-ms-input-placeholder,
.form-control.bg-color-quaternary:-ms-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-quaternary:-moz-placeholder,
.form-control.bg-color-quaternary:-moz-placeholder {
	color: #e6e6e6
}

.form-control .testimonial-quaternary blockquote {
	background: #434c56
}

.form-control .testimonial-quaternary .testimonial-arrow-down {
	border-top-color: #434c56
}

.form-control .testimonial-quaternary.testimonial-style-7 {
	background: #434c56
}

.form-control .testimonial-quotes-quaternary blockquote:before,
.form-control .testimonial-quotes-quaternary blockquote:after {
	color: #1c5fa8 !important
}

.form-control.bg-dark,
.form-control.bg-color-dark {
	color: #fff;
	border-color: #212529
}

.form-control.bg-dark::-webkit-input-placeholder,
.form-control.bg-color-dark::-webkit-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-dark::-moz-placeholder,
.form-control.bg-color-dark::-moz-placeholder {
	color: #e6e6e6
}

.form-control.bg-dark:-ms-input-placeholder,
.form-control.bg-color-dark:-ms-input-placeholder {
	color: #e6e6e6
}

.form-control.bg-dark:-moz-placeholder,
.form-control.bg-color-dark:-moz-placeholder {
	color: #e6e6e6
}

.form-control .testimonial-dark blockquote {
	background: #2c3237
}

.form-control .testimonial-dark .testimonial-arrow-down {
	border-top-color: #2c3237
}

.form-control .testimonial-dark.testimonial-style-7 {
	background: #2c3237
}

.form-control .testimonial-quotes-dark blockquote:before,
.form-control .testimonial-quotes-dark blockquote:after {
	color: #1c5fa8 !important
}

.form-control.bg-light,
.form-control.bg-color-light {
	color: #777;
	border-color: #fff
}

.form-control.bg-light::-webkit-input-placeholder,
.form-control.bg-color-light::-webkit-input-placeholder {
	color: #5e5e5e
}

.form-control.bg-light::-moz-placeholder,
.form-control.bg-color-light::-moz-placeholder {
	color: #5e5e5e
}

.form-control.bg-light:-ms-input-placeholder,
.form-control.bg-color-light:-ms-input-placeholder {
	color: #5e5e5e
}

.form-control.bg-light:-moz-placeholder,
.form-control.bg-color-light:-moz-placeholder {
	color: #5e5e5e
}

.form-control .testimonial-light blockquote {
	background: #fff
}

.form-control .testimonial-light .testimonial-arrow-down {
	border-top-color: #fff
}

.form-control .testimonial-light.testimonial-style-7 {
	background: #fff
}

.form-control .testimonial-quotes-light blockquote:before,
.form-control .testimonial-quotes-light blockquote:after {
	color: #1c5fa8 !important
}

.form-range::-webkit-slider-thumb {
	background-color: #1c5fa8
}

.form-range::-webkit-slider-thumb:active {
	background-color: rgba(28, 95, 168, .25)
}

.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(28, 95, 168, .25)
}

.form-switch .form-check-input:checked {
	background-color: #1c5fa8;
	border-color: #1c5fa8 !important
}

html .counters .counter-primary [class*=fa-],
html .counters .counter-primary .icons,
html .counters .counter-primary strong {
	color: #1c5fa8
}

html .counters .counter-secondary [class*=fa-],
html .counters .counter-secondary .icons,
html .counters .counter-secondary strong {
	color: #e36159
}

html .counters .counter-tertiary [class*=fa-],
html .counters .counter-tertiary .icons,
html .counters .counter-tertiary strong {
	color: #2baab1
}

html .counters .counter-quaternary [class*=fa-],
html .counters .counter-quaternary .icons,
html .counters .counter-quaternary strong {
	color: #383f48
}

html .counters .counter-dark [class*=fa-],
html .counters .counter-dark .icons,
html .counters .counter-dark strong {
	color: #212529
}

html .counters .counter-light [class*=fa-],
html .counters .counter-light .icons,
html .counters .counter-light strong {
	color: #fff
}

html .countdown.countdown-primary>span>span {
	color: #1c5fa8
}

html .countdown.countdown-secondary>span>span {
	color: #e36159
}

html .countdown.countdown-tertiary>span>span {
	color: #2baab1
}

html .countdown.countdown-quaternary>span>span {
	color: #383f48
}

html .countdown.countdown-dark>span>span {
	color: #212529
}

html .countdown.countdown-light>span>span {
	color: #fff
}

html .image-hotspot-primary strong {
	color: #fff !important
}

html .image-hotspot-primary .circle {
	background: #1c5fa8 !important
}

html .image-hotspot-primary .ring {
	border-color: rgba(28, 95, 168, .9)
}

html .image-hotspot-secondary strong {
	color: #fff !important
}

html .image-hotspot-secondary .circle {
	background: #e36159 !important
}

html .image-hotspot-secondary .ring {
	border-color: rgba(227, 97, 89, .9)
}

html .image-hotspot-tertiary strong {
	color: #fff !important
}

html .image-hotspot-tertiary .circle {
	background: #2baab1 !important
}

html .image-hotspot-tertiary .ring {
	border-color: rgba(43, 170, 177, .9)
}

html .image-hotspot-quaternary strong {
	color: #fff !important
}

html .image-hotspot-quaternary .circle {
	background: #383f48 !important
}

html .image-hotspot-quaternary .ring {
	border-color: rgba(56, 63, 72, .9)
}

html .image-hotspot-dark strong {
	color: #fff !important
}

html .image-hotspot-dark .circle {
	background: #212529 !important
}

html .image-hotspot-dark .ring {
	border-color: rgba(33, 37, 41, .9)
}

html .image-hotspot-light strong {
	color: #777 !important
}

html .image-hotspot-light .circle {
	background: #fff !important
}

html .image-hotspot-light .ring {
	border-color: rgba(255, 255, 255, .9)
}

.icon-featured {
	background-color: #1c5fa8
}

html .featured-box-primary .icon-featured {
	background-color: #1c5fa8
}

html .featured-box-primary h4 {
	color: #1c5fa8
}

html .featured-box-primary .box-content {
	border-top-color: #1c5fa8
}

html .featured-box-primary .box-content-border-bottom {
	border-bottom-color: #1c5fa8
}

html .featured-box-secondary .icon-featured {
	background-color: #e36159
}

html .featured-box-secondary h4 {
	color: #e36159
}

html .featured-box-secondary .box-content {
	border-top-color: #e36159
}

html .featured-box-secondary .box-content-border-bottom {
	border-bottom-color: #e36159
}

html .featured-box-tertiary .icon-featured {
	background-color: #2baab1
}

html .featured-box-tertiary h4 {
	color: #2baab1
}

html .featured-box-tertiary .box-content {
	border-top-color: #2baab1
}

html .featured-box-tertiary .box-content-border-bottom {
	border-bottom-color: #2baab1
}

html .featured-box-quaternary .icon-featured {
	background-color: #383f48
}

html .featured-box-quaternary h4 {
	color: #383f48
}

html .featured-box-quaternary .box-content {
	border-top-color: #383f48
}

html .featured-box-quaternary .box-content-border-bottom {
	border-bottom-color: #383f48
}

html .featured-box-dark .icon-featured {
	background-color: #212529
}

html .featured-box-dark h4 {
	color: #212529
}

html .featured-box-dark .box-content {
	border-top-color: #212529
}

html .featured-box-dark .box-content-border-bottom {
	border-bottom-color: #212529
}

html .featured-box-light .icon-featured {
	background-color: #fff
}

html .featured-box-light h4 {
	color: #fff
}

html .featured-box-light .box-content {
	border-top-color: #fff
}

html .featured-box-light .box-content-border-bottom {
	border-bottom-color: #fff
}

html .featured-boxes-style-3 .featured-box.featured-box-primary .icon-featured {
	border-color: #1c5fa8;
	color: #1c5fa8
}

html .featured-boxes-style-3 .featured-box.featured-box-secondary .icon-featured {
	border-color: #e36159;
	color: #e36159
}

html .featured-boxes-style-3 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1
}

html .featured-boxes-style-3 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48
}

html .featured-boxes-style-3 .featured-box.featured-box-dark .icon-featured {
	border-color: #212529;
	color: #212529
}

html .featured-boxes-style-3 .featured-box.featured-box-light .icon-featured {
	border-color: #fff;
	color: #fff
}

html .featured-boxes-style-4 .featured-box.featured-box-primary .icon-featured {
	border-color: #1c5fa8;
	color: #1c5fa8
}

html .featured-boxes-style-4 .featured-box.featured-box-secondary .icon-featured {
	border-color: #e36159;
	color: #e36159
}

html .featured-boxes-style-4 .featured-box.featured-box-tertiary .icon-featured {
	border-color: #2baab1;
	color: #2baab1
}

html .featured-boxes-style-4 .featured-box.featured-box-quaternary .icon-featured {
	border-color: #383f48;
	color: #383f48
}

html .featured-boxes-style-4 .featured-box.featured-box-dark .icon-featured {
	border-color: #212529;
	color: #212529
}

html .featured-boxes-style-4 .featured-box.featured-box-light .icon-featured {
	border-color: #fff;
	color: #fff
}

html .featured-boxes-style-5 .featured-box.featured-box-primary .icon-featured {
	color: #1c5fa8
}

html .featured-boxes-style-5 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159
}

html .featured-boxes-style-5 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1
}

html .featured-boxes-style-5 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48
}

html .featured-boxes-style-5 .featured-box.featured-box-dark .icon-featured {
	color: #212529
}

html .featured-boxes-style-5 .featured-box.featured-box-light .icon-featured {
	color: #fff
}

html .featured-boxes-style-6 .featured-box.featured-box-primary .icon-featured {
	color: #1c5fa8
}

html .featured-boxes-style-6 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159
}

html .featured-boxes-style-6 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1
}

html .featured-boxes-style-6 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48
}

html .featured-boxes-style-6 .featured-box.featured-box-dark .icon-featured {
	color: #212529
}

html .featured-boxes-style-6 .featured-box.featured-box-light .icon-featured {
	color: #fff
}

html .featured-boxes-style-8 .featured-box.featured-box-primary .icon-featured {
	color: #1c5fa8
}

html .featured-boxes-style-8 .featured-box.featured-box-secondary .icon-featured {
	color: #e36159
}

html .featured-boxes-style-8 .featured-box.featured-box-tertiary .icon-featured {
	color: #2baab1
}

html .featured-boxes-style-8 .featured-box.featured-box-quaternary .icon-featured {
	color: #383f48
}

html .featured-boxes-style-8 .featured-box.featured-box-dark .icon-featured {
	color: #212529
}

html .featured-boxes-style-8 .featured-box.featured-box-light .icon-featured {
	color: #fff
}

.featured-boxes-modern-style-primary .featured-box {
	background: #1c5fa8;
	background: linear-gradient(135deg, #1c5fa8 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1c5fa8', endColorstr='#383f48', GradientType=1)
}

.featured-boxes-modern-style-secondary .featured-box {
	background: #e36159;
	background: linear-gradient(135deg, #e36159 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36159', endColorstr='#383f48', GradientType=1)
}

.featured-boxes-modern-style-tertiary .featured-box {
	background: #2baab1;
	background: linear-gradient(135deg, #2baab1 0%, #383f48 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2baab1', endColorstr='#383f48', GradientType=1)
}

html .featured-box-effect-2.featured-box-primary .icon-featured:after {
	box-shadow: 0 0 0 3px #1c5fa8
}

html .featured-box-effect-2.featured-box-secondary .icon-featured:after {
	box-shadow: 0 0 0 3px #e36159
}

html .featured-box-effect-2.featured-box-tertiary .icon-featured:after {
	box-shadow: 0 0 0 3px #2baab1
}

html .featured-box-effect-2.featured-box-quaternary .icon-featured:after {
	box-shadow: 0 0 0 3px #383f48
}

html .featured-box-effect-2.featured-box-dark .icon-featured:after {
	box-shadow: 0 0 0 3px #212529
}

html .featured-box-effect-2.featured-box-light .icon-featured:after {
	box-shadow: 0 0 0 3px #fff
}

html .featured-box-effect-3.featured-box-primary .icon-featured:after {
	box-shadow: 0 0 0 10px #1c5fa8
}

html .featured-box-effect-3.featured-box-primary:hover .icon-featured {
	background: #1c5fa8 !important
}

html .featured-box-effect-3.featured-box-secondary .icon-featured:after {
	box-shadow: 0 0 0 10px #e36159
}

html .featured-box-effect-3.featured-box-secondary:hover .icon-featured {
	background: #e36159 !important
}

html .featured-box-effect-3.featured-box-tertiary .icon-featured:after {
	box-shadow: 0 0 0 10px #2baab1
}

html .featured-box-effect-3.featured-box-tertiary:hover .icon-featured {
	background: #2baab1 !important
}

html .featured-box-effect-3.featured-box-quaternary .icon-featured:after {
	box-shadow: 0 0 0 10px #383f48
}

html .featured-box-effect-3.featured-box-quaternary:hover .icon-featured {
	background: #383f48 !important
}

html .featured-box-effect-3.featured-box-dark .icon-featured:after {
	box-shadow: 0 0 0 10px #212529
}

html .featured-box-effect-3.featured-box-dark:hover .icon-featured {
	background: #212529 !important
}

html .featured-box-effect-3.featured-box-light .icon-featured:after {
	box-shadow: 0 0 0 10px #fff
}

html .featured-box-effect-3.featured-box-light:hover .icon-featured {
	background: #fff !important
}

.feature-box .feature-box-icon {
	background-color: #1c5fa8
}

html .feature-box-primary .feature-box-icon {
	background-color: #1c5fa8
}

html .feature-box-secondary .feature-box-icon {
	background-color: #e36159
}

html .feature-box-tertiary .feature-box-icon {
	background-color: #2baab1
}

html .feature-box-quaternary .feature-box-icon {
	background-color: #383f48
}

html .feature-box-dark .feature-box-icon {
	background-color: #212529
}

html .feature-box-light .feature-box-icon {
	background-color: #fff
}

.feature-box.feature-box-style-2 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-primary.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-primary.feature-box-style-2 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-secondary.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-secondary.feature-box-style-2 .feature-box-icon .icons {
	color: #e36159
}

html .feature-box-tertiary.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-tertiary.feature-box-style-2 .feature-box-icon .icons {
	color: #2baab1
}

html .feature-box-quaternary.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-quaternary.feature-box-style-2 .feature-box-icon .icons {
	color: #383f48
}

html .feature-box-dark.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-dark.feature-box-style-2 .feature-box-icon .icons {
	color: #212529
}

html .feature-box-light.feature-box-style-2 .feature-box-icon [class*=fa-],
html .feature-box-light.feature-box-style-2 .feature-box-icon .icons {
	color: #fff
}

.feature-box.feature-box-style-3 .feature-box-icon {
	border-color: #1c5fa8
}

.feature-box.feature-box-style-3 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-3 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon {
	border-color: #1c5fa8
}

html .feature-box-primary.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-primary.feature-box-style-3 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon {
	border-color: #e36159
}

html .feature-box-secondary.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-secondary.feature-box-style-3 .feature-box-icon .icons {
	color: #e36159
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon {
	border-color: #2baab1
}

html .feature-box-tertiary.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-tertiary.feature-box-style-3 .feature-box-icon .icons {
	color: #2baab1
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon {
	border-color: #383f48
}

html .feature-box-quaternary.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-quaternary.feature-box-style-3 .feature-box-icon .icons {
	color: #383f48
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon {
	border-color: #212529
}

html .feature-box-dark.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-dark.feature-box-style-3 .feature-box-icon .icons {
	color: #212529
}

html .feature-box-light.feature-box-style-3 .feature-box-icon {
	border-color: #fff
}

html .feature-box-light.feature-box-style-3 .feature-box-icon [class*=fa-],
html .feature-box-light.feature-box-style-3 .feature-box-icon .icons {
	color: #fff
}

.feature-box.feature-box-style-4 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-4 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-primary.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-primary.feature-box-style-4 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-secondary.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-secondary.feature-box-style-4 .feature-box-icon .icons {
	color: #e36159
}

html .feature-box-tertiary.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-tertiary.feature-box-style-4 .feature-box-icon .icons {
	color: #2baab1
}

html .feature-box-quaternary.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-quaternary.feature-box-style-4 .feature-box-icon .icons {
	color: #383f48
}

html .feature-box-dark.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-dark.feature-box-style-4 .feature-box-icon .icons {
	color: #212529
}

html .feature-box-light.feature-box-style-4 .feature-box-icon [class*=fa-],
html .feature-box-light.feature-box-style-4 .feature-box-icon .icons {
	color: #fff
}

.feature-box.feature-box-style-5 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-5 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-primary.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-primary.feature-box-style-5 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-secondary.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-secondary.feature-box-style-5 .feature-box-icon .icons {
	color: #e36159
}

html .feature-box-tertiary.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-tertiary.feature-box-style-5 .feature-box-icon .icons {
	color: #2baab1
}

html .feature-box-quaternary.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-quaternary.feature-box-style-5 .feature-box-icon .icons {
	color: #383f48
}

html .feature-box-dark.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-dark.feature-box-style-5 .feature-box-icon .icons {
	color: #212529
}

html .feature-box-light.feature-box-style-5 .feature-box-icon [class*=fa-],
html .feature-box-light.feature-box-style-5 .feature-box-icon .icons {
	color: #fff
}

html .feature-box-primary.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-primary.feature-box-style-6 .feature-box-icon .icons {
	color: #1c5fa8
}

html .feature-box-secondary.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-secondary.feature-box-style-6 .feature-box-icon .icons {
	color: #e36159
}

html .feature-box-tertiary.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-tertiary.feature-box-style-6 .feature-box-icon .icons {
	color: #2baab1
}

html .feature-box-quaternary.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-quaternary.feature-box-style-6 .feature-box-icon .icons {
	color: #383f48
}

html .feature-box-dark.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-dark.feature-box-style-6 .feature-box-icon .icons {
	color: #212529
}

html .feature-box-light.feature-box-style-6 .feature-box-icon [class*=fa-],
html .feature-box-light.feature-box-style-6 .feature-box-icon .icons {
	color: #fff
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(1) {
	background-color: #15467c
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(2) {
	background-color: #185392
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(3) {
	background-color: #1c5fa8
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(4) {
	background-color: #206bbe
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(5) {
	background-color: #2378d4
}

html .featured-boxes-full-scale>.featured-box-full-primary:nth-child(6) {
	background-color: #3385dd
}

html .featured-boxes-full .featured-box-full-primary {
	background-color: #1c5fa8;
	color: #fff
}

html .featured-boxes-full .featured-box-full-secondary {
	background-color: #e36159;
	color: #fff
}

html .featured-boxes-full .featured-box-full-tertiary {
	background-color: #2baab1;
	color: #fff
}

html .featured-boxes-full .featured-box-full-quaternary {
	background-color: #383f48;
	color: #fff
}

html .featured-boxes-full .featured-box-full-dark {
	background-color: #212529;
	color: #fff
}

html .featured-boxes-full .featured-box-full-light {
	background-color: #fff;
	color: #777
}

.pricing-table .most-popular {
	border-color: #1c5fa8
}

.pricing-table .most-popular h3 {
	background-color: #1c5fa8
}

.pricing-table.princig-table-flat .plan h3 {
	background-color: #1c5fa8
}

.pricing-table.princig-table-flat .plan h3 span {
	background: #1c5fa8
}

html .call-to-action.featured.featured-primary {
	border-top-color: #1c5fa8
}

html .call-to-action.call-to-action-primary {
	background: #1c5fa8
}

html .call-to-action.with-full-borders-primary {
	border-color: #1c5fa8
}

html .call-to-action.call-to-action-primary {
	background: #1c5fa8
}

html .call-to-action.call-to-action-primary h3,
html .call-to-action.call-to-action-primary p {
	color: #fff
}

html .call-to-action.featured.featured-secondary {
	border-top-color: #e36159
}

html .call-to-action.call-to-action-secondary {
	background: #e36159
}

html .call-to-action.with-full-borders-secondary {
	border-color: #e36159
}

html .call-to-action.call-to-action-secondary {
	background: #e36159
}

html .call-to-action.call-to-action-secondary h3,
html .call-to-action.call-to-action-secondary p {
	color: #fff
}

html .call-to-action.featured.featured-tertiary {
	border-top-color: #2baab1
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1
}

html .call-to-action.with-full-borders-tertiary {
	border-color: #2baab1
}

html .call-to-action.call-to-action-tertiary {
	background: #2baab1
}

html .call-to-action.call-to-action-tertiary h3,
html .call-to-action.call-to-action-tertiary p {
	color: #fff
}

html .call-to-action.featured.featured-quaternary {
	border-top-color: #383f48
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48
}

html .call-to-action.with-full-borders-quaternary {
	border-color: #383f48
}

html .call-to-action.call-to-action-quaternary {
	background: #383f48
}

html .call-to-action.call-to-action-quaternary h3,
html .call-to-action.call-to-action-quaternary p {
	color: #fff
}

html .call-to-action.featured.featured-dark {
	border-top-color: #212529
}

html .call-to-action.call-to-action-dark {
	background: #212529
}

html .call-to-action.with-full-borders-dark {
	border-color: #212529
}

html .call-to-action.call-to-action-dark {
	background: #212529
}

html .call-to-action.call-to-action-dark h3,
html .call-to-action.call-to-action-dark p {
	color: #fff
}

html .call-to-action.featured.featured-light {
	border-top-color: #fff
}

html .call-to-action.call-to-action-light {
	background: #fff
}

html .call-to-action.with-full-borders-light {
	border-color: #fff
}

html .call-to-action.call-to-action-light {
	background: #fff
}

html .call-to-action.call-to-action-light h3,
html .call-to-action.call-to-action-light p {
	color: #777
}

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
	background: #1c5fa8;
	box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1c5fa8
}

ul.history li .featured-box .box-content {
	border-top-color: #1c5fa8
}

.portfolio-list .portfolio-item.portfolio-item-new:after {
	background: linear-gradient(135deg, #1c5fa8 0%, #e36159 80%)
}

.recent-posts .date .month,
article.post .post-date .month {
	background-color: #1c5fa8
}

.recent-posts .date .day,
article.post .post-date .day {
	color: #1c5fa8
}

.slider .tp-bannertimer {
	background-color: #1c5fa8
}

.home-concept strong {
	color: #1c5fa8
}

html .home-intro-primary {
	background: #1c5fa8
}

html .home-intro-secondary {
	background: #e36159
}

html .home-intro-tertiary {
	background: #2baab1
}

html .home-intro-quaternary {
	background: #383f48
}

html .home-intro-dark {
	background: #212529
}

html .home-intro-light {
	background: #fff
}

html .slide-overlay-primary .slotholder:after {
	background-color: #1c5fa8 !important
}

html .slider-container .tp-caption-overlay-primary {
	background: #1c5fa8
}

html .slider-container .tp-caption-overlay-opacity-primary {
	background: rgba(28, 95, 168, .4)
}

html .tp-bullets.bullets-color-primary .tp-bullet:hover,
html .tp-bullets.bullets-color-primary .tp-bullet.selected {
	background: #1c5fa8
}

html .slide-overlay-secondary .slotholder:after {
	background-color: #e36159 !important
}

html .slider-container .tp-caption-overlay-secondary {
	background: #e36159
}

html .slider-container .tp-caption-overlay-opacity-secondary {
	background: rgba(227, 97, 89, .4)
}

html .tp-bullets.bullets-color-secondary .tp-bullet:hover,
html .tp-bullets.bullets-color-secondary .tp-bullet.selected {
	background: #e36159
}

html .slide-overlay-tertiary .slotholder:after {
	background-color: #2baab1 !important
}

html .slider-container .tp-caption-overlay-tertiary {
	background: #2baab1
}

html .slider-container .tp-caption-overlay-opacity-tertiary {
	background: rgba(43, 170, 177, .4)
}

html .tp-bullets.bullets-color-tertiary .tp-bullet:hover,
html .tp-bullets.bullets-color-tertiary .tp-bullet.selected {
	background: #2baab1
}

html .slide-overlay-quaternary .slotholder:after {
	background-color: #383f48 !important
}

html .slider-container .tp-caption-overlay-quaternary {
	background: #383f48
}

html .slider-container .tp-caption-overlay-opacity-quaternary {
	background: rgba(56, 63, 72, .4)
}

html .tp-bullets.bullets-color-quaternary .tp-bullet:hover,
html .tp-bullets.bullets-color-quaternary .tp-bullet.selected {
	background: #383f48
}

html .slide-overlay-dark .slotholder:after {
	background-color: #212529 !important
}

html .slider-container .tp-caption-overlay-dark {
	background: #212529
}

html .slider-container .tp-caption-overlay-opacity-dark {
	background: rgba(33, 37, 41, .4)
}

html .tp-bullets.bullets-color-dark .tp-bullet:hover,
html .tp-bullets.bullets-color-dark .tp-bullet.selected {
	background: #212529
}

html .slide-overlay-light .slotholder:after {
	background-color: #fff !important
}

html .slider-container .tp-caption-overlay-light {
	background: #fff
}

html .slider-container .tp-caption-overlay-opacity-light {
	background: rgba(255, 255, 255, .4)
}

html .tp-bullets.bullets-color-light .tp-bullet:hover,
html .tp-bullets.bullets-color-light .tp-bullet.selected {
	background: #fff
}

html .slide-overlay.slide-overlay-gradient .slotholder:after {
	background-color: #2baab1 !important;
	background-image: linear-gradient(to right, #2baab1 0%, #383f48 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2baab1', endColorstr='#383f48', GradientType=1)
}

.cd-product-viewer-handle .handle {
	background: #1c5fa8
}

.tparrows.tparrows-carousel.tp-leftarrow,
.tparrows.tparrows-carousel.tp-rightarrow {
	background-color: #1c5fa8;
	border-color: #1c5fa8 #1c5fa8 #15467c;
	color: #fff
}

.tparrows.tparrows-carousel.tp-leftarrow:hover,
.tparrows.tparrows-carousel.tp-rightarrow:hover,
.tparrows.tparrows-carousel.tp-leftarrow.hover,
.tparrows.tparrows-carousel.tp-rightarrow.hover {
	border-color: #2378d4 #2378d4 #1c5fa8;
	color: #fff
}

.tparrows.tparrows-carousel.tp-leftarrow:hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.hover:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.hover:not(.bg-transparent) {
	background-color: #2172c9
}

.tparrows.tparrows-carousel.tp-leftarrow:focus,
.tparrows.tparrows-carousel.tp-rightarrow:focus,
.tparrows.tparrows-carousel.tp-leftarrow.focus,
.tparrows.tparrows-carousel.tp-rightarrow.focus {
	border-color: #15467c #15467c #0d2e51;
	color: #fff;
	box-shadow: 0 0 0 3px rgba(28, 95, 168, .5)
}

.tparrows.tparrows-carousel.tp-leftarrow:focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.focus:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.focus:not(.bg-transparent) {
	background-color: #174c87
}

.tparrows.tparrows-carousel.tp-leftarrow.disabled,
.tparrows.tparrows-carousel.tp-rightarrow.disabled,
.tparrows.tparrows-carousel.tp-leftarrow:disabled,
.tparrows.tparrows-carousel.tp-rightarrow:disabled {
	border-color: #1c5fa8 #1c5fa8 #15467c
}

.tparrows.tparrows-carousel.tp-leftarrow.disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow:disabled:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:disabled:not(.bg-transparent) {
	background-color: #1c5fa8
}

.tparrows.tparrows-carousel.tp-leftarrow:active,
.tparrows.tparrows-carousel.tp-rightarrow:active,
.tparrows.tparrows-carousel.tp-leftarrow.active,
.tparrows.tparrows-carousel.tp-rightarrow.active {
	border-color: #15467c #15467c #0d2e51 !important
}

.tparrows.tparrows-carousel.tp-leftarrow:active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow:active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-leftarrow.active:not(.bg-transparent),
.tparrows.tparrows-carousel.tp-rightarrow.active:not(.bg-transparent) {
	background-color: #174c87 !important;
	background-image: none !important
}

.tparrows.arrows-primary:before {
	color: #1c5fa8
}

.shop .products .product .onsale {
	background-color: #1c5fa8;
	border-bottom-color: #164b85
}

.shop .cart-totals tr.total .amount {
	color: #1c5fa8
}

.shop .products .product .quick-view {
	background: rgba(28, 95, 168, .7)
}

.shop .products .product:hover .quick-view:hover {
	background: #1c5fa8
}

.product .product-thumb-info .addtocart-btn-wrapper .addtocart-btn:hover {
	color: #fff;
	background: #1c5fa8
}

.product .product-thumb-info .countdown-offer-wrapper {
	background: #1c5fa8
}

.dropdown-menu.dropdown-menu-arrow-centered a:focus,
.dropdown-menu.dropdown-menu-arrow-centered a:active {
	background-color: #1c5fa8
}

#footer .footer-ribbon {
	background: #1c5fa8
}

#footer .footer-ribbon:before {
	border-right-color: #113a66;
	border-left-color: #113a66
}

#footer .footer-nav.footer-nav-bottom-line nav>ul:not(:hover)>li>a.active {
	color: #1c5fa8 !important
}

#footer .footer-nav.footer-nav-bottom-line nav>ul:not(:hover)>li>a.active:before {
	background: #1c5fa8 !important
}

#footer .footer-nav.footer-nav-bottom-line nav>ul:hover>li:hover>a {
	color: #1c5fa8 !important
}

#footer .footer-nav.footer-nav-bottom-line nav>ul:hover>li:hover>a:before {
	background: #1c5fa8 !important
}

.scrollable.colored-slider .scrollable-slider {
	background: #1c5fa8
}

.sample-icon-list .sample-icon a:hover {
	background-color: #1c5fa8
}

.cursor-inner {
	background-color: #1c5fa8
}

.cursor-inner.cursor-inner-plus:before {
	color: #1c5fa8
}

.cursor-outer {
	border-color: #1c5fa8
}

.cssload-thecube .cssload-cube:before {
	background-color: #1c5fa8
}

.cssload-cube-progress {
	border-color: #1c5fa8
}

.cssload-cube-progress-inner {
	background-color: #1c5fa8
}

.cssload-float-rings-inner.cssload-one {
	border-bottom-color: #1c5fa8
}

.cssload-float-rings-inner.cssload-two {
	border-right-color: #1c5fa8
}

.cssload-float-rings-inner.cssload-three {
	border-top-color: #1c5fa8
}

.cssload-float-bars-loading,
.cssload-float-bars-loading:after,
.cssload-float-bars-loading:before {
	background: #1c5fa8
}

.cssload-speeding-wheel {
	border-top-color: #1c5fa8;
	border-bottom-color: #1c5fa8
}

.cssload-zenith {
	box-shadow: 3px 3px 1px #1c5fa8
}

.cssload-spinning-square-loading {
	background: #1c5fa8
}

.cssload-spinning-square-loading:before {
	background: #e36159
}

.cssload-spinning-square-loading:after {
	background: #fff
}

.cssload-pulse-loader {
	background: #1c5fa8
}

.map-rounded-pin {
	background: #1c5fa8
}

html.boxed .body {
	border-top-color: #1c5fa8
}

.custom-icon-style-1:before {
	border-color: #1c5fa8
}

.custom-icon-box-style-1:hover .custom-icon-style-1:before {
	background: #1c5fa8
}

.custom-icon-box-style-1:hover .custom-icon-style-1 svg path {
	fill: #fff !important
}

.custom-icon-box-style-1:hover h3 {
	color: #1c5fa8 !important
}

.custom-thumb-info-style-1:hover .thumb-info-wrapper {
	border-color: #1c5fa8 !important
}

.custom-thumb-info-style-1:hover h3 {
	color: #1c5fa8 !important
}

.custom-testimonial-style-1.testimonial-with-quotes blockquote:before {
	color: #1c5fa8
}

.custom-accordion-style-1>.card .card-header a:not(.collapsed) {
	background-color: #1c5fa8;
	color: #fff !important
}

.custom-accordion-style-1>.card:hover .card-header a.collapsed {
	color: #1c5fa8 !important
}

.custom-nav-list-effect-1>li>a:before {
	border-top-color: #1c5fa8
}

.custom-nav-list-effect-1>li.active>a,
.custom-nav-list-effect-1>li.open>a,
.custom-nav-list-effect-1>li:hover>a {
	color: #1c5fa8 !important
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover,
.datepicker table tr td span:hover {
	background: #1c5fa8
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover {
	background: #1c5fa8
}

.datepicker table thead tr:last-child th:hover {
	color: #1c5fa8
}

.datepicker table tbody tr td.day:hover {
	background: #1c5fa8
}

.datepicker table tbody tr td.day.active {
	background: #15467c
}
body {
    font-family: poppins, sans-serif;
  }
  @media (min-width: 1010px) {
    .container:not(.container-bs):not(.container-xl-custom):not(
        .container-xxl-custom
      ) {
      max-width: 1200px;
    }
  }
  .custom-border-radius-1 {
    border-radius: 7px !important;
  }
  .custom-text-underline-1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }
  .custom-btn-border-radius {
    border-radius: 12px;
  }
  .custom-btn-arrow-effect-1 > svg {
    transition: ease transform 300ms;
  }
  .custom-btn-arrow-effect-1:hover > svg {
    transform: translate3d(8px, 0, 0);
  }
  .custom-select-1.custom-select-1-arrow-position:before {
    right: 30px;
  }
  .custom-svg-1 {
    position: absolute;
    left: 0;
    top: -100px;
    width: 640px;
    display: none;
  }
  .custom-svg-2 {
    position: absolute;
    top: 43%;
    left: -260px;
    width: 190px;
    transform: translate3d(0, -50%, 0);
  }
  .custom-svg-3 {
    display: none;
    position: absolute;
    right: 0;
  }
  @media (min-width: 576px) {
    .custom-svg-3 {
      display: block;
      bottom: -80px;
      width: 80px;
    }
  }
  @media (min-width: 1440px) {
    .custom-svg-3 {
      bottom: -150px;
      width: 150px;
    }
  }
  .custom-bg-color-grey-1 {
    background-color: #f4f4f4 !important;
  }
  .custom-alert-bg-color-1 {
    background-color: #fffcd6 !important;
  }
  @media (max-width: 575px) {
    .custom-section-background {
      background-color: #fff !important;
    }
  }
  .custom-section-background .container:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 85%;
    width: 100vw;
    height: calc(100% + 150px);
    background: #fff;
    z-index: 0;
    transform: translate3d(0, -50%, 0);
  }
  @media (max-width: 575px) {
    .custom-section-background .container:before {
      opacity: 0;
    }
  }
  @media (min-width: 1920px) {
    .custom-background-style-1 {
      background-size: cover;
    }
  }
  @media (max-width: 575px) {
    .custom-background-style-1 {
      background-image: none !important;
      background-color: #fff;
    }
  }
  @media (min-width: 2050px), (max-width: 991px) {
    .custom-background-size-1 {
      background-size: cover !important;
    }
  }
  @media (min-width: 2050px) {
    .custom-background-size-1 {
      background-position: 14vw 50% !important;
    }
  }
  .custom-svg-background-1 {
    position: absolute;
    width: 1000%;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%, 0, 0);
  }
  @media (max-width: 991px) {
    .custom-svg-background-1 {
      left: 77%;
    }
  }
  @media (max-width: 575px) {
    .custom-svg-background-1 {
      left: 82%;
    }
  }
  @media (max-width: 991px) {
    .custom-remove-mobile-border-left {
      border-left: 0 !important;
    }
  }
  @media (max-width: 575px) {
    .custom-remove-mobile-xs-border-left {
      border-left: 0 !important;
    }
  }
  .custom-divider > hr {
    height: 4px !important;
  }
  @keyframes customLineAnim {
    from {
      stroke-dasharray: 1600;
      stroke-dashoffset: 1600;
    }
    to {
      stroke-dasharray: 2500;
      stroke-dashoffset: 1600;
    }
  }
  .customLineAnim {
    animation-name: customLineAnim;
  }
  @keyframes customLineAnim2 {
    from {
      stroke-dasharray: 2650;
      stroke-dashoffset: 2650;
    }
    to {
      stroke-dasharray: 2650;
      stroke-dashoffset: 0;
    }
  }
  .customLineAnim2 {
    animation-name: customLineAnim2;
  }
  @keyframes customLineProgressAnim {
    from {
      width: 0;
    }
    to {
      width: 60px;
    }
  }
  .customLineProgressAnim {
    animation-name: customLineProgressAnim;
  }
  .custom-social-icons-divider > li + li {
    position: relative;
    margin-left: 20px;
  }
  .custom-social-icons-divider > li + li:before {
    content: "";
    border-left: 1px solid #dadcdd;
    height: 80%;
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  #header .header-container {
    height: 110px;
  }
  #header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    border-radius: 5px !important;
  }
  #header
    .header-nav-features
    .header-nav-feature.px-4
    .header-nav-features-dropdown.show {
    right: calc(100% - 30px) !important;
  }
  .custom-left-border-1 {
    position: relative;
  }
  .custom-left-border-1:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -22px;
    height: 60%;
    border-left: 1px solid #ccc;
    transform: translate3d(0, -50%, 0);
  }
  .custom-icon-style-1 {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 95px;
  }
  .custom-icon-style-1:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #ccc;
    border-radius: 7px;
    transform: rotate(45deg);
    z-index: 0;
    transition: ease background 300ms;
  }
  .custom-icon-style-1 svg {
    position: relative;
    z-index: 1;
  }
  .custom-icon-box-style-1 h3 {
    transition: ease color 300ms;
  }
  .custom-thumb-info-style-1 .thumb-info-wrapper {
    border-radius: 25px !important;
    border: 5px solid transparent !important;
    transition: ease border 300ms;
  }
  .custom-thumb-info-style-1 h3 {
    transition: ease color 300ms;
  }
  .custom-img-thumbnail-style-1.img-thumbnail.img-thumbnail-hover-icon:before {
    border-radius: 0 !important;
  }
  .custom-testimonial-style-1 blockquote {
    padding: 5px 20px !important;
  }
  .custom-testimonial-style-1 blockquote p {
    font-family: poppins, sans-serif;
  }
  .custom-testimonial-style-1.testimonial-with-quotes blockquote:before {
    /* content:"\f10d"; */
    font-family: "font awesome 6 free";
    font-weight: 900;
    font-size: 50px;
  }
  @media (min-width: 1200px) {
    .custom-carousel-box-shadow-1 {
      position: relative;
    }
    .custom-carousel-box-shadow-1:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 65%;
      height: 0;
      box-shadow: 0 0 40px 120px rgba(0, 0, 0, 0.07);
      transform: translate3d(-50%, -50%, 0);
      z-index: 0;
    }
  }
  .custom-accordion-style-1 > .card {
    border: 1px solid transparent !important;
    transition: ease border-color 300ms;
  }
  .custom-accordion-style-1 > .card .card-header a {
    position: relative;
    padding: 23px 30px;
    background-color: #f4f4f4;
    transition: ease background-color 300ms, ease color 300ms;
  }
  .custom-accordion-style-1 > .card .card-header a:after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: inherit;
    font-weight: inherit;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    transform-origin: 50% 50%;
    transition: ease transform 300ms;
  }
  .custom-accordion-style-1 > .card .card-header a.collapsed:after {
    transform: translate3d(0, -50%, 0) rotate(0deg);
  }
  .custom-accordion-style-1 > .card .card-header + .collapse.show,
  .custom-accordion-style-1 > .card .card-header + .collapsing {
    border: 1px solid #ccc;
    border-top: 0;
  }
  .custom-accordion-style-1 > .card + .card {
    margin-top: 17px;
  }
  .custom-nav-list-effect-1 > li > a {
    position: relative;
    transition: ease padding 300ms, ease color 300ms;
  }
  .custom-nav-list-effect-1 > li > a:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    border-top: 4px solid #ccc;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -50%, 0);
    transition: ease opacity 300ms, ease width 300ms;
  }
  .custom-nav-list-effect-1 > li.active > a,
  .custom-nav-list-effect-1 > li.open > a,
  .custom-nav-list-effect-1 > li:hover > a {
    padding-left: 45px;
    font-weight: 700;
  }
  .custom-nav-list-effect-1 > li.active > a:before,
  .custom-nav-list-effect-1 > li.open > a:before,
  .custom-nav-list-effect-1 > li:hover > a:before {
    width: 30px;
    opacity: 1;
    visibility: visible;
  }
  .custom-feature-box-justify-center {
    justify-content: center;
  }
  .custom-feature-box-justify-center .feature-box-icon,
  .custom-feature-box-justify-center .feature-box-info {
    flex: 0 0 auto;
  }
  .custom-form-style-1 .form-control {
    border: 0;
    background: #f4f4f4;
    border-radius: 35px;
    height: auto;
    padding: 20px 25px;
  }
  .datepicker {
    padding: 10px;
    margin: 0 auto;
    line-height: 1.1em;
  }
  .datepicker.datepicker-inline {
    line-height: 1.7em;
    width: 100%;
  }
  .datepicker table {
    width: 100%;
  }
  .datepicker table tr td {
    border-radius: 0;
  }
  .datepicker table thead tr th {
    cursor: pointer;
    font-size: 12.8px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
  }
  .datepicker table thead tr th.prev {
    content: "\f0d9";
    font-family: "font awesome 6 free";
    font-weight: 900;
  }
  .datepicker table thead tr th.next {
    content: "\f0da";
    font-family: "font awesome 6 free";
    font-weight: 900;
  }
  .datepicker table thead tr th:hover {
    color: #fff;
  }
  .datepicker table thead tr:last-child th {
    font-weight: 600;
  }
  .datepicker table thead tr:last-child th:hover {
    color: #ccc;
  }
  .datepicker table td {
    text-align: center;
    font-size: 12px;
    font-size: 0.75rem;
  }
  .datepicker table td.day {
    transition: background-color 0.1s ease-in 0.1s, color 0.1s ease-in 0.1s;
    cursor: pointer;
  }
  #footer {
    background: #333;
  }
  #footer a:not(.btn):not(.no-footer-css),
  #footer li,
  #footer span,
  #footer p {
    color: #888;
  }
  .custom-list-style-1 > li {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .custom-list-style-1 > li + li {
    border-top: 1px solid #46474a;
  }
  .custom-list-style-1 > li:first-child {
    padding-top: 0;
  }
  .custom-list-style-1 > li:last-child {
    padding-bottom: 0;
  }
  
@charset "UTF-8";

.accordion .card {
    margin-top: 5px
}

.accordion .card:first-child {
    margin-top: 0
}

.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type),
.accordion .card:last-of-type {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: .25rem
}

.accordion .card-title {
    font-size: 16px
}

.accordion .card-header {
    padding: 0;
    border-radius: .25rem;
    border-bottom: 0;
    margin: -1px
}

.accordion .card-header a {
    cursor: pointer;
    display: block;
    padding: 10px 20px
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
    text-decoration: none
}

.accordion .card-header a [class^=icon-] {
    margin-right: 4px;
    position: relative;
    top: 1px
}

.accordion .card-header a [class*=fa-],
.accordion .card-header a .icons {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -1px
}

.accordion .card-header a .icons {
    top: 1px
}

.accordion.accordion-sm .card-header a {
    padding: 5px 15px;
    font-size: .85em
}

.accordion.accordion-sm .card-body {
    font-size: .9em
}

.accordion.accordion-lg .card-header a {
    padding: 15px;
    font-size: 1em;
    font-weight: 600
}

.accordion.without-bg .card-default {
    background: 0 0 !important
}

.accordion.without-bg .card-default>.card-header {
    background: 0 0 !important
}

.accordion.without-bg .card-body {
    padding-top: 0
}

.accordion.without-borders .card {
    border: 0 !important;
    box-shadow: none;
    border-radius: 0
}

.accordion.without-borders .card-header a {
    padding-left: 0;
    padding-right: 0
}

.accordion.without-borders .card-body {
    padding: 0
}

.accordion.accordion-modern .card:first-of-type,
.accordion.accordion-modern .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern .card:last-of-type {
    border: 4px solid #f7f7f7
}

.accordion.accordion-modern .card .card-header {
    background-color: #f7f7f7;
    border-radius: 0
}

.accordion.accordion-modern-2>.card {
    border: 1px solid transparent !important;
    transition: ease border-color 300ms
}

.accordion.accordion-modern-2>.card .card-header a {
    position: relative;
    padding: 23px 30px;
    background-color: #f4f4f4;
    transition: ease background-color 300ms, ease color 300ms;
    border-radius: 4px
}

.accordion.accordion-modern-2>.card .card-header a:after {
    content: '+';
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: inherit;
    font-weight: inherit;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    transform-origin: 50% 50%;
    transition: ease transform 300ms
}

.accordion.accordion-modern-2>.card .card-header a.collapsed:after {
    transform: translate3d(0, -50%, 0) rotate(0deg)
}

.accordion.accordion-modern-2>.card .card-header+.collapse.show,
.accordion.accordion-modern-2>.card .card-header+.collapsing {
    border: 1px solid transparent;
    border-top: 0
}

.accordion.accordion-modern-2>.card+.card {
    margin-top: 17px
}

.accordion.accordion-modern-status>.card {
    border: 1px solid transparent !important;
    transition: ease border-color 300ms
}

.accordion.accordion-modern-status>.card .card-header a {
    position: relative;
    padding: 23px 30px;
    background-color: #f4f4f4;
    transition: ease background-color 300ms, ease color 300ms
}

.accordion.accordion-modern-status>.card .card-header a:after {
    content: '+';
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: inherit;
    font-weight: inherit;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    transform-origin: 50% 50%;
    transition: ease transform 300ms
}

.accordion.accordion-modern-status>.card .card-header a.collapsed:after {
    transform: translate3d(0, -50%, 0) rotate(0deg)
}

.accordion.accordion-modern-status>.card .card-header+.collapse.show,
.accordion.accordion-modern-status>.card .card-header+.collapsing {
    border: 0
}

.accordion.accordion-modern-status>.card+.card {
    margin-top: 17px
}

.accordion.accordion-modern-status.accordion-modern-status-borders>.card {
    border: 1px solid rgba(0, 0, 0, .085) !important
}

.accordion.accordion-modern-status.accordion-modern-status-borders>.card .card-header {
    background: 0 0 !important
}

.accordion.accordion-modern-status.accordion-modern-status-borders>.card .card-header a {
    background: 0 0 !important
}

.accordion.accordion-modern-status.accordion-modern-status-arrow>.card .card-header a:after {
    transform: translate3d(0, -50%, 0) rotate(180deg);
    font-family: 'font awesome 6 free';
    content: "\f107";
    font-weight: 900
}

.accordion.accordion-modern-status.accordion-modern-status-arrow>.card .card-header a.collapsed:after {
    transform: translate3d(0, -50%, 0) rotate(0deg)
}

.accordion.accordion-modern-grey-scale-1 .card:first-of-type,
.accordion.accordion-modern-grey-scale-1 .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern-grey-scale-1 .card:last-of-type {
    border-color: #ededed
}

.accordion.accordion-modern-grey-scale-1 .card .card-header {
    background-color: #ededed
}

.appear-animation {
    opacity: 0
}

.appear-animation-visible {
    opacity: 1
}

.block-appear-animation {
    opacity: 1;
    overflow: hidden;
    position: relative
}

.block-appear-animation .block-appear-animation-wrapper {
    animation-duration: 1s;
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
    animation-delay: 300ms;
    opacity: 0
}

.block-appear-animation.appear-animation-visible .block-appear-animation-wrapper {
    animation-name: fadeIn
}

.transition-2ms {
    transition: all .2s ease-in-out
}

.transition-3ms {
    transition: all .3s ease-in-out
}

.transition-4ms {
    transition: all .4s ease-in-out
}

.transition-5ms {
    transition: all .5s ease-in-out
}

.transition-1s {
    transition: all 1s ease-in-out
}

.transition-magnetic {
    transition: transform .5s cubic-bezier(.25, 1, .2, 1)
}

.anim-hover-translate-top-0px:hover {
    transform: translateY(0px) !important
}

.anim-hover-translate-bottom-0px:hover {
    transform: translateY(0px) !important
}

.anim-hover-translate-left-0px:hover {
    transform: translateX(0px) !important
}

.anim-hover-translate-right-0px:hover {
    transform: translateX(0px) !important
}

.anim-hover-translate-top-5px:hover {
    transform: translateY(-5px) !important
}

.anim-hover-translate-bottom-5px:hover {
    transform: translateY(5px) !important
}

.anim-hover-translate-left-5px:hover {
    transform: translateX(-5px) !important
}

.anim-hover-translate-right-5px:hover {
    transform: translateX(5px) !important
}

.anim-hover-translate-top-10px:hover {
    transform: translateY(-10px) !important
}

.anim-hover-translate-bottom-10px:hover {
    transform: translateY(10px) !important
}

.anim-hover-translate-left-10px:hover {
    transform: translateX(-10px) !important
}

.anim-hover-translate-right-10px:hover {
    transform: translateX(10px) !important
}

.anim-hover-translate-top-20px:hover {
    transform: translateY(-20px) !important
}

.anim-hover-translate-bottom-20px:hover {
    transform: translateY(20px) !important
}

.anim-hover-translate-left-20px:hover {
    transform: translateX(-20px) !important
}

.anim-hover-translate-right-20px:hover {
    transform: translateX(20px) !important
}

.anim-hover-translate-top-30px:hover {
    transform: translateY(-30px) !important
}

.anim-hover-translate-bottom-30px:hover {
    transform: translateY(30px) !important
}

.anim-hover-translate-left-30px:hover {
    transform: translateX(-30px) !important
}

.anim-hover-translate-right-30px:hover {
    transform: translateX(30px) !important
}

.anim-hover-translate-top-40px:hover {
    transform: translateY(-40px) !important
}

.anim-hover-translate-bottom-40px:hover {
    transform: translateY(40px) !important
}

.anim-hover-translate-left-40px:hover {
    transform: translateX(-40px) !important
}

.anim-hover-translate-right-40px:hover {
    transform: translateX(40px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-0px {
    transform: translateY(0px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-0px {
    transform: translateY(0px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-0px {
    transform: translateX(0px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-0px {
    transform: translateX(0px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-5px {
    transform: translateY(-5px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-5px {
    transform: translateY(5px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-5px {
    transform: translateX(-5px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-5px {
    transform: translateX(5px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-10px {
    transform: translateY(-10px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-10px {
    transform: translateY(10px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-10px {
    transform: translateX(-10px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-10px {
    transform: translateX(10px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-20px {
    transform: translateY(-20px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-20px {
    transform: translateY(20px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-20px {
    transform: translateX(-20px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-20px {
    transform: translateX(20px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-30px {
    transform: translateY(-30px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-30px {
    transform: translateY(30px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-30px {
    transform: translateX(-30px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-30px {
    transform: translateX(30px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-top-40px {
    transform: translateY(-40px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-bottom-40px {
    transform: translateY(40px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-left-40px {
    transform: translateX(-40px) !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-translate-right-40px {
    transform: translateX(40px) !important
}

.anim-hover-scale-1-1:hover {
    transform: scale(1.1)
}

.anim-hover-scale-1-2:hover {
    transform: scale(1.2)
}

.anim-hover-opacity-0:hover {
    opacity: 0 !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-opacity-0 {
    opacity: 0 !important
}

.anim-hover-opacity-10:hover {
    opacity: 1 !important
}

.anim-hover-inner-wrapper:hover .anim-hover-inner-opacity-10 {
    opacity: 1 !important
}

@keyframes sideMenuArrow {
    0% {
        left: 5px
    }

    50% {
        left: 10px
    }

    100% {
        left: 5px
    }
}

@keyframes sideMenuArrowBack {
    0% {
        right: 5px
    }

    50% {
        right: 10px
    }

    100% {
        right: 5px
    }
}

@keyframes maskUp {
    from {
        transform: translate(0, 100%)
    }

    to {
        transform: translate(0, 0)
    }
}

@keyframes maskRight {
    from {
        transform: translate(-100%, 0)
    }

    to {
        transform: translate(0, 0)
    }
}

@keyframes maskDown {
    from {
        transform: translate(0, -100%)
    }

    to {
        transform: translate(0, 0)
    }
}

@keyframes maskLeft {
    from {
        transform: translate(100%, 0)
    }

    to {
        transform: translate(0, 0)
    }
}

.maskUp {
    animation-name: maskUp
}

.maskRight {
    animation-name: maskRight
}

.maskDown {
    animation-name: maskDown
}

.maskLeft {
    animation-name: maskLeft
}

@keyframes textEntrance {
    from {
        opacity: 0;
        transform: translateY(100%) rotateX(-80deg)
    }

    to {
        transform: none;
        opacity: 1
    }
}

.textEntrance {
    transform-origin: center top;
    transform-style: preserve-3d;
    transition: opacity 0s cubic-bezier(.215, .61, .355, 1), transform 0s cubic-bezier(.215, .61, .355, 1);
    transition-duration: .8s;
    animation-name: textEntrance
}

@keyframes headerReveal {
    from {
        top: -150px
    }

    to {
        top: 0
    }
}

@keyframes fadeInUpShorter {
    from {
        opacity: 0;
        transform: translate(0, 50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUpShorter {
    animation-name: fadeInUpShorter
}

@keyframes fadeInUpShorterPlus {
    from {
        opacity: 0;
        transform: translate(0, 15px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUpShorterPlus {
    animation-name: fadeInUpShorterPlus
}

@keyframes fadeInLeftShorter {
    from {
        opacity: 0;
        transform: translate(50px, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeftShorter {
    animation-name: fadeInLeftShorter
}

@keyframes fadeInLeftShorterPlus {
    from {
        opacity: 0;
        transform: translate(15px, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeftShorterPlus {
    animation-name: fadeInLeftShorterPlus
}

@keyframes fadeInRightShorter {
    from {
        opacity: 0;
        transform: translate(-50px, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRightShorter {
    animation-name: fadeInRightShorter
}

@keyframes fadeInRightShorterPlus {
    from {
        opacity: 0;
        transform: translate(-15px, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRightShorterPlus {
    animation-name: fadeInRightShorterPlus
}

@keyframes fadeInRightShorterOpacity {
    from {
        opacity: 0;
        transform: translate(-50px, 0)
    }

    to {
        opacity: .7;
        transform: none
    }
}

.fadeInRightShorterOpacity {
    animation-name: fadeInRightShorterOpacity
}

@keyframes fadeInDownShorter {
    from {
        opacity: 0;
        transform: translate(0, -50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInDownShorter {
    animation-name: fadeInDownShorter
}

@keyframes fadeInDownShorterPlus {
    from {
        opacity: 0;
        transform: translate(0, -15px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInDownShorterPlus {
    animation-name: fadeInDownShorterPlus
}

@keyframes fadeInRightDownShorter {
    from {
        opacity: 0;
        transform: translate(-50px, -50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRightDownShorter {
    animation-name: fadeInRightDownShorter
}

@keyframes fadeInRightUpShorter {
    from {
        opacity: 0;
        transform: translate(-50px, 50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRightUpShorter {
    animation-name: fadeInRightUpShorter
}

@keyframes fadeInLeftDownShorter {
    from {
        opacity: 0;
        transform: translate(50px, -50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeftDownShorter {
    animation-name: fadeInLeftDownShorter
}

@keyframes fadeInLeftUpShorter {
    from {
        opacity: 0;
        transform: translate(50px, 50px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeftUpShorter {
    animation-name: fadeInLeftUpShorter
}

@keyframes expandIn {
    from {
        opacity: 0;
        transform: scale(.8)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

.expandIn {
    animation-name: expandIn
}

@keyframes gradientTransition {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes sliderScrollButton {
    0% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0)
    }

    70% {
        opacity: 1;
        transform: translate3d(-50%, 10px, 0)
    }

    100% {
        opacity: 0;
        transform: translate3d(-50%, 10px, 0)
    }
}

@keyframes arrowLeftRight {
    0% {
        transform: translate3d(-1px, 0, 0)
    }

    50% {
        transform: translate3d(1px, 0, 0)
    }

    100% {
        transform: translate3d(-1px, 0, 0)
    }
}

@keyframes navItemArrow {
    0% {
        position: relative;
        left: -1px
    }

    50% {
        position: relative;
        left: 3px
    }

    100% {
        position: relative;
        left: -1px
    }
}

.hover-in {
    transition: .1s ease-out !important
}

.hover-out {
    transition: .1s ease-in !important
}

.hover-3d {
    transition: none
}

@keyframes hotspot-pulsate {
    0% {
        transform: scale(1);
        opacity: .8
    }

    45% {
        transform: scale(1.75);
        opacity: 0
    }
}

@keyframes stop-hotspot-pulsate {
    from {
        opacity: .4
    }

    to {
        transform: scale(2);
        opacity: 0
    }
}

@keyframes dotPulse {
    from {
        opacity: 1;
        transform: scale(.2)
    }

    to {
        opacity: 0;
        transform: scale(1)
    }
}

.dotPulse {
    animation-name: dotPulse;
    animation-iteration-count: infinite;
    animation-duration: 4s
}

@keyframes blurIn {
    from {
        opacity: 0;
        filter: blur(20px);
        transform: scale(1.3)
    }

    to {
        opacity: 1;
        filter: blur(0.1px);
        transform: none
    }
}

.blurIn {
    animation-name: blurIn
}

@keyframes kenBurnsToRight {
    from {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

.kenBurnsToRight {
    animation-name: kenBurnsToRight;
    animation-timing-function: linear;
    transform-origin: right
}

.kenBurnsToRightTop {
    animation-name: kenBurnsToRight;
    animation-timing-function: linear;
    transform-origin: right top
}

@keyframes kenBurnsToLeft {
    from {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

.kenBurnsToLeft {
    animation-name: kenBurnsToLeft;
    animation-timing-function: linear;
    transform-origin: left
}

.kenBurnsToLeftTop {
    animation-name: kenBurnsToLeft;
    animation-timing-function: linear;
    transform-origin: left top
}

@keyframes bgPositionBottomToTop {
    from {
        background-position: bottom
    }

    to {
        background-position: top
    }
}

.bgPositionBottomToTop {
    animation-name: bgPositionBottomToTop;
    animation-timing-function: linear;
    transform-origin: right
}

@keyframes pulseAnim {
    from {
        transform: scale(1);
        opacity: 1
    }

    to {
        transform: scale(1.4);
        opacity: 0
    }
}

.pulseAnim:not(:hover):after {
    animation-name: pulseAnim
}

.pulseAnim:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    z-index: -1
}

.pulseAnim.pulseAnimAnimated:after {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 1s
}

.pulseAnim:not(i):after {
    z-index: 0
}

.pulseAnim:not(i) * {
    position: relative;
    z-index: 1
}

@keyframes basicRotation {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.basicRotation {
    animation-name: basicRotation
}

@keyframes blockSlideToRight {
    from {
        transform: translate(-110%, 0)
    }

    to {
        transform: translate(110%, 0)
    }
}

@keyframes blockSlideToLeft {
    from {
        transform: translate(110%, 0)
    }

    to {
        transform: translate(-110%, 0)
    }
}

@keyframes blockSlideToTop {
    from {
        transform: translate(0, 110%)
    }

    to {
        transform: translate(0, -110%)
    }
}

@keyframes blockSlideToBottom {
    from {
        transform: translate(0, -110%)
    }

    to {
        transform: translate(0, 110%)
    }
}

.blockSlide:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #212529;
    position: absolute;
    z-index: 1000;
    animation-duration: 1s;
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
    animation-name: blockSlideToRight
}

.blockSlideLight:after {
    background: #f7f7f7
}

.blockSlideToLeft:after {
    animation-name: blockSlideToLeft
}

.blockSlideToTop:after {
    animation-name: blockSlideToTop
}

.blockSlideToBottom:after {
    animation-name: blockSlideToBottom
}

@keyframes svgAnimationEffect1 {
    from {
        stroke-dasharray: 1000;
        stroke-dashoffset: -500
    }

    to {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0
    }
}

.svg-animation-effect-1-hover svg {
    transform: scale(1);
    transition: ease transform 300ms
}

.svg-animation-effect-1-hover svg path,
.svg-animation-effect-1-hover svg polygon,
.svg-animation-effect-1-hover svg polyline,
.svg-animation-effect-1-hover svg rect {
    transition: ease fill-opacity 300ms
}

.svg-animation-effect-1-hover:hover svg {
    transform: scale(1.1)
}

.svg-animation-effect-1-hover:hover svg path,
.svg-animation-effect-1-hover:hover svg polygon,
.svg-animation-effect-1-hover:hover svg polyline,
.svg-animation-effect-1-hover:hover svg rect {
    animation-name: svgAnimationEffect1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    fill-opacity: 0;
    stroke-width: .4px
}

[data-plugin-animated-words] {
    opacity: 0
}

[data-plugin-animated-words].initialized {
    opacity: 1
}

.animated-words-wrapper {
    display: inline-block
}

.animated-words-item {
    display: inline-block
}

.animated-letters-wrapper {
    display: inline-block
}

.animated-letters-item {
    display: inline-block
}

.rotate-animation {
    animation: rotate-animation 10s infinite linear
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.rotate-animation-inverse {
    animation: rotate-animation-inverse 10s infinite linear
}

@keyframes rotate-animation-inverse {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-180deg)
    }

    100% {
        transform: rotate(-360deg)
    }
}

.scroll-rotate {
    position: relative;
    transform: translatex(-50%) translatey(-50%)
}

.highlight-scribble-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 45%;
    top: auto;
    bottom: -25%
}

.highlight-scribble-1 path {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    stroke-linecap: round
}

@keyframes highlightScribble1 {
    0% {
        stroke-dashoffset: 1;
        opacity: 0
    }

    1% {
        opacity: 1
    }

    100% {
        stroke-dashoffset: 0
    }
}

.highlightScribble1 path {
    animation: highlightScribble1 1.3s cubic-bezier(.65, 0, .35, 1) forwards
}

.arrow {
    /* background: transparent url(../img/arrows.png) no-repeat 0 0; */
    width: 47px;
    height: 120px;
    display: inline-block;
    position: relative
}

.arrow.arrow-light {
    /* background-image: url(../img/arrows-dark.png) */
}

.arrow.vtl {
    background-position: 0 0;
    width: 47px;
    height: 96px
}

.arrow.vtr {
    background-position: -101px 0;
    width: 47px;
    height: 96px
}

.arrow.vbl {
    background-position: 0 -144px;
    width: 47px;
    height: 96px
}

.arrow.vbr {
    background-position: -101px -144px;
    width: 47px;
    height: 96px
}

.arrow.hlt {
    background-position: -209px 0;
    width: 120px;
    height: 47px
}

.arrow.hlb {
    background-position: -209px -101px;
    width: 120px;
    height: 47px
}

.arrow.hrt {
    background-position: -353px 0;
    width: 120px;
    height: 47px
}

.arrow.hrb {
    background-position: -353px -101px;
    width: 120px;
    height: 47px
}

@media(max-width:575px) {
    .arrow {
        display: none
    }
}

.badge-primary {
    background-color: #ccc;
    color: #fff
}

.badge-success {
    background-color: #28a745;
    color: #fff
}

.badge-warning {
    background-color: #ffc107;
    color: #fff
}

.badge-danger {
    background-color: #dc3545;
    color: #fff
}

.badge-info {
    background-color: #17a2b8;
    color: #fff
}

.badge-dark {
    background-color: #212529;
    color: #fff
}

.badge.badge-xs {
    padding: .3em .5em
}

.badge.badge-sm {
    padding: .5em .8em
}

.badge.badge-md {
    padding: 1em 1.6em;
    font-size: 85%
}

.badge.badge-lg {
    padding: 1.5em 2em;
    font-size: 90%
}

.badge.badge-xl {
    padding: 2em 2.5em;
    font-size: 100%
}

.badge-ecommerce {
    font-size: 9.6px;
    font-size: .6rem;
    font-weight: 600;
    border-radius: 0;
    padding: 6.4px 8px;
    padding: .4rem .5rem
}

.circular-bar {
    text-align: center;
    margin-bottom: 25px
}

.circular-bar .circular-bar-chart {
    position: relative
}

.circular-bar strong {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    position: absolute;
    top: 35%;
    width: 80%;
    left: 10%;
    text-align: center
}

.circular-bar label {
    display: block;
    font-weight: 100;
    font-size: 17px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    width: 80%;
    left: 10%;
    text-align: center
}

.circular-bar.only-icon [class*=fa-],
.circular-bar.only-icon .icons {
    display: block;
    font-size: 38px;
    line-height: 30px;
    position: absolute;
    top: 40%;
    width: 80%;
    left: 10%;
    text-align: center
}

.circular-bar.single-line strong {
    top: 40%
}

.circular-bar.circular-bar-sm label {
    font-size: 14px;
    top: 36%
}

.circular-bar.circular-bar-lg strong {
    font-size: 20px;
    top: 40%
}

.progress {
    border-radius: 25px;
    height: 20px;
    background: #fafafa;
    overflow: visible
}

.progress.progress-border-radius {
    border-radius: 3px
}

.progress.progress-border-radius .progress-bar {
    border-radius: 3px
}

.progress.progress-no-border-radius {
    border-radius: 0
}

.progress.progress-no-border-radius .progress-bar {
    border-radius: 0
}

.progress.progress-xs {
    height: 5px
}

.progress.progress-xs .progress-bar {
    line-height: 5px;
    height: auto
}

.progress.progress-sm {
    height: 10px
}

.progress.progress-sm .progress-bar {
    line-height: 10px;
    height: auto
}

.progress.progress-lg {
    height: 35px
}

.progress.progress-lg .progress-bar {
    font-size: 13px;
    line-height: 35px;
    height: auto
}

.progress.progress-dark {
    background: #333
}

.progress.progress-right-side-value {
    position: relative;
    margin-right: 40px
}

.progress.progress-right-side-value .progress-bar-value {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(40px, -50%, 0)
}

.progress-bar {
    background-color: #ccc;
    box-shadow: none;
    position: relative;
    border-radius: 25px;
    overflow: visible
}

.progress-bar-tooltip {
    position: absolute;
    padding: 4px 8px;
    background-color: #333;
    color: #fff;
    line-height: 15px;
    font-size: 11px;
    display: block;
    position: absolute;
    top: -28px;
    right: 5px;
    border-radius: 3px;
    opacity: 0
}

.progress-bar-tooltip:after {
    border-color: #333 transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    bottom: -5px;
    content: "";
    display: block;
    left: 13px;
    position: absolute;
    width: 0
}

.progress-bar-primary {
    background-color: #ccc;
    color: #fff
}

.progress-bar-success {
    background-color: #28a745;
    color: #fff
}

.progress-bar-warning {
    background-color: #ffc107;
    color: #fff
}

.progress-bar-danger {
    background-color: #dc3545;
    color: #fff
}

.progress-bar-info {
    background-color: #17a2b8;
    color: #fff
}

.progress-bar-dark {
    background-color: #212529;
    color: #fff
}

.breadcrumb {
    background: 0 0;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1
}

.breadcrumb>li {
    display: inline-block;
    font-size: .8em;
    text-transform: uppercase;
    text-shadow: none
}

.breadcrumb>li+li:before {
    color: inherit;
    opacity: .5;
    font-family: 'font awesome 6 free';
    font-weight: 900;
    /* content: "\f105"; */
    padding: 0 7px 0 5px
}

.breadcrumb.breadcrumb-dividers-no-opacity>li+li:before {
    opacity: 1
}

.breadcrumb.breadcrumb-style-2>li+li:before {
    content: "/"
}

.breadcrumb.breadcrumb-block {
    background: #212529;
    display: inline-block !important;
    padding: 7.2px 19.2px;
    padding: .45rem 1.2rem;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .15)
}

.breadcrumb.breadcrumb-light {
    color: #fff
}

.breadcrumb.breadcrumb-light a {
    color: #fff
}

.breadcrumb.breadcrumb-light .active {
    opacity: .7
}

.btn {
    font-size: 12.8px;
    font-size: .8rem;
    padding: 8.528px 14.928px;
    padding: .533rem .933rem;
    cursor: pointer;
    font-weight: 600
}

.btn.btn-xs {
    font-size: 11.2px;
    font-size: .7rem;
    padding: 3.2px 8px;
    padding: .2rem .5rem
}

.btn.btn-sm {
    font-size: 12.8px;
    font-size: .8rem;
    padding: 4.8px 10.4px;
    padding: .3rem .65rem
}

.btn.btn-lg {
    font-size: 16px;
    font-size: 1rem;
    padding: 8px 16px;
    padding: .5rem 1rem
}

.btn.btn-xl {
    font-size: 16px;
    font-size: 1rem;
    padding: 12.8px 32px;
    padding: .8rem 2rem
}

.btn.btn-px-1 {
    padding-left: 5.6px;
    padding-left: .35rem;
    padding-right: 5.6px;
    padding-right: .35rem
}

.btn.btn-px-2 {
    padding-left: 12px;
    padding-left: .75rem;
    padding-right: 12px;
    padding-right: .75rem
}

.btn.btn-px-3 {
    padding-left: 20px;
    padding-left: 1.25rem;
    padding-right: 20px;
    padding-right: 1.25rem
}

.btn.btn-px-4 {
    padding-left: 28px;
    padding-left: 1.75rem;
    padding-right: 28px;
    padding-right: 1.75rem
}

.btn.btn-px-5 {
    padding-left: 38.4px;
    padding-left: 2.4rem;
    padding-right: 38.4px;
    padding-right: 2.4rem
}

.btn.btn-py-1 {
    padding-top: 5.6px;
    padding-top: .35rem;
    padding-bottom: 5.6px;
    padding-bottom: .35rem
}

.btn.btn-py-2 {
    padding-top: 12px;
    padding-top: .75rem;
    padding-bottom: 12px;
    padding-bottom: .75rem
}

.btn.btn-py-3 {
    padding-top: 20px;
    padding-top: 1.25rem;
    padding-bottom: 20px;
    padding-bottom: 1.25rem
}

.btn.btn-py-4 {
    padding-top: 28px;
    padding-top: 1.75rem;
    padding-bottom: 28px;
    padding-bottom: 1.75rem
}

.btn.btn-py-5 {
    padding-top: 32px;
    padding-top: 2rem;
    padding-bottom: 32px;
    padding-bottom: 2rem
}

html body .btn.focus,
html body .btn:focus,
html body .btn.active,
html body .btn:active,
html body.body .btn.focus,
html body.body .btn:focus,
html body.body .btn.active,
html body.body .btn:active {
    box-shadow: none !important
}

.btn+.dropdown-menu .dropdown-item {
    font-size: 12.8px;
    font-size: .8rem
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none !important
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-outline {
    border-width: 2px;
    padding: 7.728px 14.928px;
    padding: .483rem .933rem
}

.btn-outline.btn-xs {
    padding: 2.4px 8px;
    padding: .15rem .5rem
}

.btn-outline.btn-sm {
    padding: 4px 10.4px;
    padding: .25rem .65rem
}

.btn-outline.btn-lg {
    padding: 7.2px 16px;
    padding: .45rem 1rem
}

.btn-outline.btn-xl {
    padding: 12px 32px;
    padding: .75rem 2rem
}

.btn-outline.btn-outline-thin {
    border-width: 1px
}

.btn-modern {
    text-transform: uppercase;
    font-size: .8em;
    padding: 12.8px 24px;
    padding: .8rem 1.5rem;
    font-weight: 600
}

.btn-rounded {
    border-radius: 35px
}

.btn-rounded-icon {
    border-radius: 50px;
    padding: 0;
    width: 50px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.btn-rounded-icon[data-hash]>i {
    pointer-events: none
}

.btn-3d {
    border-bottom-width: 3px
}

.btn-with-arrow,
.btn-with-arrow-solid {
    font-weight: 600
}

.btn-with-arrow:not(.btn-with-arrow-solid),
.btn-with-arrow-solid:not(.btn-with-arrow-solid) {
    background-color: transparent
}

.btn-with-arrow span,
.btn-with-arrow-solid span {
    background-color: #ccc;
    display: inline-block;
    height: 25px;
    line-height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-left: 10px;
    transition: all .1s linear
}

.btn-with-arrow i,
.btn-with-arrow-solid i {
    color: #fff;
    font-size: 8px;
    top: -1px;
    position: relative
}

.btn-with-arrow:hover span,
.btn-with-arrow-solid:hover span {
    transform: translateX(5px)
}

.btn-with-arrow.btn-lg span,
.btn-with-arrow.btn-xl span,
.btn-with-arrow-solid.btn-lg span,
.btn-with-arrow-solid.btn-xl span {
    line-height: 23px
}

.btn-with-arrow.btn-xs span,
.btn-with-arrow-solid.btn-xs span {
    line-height: 25px
}

.btn-swap-1 {
    position: relative
}

.btn-swap-1>* {
    transition: cubic-bezier(.55, 0, .1, 1) all 300ms;
    white-space: nowrap
}

.btn-swap-1>:nth-child(1) {
    max-width: 100%;
    text-align: center;
    top: calc(50% - 10px);
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    position: absolute
}

.btn-swap-1>:nth-child(2) {
    display: inline-block;
    transform: translateY(120%);
    opacity: 0
}

.btn-swap-1:hover>:nth-child(1) {
    transform: translate(-50%, -120%);
    opacity: 0
}

.btn-swap-1:hover>:nth-child(2) {
    transform: none;
    opacity: 1
}

@keyframes btnArrowEffect1 {
    0% {
        transform: translateX(0)
    }

    51% {
        transform: translateX(20px);
        opacity: 1
    }

    52% {
        opacity: 0;
        transform: translateX(-20px)
    }

    53% {
        opacity: 0
    }

    54% {
        opacity: 1
    }

    100% {
        transform: translateX(0)
    }
}

.btn-arrow-effect-1:hover>:nth-child(1) {
    animation: btnArrowEffect1 ease-out .3s
}

.btn-link-underline-1:before {
    height: 1px !important
}

.btn-link-effect-1 {
    position: relative
}

.btn-link-effect-1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background: #212529;
    transition: width .25s ease-out
}

.btn-link-effect-1:hover:before,
.btn-link-effect-1:active:before {
    width: 100%
}

.btn-link-effect-2 {
    position: relative
}

.btn-link-effect-2:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background: #212529;
    transition: width .25s ease-out
}

.btn-link-effect-2:hover:before,
.btn-link-effect-2:active:before {
    width: 100%
}

.btn-link-effect-3 {
    position: relative
}

.btn-link-effect-3:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 0;
    height: 2px;
    background: #212529;
    transition: width .3s ease 0s, left .3s ease 0s
}

.btn-link-effect-3:hover:before,
.btn-link-effect-3:active:before {
    width: 100%;
    left: 0
}

.btn-link-effect-4 {
    position: relative
}

.btn-link-effect-4:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background: #212529;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform .3s
}

.btn-link-effect-4:hover:before,
.btn-link-effect-4:active:before {
    transform-origin: bottom left;
    transform: scaleX(1)
}

.btn-link-effect-5 {
    position: relative
}

.btn-link-effect-5:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background: #212529;
    transition: height .2s ease
}

.btn-link-effect-5:hover:before,
.btn-link-effect-5:active:before {
    height: 100%
}

.btn-icon i {
    margin-right: 10px
}

.btn-icon-right i {
    margin-right: 0;
    margin-left: 10px
}

.btn.dropdown-toggle:after {
    margin-left: .155em;
    vertical-align: .155em
}

.btn-primary {
    background-color: #ccc;
    border-color: #ccc #ccc #b3b2b2;
    color: #fff
}

.btn-primary:hover,
.btn-primary.hover {
    background-color: #dfdfdf;
    border-color: #e6e5e5 #e6e5e5 #ccc;
    color: #fff
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(204, 204, 204, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #ccc;
    border-color: #ccc #ccc #b3b2b2
}

.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    background-color: #b9b9b9;
    background-image: none;
    border-color: #b3b2b2 #b3b2b2 #999
}

.btn-outline.btn-primary {
    color: #ccc;
    background-color: transparent;
    background-image: none;
    border-color: #ccc
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-primary.hover {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc
}

.btn-outline.btn-primary:focus,
.btn-outline.btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(204, 204, 204, .5)
}

.btn-outline.btn-primary.disabled,
.btn-outline.btn-primary:disabled {
    color: #ccc;
    background-color: transparent
}

.btn-outline.btn-primary:active,
.btn-outline.btn-primary.active,
.show>.btn-outline.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745 #28a745 #1e7e34;
    color: #fff
}

.btn-success:hover,
.btn-success.hover {
    background-color: #2fc652;
    border-color: #34ce57 #34ce57 #28a745;
    color: #fff
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745 #28a745 #1e7e34
}

.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34 #1e7e34 #145523
}

.btn-outline.btn-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745
}

.btn-outline.btn-success:hover,
.btn-outline.btn-success.hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline.btn-success:focus,
.btn-outline.btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, .5)
}

.btn-outline.btn-success.disabled,
.btn-outline.btn-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline.btn-success:active,
.btn-outline.btn-success.active,
.show>.btn-outline.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107 #ffc107 #d39e00;
    color: #fff
}

.btn-warning:hover,
.btn-warning.hover {
    background-color: #ffcb2d;
    border-color: #ffce3a #ffce3a #ffc107;
    color: #fff
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107 #ffc107 #d39e00
}

.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00 #d39e00 #a07800
}

.btn-outline.btn-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107
}

.btn-outline.btn-warning:hover,
.btn-outline.btn-warning.hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline.btn-warning:focus,
.btn-outline.btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, .5)
}

.btn-outline.btn-warning.disabled,
.btn-outline.btn-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline.btn-warning:active,
.btn-outline.btn-warning.active,
.show>.btn-outline.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545 #dc3545 #bd2130;
    color: #fff
}

.btn-danger:hover,
.btn-danger.hover {
    background-color: #e25663;
    border-color: #e4606d #e4606d #dc3545;
    color: #fff
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545 #dc3545 #bd2130
}

.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130 #bd2130 #921925
}

.btn-outline.btn-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545
}

.btn-outline.btn-danger:hover,
.btn-outline.btn-danger.hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline.btn-danger:focus,
.btn-outline.btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, .5)
}

.btn-outline.btn-danger.disabled,
.btn-outline.btn-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline.btn-danger:active,
.btn-outline.btn-danger.active,
.show>.btn-outline.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8 #17a2b8 #117a8b;
    color: #fff
}

.btn-info:hover,
.btn-info.hover {
    background-color: #1bc0da;
    border-color: #1fc8e3 #1fc8e3 #17a2b8;
    color: #fff
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8 #17a2b8 #117a8b
}

.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b #117a8b #0c525d
}

.btn-outline.btn-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8
}

.btn-outline.btn-info:hover,
.btn-outline.btn-info.hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline.btn-info:focus,
.btn-outline.btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, .5)
}

.btn-outline.btn-info.disabled,
.btn-outline.btn-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline.btn-info:active,
.btn-outline.btn-info.active,
.show>.btn-outline.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-dark {
    background-color: #212529;
    border-color: #212529 #212529 #0a0c0d;
    color: #fff
}

.btn-dark:hover,
.btn-dark.hover {
    background-color: #32383e;
    border-color: #383f45 #383f45 #212529;
    color: #fff
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #212529;
    border-color: #212529 #212529 #0a0c0d
}

.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
    background-color: #101214;
    background-image: none;
    border-color: #0a0c0d #0a0c0d #000
}

.btn-outline.btn-dark {
    color: #212529;
    background-color: transparent;
    background-image: none;
    border-color: #212529
}

.btn-outline.btn-dark:hover,
.btn-outline.btn-dark.hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-outline.btn-dark:focus,
.btn-outline.btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(33, 37, 41, .5)
}

.btn-outline.btn-dark.disabled,
.btn-outline.btn-dark:disabled {
    color: #212529;
    background-color: transparent
}

.btn-outline.btn-dark:active,
.btn-outline.btn-dark.active,
.show>.btn-outline.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

html body .btn-light,
html body .btn-outline.btn-light {
    border-color: #d9d9d9 #d9d9d9 #bfbfbf;
    color: #777
}

html body .btn-light.btn-outline-light-opacity-2,
html body .btn-outline.btn-light.btn-outline-light-opacity-2 {
    border-color: rgba(255, 255, 255, .2)
}

html body .btn-light:hover,
html body .btn-light.hover,
html body .btn-outline.btn-light:hover,
html body .btn-outline.btn-light.hover {
    background-color: #f2f2f2;
    border-color: #e6e5e5 #e6e5e5 #ccc;
    color: #777
}

html body .btn-light:focus,
html body .btn-light.focus,
html body .btn-outline.btn-light:focus,
html body .btn-outline.btn-light.focus {
    box-shadow: 0 0 0 3px rgba(191, 191, 191, .5)
}

html body .btn-light.disabled,
html body .btn-light:disabled,
html body .btn-outline.btn-light.disabled,
html body .btn-outline.btn-light:disabled {
    border-color: #bfbfbf #bfbfbf #bfbfbf
}

html body .btn-light:active,
html body .btn-light.active,
.show>html body .btn-light.dropdown-toggle,
html body .btn-outline.btn-light:active,
html body .btn-outline.btn-light.active,
.show>html body .btn-outline.btn-light.dropdown-toggle {
    border-color: #bfbfbf #bfbfbf #bfbfbf
}

html body .btn-light-2,
html body .btn-outline.btn-light-2 {
    border-color: #fff;
    color: #fff
}

html body .btn-light-2:hover,
html body .btn-light-2.hover,
html body .btn-outline.btn-light-2:hover,
html body .btn-outline.btn-light-2.hover {
    background-color: #fff;
    border-color: #fff;
    color: #0e0e0e
}

html body .btn-light-2:focus,
html body .btn-light-2.focus,
html body .btn-outline.btn-light-2:focus,
html body .btn-outline.btn-light-2.focus {
    box-shadow: 0 0 0 3px rgba(179, 178, 178, .5)
}

html body .btn-light-2.disabled,
html body .btn-light-2:disabled,
html body .btn-outline.btn-light-2.disabled,
html body .btn-outline.btn-light-2:disabled {
    border-color: #b3b2b2 #b3b2b2 #b3b2b2
}

html body .btn-light-2:active,
html body .btn-light-2.active,
.show>html body .btn-light-2.dropdown-toggle,
html body .btn-outline.btn-light-2:active,
html body .btn-outline.btn-light-2.active,
.show>html body .btn-outline.btn-light-2.dropdown-toggle {
    border-color: #fff
}

html[dir=rtl] .input-group .form-control:not(:first-child),
html[dir=rtl] .input-group-addon:not(:first-child),
html[dir=rtl] .input-group-btn:not(:first-child)>.btn,
html[dir=rtl] .input-group-btn:not(:first-child)>.btn-group>.btn,
html[dir=rtl] .input-group-btn:not(:first-child)>.dropdown-toggle,
html[dir=rtl] .input-group-btn:not(:last-child)>.btn-group:not(:first-child)>.btn,
html[dir=rtl] .input-group-btn:not(:last-child)>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.hamburguer-btn {
    background: 0 0;
    outline: 0 !important;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer
}

.hamburguer-btn.hamburguer-btn-side-header {
    position: absolute;
    top: 15px;
    right: 8px
}

.hamburguer-btn.hamburguer-btn-side-header-overlay {
    right: 30px;
    z-index: 9999
}

.hamburguer-btn .hamburguer {
    pointer-events: none
}

.hamburguer-btn .hamburguer span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transition: ease width 300ms;
    transform: translateY(-50%);
    background: #333
}

.hamburguer-btn .hamburguer span:nth-child(1) {
    top: 30%
}

.hamburguer-btn .hamburguer span:nth-child(2) {
    transition-delay: 100ms
}

.hamburguer-btn .hamburguer span:nth-child(3) {
    top: 70%;
    transition-delay: 200ms
}

.hamburguer-btn .close {
    opacity: 0;
    pointer-events: none;
    transition: ease all 300ms
}

.hamburguer-btn .close span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 1px;
    background: #333;
    transition: ease all 300ms;
    transform-origin: 50% 0
}

.hamburguer-btn .close span:nth-child(1) {
    transform: translateX(-50%) rotate(45deg)
}

.hamburguer-btn .close span:nth-child(2) {
    transform: translateX(-50%) rotate(-45deg)
}

.hamburguer-btn.active .hamburguer {
    opacity: 0
}

.hamburguer-btn.active .hamburguer span {
    width: 0
}

.hamburguer-btn.active .close {
    opacity: 1;
    transition: ease all 300ms
}

.hamburguer-btn.active .close span {
    width: 80%;
    transition: ease all 300ms
}

.hamburguer-btn.active .close span:nth-child(2) {
    transition-delay: 300ms
}

.hamburguer-btn.hamburguer-btn-light .hamburguer span,
.hamburguer-btn.hamburguer-btn-light .close span {
    background: #fff
}

html.overflow-hidden .hamburguer-btn.hamburguer-btn-side-header-overlay {
    right: 13px
}

html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-light .close span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-light .close span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .hamburguer span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-light .close span {
    background: #fff
}

html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
html.sticky-header-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
.pin-wrapper .sticky-active:not([class*=sticky-wrapper-effect]) .hamburguer-btn.hamburguer-btn-sticky-dark .close span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .hamburguer span,
.pin-wrapper .sticky-effect-active .hamburguer-btn.hamburguer-btn-sticky-dark .close span {
    background: #212529
}

.btn-badge {
    position: relative
}

.btn-badge .badge {
    top: -8px;
    top: -.5rem;
    right: -8px;
    right: -.5rem;
    position: absolute
}

.btn-gradient:not(.btn-outline) {
    border: 0
}

.btn-gradient:not(.btn-outline):hover,
.btn-gradient:not(.btn-outline).hover {
    border: 0
}

.btn-gradient:not(.btn-outline):focus,
.btn-gradient:not(.btn-outline).focus {
    border: 0
}

.btn-gradient:not(.btn-outline).disabled,
.btn-gradient:not(.btn-outline):disabled {
    border: 0
}

.btn-gradient:not(.btn-outline):active,
.btn-gradient:not(.btn-outline).active {
    border: 0
}

.btn-gradient.btn-outline {
    border: 2px solid transparent;
    background: 0 0;
    border-image-slice: 1 !important
}

.btn-gradient.btn-outline:hover,
.btn-gradient.btn-outline.hover {
    border: 2px solid transparent
}

.btn-gradient.btn-outline.disabled,
.btn-gradient.btn-outline:disabled {
    border: 2px solid transparent
}

.btn-gradient.btn-outline:active,
.btn-gradient.btn-outline.active {
    border: 2px solid transparent
}

.play-button-sm {
    width: 35px;
    height: 35px
}

.play-button-md {
    width: 60px;
    height: 60px
}

.play-button-lg {
    width: 90px;
    height: 90px
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px !important
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.6;
    border-radius: 25px !important
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 2;
    border-radius: 35px !important
}

.btn.btn-effect-1 {
    transition: .25s
}

.btn.btn-effect-1:hover,
.btn.btn-effect-1:focus {
    box-shadow: inset 0 0 0 2em var(--hover)
}

.btn.btn-effect-2 {
    transition: .25s
}

.btn.btn-effect-2:hover,
.btn.btn-effect-2:focus {
    animation: btn-pulse 1s;
    box-shadow: 0 0 0 2em transparent
}

.btn.btn-effect-3 {
    transition: .25s
}

.btn.btn-effect-3:hover,
.btn.btn-effect-3:focus {
    box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover)
}

.btn.btn-effect-4 {
    transition: .25s
}

.btn.btn-effect-4:hover,
.btn.btn-effect-4:focus {
    box-shadow: 0 .5em .5em -.4em var(--hover);
    transform: translateY(-0.25em)
}

.btn.btn-effect-5 {
    transition: .25s
}

.btn.btn-effect-5:hover,
.btn.btn-effect-5:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover)
}

.btn.btn-effect-6 {
    transition: .25s
}

.btn.btn-effect-6:hover,
.btn.btn-effect-6:focus {
    box-shadow: inset 6.5em 0 0 0 var(--hover)
}

.btn.btn-effect-7 {
    position: relative
}

.btn.btn-effect-7:before {
    background: var(--hover);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: transform .2s cubic-bezier(.4, .3, .35, .9) 0s;
    transform: scaleX(0);
    transform-origin: right center
}

.btn.btn-effect-7:hover:before,
.btn.btn-effect-7:focus:before {
    transform: scale(1);
    transform-origin: left center
}

.btn.btn-effect-7 span {
    position: relative;
    z-index: 1
}

@keyframes btn-pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover)
    }
}

.call-to-action {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 32px 55px;
    clear: both
}

.call-to-action::after {
    clear: both;
    content: "";
    display: block
}

@media(max-width:767px) {
    .call-to-action {
        padding: 10px
    }
}

.call-to-action h3 {
    text-transform: none;
    color: #212529;
    padding: 0;
    margin: 0 0 9px
}

.call-to-action p {
    font-size: 1.2em
}

.call-to-action>[class*=col-] {
    display: flex;
    align-items: center
}

.call-to-action>[class*=col-]:nth-child(2) {
    justify-content: flex-end;
    text-align: center
}

@media(max-width:767px) {
    .call-to-action>[class*=col-]:nth-child(2) {
        justify-content: center
    }
}

.call-to-action .container>.row,
.call-to-action .container-fluid>.row {
    display: flex;
    flex-wrap: wrap
}

.call-to-action .container>.row>[class*=col-],
.call-to-action .container-fluid>.row>[class*=col-] {
    display: flex;
    align-items: center
}

.call-to-action .container>.row>[class*=col-]:nth-child(2),
.call-to-action .container-fluid>.row>[class*=col-]:nth-child(2) {
    justify-content: flex-end;
    text-align: center
}

@media(max-width:767px) {

    .call-to-action .container>.row>[class*=col-]:nth-child(2),
    .call-to-action .container-fluid>.row>[class*=col-]:nth-child(2) {
        justify-content: center
    }
}

@media(max-width:767px) {
    .call-to-action>[class*=col-]:not([class*=col-xs]) {
        width: 100%
    }

    .call-to-action .container>.row [class*=col-]:not([class*=col-xs]) {
        width: 100%
    }
}

.call-to-action .call-to-action-content {
    text-align: center
}

.call-to-action.call-to-action-big {
    padding: 63px 55px
}

@media(max-width:575px) {
    .call-to-action.call-to-action-big {
        padding: 63px 0
    }
}

.call-to-action.call-to-action-front {
    z-index: 1
}

.call-to-action.call-to-action-default {
    background: #f7f7f7
}

.call-to-action.call-to-action-strong-grey {
    background: #ededed
}

.call-to-action.call-to-action-dark {
    background: #333940
}

.call-to-action.call-to-action-dark h3,
.call-to-action.call-to-action-dark p {
    color: #fff
}

.call-to-action.with-borders {
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04)
}

.call-to-action.with-full-borders {
    border: 2px solid #ccc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04)
}

.call-to-action.featured {
    background: #fff;
    margin: 0;
    border-top: 3px solid #ccc;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04)
}

.call-to-action.with-button-arrow .call-to-action-btn {
    position: relative
}

@media(min-width:992px) {
    .call-to-action.with-button-arrow .call-to-action-btn {
        left: -80px
    }
}

.call-to-action.with-button-arrow .call-to-action-btn .arrow {
    position: absolute
}

@media(max-width:991px) {
    .call-to-action.with-button-arrow .call-to-action-btn .arrow {
        display: none
    }
}

.call-to-action.with-button-arrow .call-to-action-btn.no-left {
    left: 0
}

@media(max-width:991px) {
    .call-to-action.with-button-arrow .call-to-action-btn.no-left {
        margin-top: -25px !important
    }
}

.call-to-action.with-button-arrow.button-arrow-price-centered .call-to-action-btn>span {
    position: relative
}

.call-to-action.with-button-arrow.button-arrow-price-centered .call-to-action-btn>span .arrow {
    right: 50% !important
}

.call-to-action.content-align-center .call-to-action-content,
.call-to-action.content-align-center .call-to-action-btn {
    width: 100%;
    text-align: center
}

.call-to-action.button-centered .call-to-action-content {
    text-align: center;
    width: 100%;
    padding: 35px 0 0;
    margin: 0
}

.call-to-action.button-centered .call-to-action-btn {
    text-align: center;
    position: static;
    margin: 20px auto 25px;
    width: auto
}

.call-to-action.call-to-action-in-footer {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 30px;
    margin-bottom: -50px !important
}

.call-to-action.call-to-action-in-footer:before {
    content: "";
    display: block;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    height: 20px;
    width: 20px;
    top: -5px;
    left: 50%;
    margin-left: -20px
}

.call-to-action.no-top-arrow:before {
    display: none
}

.call-to-action.call-to-action-in-footer-margin-top {
    margin-top: 60px
}

.container .call-to-action {
    border-radius: 8px
}

@media(max-width:767px) {

    .call-to-action .call-to-action-content,
    .call-to-action .call-to-action-btn {
        margin: 0;
        padding: 20px;
        position: static;
        width: auto
    }
}

.card {
    position: relative;
    border: 1px solid rgba(0, 0, 0, .06)
}

.card-header {
    border-bottom: 1px solid rgba(0, 0, 0, .06)
}

.card-footer {
    border-top: 1px solid rgba(0, 0, 0, .06)
}

.card-body {
    padding: 32px;
    padding: 2rem;
    position: relative;
    z-index: 1
}

.card-text-color-hover-light:hover * {
    color: #fff !important
}

.card-background-image-hover {
    background-size: cover;
    background-position: center
}

.card-background-image-hover .card-body {
    background: rgba(255, 255, 255, .9);
    margin: 16px;
    margin: 1rem;
    padding: 16px;
    padding: 1rem
}

.card-border {
    overflow: hidden
}

.card-border:after {
    content: "";
    transition: .2s all ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    left: 50%;
    height: 5px;
    width: 100%;
    transform: translateX(-50%);
    background-color: var(--primary)
}

.card-border.card-border-top:after {
    top: 0
}

.card-border.card-border-bottom:after {
    bottom: 0
}

.card-border.card-border-hover:after {
    width: 0
}

.card-border.card-border-hover:hover:after {
    width: 100%
}

.flip-card {
    position: relative;
    border: none;
    perspective: 1000px
}

.flip-card .flip-front,
.flip-card .flip-back {
    padding: 32px;
    padding: 2rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #fff;
    box-shadow: 0 0 44px -10px rgba(0, 0, 0, .15);
    transform-style: preserve-3d;
    transition: ease transform 500ms
}

.flip-card .flip-front .flip-content,
.flip-card .flip-back .flip-content {
    transform: translate3d(0, 0, 1px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.flip-card .flip-front.overlay:before,
.flip-card .flip-back.overlay:before {
    transform: translate3d(0, 0, 0px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.flip-card .flip-front {
    transform: translate3d(0, 0, 1px) rotateY(0deg)
}

.flip-card .flip-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, -1px) rotateY(180deg)
}

.flip-card:hover .flip-front {
    transform: translate3d(0, 0, -1px) rotateY(-180deg)
}

.flip-card:hover .flip-back {
    transform: translate3d(0, 0, 1px) rotateY(0deg)
}

.flip-card.flip-card-vertical .flip-front {
    transform: translate3d(0, 0, 1px) rotateX(0deg)
}

.flip-card.flip-card-vertical .flip-back {
    transform: translate3d(0, 0, -1px) rotateX(180deg)
}

.flip-card.flip-card-vertical:hover .flip-front {
    transform: translate3d(0, 0, -1px) rotateX(-180deg)
}

.flip-card.flip-card-vertical:hover .flip-back {
    transform: translate3d(0, 0, 1px) rotateX(0deg)
}

.flip-card.flip-card-3d .flip-front .flip-content,
.flip-card.flip-card-3d .flip-back .flip-content {
    perspective: inherit;
    transform: translate3d(0, 0, 60px)
}

.owl-carousel {
    display: block;
    margin-bottom: 20px;
    opacity: 0
}

.owl-carousel.owl-loaded {
    opacity: 1
}

.owl-carousel:not(.owl-loaded):not(.owl-carousel-light)>div,
.owl-carousel:not(.owl-loaded):not(.owl-carousel-light) span {
    display: none
}

.owl-carousel:not(.owl-loaded):not(.owl-carousel-light)>div:first-child,
.owl-carousel:not(.owl-loaded):not(.owl-carousel-light) span:first-child {
    display: block
}

.owl-carousel .owl-item img {
    transform-style: unset
}

.owl-carousel .owl-item img[data-icon] {
    display: inline
}

.owl-carousel .thumbnail {
    margin-right: 1px
}

.owl-carousel .item-video {
    height: 300px
}

.owl-carousel .owl-nav {
    top: 50%;
    position: absolute;
    width: 100%;
    margin-top: 0;
    transform: translate3d(0, -50%, 0)
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    outline: 0;
    margin: 0;
    transform: translate3d(0, -50%, 0)
}

.owl-carousel .owl-nav button.owl-prev {
    left: 0
}

.owl-carousel .owl-nav button.owl-prev:before {
    font-family: 'font awesome 6 free';
    font-weight: 900;
    font-size: 8px;
    font-size: .5rem;
    content: "\f053";
    position: relative;
    left: -1px;
    top: -1px
}

.owl-carousel .owl-nav button.owl-next {
    right: 0
}

.owl-carousel .owl-nav button.owl-next:before {
    font-family: 'font awesome 6 free';
    font-weight: 900;
    font-size: 8px;
    font-size: .5rem;
    content: "\f054";
    position: relative;
    left: 1px;
    top: -1px
}

.owl-carousel.stage-margin .owl-stage-outer {
    margin-left: 40px !important;
    margin-right: 40px !important
}

.owl-carousel.stage-margin .owl-stage-outer .owl-stage {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.owl-carousel.stage-margin.stage-margin-sm .owl-stage-outer {
    margin-left: 50px !important;
    margin-right: 50px !important
}

.owl-carousel.stage-margin.stage-margin-md .owl-stage-outer {
    margin-left: 75px !important;
    margin-right: 75px !important
}

.owl-carousel.stage-margin.stage-margin-lg .owl-stage-outer {
    margin-left: 100px !important;
    margin-right: 100px !important
}

.owl-carousel.top-border {
    border-top: 1px solid #eaeaea;
    padding-top: 18px
}

.owl-carousel.nav-remove-prev .owl-nav .owl-prev {
    display: none
}

.owl-carousel.nav-remove-next .owl-nav .owl-next {
    display: none
}

.owl-carousel.nav-full-height .owl-stage-outer {
    z-index: 1
}

.owl-carousel.nav-full-height .owl-nav {
    height: 100%
}

.owl-carousel.nav-full-height .owl-nav .owl-next,
.owl-carousel.nav-full-height .owl-nav .owl-prev {
    height: 100% !important
}

.owl-carousel.show-nav-hover .owl-nav {
    opacity: 0;
    transition: all .2s ease-in-out
}

.owl-carousel.show-nav-hover .owl-nav button.owl-prev {
    left: 0;
    transition: all .2s ease-in-out
}

.owl-carousel.show-nav-hover .owl-nav button.owl-next {
    right: 0;
    transition: all .2s ease-in-out
}

.owl-carousel.show-nav-hover:hover .owl-nav {
    opacity: 1
}

.owl-carousel.show-nav-hover:hover .owl-nav button.owl-prev {
    left: -40px
}

.owl-carousel.show-nav-hover:hover .owl-nav button.owl-next {
    right: -40px
}

.owl-carousel.show-nav-title .owl-nav {
    top: 0;
    right: 0;
    margin-top: -25px;
    width: auto
}

.owl-carousel.show-nav-title .owl-nav button[class*=owl-],
.owl-carousel.show-nav-title .owl-nav button[class*=owl-]:hover,
.owl-carousel.show-nav-title .owl-nav button[class*=owl-]:active {
    font-size: 18px;
    background: 0 0 !important;
    width: 18px
}

.owl-carousel.show-nav-title .owl-nav button.owl-prev {
    left: -40px
}

.owl-carousel.show-nav-title .owl-nav button.owl-prev:before,
.owl-carousel.show-nav-title .owl-nav button.owl-prev:after,
.owl-carousel.show-nav-title .owl-nav button.owl-next:before,
.owl-carousel.show-nav-title .owl-nav button.owl-next:after {
    font-size: inherit
}

.owl-carousel.show-nav-title.show-nav-title-both-sides .owl-nav {
    width: 100%
}

.owl-carousel.show-nav-title.show-nav-title-both-sides button.owl-prev {
    left: 0
}

.owl-carousel.show-nav-title.show-nav-title-both-sides button.owl-next {
    right: 0
}

.owl-carousel.show-nav-title.show-nav-title-both-sides-style-2 .owl-nav {
    margin-top: 15px
}

.owl-carousel.rounded-nav .owl-nav button[class*=owl-] {
    padding: 3px 7px;
    border-radius: 50%;
    background: 0 0;
    border: 1px solid #999;
    color: #999
}

.owl-carousel.rounded-nav .owl-nav button[class*=owl-]:hover,
.owl-carousel.rounded-nav .owl-nav button[class*=owl-].hover {
    background: 0 0;
    border: 1px solid #a1a1a1;
    color: #a1a1a1
}

.owl-carousel.rounded-nav .owl-nav button[class*=owl-]:active,
.owl-carousel.rounded-nav .owl-nav button[class*=owl-].active {
    background: 0 0;
    border: 1px solid #666;
    color: #666
}

.owl-carousel.nav-bottom .owl-stage-outer {
    margin-bottom: 10px
}

.owl-carousel.nav-bottom .owl-nav {
    position: static;
    margin: 0;
    padding: 0;
    width: auto;
    transform: none
}

.owl-carousel.nav-bottom .owl-nav button.owl-prev,
.owl-carousel.nav-bottom .owl-nav button.owl-next {
    position: static;
    transform: none
}

.owl-carousel.nav-bottom .owl-nav button.owl-prev {
    margin-right: 5px
}

.owl-carousel.nav-bottom .owl-nav button.owl-next {
    margin-left: 5px
}

.owl-carousel.nav-bottom.nav-bottom-align-left .owl-nav {
    text-align: left
}

.owl-carousel.nav-bottom.nav-bottom-align-right .owl-nav {
    text-align: right
}

.owl-carousel.nav-bottom-inside .owl-nav {
    position: relative;
    margin: -68.8px 0 0;
    margin: -4.3rem 0 0;
    padding: 0;
    width: auto
}

.owl-carousel.nav-bottom-inside .owl-nav button.owl-prev,
.owl-carousel.nav-bottom-inside .owl-nav button.owl-next {
    position: static
}

.owl-carousel.nav-inside .owl-nav button.owl-prev {
    left: 15px
}

.owl-carousel.nav-inside .owl-nav button.owl-next {
    right: 15px;
    left: auto
}

.owl-carousel.nav-inside.nav-inside-edge .owl-nav button.owl-prev {
    left: 0
}

.owl-carousel.nav-inside.nav-inside-edge .owl-nav button.owl-next {
    right: 0;
    left: auto
}

.owl-carousel.nav-inside.nav-inside-plus .owl-nav button.owl-prev {
    left: 30px
}

.owl-carousel.nav-inside.nav-inside-plus .owl-nav button.owl-next {
    right: 30px;
    left: auto
}

.owl-carousel.nav-inside.nav-bottom .owl-nav {
    position: absolute;
    top: auto;
    bottom: 40px;
    width: 100%
}

.owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-prev,
.owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-next {
    position: relative
}

.owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-prev {
    left: 0
}

.owl-carousel.nav-inside.nav-bottom .owl-nav button.owl-next {
    right: 0
}

.owl-carousel.nav-inside.nav-bottom.nav-style-2 .owl-nav button.owl-prev,
.owl-carousel.nav-inside.nav-bottom.nav-style-2 .owl-nav button.owl-next {
    position: relative
}

.owl-carousel.nav-inside.nav-inside-half-section .owl-nav {
    top: auto;
    bottom: 60px
}

.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-prev,
.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-next {
    transform: none;
    width: 60px !important;
    height: 60px !important
}

.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-prev:before,
.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-next:before {
    font-size: 12.8px;
    font-size: .8rem;
    left: 0;
    top: 0
}

.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-prev {
    left: -60px;
    top: -61px
}

.owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-next {
    left: -60px
}

@media(max-width:991px) {
    .owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-prev {
        left: 0
    }

    .owl-carousel.nav-inside.nav-inside-half-section .owl-nav button.owl-next {
        left: 0
    }
}

.owl-carousel.nav-outside .owl-nav button.owl-prev {
    left: 0
}

.owl-carousel.nav-outside .owl-nav button.owl-next {
    right: 0
}

@media(min-width:992px) {
    .owl-carousel.nav-outside .owl-nav button.owl-prev {
        left: -50px
    }

    .owl-carousel.nav-outside .owl-nav button.owl-next {
        right: -50px
    }
}

.owl-carousel.nav-position-1 .owl-nav button.owl-prev {
    left: 20px
}

.owl-carousel.nav-position-1 .owl-nav button.owl-next {
    right: 20px
}

.owl-carousel.nav-icon-1 .owl-nav .owl-next:before {
    content: "\f061"
}

.owl-carousel.nav-icon-1 .owl-nav .owl-prev:before {
    content: "\f060"
}

.owl-carousel.nav-size-md .owl-nav .owl-next {
    width: 40px;
    height: 40px
}

.owl-carousel.nav-size-md .owl-nav .owl-next:before {
    top: 0;
    font-size: 12px;
    font-size: .75rem
}

.owl-carousel.nav-size-md .owl-nav .owl-prev {
    width: 40px;
    height: 40px
}

.owl-carousel.nav-size-md .owl-nav .owl-prev:before {
    top: 0;
    font-size: 12px;
    font-size: .75rem
}

.owl-carousel.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-style-1 .owl-nav .owl-prev {
    width: 20px;
    background: 0 0 !important;
    color: #000
}

.owl-carousel.nav-style-1 .owl-nav .owl-next:hover,
.owl-carousel.nav-style-1 .owl-nav .owl-next:active,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:hover,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:active {
    color: #ccc
}

.owl-carousel.nav-style-1 .owl-nav .owl-next:before,
.owl-carousel.nav-style-1 .owl-nav .owl-next:after,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-1 .owl-nav .owl-prev:after {
    font-size: inherit
}

.owl-carousel.nav-style-2 .owl-nav .owl-next,
.owl-carousel.nav-style-2 .owl-nav .owl-prev {
    background: 0 0 !important
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 1px;
    width: 1.3em;
    height: 1.3em;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    font-size: inherit;
    transform: translate3d(0, -50%, 0) rotate(-45deg)
}

.owl-carousel.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-style-2 .owl-nav .owl-prev:after {
    content: '';
    display: block;
    border-top: 3px solid #ccc;
    width: 2.5em;
    position: absolute;
    top: 50%;
    font-size: inherit;
    transform: translate3d(0, -50%, 0)
}

.owl-carousel.nav-style-2 .owl-nav .owl-next {
    transform: rotate(180deg) !important;
    transform-origin: 15px 8px
}

.owl-carousel.nav-style-2.nav-bottom.nav-inside .owl-nav .owl-next {
    transform-origin: 15px
}

.owl-carousel.nav-style-3 .owl-nav {
    top: 25%
}

.owl-carousel.nav-style-3 .owl-nav .owl-next,
.owl-carousel.nav-style-3 .owl-nav .owl-prev {
    width: 30px;
    background: 0 0 !important;
    color: #ccc
}

.owl-carousel.nav-style-3 .owl-nav .owl-next:hover,
.owl-carousel.nav-style-3 .owl-nav .owl-next:active,
.owl-carousel.nav-style-3 .owl-nav .owl-prev:hover,
.owl-carousel.nav-style-3 .owl-nav .owl-prev:active {
    color: #ccc
}

.owl-carousel.nav-style-3 .owl-nav .owl-next:before,
.owl-carousel.nav-style-3 .owl-nav .owl-next:after,
.owl-carousel.nav-style-3 .owl-nav .owl-prev:before,
.owl-carousel.nav-style-3 .owl-nav .owl-prev:after {
    font-size: 1.5em
}

.owl-carousel.nav-style-4 .owl-nav .owl-prev {
    left: 75px
}

@media(max-width:991px) {
    .owl-carousel.nav-style-4 .owl-nav .owl-prev {
        left: 40px
    }
}

@media(max-width:767px) {
    .owl-carousel.nav-style-4 .owl-nav .owl-prev {
        left: 13px
    }
}

.owl-carousel.nav-style-4 .owl-nav .owl-next {
    right: 75px
}

@media(max-width:991px) {
    .owl-carousel.nav-style-4 .owl-nav .owl-next {
        right: 40px
    }
}

@media(max-width:767px) {
    .owl-carousel.nav-style-4 .owl-nav .owl-next {
        right: 13px
    }
}

.owl-carousel.nav-style-4 .owl-nav .owl-prev,
.owl-carousel.nav-style-4 .owl-nav .owl-next {
    background: #fff;
    font-size: 11.2px;
    font-size: .7rem;
    width: 40px;
    height: 40px;
    color: #000;
    border-radius: 100%;
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, .3)
}

.owl-carousel.nav-style-4 .owl-nav .owl-prev:hover,
.owl-carousel.nav-style-4 .owl-nav .owl-next:hover {
    color: #fff
}

.owl-carousel.nav-style-4.nav-style-4-pos-2 .owl-nav .owl-prev {
    left: 0
}

.owl-carousel.nav-style-4.nav-style-4-pos-2 .owl-nav .owl-next {
    right: 0
}

.owl-carousel.nav-style-diamond .owl-nav .owl-prev,
.owl-carousel.nav-style-diamond .owl-nav .owl-next {
    transform: rotate(45deg);
    transform-origin: 100% 0%
}

.owl-carousel.nav-style-diamond .owl-nav .owl-prev:before,
.owl-carousel.nav-style-diamond .owl-nav .owl-next:before {
    display: block;
    transform: rotate(-45deg);
    transform-origin: 60% 50%
}

.owl-carousel.nav-style-diamond .owl-nav .owl-next:before {
    transform-origin: 50%
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next {
    width: 35px;
    height: 35px
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev:before,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next:before {
    content: none
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg {
    width: 2em
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon,
.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-next svg polygon {
    fill: #fff;
    stroke: #fff
}

.owl-carousel.nav-svg-arrows-1 .owl-nav .owl-prev svg {
    transform: rotate(180deg)
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-prev,
.owl-carousel.nav-arrows-1 .owl-nav .owl-next {
    width: 35px;
    height: 35px;
    font-size: 19.2px;
    font-size: 1.2rem;
    background: 0 0
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-next:before {
    content: '\f061';
    font-size: inherit
}

.owl-carousel.nav-arrows-1 .owl-nav .owl-prev:before {
    content: '\f060';
    font-size: inherit
}

.owl-carousel.nav-arrows-2 .owl-nav .owl-prev,
.owl-carousel.nav-arrows-2 .owl-nav .owl-next {
    width: 35px;
    height: 35px;
    font-size: 19.2px;
    font-size: 1.2rem;
    background: 0 0
}

.owl-carousel.nav-arrows-2 .owl-nav .owl-next:before {
    content: '\f101';
    font-size: inherit
}

.owl-carousel.nav-arrows-2 .owl-nav .owl-prev:before {
    content: '\f100';
    font-size: inherit
}

.owl-carousel.nav-arrows-thin .owl-nav .owl-prev:before,
.owl-carousel.nav-arrows-thin .owl-nav .owl-next:before {
    font-family: simple-line-icons;
    speak: none;
    font-style: normal;
    font-weight: 700;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased
}

.owl-carousel.nav-arrows-thin .owl-nav .owl-next:before {
    content: "\e606";
    font-size: inherit
}

.owl-carousel.nav-arrows-thin .owl-nav .owl-prev:before {
    content: "\e605";
    font-size: inherit
}

.owl-carousel.nav-center-images-only .owl-nav {
    top: 37%
}

.owl-carousel.nav-center-outside .owl-nav {
    width: calc(100% + 90px);
    left: 49.9%;
    transform: translate3d(-50%, 0, 0)
}

.owl-carousel.full-width .owl-nav button[class*=owl-],
.owl-carousel.full-width .owl-nav button[class*=owl-]:hover,
.owl-carousel.big-nav .owl-nav button[class*=owl-],
.owl-carousel.big-nav .owl-nav button[class*=owl-]:hover {
    height: auto;
    padding: 20px 0 !important
}

.owl-carousel.full-width .owl-nav button.owl-prev,
.owl-carousel.big-nav .owl-nav button.owl-prev {
    border-radius: 0 4px 4px 0
}

.owl-carousel.full-width .owl-nav button.owl-next,
.owl-carousel.big-nav .owl-nav button.owl-next {
    border-radius: 4px 0 0 4px
}

.owl-carousel.nav-squared .owl-nav button[class*=owl-] {
    border-radius: 0
}

.owl-carousel.nav-sm .owl-nav button.owl-prev,
.owl-carousel.nav-sm .owl-nav button.owl-next {
    width: 30px !important;
    height: 30px !important
}

.owl-carousel.nav-md .owl-nav button.owl-prev,
.owl-carousel.nav-md .owl-nav button.owl-next {
    width: 40px;
    height: 40px
}

.owl-carousel.nav-lg .owl-nav button.owl-prev,
.owl-carousel.nav-lg .owl-nav button.owl-next {
    width: 45px;
    height: 60px
}

.owl-carousel.nav-lg.rounded-nav .owl-nav button.owl-prev,
.owl-carousel.nav-lg.rounded-nav .owl-nav button.owl-next {
    width: 60px;
    height: 60px
}

.owl-carousel.nav-font-size-sm .owl-nav button.owl-prev,
.owl-carousel.nav-font-size-sm .owl-nav button.owl-next {
    font-size: 10px
}

.owl-carousel.nav-font-size-sm .owl-nav button.owl-prev:before,
.owl-carousel.nav-font-size-sm .owl-nav button.owl-next:before {
    font-size: inherit
}

.owl-carousel.nav-font-size-md .owl-nav button.owl-prev,
.owl-carousel.nav-font-size-md .owl-nav button.owl-next {
    font-size: 14px
}

.owl-carousel.nav-font-size-md .owl-nav button.owl-prev:before,
.owl-carousel.nav-font-size-md .owl-nav button.owl-next:before {
    font-size: inherit
}

.owl-carousel.nav-font-size-lg .owl-nav button.owl-prev,
.owl-carousel.nav-font-size-lg .owl-nav button.owl-next {
    font-size: 19px
}

.owl-carousel.nav-font-size-lg .owl-nav button.owl-prev:before,
.owl-carousel.nav-font-size-lg .owl-nav button.owl-next:before {
    font-size: inherit;
    left: 2px
}

.owl-carousel.nav-font-size-xl .owl-nav button.owl-prev,
.owl-carousel.nav-font-size-xl .owl-nav button.owl-next {
    font-size: 25px
}

.owl-carousel.nav-font-size-xl .owl-nav button.owl-prev:before,
.owl-carousel.nav-font-size-xl .owl-nav button.owl-next:before {
    font-size: inherit;
    left: 2px
}

.owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*=owl-] {
    background-color: #ececec !important;
    border-color: #ececec !important;
    color: #212529 !important
}

.owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*=owl-]:hover {
    background-color: #fff !important;
    border-color: #fff !important
}

.owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav button[class*=owl-]:active {
    background-color: #d2d2d2 !important;
    border-color: #d2d2d2 !important
}

.owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav button[class*=owl-] {
    width: 35px;
    height: 45px;
    background-color: rgba(236, 236, 236, .4) !important;
    border-color: transparent !important
}

.owl-carousel.nav-light:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-svg-arrows-1 .owl-nav button[class*=owl-] svg polygon {
    fill: #212529 !important;
    stroke: #212529 !important
}

.owl-carousel.nav-light.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-light.nav-style-1 .owl-nav .owl-prev {
    color: #fff !important
}

.owl-carousel.nav-light.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-light.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-light.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-light.nav-style-2 .owl-nav .owl-prev:after {
    border-color: #fff !important
}

.owl-carousel.nav-light.nav-style-3 .owl-nav .owl-next,
.owl-carousel.nav-light.nav-style-3 .owl-nav .owl-prev {
    color: #fff !important
}

.owl-carousel.nav-light.nav-svg-arrows-1 .owl-nav .owl-next svg polygon,
.owl-carousel.nav-light.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon {
    fill: #fff !important;
    stroke: #fff !important
}

.owl-carousel.nav-light.nav-arrows-1 .owl-nav .owl-next,
.owl-carousel.nav-light.nav-arrows-1 .owl-nav .owl-prev {
    color: #fff !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-next,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-prev {
    background-color: #262a2f !important;
    border-color: #262a2f #262a2f #2c3237 !important;
    color: #fff !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-next:hover,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-prev:hover {
    background-color: #2c3237 !important;
    border-color: #2c3237 !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-next:active,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1) .owl-nav .owl-prev:active {
    background-color: #212529 !important;
    border-color: #212529 !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav .owl-next,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav .owl-prev {
    width: 35px;
    height: 45px;
    background-color: rgba(38, 42, 47, .4) !important;
    border-color: transparent !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav .owl-next:hover,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency .owl-nav .owl-prev:hover {
    background-color: #262a2f !important
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency.nav-style-diamond .owl-nav .owl-next,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-with-transparency.nav-style-diamond .owl-nav .owl-prev {
    width: 40px;
    height: 40px
}

.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-svg-arrows-1 .owl-nav .owl-next svg polygon,
.owl-carousel.nav-dark:not(.nav-style-1):not(.nav-style-2):not(.nav-style-3):not(.show-nav-title):not(.nav-arrows-1).nav-svg-arrows-1 .owl-nav .owl-prev svg polygon {
    fill: #fff !important;
    stroke: #fff !important
}

.owl-carousel.nav-dark.nav-style-1 .owl-nav .owl-next,
.owl-carousel.nav-dark.nav-style-1 .owl-nav .owl-prev {
    color: #212529 !important
}

.owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-next:before,
.owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-next:after,
.owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-prev:before,
.owl-carousel.nav-dark.nav-style-2 .owl-nav .owl-prev:after {
    border-color: #212529 !important
}

.owl-carousel.nav-dark.nav-style-3 .owl-nav .owl-next,
.owl-carousel.nav-dark.nav-style-3 .owl-nav .owl-prev {
    color: #212529 !important
}

.owl-carousel.nav-dark.nav-svg-arrows-1 .owl-nav .owl-next svg polygon,
.owl-carousel.nav-dark.nav-svg-arrows-1 .owl-nav .owl-prev svg polygon {
    fill: #212529 !important;
    stroke: #212529 !important
}

.owl-carousel.nav-dark.nav-arrows-1 .owl-nav .owl-next,
.owl-carousel.nav-dark.nav-arrows-1 .owl-nav .owl-prev {
    color: #212529 !important
}

.owl-carousel .owl-dots .owl-dot {
    outline: 0
}

.owl-carousel .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 4px
}

.owl-carousel.dots-inside .owl-dots {
    position: absolute;
    bottom: 2px;
    right: 10px;
    margin-top: 0
}

.owl-carousel.dots-title .owl-dots {
    position: absolute;
    margin-top: 0 !important;
    top: -51px;
    left: 155px
}

.owl-carousel.dots-title .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 4px
}

.owl-carousel.dots-title.dots-title-pos-2 .owl-dots {
    left: 235px
}

.owl-carousel.dots-light .owl-dots .owl-dot span {
    background: rgba(255, 255, 255, .6)
}

.owl-carousel.dots-light .owl-dots .owl-dot.active span,
.owl-carousel.dots-light .owl-dots .owl-dot:hover span {
    background: #fff !important
}

.owl-carousel.dots-dark .owl-dots .owl-dot span {
    background: rgba(33, 33, 33, .6)
}

.owl-carousel.dots-dark .owl-dots .owl-dot.active span,
.owl-carousel.dots-dark .owl-dots .owl-dot:hover span {
    background: #212121 !important
}

.owl-carousel.dots-morphing .owl-dots .owl-dot span {
    width: 20px;
    height: 6px;
    transition: ease width 300ms
}

.owl-carousel.dots-morphing .owl-dots .owl-dot.active span,
.owl-carousel.dots-morphing .owl-dots .owl-dot:hover span {
    width: 40px
}

.owl-carousel.dots-modern .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center
}

.owl-carousel.dots-modern .owl-dots .owl-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px
}

.owl-carousel.dots-modern .owl-dots .owl-dot span {
    width: 4px;
    height: 4px;
    transition: ease all 300ms 300ms
}

.owl-carousel.dots-modern .owl-dots .owl-dot.active span {
    transition: ease all 300ms;
    transform: scale(2)
}

.owl-carousel.dots-modern.dots-modern-lg .owl-dots .owl-dot {
    margin: 0 3px
}

.owl-carousel.dots-modern.dots-modern-lg .owl-dots .owl-dot span {
    width: 6px;
    height: 6px
}

.owl-carousel.dots-orientation-portrait .owl-dots {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    margin-right: 15px !important
}

.owl-carousel.dots-align-left .owl-dots {
    text-align: left;
    justify-content: flex-start
}

.owl-carousel.dots-align-right .owl-dots {
    text-align: left
}

.owl-carousel.dots-horizontal-center .owl-dots {
    left: 0;
    right: 0;
    width: 100%
}

.owl-carousel.dots-vertical-center .owl-dots {
    top: 50%;
    bottom: auto;
    margin: 0;
    transform: translate3d(0, -50%, 0)
}

@media(max-width:575px) {
    .owl-carousel.show-dots-xs .owl-dots {
        opacity: 1 !important;
        visibility: visible !important
    }
}

@media(min-width:576px) and (max-width:767px) {
    .owl-carousel.show-dots-sm .owl-dots {
        opacity: 1 !important;
        visibility: visible !important
    }
}

@media(min-width:768px) and (max-width:991px) {
    .owl-carousel.show-dots-md .owl-dots {
        opacity: 1 !important;
        visibility: visible !important
    }
}

.owl-carousel.show-dots-hover .owl-dots {
    opacity: 0;
    visibility: hidden;
    transition: ease opacity 300ms
}

.owl-carousel.show-dots-hover:hover .owl-dots {
    opacity: 1;
    visibility: visible
}

.owl-carousel.carousel-shadow-1 {
    position: relative
}

.owl-carousel.carousel-shadow-1:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 0;
    box-shadow: 0 0 110px 180px rgba(0, 0, 0, .04);
    transform: translate3d(-50%, -50%, 0);
    z-index: 0
}

.owl-carousel.carousel-shadow-1.carousel-shadow-1-bold:before {
    box-shadow: 0 0 110px 230px rgba(0, 0, 0, .04)
}

.owl-carousel .img-thumbnail.img-thumbnail-hover-icon {
    display: block
}

.owl-carousel.carousel-right-side-nav {
    width: calc(100% - 55px)
}

.owl-carousel.carousel-right-side-nav .owl-nav .owl-next {
    width: 55px;
    transform: translate3d(100%, -50%, 0)
}

.owl-carousel.carousel-bottom-inside-shadow .owl-stage-outer:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 35%;
    width: 100%;
    background-image: linear-gradient(360deg, #CCC 0%, transparent 100%)
}

.owl-carousel [class*=opacity-]:not([class*=opacity-hover]) {
    transition: ease opacity 300ms
}

.owl-carousel [class*=opacity-]:not([class*=opacity-hover]):hover {
    opacity: 1 !important
}

@media(min-width:576px) {
    .owl-carousel.carousel-sync-style-1 {
        position: absolute;
        top: 50%;
        left: -30px;
        max-width: 355px;
        transform: translate3d(0, -50%, 0)
    }
}

.owl-carousel-spaced {
    margin-left: -5px
}

.owl-carousel-spaced .owl-item>div {
    margin: 5px
}

.owl-carousel.testimonials img {
    display: inline-block;
    height: 70px;
    width: 70px
}

@media(max-width:575px) {
    .owl-carousel-spaced {
        margin-left: 0
    }
}

.carousel-half-full-width-wrapper>.owl-carousel {
    width: 100%
}

@media(min-width:576px) {
    .carousel-half-full-width-wrapper>.owl-carousel {
        width: calc(100vw - ((100vw - 540px)/2))
    }
}

@media(min-width:768px) {
    .carousel-half-full-width-wrapper>.owl-carousel {
        width: calc(100vw - ((100vw - 720px)/2))
    }
}

@media(min-width:992px) {
    .carousel-half-full-width-wrapper>.owl-carousel {
        width: calc(100vw - ((100vw - 960px)/2))
    }
}

@media(min-width:1200px) {
    .carousel-half-full-width-wrapper>.owl-carousel {
        width: calc(100vw - ((100vw - 1140px)/2))
    }
}

.carousel-half-full-width-wrapper>.owl-carousel .owl-stage-outer {
    margin-bottom: 20px
}

.carousel-half-full-width-wrapper.carousel-half-full-width-left {
    direction: rtl
}

.carousel-half-full-width-wrapper.carousel-half-full-width-left>.owl-carousel .owl-nav {
    display: flex
}

.carousel-half-full-width-wrapper.carousel-half-full-width-left>.owl-carousel .owl-nav .owl-prev {
    order: 2
}

.carousel-half-full-width-wrapper.carousel-half-full-width-left>.owl-carousel .owl-nav .owl-next {
    order: 1
}

.carousel-areas {
    background: #f2f2f2;
    background: linear-gradient(to bottom, #f2f2f2 0%, rgba(33, 37, 41, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#212529', GradientType=0);
    margin-bottom: -10px;
    padding: 8px 8px 0;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 50px 20px rgba(0, 0, 0, .07)
}

.carousel-areas .owl-carousel {
    box-shadow: 0 5px 5px rgba(0, 0, 0, .2)
}

.carousel-areas .owl-carousel .owl-nav button.owl-prev {
    left: -55px
}

.carousel-areas .owl-carousel .owl-nav button.owl-next {
    right: -55px
}

.carousel-areas .owl-carousel:first-child img {
    border-radius: 6px 6px 0 0
}

@media(max-width:991px) {
    .carousel-areas .owl-carousel .owl-nav {
        display: none
    }
}

.owl-carousel.carousel-center-active-item .owl-item {
    opacity: .2;
    transition: ease opacity 300ms
}

.owl-carousel.carousel-center-active-item .owl-item.current {
    opacity: 1 !important
}

.owl-carousel.carousel-center-active-item.carousel-center-active-item-style-2 .owl-item {
    opacity: .7
}

.owl-carousel.carousel-center-active-item-2 .owl-stage-outer {
    overflow: visible
}

.owl-carousel.carousel-center-active-item-2 .owl-item>div {
    width: 66.6666%;
    margin-left: auto;
    padding: 48px;
    padding: 3rem;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 70px -40px rgba(0, 0, 0, .2)
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active>div {
    margin-right: auto
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active>div * {
    color: #fff !important
}

.owl-carousel.carousel-center-active-item-2 .owl-item.active+.owl-item>div {
    margin-right: auto;
    margin-left: 0
}

.owl-carousel.carousel-center-active-item-3 .owl-stage-outer {
    overflow: visible
}

.owl-carousel.carousel-center-active-item-3 .owl-item>div {
    width: 100%;
    margin-left: auto;
    transition: ease opacity 300ms
}

.owl-carousel.carousel-center-active-item-3 .owl-item.active>div {
    margin-right: auto
}

.owl-carousel.carousel-center-active-item-3 .owl-item.active+.owl-item>div {
    margin-right: auto;
    margin-left: 0
}

.owl-carousel-light.owl-carousel-light-init-fadeIn {
    transition: ease opacity 300ms
}

.owl-carousel-light .owl-stage-outer,
.owl-carousel-light .owl-stage {
    height: 100%
}

.owl-carousel-light .owl-item {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    transition: ease opacity 300ms
}

.owl-carousel-light .owl-item.active {
    display: block;
    visibility: visible;
    opacity: 1
}

.owl-carousel-light .owl-dots .owl-dot span {
    margin: 5px 2px
}

.horizontal-scroller {
    padding: 32px 0;
    padding: 2rem 0;
    height: 100vh;
    position: relative
}

.horizontal-scroller-scroll {
    position: relative;
    overflow: hidden;
    padding: 32px;
    padding: 2rem
}

.horizontal-scroller-images {
    height: 100%;
    display: flex;
    align-items: center
}

.horizontal-scroller-item {
    height: 100%;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    padding: 0 32px;
    padding: 0 2rem
}

.horizontal-scroller-image {
    -o-object-fit: fill;
    object-fit: fill;
    margin: 0 auto;
    max-height: 80vh;
    padding-top: 10vh
}

.cascading-images-wrapper {
    display: inline-block;
    padding: 12% 20%
}

.cascading-images-wrapper .cascading-images {
    transform: translate3d(0, -35%, 0)
}

.cascading-images-wrapper .cascading-images img {
    max-width: 100%
}

.countdown {
    display: flex;
    text-align: center
}

.countdown>span {
    flex-basis: 25%;
    max-width: 25%;
    width: 25%;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    color: #777
}

.countdown>span>span {
    display: block;
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
    color: #212529
}

.countdown.countdown-light>span {
    color: rgba(255, 255, 255, .6)
}

.countdown.countdown-light>span>span {
    color: #fff
}

.countdown.countdown-light.countdown-borders>span {
    border-color: #fff
}

.countdown.countdown-borders {
    margin-right: -15px;
    margin-left: -15px
}

.countdown.countdown-borders>span {
    border-width: 2px;
    border-style: solid;
    border-color: #212529;
    margin-right: 15px;
    margin-left: 15px;
    padding: 24px;
    padding: 1.5rem
}

@media(max-width:767px) {
    .countdown {
        flex-wrap: wrap
    }

    .countdown>span {
        flex-basis: calc(50% - 15px);
        max-width: calc(50% - 15px);
        width: calc(50% - 15px);
        margin-bottom: 30px
    }

    .countdown>span:nth-child(3),
    .countdown>span:nth-child(4) {
        margin-bottom: 0
    }

    .countdown.countdown-borders {
        justify-content: space-between;
        margin-right: 0;
        margin-left: 0
    }

    .countdown.countdown-borders>span {
        margin-right: 0;
        margin-left: 0
    }
}

@media(max-width:576px) {
    .countdown>span {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        margin-bottom: 50px
    }

    .countdown>span:nth-child(3) {
        margin-bottom: 50px
    }

    .countdown.countdown-borders>span {
        margin-bottom: 30px
    }

    .countdown.countdown-borders>span:nth-child(3) {
        margin-bottom: 30px
    }
}

.counters .counter {
    text-align: center
}

.counters [class*=fa-],
.counters .icons {
    display: block;
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 5px
}

.counters strong {
    display: block;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px
}

.counters label {
    display: block;
    font-weight: 100;
    font-size: 20px;
    line-height: 20px
}

.counters .counter-side-icon [class*=fa-],
.counters .counter-side-icon .icons,
.counters .counter-side-icon strong {
    display: inline-block
}

.counters .counter-with-unit strong {
    display: inline-block
}

.counters .counter-with-unit.counter-unit-on-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start
}

.counters .counter-with-unit.counter-unit-on-top .unit {
    font-size: 32px;
    font-size: 2rem;
    line-height: 43.2px;
    line-height: 2.7rem;
    margin-left: 4.8px;
    margin-left: .3rem
}

.counters .counter-with-unit.counter-unit-on-top label {
    width: 100%;
    margin-top: 11.2px;
    margin-top: .7rem
}

.counters .counter-with-unit.counter-unit-on-bottom {
    align-items: flex-end
}

.counters.with-borders .counter {
    border-radius: 6px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04);
    padding: 25px
}

.counters.counters-sm [class*=fa-],
.counters.counters-sm .icons {
    font-size: 25px;
    line-height: 25px
}

.counters.counters-sm strong {
    font-size: 35px;
    line-height: 35px
}

.counters.counters-sm label {
    font-size: 15px;
    line-height: 15px
}

.counters.counters-lg [class*=fa-] {
    font-size: 45px;
    line-height: 45px
}

.counters.counters-lg strong {
    font-size: 60px;
    line-height: 60px
}

.counters.counters-lg label {
    font-size: 25px;
    line-height: 25px
}

.counters.counters-text-light .counter {
    color: #fff !important
}

.counters.counters-text-light .counter [class*=fa-],
.counters.counters-text-light .counter .icons,
.counters.counters-text-light .counter strong,
.counters.counters-text-light .counter label {
    color: #fff !important
}

.counters.counters-text-dark .counter {
    color: #333940 !important
}

.counters.counters-text-dark .counter [class*=fa-],
.counters.counters-text-dark .counter .icons,
.counters.counters-text-dark .counter strong,
.counters.counters-text-dark .counter label {
    color: #333940 !important
}

hr {
    background: rgba(0, 0, 0, .06);
    border: 0;
    height: 1px;
    margin: 22px 0;
    opacity: 1
}

hr.short {
    margin: 11px 0
}

hr.tall {
    margin: 44px 0
}

hr.taller {
    margin: 66px 0
}

hr.gratient {
    background: 0 0;
    background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06), transparent)
}

hr.gradient-to-right {
    background: 0 0;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.06), transparent)
}

hr.gradient-to-left {
    background: 0 0;
    background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.06))
}

hr.solid {
    background: rgba(0, 0, 0, .06)
}

hr.light {
    background: rgba(255, 255, 255, .15)
}

hr.invisible {
    background: 0 0
}

.divider {
    border: 0;
    height: 1px;
    margin: 44px auto;
    background: rgba(0, 0, 0, .06);
    text-align: center;
    position: relative;
    clear: both
}

.divider.taller {
    margin: 66px auto
}

.divider [class*=fa-],
.divider .icons {
    text-align: center;
    background: #fff;
    border-radius: 50px;
    color: #a9a9a9;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    width: 50px;
    font-size: 20px;
    margin: 0 auto 0 -25px;
    top: -25px;
    left: 50%;
    z-index: 1
}

.divider.divider-solid {
    background: rgba(0, 0, 0, .06)
}

.divider.divider-icon-sm [class*=fa-],
.divider.divider-icon-sm .icons {
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: -15px;
    font-size: 15px;
    margin-left: -16px
}

.divider.divider-icon-lg {
    margin: 86px 0
}

.divider.divider-icon-lg [class*=fa-],
.divider.divider-icon-lg .icons {
    width: 60px;
    height: 60px;
    line-height: 60px;
    top: -30px;
    font-size: 30px;
    margin-left: -30px
}

.divider.divider-icon-lg.taller {
    margin: 100px auto
}

.divider.divider-xs {
    width: 35%
}

.divider.divider-sm {
    width: 67%
}

.divider.divider-left [class*=fa-],
.divider.divider-left .icons {
    left: 0;
    margin-left: 0;
    margin-right: 0;
    right: auto
}

.divider.divider-right [class*=fa-],
.divider.divider-right .icons {
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: 0
}

.divider.divider-style-2 [class*=fa-],
.divider.divider-style-2 .icons {
    background: #f7f7f7;
    color: #a9a9a9
}

.divider.divider-style-3 [class*=fa-],
.divider.divider-style-3 .icons {
    border: 1px solid #cecece
}

.divider.divider-style-4 [class*=fa-],
.divider.divider-style-4 .icons {
    border: 1px solid #cecece
}

.divider.divider-style-4 [class*=fa-]:after,
.divider.divider-style-4 .icons:after {
    border: 3px solid #f7f7f7;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    display: block;
    height: 100%;
    left: -4px;
    padding: 1px;
    position: absolute;
    top: -4px;
    width: 100%
}

.divider.divider-small {
    margin: 22px auto;
    background: 0 0
}

.divider.divider-small hr {
    width: 50px;
    height: 3px;
    background: #555
}

.divider.divider-small.divider-small-center {
    text-align: center
}

.divider.divider-small.divider-small-center hr {
    margin-left: auto;
    margin-right: auto
}

.divider.divider-small.divider-small-right {
    text-align: right
}

.divider.divider-small.divider-small-right hr {
    margin-left: auto
}

.divider.divider-small.divider-light hr {
    background: #ddd
}

.divider.divider-small.divider-small-sm hr {
    height: 1px
}

.divider.divider-small.divider-small-lg hr {
    height: 6px
}

.divider.divider-small.tall {
    margin: 34px 0
}

.divider.divider-small.taller {
    margin: 46px 0
}

.divider .divider-small-text {
    background: #fff;
    color: #999;
    display: inline-block;
    text-align: center;
    font-size: .8em;
    height: 30px;
    line-height: 30px;
    padding: 0 30px;
    text-transform: uppercase;
    margin: 0;
    position: relative;
    top: -15px
}

hr.dashed,
.divider.dashed {
    background: 0 0;
    position: relative
}

hr.dashed:after,
.divider.dashed:after {
    border-top: 1px dashed rgba(0, 0, 0, .06);
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    width: 100%
}

hr.pattern,
.divider.pattern {
    background: 0 0;
    position: relative
}

hr.pattern:after,
.divider.pattern:after {
    /* background: transparent url(../img/patterns/worn_dots.png) repeat 0 0; */
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    width: 100%
}

hr.pattern.pattern-2:after,
.divider.pattern.pattern-2:after {
    /* background-image: url(../img/patterns/az_subtle.png) */
}

@keyframes dividerProgress25 {
    from {
        width: 0
    }

    to {
        width: 25%
    }
}

.dividerProgress25 {
    animation-name: dividerProgress25
}

.divider-top-border {
    border-top: 1px solid rgba(0, 0, 0, .06)
}

.divider-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, .06)
}

.divider-left-border {
    border-left: 1px solid rgba(0, 0, 0, .06)
}

.divider-right-border {
    border-right: 1px solid rgba(0, 0, 0, .06)
}

.dropdown.dropdown-style-1 .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 16px 17.6px;
    padding: 1rem 1.1rem;
    background: #fff;
    border-radius: 0;
    border-right: 1px solid #ededed;
    min-width: 280px;
    font-weight: 700;
    text-decoration: none
}

.dropdown.dropdown-style-1 .dropdown-toggle:after {
    content: '\f107';
    position: absolute;
    right: 17.6px;
    right: 1.1rem;
    top: 50%;
    font-family: 'font awesome 6 free';
    font-weight: 700;
    transform: translate3d(0, -50%, 0);
    border: 0;
    margin: 0;
    width: 10px;
    height: 10px;
    line-height: 10px
}

.dropdown.dropdown-style-1 .dropdown-toggle>i {
    display: none
}

.dropdown.dropdown-style-1 .dropdown-menu {
    width: 100%;
    border-radius: 0;
    margin-top: -1px;
    border-color: #ededed
}

.dropdown.dropdown-style-1 .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding-right: 17.6px;
    padding-right: 1.1rem;
    padding-left: 17.6px;
    padding-left: 1.1rem
}

.dropdown.dropdown-corner-left-fix .dropdown-menu {
    margin-left: -5px
}

.dropdown-menu.dropdown-menu-arrow-centered:before {
    right: auto;
    left: 50%;
    transform: translate3d(-50%, -6px, 0)
}

.dropdown-menu.dropdown-menu-arrow-centered-style-2 {
    border: 0;
    margin-top: -2px;
    margin-left: -2px;
    padding: 0;
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, .05);
    background: #fff;
    padding: 3px;
    min-width: 180px;
    z-index: 10000;
    margin-right: 0;
    margin-top: 1px;
    transition: opacity .2s ease-out;
    border-radius: 0
}

.dropdown-item:not(.btn):hover {
    text-decoration: none
}

.icon-featured {
    display: inline-block;
    font-size: 0;
    margin: 15px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 2.3em;
    line-height: 110px
}

.icon-featured:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box
}

.icon-featured.icon-featured-lg {
    width: 180px !important;
    height: 180px !important;
    line-height: 180px !important
}

.featured-box {
    background: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-radius: 8px;
    border-right: 1px solid #ececec;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    min-height: 100px;
    position: relative;
    text-align: center;
    z-index: 1
}

.featured-box h4 {
    font-size: 1.3em;
    font-weight: 400;
    letter-spacing: -.7px;
    margin-top: 5px;
    margin-bottom: 5px
}

.featured-box .box-content {
    border-radius: 8px;
    border-top: 1px solid rgba(0, 0, 0, .06);
    border-top-width: 4px;
    padding: 28.8px;
    padding: 1.8rem;
    position: relative
}

.featured-box .box-content:not(.box-content-border-0) {
    top: -1px;
    border-top-width: 4px
}

.featured-box .box-content.box-content-border-0 {
    border-top: 1px solid rgba(0, 0, 0, .06) !important;
    border-bottom: 0 !important
}

.featured-box .box-content.box-content-border-bottom {
    top: 1px
}

.featured-box .box-content-border-bottom {
    border-top: 1px solid rgba(0, 0, 0, .06) !important;
    border-bottom: 4px solid rgba(0, 0, 0, .06)
}

.featured-box.border-radius {
    border-radius: 8px !important
}

.featured-box.border-radius.box-shadow-1:before {
    border-radius: 8px !important
}

.featured-box-text-left {
    text-align: left
}

.featured-boxes-flat .featured-box {
    box-shadow: none;
    margin: 10px 0
}

.featured-boxes-flat .featured-box .box-content {
    background: #fff;
    margin-top: 65px
}

.featured-boxes-flat .featured-box .icon-featured {
    display: inline-block;
    font-size: 2em;
    height: 90px;
    line-height: 90px;
    padding: 0;
    width: 90px;
    margin: -100px 0 -15px;
    position: relative;
    top: -40px
}

.featured-boxes-style-2 .featured-box {
    background: 0 0;
    border: 0;
    margin: 10px 0;
    box-shadow: none
}

.featured-boxes-style-2 .featured-box .box-content {
    border: 0;
    padding-top: 0;
    padding-bottom: 0
}

.featured-boxes-style-2 .featured-box .icon-featured {
    display: inline-block;
    font-size: 1.8em;
    height: 75px;
    line-height: 75px;
    padding: 0;
    width: 75px;
    margin-top: 0
}

.featured-boxes-style-3 .featured-box {
    margin: 10px 0
}

.featured-boxes-style-3 .featured-box .icon-featured {
    display: inline-block;
    font-size: 1.8em;
    height: 75px;
    line-height: 75px;
    padding: 0;
    width: 75px;
    margin-top: -37px;
    top: -37px;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, .06);
    color: rgba(0, 0, 0, .06)
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box {
    background: 0 0;
    border: 0;
    box-shadow: none
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box .box-content {
    border: 0;
    padding-top: 0;
    padding-bottom: 0
}

.featured-boxes-style-4 .featured-box {
    background: 0 0;
    border: 0;
    margin: 10px 0;
    box-shadow: none
}

.featured-boxes-style-4 .featured-box .box-content {
    border: 0;
    padding-top: 0;
    padding-bottom: 0
}

.featured-boxes-style-4 .featured-box .icon-featured {
    display: inline-block;
    font-size: 35px;
    height: 45px;
    line-height: 45px;
    padding: 0;
    width: 45px;
    margin-top: 0;
    margin-bottom: 10px;
    background: 0 0;
    color: rgba(0, 0, 0, .06);
    border-radius: 0
}

.featured-boxes-style-5 .featured-box,
.featured-boxes-style-6 .featured-box,
.featured-boxes-style-7 .featured-box,
.featured-boxes-style-9 .featured-box {
    background: 0 0;
    border: 0;
    box-shadow: none;
    margin: 10px 0
}

.featured-boxes-style-5 .featured-box .box-content,
.featured-boxes-style-6 .featured-box .box-content,
.featured-boxes-style-7 .featured-box .box-content,
.featured-boxes-style-9 .featured-box .box-content {
    border: 0;
    padding-top: 0;
    padding-bottom: 0
}

.featured-boxes-style-5 .featured-box .box-content h4,
.featured-boxes-style-6 .featured-box .box-content h4,
.featured-boxes-style-7 .featured-box .box-content h4,
.featured-boxes-style-9 .featured-box .box-content h4 {
    color: #333940
}

.featured-boxes-style-5 .featured-box .icon-featured,
.featured-boxes-style-6 .featured-box .icon-featured,
.featured-boxes-style-7 .featured-box .icon-featured,
.featured-boxes-style-9 .featured-box .icon-featured {
    display: inline-block;
    font-size: 2em;
    height: 75px;
    padding: 0;
    width: 75px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;
    color: #777;
    line-height: 73px
}

.featured-boxes-style-6 .featured-box .icon-featured {
    border: 1px solid #cecece;
    color: #777
}

.featured-boxes-style-6 .featured-box .icon-featured:after {
    border: 5px solid #f7f7f7;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    display: block;
    height: 100%;
    left: -6px;
    padding: 1px;
    position: absolute;
    top: -6px;
    width: 100%
}

.featured-boxes-style-7 .featured-box .icon-featured {
    border: 1px solid #dfdfdf;
    color: #777
}

.featured-boxes-style-8 .featured-box .icon-featured {
    display: inline-block;
    font-size: 2em;
    height: 75px;
    padding: 0;
    width: 75px;
    margin: -15px -15px 0 0;
    background: #fff;
    line-height: 75px;
    border: 0;
    color: #777
}

.featured-boxes-style-8 .featured-box .icon-featured:after {
    display: none
}

.featured-boxes-style-9 .featured-box .icon-featured {
    background: #fff;
    box-shadow: 0 0 32px -7px rgba(0, 0, 0, .1);
    border: 0;
    width: 100px;
    height: 100px;
    line-height: 100px;
    transition-duration: .4s
}

.featured-boxes-modern-style-1 .featured-box {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    height: auto !important;
    margin: 0 !important
}

.featured-boxes-modern-style-1 .featured-box .featured-box-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: cubic-bezier(.39, .66, .42, .99) all 2s;
    transform: scale(1)
}

.featured-boxes-modern-style-1 .featured-box .box-content {
    padding: 64px;
    padding: 4rem;
    border: none;
    z-index: 3
}

.featured-boxes-modern-style-1 .featured-box .box-content .icon-featured {
    color: #fff;
    background: 0 0;
    width: auto;
    height: auto;
    line-height: 1.4;
    font-size: 46.4px;
    font-size: 2.9rem;
    margin: 0 0 16px;
    margin: 0 0 1rem
}

.featured-boxes-modern-style-1 .featured-box .box-content h2 {
    color: #fff;
    font-weight: 600;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 11.2px;
    margin-bottom: .7rem
}

.featured-boxes-modern-style-1 .featured-box .box-content p {
    color: #999;
    margin-bottom: 25.6px;
    margin-bottom: 1.6rem
}

.featured-boxes-modern-style-1 .featured-box:hover .featured-box-background {
    transform: scale(1.1)
}

@media(max-width:575px) {
    .featured-boxes-modern-style-1 .featured-box .box-content {
        padding: 2rem 1.5rem
    }
}

.featured-boxes-modern-style-2 .featured-box {
    position: relative;
    background: #6c6ef1;
    background: linear-gradient(135deg, #6c6ef1 0%, #e2498e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1);
    border-radius: 0;
    margin: 0 !important;
    overflow: hidden
}

.featured-boxes-modern-style-2 .featured-box:before {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    background: #fff
}

.featured-boxes-modern-style-2 .featured-box .box-content {
    padding: 64px;
    padding: 4rem;
    border: none
}

.featured-boxes-modern-style-2 .featured-box .box-content .icon-featured {
    color: #fff;
    background: 0 0;
    width: auto;
    height: auto;
    line-height: 1.4;
    font-size: 46.4px;
    font-size: 2.9rem;
    margin: 0 0 16px;
    margin: 0 0 1rem
}

.featured-boxes-modern-style-2 .featured-box .box-content h2 {
    color: #212529;
    font-weight: 700;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 11.2px;
    margin-bottom: .7rem
}

.featured-boxes-modern-style-2 .featured-box .box-content p {
    color: #999;
    margin-bottom: 25.6px;
    margin-bottom: 1.6rem
}

@media(max-width:575px) {
    .featured-boxes-modern-style-2 .featured-box .box-content {
        padding: 2rem 1.5rem
    }
}

.featured-boxes-modern-style-2-hover-only .featured-box:before {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    transition: cubic-bezier(.39, .66, .42, .99) all 200ms
}

.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box {
    background: rgba(0, 0, 0, .06) !important;
    box-shadow: none;
    border: 0
}

.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-no-borders {
    background: 0 0 !important
}

.featured-boxes-modern-style-2-hover-only:not(:hover) .featured-box-box-shadow {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .1)
}

.featured-boxes-modern-style-2-hover-only:hover .featured-box:before {
    top: 8px;
    right: 8px;
    bottom: 8px;
    left: 8px
}

.featured-boxes .featured-box {
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
    margin-top: 24px;
    margin-top: 1.5rem
}

.featured-box-effect-1 .icon-featured:after {
    top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 3px #fff;
    transition: transform .2s, opacity .2s;
    transform: scale(1);
    opacity: 0
}

.featured-box-effect-1:hover .icon-featured:after {
    transform: scale(.8);
    opacity: 1
}

.featured-box-effect-2 .icon-featured:after {
    top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 3px #fff;
    transition: transform .2s, opacity .2s;
    transform: scale(.8);
    opacity: 0
}

.featured-box-effect-2:hover .icon-featured:after {
    transform: scale(.95);
    opacity: 1
}

.featured-box-effect-3 .icon-featured:after {
    top: 0;
    left: 0;
    box-shadow: 0 0 0 10px #fff;
    transition: transform .2s, opacity .2s;
    transform: scale(.9);
    opacity: 0
}

.featured-box-effect-3:hover .icon-featured {
    color: #fff !important
}

.featured-box-effect-3:hover .icon-featured:after {
    transform: scale(1);
    opacity: .8
}

.featured-box-effect-4 .icon-featured {
    transition: transform .2s, transform .2s;
    transform: scale(1)
}

.featured-box-effect-4:hover .icon-featured {
    transform: scale(1.15)
}

.featured-box-effect-5 .icon-featured {
    overflow: hidden;
    transition: all .3s
}

.featured-box-effect-5:hover .icon-featured {
    animation: toRightFromLeft .3s forwards
}

@keyframes toRightFromLeft {
    49% {
        transform: translate(100%)
    }

    50% {
        opacity: 0;
        transform: translate(-100%)
    }

    51% {
        opacity: 1
    }
}

.featured-box-effect-6 .icon-featured:after {
    transition: all .2s, transform .2s
}

.featured-box-effect-6:hover .box-content .icon-featured:after {
    transform: scale(1.1)
}

.featured-box-effect-7 .icon-featured:after {
    opacity: 0;
    box-shadow: 3px 3px #d5d5d5;
    transform: rotate(-90deg);
    transition: opacity .2s, transform .2s;
    left: 0;
    top: -1px
}

.featured-box-effect-7:hover .icon-featured:after {
    opacity: 1;
    transform: rotate(0deg)
}

.featured-box-effect-7 .icon-featured:before {
    transform: scale(.8);
    opacity: .7;
    transition: transform .2s, opacity .2s
}

.featured-box-effect-7:hover .icon-featured:before {
    transform: scale(1);
    opacity: 1
}

.feature-box {
    display: flex
}

.feature-box .feature-box-icon {
    display: inline-flex;
    /* align-items: center; */
    justify-content: center;
    flex: 1 0 auto;
    background: rgba(0, 0, 0, .06);
    border-radius: 100%;
    color: #fff;
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    top: 7px;
    text-align: center;
    padding: .8em;
    height: 1px;
    width: 2.7em;
    height: 2.7em
}

.feature-box .feature-box-info {
    flex: 1 1 100%;
    padding-left: 15px
}

.feature-box.align-items-center .feature-box-icon {
    top: 0
}

.feature-box.feature-box-style-2 h4 {
    color: #212529
}

.feature-box.feature-box-style-2 .feature-box-icon {
    background: 0 0;
    top: -3px
}

.feature-box.feature-box-style-2 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-2 .feature-box-icon .icons {
    font-size: 1.7em
}

.feature-box.feature-box-style-2.align-items-center .feature-box-icon {
    top: 0
}

.feature-box.feature-box-style-3 h4 {
    color: #212529
}

.feature-box.feature-box-style-3 .feature-box-icon {
    border: 1px solid rgba(0, 0, 0, .06);
    background: 0 0;
    line-height: 32px
}

.feature-box.feature-box-style-3 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-3 .feature-box-icon .icons {
    color: rgba(0, 0, 0, .06)
}

.feature-box.feature-box-style-4 {
    flex-wrap: wrap
}

.feature-box.feature-box-style-4 h4 {
    color: #212529
}

.feature-box.feature-box-style-4 .feature-box-icon {
    flex-basis: 100%;
    justify-content: flex-start;
    background: 0 0;
    padding: 0 0 19.2px;
    padding: 0 0 1.2rem
}

.feature-box.feature-box-style-4 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-4 .feature-box-icon .icons {
    font-size: 2.35em;
    width: auto;
    height: auto
}

.feature-box.feature-box-style-4 .feature-box-info {
    clear: both;
    padding-left: 0
}

.feature-box.feature-box-style-5 h4 {
    color: #212529
}

.feature-box.feature-box-style-5 .feature-box-icon {
    background: 0 0;
    top: 0;
    padding: 1em 1.4em;
    min-width: 5em
}

.feature-box.feature-box-style-5 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-5 .feature-box-icon .icons {
    font-size: 2.75em
}

.feature-box.feature-box-style-5.align-items-center .feature-box-icon {
    top: 0
}

.feature-box.feature-box-style-6 h4 {
    color: #212529
}

.feature-box.feature-box-style-6 .feature-box-icon {
    border: 1px solid #cecece;
    background: 0 0;
    line-height: 32px
}

.feature-box.feature-box-style-6 .feature-box-icon:after {
    border: 3px solid #f7f7f7;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    display: block;
    height: 100%;
    left: -4px;
    padding: 1px;
    position: absolute;
    top: -4px;
    width: 100%
}

.feature-box.feature-box-style-6 .feature-box-icon [class*=fa-],
.feature-box.feature-box-style-6 .feature-box-icon .icons {
    color: #a9a9a9
}

.feature-box .feature-box-icon.feature-box-icon-large {
    width: 3.7em;
    height: 3.7em
}

.feature-box .feature-box-icon.feature-box-icon-extra-large {
    width: 4.5em;
    height: 4.5em
}

.feature-box.reverse-allres {
    text-align: right;
    flex-direction: row-reverse
}

.feature-box.reverse-allres .feature-box-info {
    padding-right: 15px;
    padding-left: 0
}

.feature-box.reverse-allres.feature-box-style-4 .feature-box-icon {
    justify-content: flex-end
}

.feature-box.reverse-allres.feature-box-style-4 .feature-box-info {
    padding-right: 0
}

@media(min-width:992px) {
    .feature-box.reverse {
        text-align: right;
        flex-direction: row-reverse
    }

    .feature-box.reverse .feature-box-info {
        padding-right: 15px;
        padding-left: 0
    }

    .feature-box.reverse.feature-box-style-4 .feature-box-icon {
        justify-content: flex-end
    }

    .feature-box.reverse.feature-box-style-4 .feature-box-info {
        padding-right: 0
    }
}

.featured-boxes-full .featured-box-full {
    text-align: center;
    padding: 30px
}

.featured-boxes-full .featured-box-full [class*=fa-],
.featured-boxes-full .featured-box-full .icons,
.featured-boxes-full .featured-box-full h1,
.featured-boxes-full .featured-box-full h2,
.featured-boxes-full .featured-box-full h3,
.featured-boxes-full .featured-box-full h4,
.featured-boxes-full .featured-box-full h5,
.featured-boxes-full .featured-box-full h6,
.featured-boxes-full .featured-box-full p,
.featured-boxes-full .featured-box-full a {
    padding: 0;
    margin: 0
}

.featured-boxes-full .featured-box-full:not(.featured-box-full-light) [class*=fa-],
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) .icons,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h1,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h2,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h3,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h4,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h5,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) h6,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) p,
.featured-boxes-full .featured-box-full:not(.featured-box-full-light) a {
    color: #fff
}

.featured-boxes-full .featured-box-full .icons,
.featured-boxes-full .featured-box-full [class*=fa-] {
    font-size: 3.9em;
    margin-bottom: 15px
}

.featured-boxes-full .featured-box-full p {
    padding-top: 12px;
    opacity: .8
}

.featured-boxes-full .featured-box-full.featured-box-full-light {
    border: 1px solid rgba(0, 0, 0, .06)
}

.featured-boxes-full .featured-box-full.featured-box-full-light:not(:last-child) {
    border-right: 0
}

.featured-boxes-full .featured-box-full.featured-box-full-light:first-child {
    border-left: 0
}

.flag {
    width: 16px;
    height: 11px;
    /* background: url(../img/flags.png) no-repeat */
}

.flag.flag-ad {
    background-position: -16px 0
}

.flag.flag-ae {
    background-position: -32px 0
}

.flag.flag-af {
    background-position: -48px 0
}

.flag.flag-ag {
    background-position: -64px 0
}

.flag.flag-ai {
    background-position: -80px 0
}

.flag.flag-al {
    background-position: -96px 0
}

.flag.flag-am {
    background-position: -112px 0
}

.flag.flag-an {
    background-position: -128px 0
}

.flag.flag-ao {
    background-position: -144px 0
}

.flag.flag-ar {
    background-position: -160px 0
}

.flag.flag-as {
    background-position: -176px 0
}

.flag.flag-at {
    background-position: -192px 0
}

.flag.flag-au {
    background-position: -208px 0
}

.flag.flag-aw {
    background-position: -224px 0
}

.flag.flag-az {
    background-position: -240px 0
}

.flag.flag-ba {
    background-position: 0 -11px
}

.flag.flag-bb {
    background-position: -16px -11px
}

.flag.flag-bd {
    background-position: -32px -11px
}

.flag.flag-be {
    background-position: -48px -11px
}

.flag.flag-bf {
    background-position: -64px -11px
}

.flag.flag-bg {
    background-position: -80px -11px
}

.flag.flag-bh {
    background-position: -96px -11px
}

.flag.flag-bi {
    background-position: -112px -11px
}

.flag.flag-bj {
    background-position: -128px -11px
}

.flag.flag-bm {
    background-position: -144px -11px
}

.flag.flag-bn {
    background-position: -160px -11px
}

.flag.flag-bo {
    background-position: -176px -11px
}

.flag.flag-br {
    background-position: -192px -11px
}

.flag.flag-bs {
    background-position: -208px -11px
}

.flag.flag-bt {
    background-position: -224px -11px
}

.flag.flag-bv {
    background-position: -240px -11px
}

.flag.flag-bw {
    background-position: 0 -22px
}

.flag.flag-by {
    background-position: -16px -22px
}

.flag.flag-bz {
    background-position: -32px -22px
}

.flag.flag-ca {
    background-position: -48px -22px
}

.flag.flag-catalonia {
    background-position: -64px -22px
}

.flag.flag-cd {
    background-position: -80px -22px
}

.flag.flag-cf {
    background-position: -96px -22px
}

.flag.flag-cg {
    background-position: -112px -22px
}

.flag.flag-ch {
    background-position: -128px -22px
}

.flag.flag-ci {
    background-position: -144px -22px
}

.flag.flag-ck {
    background-position: -160px -22px
}

.flag.flag-cl {
    background-position: -176px -22px
}

.flag.flag-cm {
    background-position: -192px -22px
}

.flag.flag-cn {
    background-position: -208px -22px
}

.flag.flag-co {
    background-position: -224px -22px
}

.flag.flag-cr {
    background-position: -240px -22px
}

.flag.flag-cu {
    background-position: 0 -33px
}

.flag.flag-cv {
    background-position: -16px -33px
}

.flag.flag-cw {
    background-position: -32px -33px
}

.flag.flag-cy {
    background-position: -48px -33px
}

.flag.flag-cz {
    background-position: -64px -33px
}

.flag.flag-de {
    background-position: -80px -33px
}

.flag.flag-dj {
    background-position: -96px -33px
}

.flag.flag-dk {
    background-position: -112px -33px
}

.flag.flag-dm {
    background-position: -128px -33px
}

.flag.flag-do {
    background-position: -144px -33px
}

.flag.flag-dz {
    background-position: -160px -33px
}

.flag.flag-ec {
    background-position: -176px -33px
}

.flag.flag-ee {
    background-position: -192px -33px
}

.flag.flag-eg {
    background-position: -208px -33px
}

.flag.flag-eh {
    background-position: -224px -33px
}

.flag.flag-england {
    background-position: -240px -33px
}

.flag.flag-er {
    background-position: 0 -44px
}

.flag.flag-es {
    background-position: -16px -44px
}

.flag.flag-et {
    background-position: -32px -44px
}

.flag.flag-eu {
    background-position: -48px -44px
}

.flag.flag-fi {
    background-position: -64px -44px
}

.flag.flag-fj {
    background-position: -80px -44px
}

.flag.flag-fk {
    background-position: -96px -44px
}

.flag.flag-fm {
    background-position: -112px -44px
}

.flag.flag-fo {
    background-position: -128px -44px
}

.flag.flag-fr {
    background-position: -144px -44px
}

.flag.flag-ga {
    background-position: -160px -44px
}

.flag.flag-gb {
    background-position: -176px -44px
}

.flag.flag-gd {
    background-position: -192px -44px
}

.flag.flag-ge {
    background-position: -208px -44px
}

.flag.flag-gf {
    background-position: -224px -44px
}

.flag.flag-gg {
    background-position: -240px -44px
}

.flag.flag-gh {
    background-position: 0 -55px
}

.flag.flag-gi {
    background-position: -16px -55px
}

.flag.flag-gl {
    background-position: -32px -55px
}

.flag.flag-gm {
    background-position: -48px -55px
}

.flag.flag-gn {
    background-position: -64px -55px
}

.flag.flag-gp {
    background-position: -80px -55px
}

.flag.flag-gq {
    background-position: -96px -55px
}

.flag.flag-gr {
    background-position: -112px -55px
}

.flag.flag-gs {
    background-position: -128px -55px
}

.flag.flag-gt {
    background-position: -144px -55px
}

.flag.flag-gu {
    background-position: -160px -55px
}

.flag.flag-gw {
    background-position: -176px -55px
}

.flag.flag-gy {
    background-position: -192px -55px
}

.flag.flag-hk {
    background-position: -208px -55px
}

.flag.flag-hm {
    background-position: -224px -55px
}

.flag.flag-hn {
    background-position: -240px -55px
}

.flag.flag-hr {
    background-position: 0 -66px
}

.flag.flag-ht {
    background-position: -16px -66px
}

.flag.flag-hu {
    background-position: -32px -66px
}

.flag.flag-ic {
    background-position: -48px -66px
}

.flag.flag-id {
    background-position: -64px -66px
}

.flag.flag-ie {
    background-position: -80px -66px
}

.flag.flag-il {
    background-position: -96px -66px
}

.flag.flag-im {
    background-position: -112px -66px
}

.flag.flag-in {
    background-position: -128px -66px
}

.flag.flag-io {
    background-position: -144px -66px
}

.flag.flag-iq {
    background-position: -160px -66px
}

.flag.flag-ir {
    background-position: -176px -66px
}

.flag.flag-is {
    background-position: -192px -66px
}

.flag.flag-it {
    background-position: -208px -66px
}

.flag.flag-je {
    background-position: -224px -66px
}

.flag.flag-jm {
    background-position: -240px -66px
}

.flag.flag-jo {
    background-position: 0 -77px
}

.flag.flag-jp {
    background-position: -16px -77px
}

.flag.flag-ke {
    background-position: -32px -77px
}

.flag.flag-kg {
    background-position: -48px -77px
}

.flag.flag-kh {
    background-position: -64px -77px
}

.flag.flag-ki {
    background-position: -80px -77px
}

.flag.flag-km {
    background-position: -96px -77px
}

.flag.flag-kn {
    background-position: -112px -77px
}

.flag.flag-kp {
    background-position: -128px -77px
}

.flag.flag-kr {
    background-position: -144px -77px
}

.flag.flag-kurdistan {
    background-position: -160px -77px
}

.flag.flag-kw {
    background-position: -176px -77px
}

.flag.flag-ky {
    background-position: -192px -77px
}

.flag.flag-kz {
    background-position: -208px -77px
}

.flag.flag-la {
    background-position: -224px -77px
}

.flag.flag-lb {
    background-position: -240px -77px
}

.flag.flag-lc {
    background-position: 0 -88px
}

.flag.flag-li {
    background-position: -16px -88px
}

.flag.flag-lk {
    background-position: -32px -88px
}

.flag.flag-lr {
    background-position: -48px -88px
}

.flag.flag-ls {
    background-position: -64px -88px
}

.flag.flag-lt {
    background-position: -80px -88px
}

.flag.flag-lu {
    background-position: -96px -88px
}

.flag.flag-lv {
    background-position: -112px -88px
}

.flag.flag-ly {
    background-position: -128px -88px
}

.flag.flag-ma {
    background-position: -144px -88px
}

.flag.flag-mc {
    background-position: -160px -88px
}

.flag.flag-md {
    background-position: -176px -88px
}

.flag.flag-me {
    background-position: -192px -88px
}

.flag.flag-mg {
    background-position: -208px -88px
}

.flag.flag-mh {
    background-position: -224px -88px
}

.flag.flag-mk {
    background-position: -240px -88px
}

.flag.flag-ml {
    background-position: 0 -99px
}

.flag.flag-mm {
    background-position: -16px -99px
}

.flag.flag-mn {
    background-position: -32px -99px
}

.flag.flag-mo {
    background-position: -48px -99px
}

.flag.flag-mp {
    background-position: -64px -99px
}

.flag.flag-mq {
    background-position: -80px -99px
}

.flag.flag-mr {
    background-position: -96px -99px
}

.flag.flag-ms {
    background-position: -112px -99px
}

.flag.flag-mt {
    background-position: -128px -99px
}

.flag.flag-mu {
    background-position: -144px -99px
}

.flag.flag-mv {
    background-position: -160px -99px
}

.flag.flag-mw {
    background-position: -176px -99px
}

.flag.flag-mx {
    background-position: -192px -99px
}

.flag.flag-my {
    background-position: -208px -99px
}

.flag.flag-mz {
    background-position: -224px -99px
}

.flag.flag-na {
    background-position: -240px -99px
}

.flag.flag-nc {
    background-position: 0 -110px
}

.flag.flag-ne {
    background-position: -16px -110px
}

.flag.flag-nf {
    background-position: -32px -110px
}

.flag.flag-ng {
    background-position: -48px -110px
}

.flag.flag-ni {
    background-position: -64px -110px
}

.flag.flag-nl {
    background-position: -80px -110px
}

.flag.flag-no {
    background-position: -96px -110px
}

.flag.flag-np {
    background-position: -112px -110px
}

.flag.flag-nr {
    background-position: -128px -110px
}

.flag.flag-nu {
    background-position: -144px -110px
}

.flag.flag-nz {
    background-position: -160px -110px
}

.flag.flag-om {
    background-position: -176px -110px
}

.flag.flag-pa {
    background-position: -192px -110px
}

.flag.flag-pe {
    background-position: -208px -110px
}

.flag.flag-pf {
    background-position: -224px -110px
}

.flag.flag-pg {
    background-position: -240px -110px
}

.flag.flag-ph {
    background-position: 0 -121px
}

.flag.flag-pk {
    background-position: -16px -121px
}

.flag.flag-pl {
    background-position: -32px -121px
}

.flag.flag-pm {
    background-position: -48px -121px
}

.flag.flag-pn {
    background-position: -64px -121px
}

.flag.flag-pr {
    background-position: -80px -121px
}

.flag.flag-ps {
    background-position: -96px -121px
}

.flag.flag-pt {
    background-position: -112px -121px
}

.flag.flag-pw {
    background-position: -128px -121px
}

.flag.flag-py {
    background-position: -144px -121px
}

.flag.flag-qa {
    background-position: -160px -121px
}

.flag.flag-re {
    background-position: -176px -121px
}

.flag.flag-ro {
    background-position: -192px -121px
}

.flag.flag-rs {
    background-position: -208px -121px
}

.flag.flag-ru {
    background-position: -224px -121px
}

.flag.flag-rw {
    background-position: -240px -121px
}

.flag.flag-sa {
    background-position: 0 -132px
}

.flag.flag-sb {
    background-position: -16px -132px
}

.flag.flag-sc {
    background-position: -32px -132px
}

.flag.flag-scotland {
    background-position: -48px -132px
}

.flag.flag-sd {
    background-position: -64px -132px
}

.flag.flag-se {
    background-position: -80px -132px
}

.flag.flag-sg {
    background-position: -96px -132px
}

.flag.flag-sh {
    background-position: -112px -132px
}

.flag.flag-si {
    background-position: -128px -132px
}

.flag.flag-sk {
    background-position: -144px -132px
}

.flag.flag-sl {
    background-position: -160px -132px
}

.flag.flag-sm {
    background-position: -176px -132px
}

.flag.flag-sn {
    background-position: -192px -132px
}

.flag.flag-so {
    background-position: -208px -132px
}

.flag.flag-somaliland {
    background-position: -224px -132px
}

.flag.flag-sr {
    background-position: -240px -132px
}

.flag.flag-ss {
    background-position: 0 -143px
}

.flag.flag-st {
    background-position: -16px -143px
}

.flag.flag-sv {
    background-position: -32px -143px
}

.flag.flag-sx {
    background-position: -48px -143px
}

.flag.flag-sy {
    background-position: -64px -143px
}

.flag.flag-sz {
    background-position: -80px -143px
}

.flag.flag-tc {
    background-position: -96px -143px
}

.flag.flag-td {
    background-position: -112px -143px
}

.flag.flag-tf {
    background-position: -128px -143px
}

.flag.flag-tg {
    background-position: -144px -143px
}

.flag.flag-th {
    background-position: -160px -143px
}

.flag.flag-tibet {
    background-position: -176px -143px
}

.flag.flag-tj {
    background-position: -192px -143px
}

.flag.flag-tk {
    background-position: -208px -143px
}

.flag.flag-tl {
    background-position: -224px -143px
}

.flag.flag-tm {
    background-position: -240px -143px
}

.flag.flag-tn {
    background-position: 0 -154px
}

.flag.flag-to {
    background-position: -16px -154px
}

.flag.flag-tr {
    background-position: -32px -154px
}

.flag.flag-tt {
    background-position: -48px -154px
}

.flag.flag-tv {
    background-position: -64px -154px
}

.flag.flag-tw {
    background-position: -80px -154px
}

.flag.flag-tz {
    background-position: -96px -154px
}

.flag.flag-ua {
    background-position: -112px -154px
}

.flag.flag-ug {
    background-position: -128px -154px
}

.flag.flag-um {
    background-position: -144px -154px
}

.flag.flag-us {
    background-position: -160px -154px
}

.flag.flag-uy {
    background-position: -176px -154px
}

.flag.flag-uz {
    background-position: -192px -154px
}

.flag.flag-va {
    background-position: -208px -154px
}

.flag.flag-vc {
    background-position: -224px -154px
}

.flag.flag-ve {
    background-position: -240px -154px
}

.flag.flag-vg {
    background-position: 0 -165px
}

.flag.flag-vi {
    background-position: -16px -165px
}

.flag.flag-vn {
    background-position: -32px -165px
}

.flag.flag-vu {
    background-position: -48px -165px
}

.flag.flag-wales {
    background-position: -64px -165px
}

.flag.flag-wf {
    background-position: -80px -165px
}

.flag.flag-ws {
    background-position: -96px -165px
}

.flag.flag-xk {
    background-position: -112px -165px
}

.flag.flag-ye {
    background-position: -128px -165px
}

.flag.flag-yt {
    background-position: -144px -165px
}

.flag.flag-za {
    background-position: -160px -165px
}

.flag.flag-zanzibar {
    background-position: -176px -165px
}

.flag.flag-zm {
    background-position: -192px -165px
}

.flag.flag-zw {
    background-position: -208px -165px
}

form label {
    font-weight: 400
}

textarea {
    resize: vertical
}

select {
    border: 1px solid #e5e7e9;
    border-radius: 6px;
    outline: none
}

.label {
    font-weight: 400
}

label.required:after {
    content: "*";
    font-size: .8em;
    margin: .3em;
    position: relative;
    top: -2px
}

.form-group {
    margin-bottom: 16px;
    margin-bottom: 1rem
}

.form-group:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.form-control {
    border-color: rgba(0, 0, 0, .09)
}

.form-control:not(.form-control-lg) {
    font-size: 12px;
    font-size: .75rem;
    line-height: 1.3
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 13.6px;
    font-size: .85rem;
    line-height: 1.85
}

.form-control.form-control-focused {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset;
    border-color: #ccc
}

.form-control:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset;
    border-color: #ccc
}

.form-control.error {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.form-control.error:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
}

.form-select.form-select-icon-light {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPSd3aGl0ZScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBzdHJva2Utd2lkdGg9JzInIGQ9J00yIDVsNiA2IDYtNicvPjwvc3ZnPg==)
}

.form-switch.form-switch-sm {
    margin-bottom: 8px;
    margin-bottom: .5rem
}

.form-switch.form-switch-sm .form-check-input {
    height: 16px;
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem
}

.form-switch.form-switch-md {
    margin-bottom: 16px;
    margin-bottom: 1rem
}

.form-switch.form-switch-md .form-check-input {
    height: 24px;
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem
}

.form-switch.form-switch-lg {
    margin-bottom: 24px;
    margin-bottom: 1.5rem
}

.form-switch.form-switch-lg .form-check-input {
    height: 32px;
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem
}

.form-switch.form-switch-xl {
    margin-bottom: 32px;
    margin-bottom: 2rem
}

.form-switch.form-switch-xl .form-check-input {
    height: 40px;
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem
}

.form-switch .form-check-input {
    box-shadow: none !important
}

.form-switch .form-check-input:focus {
    border: 1px solid rgba(0, 0, 0, .25);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPSdyZ2JhKDAsIDAsIDAsIDAuMjUpJy8+PC9zdmc+) !important
}

.form-switch .form-check-input:checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPSd3aGl0ZScvPjwvc3ZnPg==) !important
}

.page-link {
    border-color: rgba(0, 0, 0, .06)
}

.input-group .form-control {
    height: auto
}

.input-group-rounded .form-control {
    border-radius: 2rem 0 0 2rem;
    border: 0;
    padding-left: 16px;
    padding-left: 1rem
}

.input-group-rounded .btn {
    border-color: rgba(0, 0, 0, .09);
    border-radius: 0 2rem 2rem 0;
    font-size: 11.2px;
    font-size: .7rem;
    padding: 13.92px;
    padding: .87rem;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    z-index: 3
}

input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color],
textarea {
    -webkit-appearance: none
}

.form-control::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=datetime-local]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=color]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #bdbdbd
}

.form-control::-moz-placeholder,
input[type=text]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=datetime]::-moz-placeholder,
input[type=datetime-local]::-moz-placeholder,
input[type=date]::-moz-placeholder,
input[type=month]::-moz-placeholder,
input[type=time]::-moz-placeholder,
input[type=week]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=url]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=color]::-moz-placeholder,
textarea::-moz-placeholder {
    color: #bdbdbd
}

.form-control:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=datetime]:-ms-input-placeholder,
input[type=datetime-local]:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder,
input[type=month]:-ms-input-placeholder,
input[type=time]:-ms-input-placeholder,
input[type=week]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=color]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #bdbdbd
}

.form-placeholders-light .form-control::-webkit-input-placeholder,
.form-placeholders-light input[type=text]::-webkit-input-placeholder,
.form-placeholders-light input[type=password]::-webkit-input-placeholder,
.form-placeholders-light input[type=datetime]::-webkit-input-placeholder,
.form-placeholders-light input[type=datetime-local]::-webkit-input-placeholder,
.form-placeholders-light input[type=date]::-webkit-input-placeholder,
.form-placeholders-light input[type=month]::-webkit-input-placeholder,
.form-placeholders-light input[type=time]::-webkit-input-placeholder,
.form-placeholders-light input[type=week]::-webkit-input-placeholder,
.form-placeholders-light input[type=number]::-webkit-input-placeholder,
.form-placeholders-light input[type=email]::-webkit-input-placeholder,
.form-placeholders-light input[type=url]::-webkit-input-placeholder,
.form-placeholders-light input[type=search]::-webkit-input-placeholder,
.form-placeholders-light input[type=tel]::-webkit-input-placeholder,
.form-placeholders-light input[type=color]::-webkit-input-placeholder,
.form-placeholders-light textarea::-webkit-input-placeholder {
    color: #fff
}

.form-placeholders-light .form-control::-moz-placeholder,
.form-placeholders-light input[type=text]::-moz-placeholder,
.form-placeholders-light input[type=password]::-moz-placeholder,
.form-placeholders-light input[type=datetime]::-moz-placeholder,
.form-placeholders-light input[type=datetime-local]::-moz-placeholder,
.form-placeholders-light input[type=date]::-moz-placeholder,
.form-placeholders-light input[type=month]::-moz-placeholder,
.form-placeholders-light input[type=time]::-moz-placeholder,
.form-placeholders-light input[type=week]::-moz-placeholder,
.form-placeholders-light input[type=number]::-moz-placeholder,
.form-placeholders-light input[type=email]::-moz-placeholder,
.form-placeholders-light input[type=url]::-moz-placeholder,
.form-placeholders-light input[type=search]::-moz-placeholder,
.form-placeholders-light input[type=tel]::-moz-placeholder,
.form-placeholders-light input[type=color]::-moz-placeholder,
.form-placeholders-light textarea::-moz-placeholder {
    color: #fff
}

.form-placeholders-light .form-control:-ms-input-placeholder,
.form-placeholders-light input[type=text]:-ms-input-placeholder,
.form-placeholders-light input[type=password]:-ms-input-placeholder,
.form-placeholders-light input[type=datetime]:-ms-input-placeholder,
.form-placeholders-light input[type=datetime-local]:-ms-input-placeholder,
.form-placeholders-light input[type=date]:-ms-input-placeholder,
.form-placeholders-light input[type=month]:-ms-input-placeholder,
.form-placeholders-light input[type=time]:-ms-input-placeholder,
.form-placeholders-light input[type=week]:-ms-input-placeholder,
.form-placeholders-light input[type=number]:-ms-input-placeholder,
.form-placeholders-light input[type=email]:-ms-input-placeholder,
.form-placeholders-light input[type=url]:-ms-input-placeholder,
.form-placeholders-light input[type=search]:-ms-input-placeholder,
.form-placeholders-light input[type=tel]:-ms-input-placeholder,
.form-placeholders-light input[type=color]:-ms-input-placeholder,
.form-placeholders-light textarea:-ms-input-placeholder {
    color: #fff
}

.btn-lg,
.btn-group-lg>.btn {
    line-height: 1.334
}

.input-group-btn .btn {
    padding-top: 8px;
    padding-top: .5rem;
    padding-bottom: 8px;
    padding-bottom: .5rem
}

select.form-control-sm,
select.form-control-lg {
    line-height: 1;
    height: auto !important
}

.custom-select-1 {
    position: relative
}

.custom-select-1 select {
    line-height: 1;
    font-size: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select-1 select::-ms-expand {
    display: none
}

.custom-select-1.custom-select-1-icon-light select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPSd3aGl0ZScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBzdHJva2Utd2lkdGg9JzInIGQ9J00yIDVsNiA2IDYtNicvPjwvc3ZnPg==)
}

.custom-checkbox-1 {
    position: relative;
    padding: 0 0 0 25px;
    margin-bottom: 7px;
    margin-top: 0
}

.custom-checkbox-1.form-check .form-check-input {
    margin-left: 0
}

.custom-checkbox-1.form-check .form-check-label {
    padding-left: 0
}

.custom-checkbox-1.checkbox-inline {
    display: inline-block;
    vertical-align: middle
}

.form-group .custom-checkbox-1.checkbox-inline {
    margin-top: 7px;
    padding-top: 0
}

.custom-checkbox-1:last-child,
.custom-checkbox-1:last-of-type {
    margin-bottom: 0
}

.custom-checkbox-1 input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 3px;
    margin: -6px 0 0;
    z-index: 2;
    cursor: pointer
}

.custom-checkbox-1 input[type=checkbox]:checked+label:after {
    position: absolute;
    display: inline-block;
    font-family: 'font awesome 6 free';
    content: '\F00C';
    top: 50%;
    left: 4px;
    margin-top: -5px;
    font-size: 11px;
    font-weight: 900;
    line-height: 1;
    width: 16px;
    height: 16px;
    color: #333
}

.custom-checkbox-1 input[type=checkbox]:disabled {
    cursor: not-allowed
}

.custom-checkbox-1 input[type=checkbox]:disabled:checked+label:after {
    color: #999
}

.custom-checkbox-1 input[type=checkbox]:disabled+label {
    cursor: not-allowed
}

.custom-checkbox-1 input[type=checkbox]:disabled+label:before {
    background-color: #eee
}

.custom-checkbox-1 label {
    cursor: pointer;
    margin-bottom: 0;
    text-align: left;
    line-height: 1.5
}

.custom-checkbox-1 label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -9px;
    width: 19px;
    height: 18px;
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #dadada;
    background-color: #fff
}

.custom-checkbox-1 label+label.error {
    display: block
}

.custom-checkbox-1.checkbox-custom-transparent label:before {
    background-color: transparent
}

.custom-checkbox-1.checkbox-custom-transparent input[type=checkbox]:checked+label:after {
    color: #fff
}

.custom-checkbox-1.checkbox-custom-transparent input[type=checkbox]:disabled:checked+label:after {
    color: #999
}

.custom-checkbox-switch {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 50px;
    height: 26px;
    background-color: #ccc;
    border: 1px solid #d9dadc;
    border-radius: 50px;
    cursor: pointer;
    transition: ease all 300ms
}

.custom-checkbox-switch:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 6px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    transform: translate3d(0, -50%, 0);
    transition: ease left 300ms
}

.custom-checkbox-switch:checked {
    border-color: #ccc
}

.custom-checkbox-switch:checked:after {
    left: 27px
}

.bootstrap-timepicker-widget input {
    border: 0
}

.bootstrap-timepicker-widget table td input {
    width: 40px
}

.fileupload .btn {
    line-height: 20px;
    margin-left: -5px
}

label.valid {
    display: inline-block;
    text-indent: -9999px
}

label.error {
    color: #c10000;
    font-size: .9em;
    line-height: 18px;
    padding: 5px 0 0
}

label.error#captcha-error {
    margin-top: 15px
}

.form-errors-light .form-control.error {
    border-color: #fff
}

.form-errors-light label.error {
    color: #fff
}

input[type=searchi]::-webkit-search-cancel-button {
    margin-right: -7px !important
}

.simple-search .form-control {
    border-radius: 1.3rem 0 0 1.3rem;
    border-color: #eee;
    border-right: 0
}

.simple-search .form-control:focus {
    box-shadow: none
}

.simple-search .btn {
    border: 0;
    box-shadow: none !important;
    border-radius: 0 1.3rem 1.3rem 0;
    border: 1px solid #eee;
    border-left: 0
}

.search-with-select .mobile-search-toggle-btn {
    display: none
}

.search-with-select .search-form-wrapper>.form-control {
    background-color: #f4f4f4;
    border-radius: 1.3rem 0 0 1.3rem;
    border: 0;
    height: auto;
    padding: 9.6px 22.4px;
    padding: .6rem 1.4rem;
    font-size: 12.8px !important;
    font-size: .8rem !important;
    color: #8d8d8d
}

.search-with-select .search-form-wrapper>.form-control::-webkit-input-placeholder {
    color: #8d8d8d
}

.search-with-select .search-form-wrapper>.form-control::-moz-placeholder {
    color: #8d8d8d
}

.search-with-select .search-form-wrapper>.form-control:-ms-input-placeholder {
    color: #8d8d8d
}

.search-with-select .search-form-wrapper>.form-control:focus {
    box-shadow: none
}

.search-with-select .search-form-wrapper .search-form-select-wrapper {
    display: flex;
    background-color: #f4f4f4;
    border-radius: 0 1.3rem 1.3rem 0
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .custom-select-1:before {
    color: #8d8d8d
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .custom-select-1 select {
    background-color: transparent;
    border: 1px solid #fff;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
    color: #8d8d8d;
    height: 100%;
    z-index: 1
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .custom-select-1 select:focus {
    box-shadow: none
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .btn {
    background: 0 0 !important;
    border: 0;
    box-shadow: none !important
}

.search-with-select .search-form-wrapper .search-form-select-wrapper .btn .header-nav-top-icon {
    font-size: 16px;
    font-size: 1rem;
    top: 2px;
    right: 2px;
    color: #212529;
    font-weight: 700
}

@media(max-width:991px) {
    .search-with-select {
        position: relative;
        float: right
    }

    .search-with-select .mobile-search-toggle-btn {
        display: block;
        text-decoration: none
    }

    .search-with-select .mobile-search-toggle-btn.open+.search-form-wrapper {
        display: flex;
        visibility: visible;
        opacity: 1
    }

    .search-with-select .mobile-search-toggle-btn>i {
        position: relative;
        top: 2px;
        font-size: 1.9rem
    }

    .search-with-select .search-form-wrapper {
        display: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 36px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: 300px;
        background-color: #d8d8d8;
        padding: .2rem;
        border-radius: 28px;
        transition: ease opacity 300ms
    }

    .search-with-select .search-form-wrapper:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        border-bottom: 6px solid #d8d8d8;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        transform: translate3d(-50%, 0, 0)
    }
}

.form-style-2 .form-group {
    margin-bottom: 11.2px;
    margin-bottom: .7rem
}

.form-style-2 .form-control {
    padding: 11.2px 16px;
    padding: .7rem 1rem;
    border: 0
}

.form-style-2 .form-control::-webkit-input-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-2 .form-control::-moz-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-2 .form-control:-ms-input-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-2 .form-control:-moz-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-2 .form-control:not(textarea) {
    height: calc(2.2em + .75rem + 2px)
}

.form-style-2 .form-control.error {
    border-width: 2px
}

.form-style-3 .form-group {
    margin-bottom: 17.6px;
    margin-bottom: 1.1rem
}

.form-style-3 .form-control {
    padding: 11.2px 16px;
    padding: .7rem 1rem;
    background-color: #f4f4f4;
    border: none
}

.form-style-3 .form-control::-webkit-input-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-3 .form-control::-moz-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-3 .form-control:-ms-input-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-3 .form-control:-moz-placeholder {
    color: #202528;
    opacity: .5
}

.form-style-3 .form-control.error {
    border: 2px solid #e21a1a
}

.form-style-4 .form-control {
    background-color: transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom-color: rgba(0, 0, 0, .4);
    color: #fff;
    box-shadow: none !important;
    border-radius: 0
}

.form-style-4 .form-control::-moz-placeholder {
    color: #d8d8d8
}

.form-style-4 .form-control::placeholder {
    color: #d8d8d8
}

.form-style-4 select.form-control {
    color: #fff
}

.form-style-4 select.form-control option {
    color: #777
}

.form-style-4 .form-check-label {
    color: #d8d8d8
}

.form-style-4.form-style-4-text-dark .form-control {
    color: #212121
}

.form-style-4.form-style-4-text-dark .form-control::-moz-placeholder {
    color: #777
}

.form-style-4.form-style-4-text-dark .form-control::placeholder {
    color: #777
}

.form-style-4.form-style-4-text-dark .form-check-label {
    color: #777
}

.form-style-4.form-style-4-border-light .form-control {
    border-color: rgba(255, 255, 255, .4)
}

.form-style-4.form-style-4-border-light-2 .form-control {
    border-color: rgba(255, 255, 255, .15)
}

.form-style-4.form-with-icons .form-control {
    padding-left: 31px
}

.form-style-5 .form-control {
    background-color: rgba(255, 255, 255, .05);
    border-radius: 35px;
    height: auto;
    padding: 8px 24px;
    padding: .5rem 1.5rem;
    color: #bfbfbf
}

.form-style-5 .form-control:focus {
    border-color: #676767
}

.form-label-group {
    position: relative;
    margin-bottom: 16px;
    margin-bottom: 1rem
}

.form-label-group input,
.form-label-group label {
    height: 50px;
    height: 3.125rem;
    padding: 12px;
    padding: .75rem
}

.form-label-group label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out
}

.form-label-group input::-moz-placeholder {
    color: transparent
}

.form-label-group input::placeholder {
    color: transparent
}

.form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 20px;
    padding-top: 1.25rem;
    padding-bottom: 4px;
    padding-bottom: .25rem
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: 20px;
    padding-top: 1.25rem;
    padding-bottom: 4px;
    padding-bottom: .25rem
}

.form-label-group input:not(:-moz-placeholder-shown)~label {
    padding-top: 4px;
    padding-top: .25rem;
    padding-bottom: 4px;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: 4px;
    padding-top: .25rem;
    padding-bottom: 4px;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777
}

@supports(-ms-ime-align:auto) {
    .form-label-group {
        display: flex;
        flex-direction: column-reverse
    }

    .form-label-group label {
        position: static
    }

    .form-label-group input::-ms-input-placeholder {
        color: #777
    }
}

.form-squared-borders .form-control {
    border-radius: 0
}

.form-with-icons .form-control {
    padding-left: 41px
}

.form-fields-rounded .form-control {
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 35px
}

.form-fields-rounded textarea.form-control {
    border-radius: 22px
}

.form-with-shadow .form-control {
    box-shadow: 0 0 17px -4px rgba(0, 0, 0, .1) !important
}

.form-fields-size-sm .form-control {
    padding: 0 15px;
    height: 40px
}

.form-fields-size-sm textarea.form-control {
    padding: 12px 15px;
    height: auto
}

.form-fields-size-md .form-control {
    padding: 0 23px;
    height: 54px
}

.form-fields-size-md textarea.form-control {
    padding: 18px 23px;
    height: auto
}

.form-fields-size-lg .form-control {
    padding: 0 25px;
    height: 63px
}

.form-fields-size-lg textarea.form-control {
    padding: 23px 25px;
    height: auto
}

.form-fields-font-size-sm .form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 12px;
    font-size: .75rem
}

.form-fields-font-size-md .form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 15.2px;
    font-size: .95rem
}

.form-fields-font-size-lg .form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 18.4px;
    font-size: 1.15rem
}

[data-plugin-gdpr-wrapper] {
    opacity: 0;
    transition: ease opacity 300ms
}

[data-plugin-gdpr-wrapper].show {
    opacity: 1
}

.gdpr-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(0, 0, 0, .9);
    padding: 16px 24px;
    padding: 1rem 1.5rem;
    z-index: 1050;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
    transition: ease opacity 300ms, ease transform 300ms
}

.gdpr-bar .gdpr-bar-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px
}

.gdpr-bar .gdpr-bar-actions .gdpr-agree-trigger {
    margin-left: 32px;
    margin-left: 2rem
}

.gdpr-bar.show {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0)
}

.gdpr-bar.removing {
    opacity: 0;
    transform: translate3d(0, 100%, 0)
}

@media(min-width:1200px) {
    .gdpr-bar {
        flex-direction: row;
        align-items: center
    }

    .gdpr-bar .gdpr-bar-actions {
        justify-content: flex-end;
        margin-top: 0
    }
}

.gdpr-preferences-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background: rgba(0, 0, 0, .8);
    z-index: 1051;
    opacity: 0;
    visibility: hidden;
    transition: ease opacity 300ms
}

.gdpr-preferences-popup .gdpr-preferences-popup-content {
    max-width: 1000px;
    background: #fff
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-close-popup {
    position: absolute;
    top: 25px;
    right: 25px
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body {
    max-height: 60vh;
    overflow-y: auto;
    padding: 40px;
    padding: 2.5rem
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item {
    display: flex;
    flex-wrap: wrap;
    font-size: 12.8px;
    font-size: .8rem
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-title {
    width: 100%
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-description {
    width: 70%
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-action {
    width: 30%;
    text-align: right
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item+.gdpr-cookie-item {
    margin-top: 10px
}

.gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 40px;
    padding: 1.5rem 2.5rem;
    background: #f4f4f4
}

.gdpr-preferences-popup.show {
    opacity: 1;
    visibility: visible
}

@media(min-width:992px) {
    .gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item {
        flex-wrap: nowrap
    }

    .gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-title {
        width: 20%
    }

    .gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-description {
        width: 60%
    }

    .gdpr-preferences-popup .gdpr-preferences-popup-content .gdpr-preferences-popup-content-body .gdpr-cookie-item .gdpr-cookie-item-action {
        width: 20%
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #212529;
    font-weight: 200;
    letter-spacing: -.05em;
    margin: 0;
    -webkit-font-smoothing: antialiased
}

h1 {
    font-size: 2.6em;
    line-height: 44px;
    margin: 0 0 32px
}

h2 {
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px
}

h3 {
    font-size: 1.8em;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 33px;
    text-transform: uppercase
}

h4 {
    font-size: 1.4em;
    font-weight: 600;
    line-height: 27px;
    margin: 0 0 14px
}

h5 {
    font-size: 1em;
    font-weight: 600;
    line-height: 18px;
    margin: 0 0 14px;
    text-transform: uppercase
}

h6 {
    color: #333;
    font-size: 1em;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 14px
}

h1.big {
    font-size: 4.2em;
    line-height: 54px
}

h1.small {
    font-size: 2.2em;
    line-height: 42px;
    font-weight: 600
}

h1.short {
    margin-bottom: 15px
}

h2.short {
    margin-bottom: 15px
}

h3.short,
h4.short,
h5.short,
h6.short {
    margin-bottom: 9px
}

h1.shorter,
h2.shorter,
h3.shorter,
h4.shorter,
h5.shorter,
h6.shorter {
    margin-bottom: 0
}

h1.tall,
h2.tall,
h3.tall,
h4.tall,
h5.tall,
h6.tall {
    margin-bottom: 33px
}

h1.taller,
h2.taller,
h3.taller,
h4.taller,
h5.taller,
h6.taller {
    margin-bottom: 44px
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
    margin-top: 22px
}

h1.more-spaced,
h2.more-spaced,
h3.more-spaced,
h4.more-spaced,
h5.more-spaced,
h6.more-spaced {
    margin-top: 44px
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
    color: #212529
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
    color: #fff
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .2)
}

.heading.heading-border {
    margin-bottom: 25px
}

.heading.heading-border h1,
.heading.heading-border h2,
.heading.heading-border h3,
.heading.heading-border h4,
.heading.heading-border h5,
.heading.heading-border h6 {
    margin: 0;
    padding: 0
}

.heading.heading-bottom-border h1 {
    border-bottom: 5px solid #eaeaea;
    padding-bottom: 10px
}

.heading.heading-bottom-border h2,
.heading.heading-bottom-border h3 {
    border-bottom: 2px solid #eaeaea;
    padding-bottom: 10px
}

.heading.heading-bottom-border h4,
.heading.heading-bottom-border h5,
.heading.heading-bottom-border h6 {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px
}

.heading.heading-bottom-double-border h1,
.heading.heading-bottom-double-border h2,
.heading.heading-bottom-double-border h3 {
    border-bottom: 3px double #eaeaea;
    padding-bottom: 10px
}

.heading.heading-bottom-double-border h4,
.heading.heading-bottom-double-border h5,
.heading.heading-bottom-double-border h6 {
    border-bottom: 3px double #eaeaea;
    padding-bottom: 5px
}

.heading.heading-middle-border {
    position: relative
}

.heading.heading-middle-border:before {
    border-top: 1px solid #eaeaea;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%
}

.heading.heading-middle-border h1,
.heading.heading-middle-border h2,
.heading.heading-middle-border h3,
.heading.heading-middle-border h4,
.heading.heading-middle-border h5,
.heading.heading-middle-border h6 {
    position: relative;
    background: #fff;
    display: inline-block
}

.heading.heading-middle-border h1 {
    padding-right: 15px
}

.heading.heading-middle-border h2,
.heading.heading-middle-border h3 {
    padding-right: 10px
}

.heading.heading-middle-border h4,
.heading.heading-middle-border h5,
.heading.heading-middle-border h6 {
    padding-right: 5px
}

.heading.heading-middle-border-reverse {
    text-align: right
}

.heading.heading-middle-border-reverse h1,
.heading.heading-middle-border-reverse h2,
.heading.heading-middle-border-reverse h3,
.heading.heading-middle-border-reverse h4,
.heading.heading-middle-border-reverse h5,
.heading.heading-middle-border-reverse h6 {
    padding-right: 0
}

.heading.heading-middle-border-reverse h1 {
    padding-left: 15px
}

.heading.heading-middle-border-reverse h2,
.heading.heading-middle-border-reverse h3 {
    padding-left: 10px
}

.heading.heading-middle-border-reverse h4,
.heading.heading-middle-border-reverse h5,
.heading.heading-middle-border-reverse h6 {
    padding-left: 5px
}

.heading.heading-middle-border-center {
    text-align: center
}

.heading.heading-middle-border-center h1 {
    padding-left: 15px;
    padding-right: 15px
}

.heading.heading-middle-border-center h2,
.heading.heading-middle-border-center h3 {
    padding-left: 10px;
    padding-right: 10px
}

.heading.heading-middle-border-center h4,
.heading.heading-middle-border-center h5,
.heading.heading-middle-border-center h6 {
    padding-left: 5px;
    padding-right: 5px
}

.heading.heading-border-xs:before {
    border-top-width: 1px
}

.heading.heading-border-sm:before {
    border-top-width: 2px
}

.heading.heading-border-lg:before {
    border-top-width: 3px
}

.heading.heading-border-xl:before {
    border-top-width: 5px
}

.modal-header h3 {
    color: #333;
    text-transform: none
}

@media(max-width:575px) {
    h2 {
        line-height: 40px
    }
}

@media(max-width:767px) {
    h1.big {
        font-size: 3.2em;
        line-height: 42px
    }
}

.highlight {
    position: relative;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #CCC 0);
    background-size: 200%;
    background-position: 0;
    padding-right: 8px;
    padding-right: .5rem;
    padding-left: 8px;
    padding-left: .5rem;
    transition: background-position .8s
}

.highlight.highlight-bg-opacity {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(204, 204, 204, 0.2) 0)
}

.highlight.highlight-change-text-color {
    color: #fff
}

.highlight.highlight-animated.highlight-animated-start {
    background-position: -100%
}

.featured-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    border-radius: 100%;
    color: #fff;
    height: 2.5em;
    line-height: 2.5em;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 2.5em
}

.featured-icon i {
    color: #fff;
    font-size: .9em
}

.featured-icon.featured-icon-style-2 {
    width: 5em;
    height: 5em;
    line-height: 5em
}

.featured-icon.featured-icon-hover-effect-1 {
    transition: ease transform 300ms
}

.featured-icon.featured-icon-hover-effect-1:hover {
    transform: scale(1.1)
}

.sample-icon-list {
    margin-top: 22px
}

.sample-icon-list .sample-icon a {
    display: block;
    color: #222;
    line-height: 36px;
    height: 36px;
    padding-left: 10px;
    border-radius: 4px;
    font-size: 11px
}

.sample-icon-list .sample-icon a [class*=fa-],
.sample-icon-list .sample-icon a .icons {
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    margin-right: 10px
}

.sample-icon-list .sample-icon a:hover {
    color: #fff;
    text-decoration: none
}

.sample-icon-list .sample-icon a:hover [class*=fa-],
.sample-icon-list .sample-icon a:hover .icons {
    font-size: 28px;
    vertical-align: -6px
}

#lineIcons .sample-icon-list .sample-icon a {
    line-height: 39px
}

i.pi {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 14px
}

i.pi.pi-porto-brand:before {
    content: '\f111';
    font-family: 'font awesome 6 free';
    font-weight: 700;
    font-size: .51em;
    color: #fff;
    padding: 1.2em
}

i.pi.pi-porto-brand:after {
    content: '';
    width: 2em;
    height: 2em;
    border: .5em solid #fff;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0)
}

i.pi.pi-close {
    padding: .6em;
    transform: rotate(45deg)
}

i.pi.pi-close:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-right: 1px solid #fff;
    height: 1em
}

i.pi.pi-close:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-bottom: 1px solid #fff;
    width: 1em
}

.icon-aria-expanded-change[aria-expanded=true] .fa-chevron-down {
    display: none
}

.icon-aria-expanded-change[aria-expanded=false] .fa-chevron-up {
    display: none
}

[data-icon] {
    opacity: 0
}

.icon-animated {
    opacity: 0
}

.elements-list-shadow-icon {
    font-size: 64px;
    font-size: 4rem;
    position: absolute;
    top: -18px;
    opacity: .08;
    right: -18px
}

.icon-bg {
    display: inline-block;
    /* background: url(../img/icons/icons.png) no-repeat; */
    width: 80px;
    height: 80px
}

.icon-1 {
    background-position: -10px -10px
}

.icon-2 {
    background-position: -110px -10px
}

.icon-3 {
    background-position: -210px -10px
}

.icon-plugin-1 {
    background-position: 0 100%;
    width: 115px;
    height: 115px
}

.icon-plugin-2 {
    background-position: -115px 100%;
    width: 115px;
    height: 115px
}

.icon-feature-1 {
    background-position: -20px -110px
}

.icon-feature-2 {
    background-position: -120px -110px
}

.icon-feature-3 {
    background-position: -220px -110px
}

.icon-feature-4 {
    background-position: -320px -110px
}

.icon-feature-5 {
    background-position: -420px -110px
}

.icon-feature-6 {
    background-position: -520px -110px
}

.icon-feature-7 {
    background-position: -620px -110px
}

.icon-feature-8 {
    background-position: -720px -110px
}

.icon-feature-9 {
    background-position: -820px -110px
}

.icon-feature-10 {
    background-position: -920px -110px
}

.icon-feature-11 {
    background-position: -20px -210px
}

.icon-feature-12 {
    background-position: -120px -210px
}

.icon-feature-13 {
    background-position: -220px -210px
}

.icon-feature-14 {
    background-position: -320px -210px
}

.icon-feature-15 {
    background-position: -420px -210px
}

.icon-feature-16 {
    background-position: -520px -210px
}

.icon-feature-17 {
    background-position: -624px -210px
}

.icon-feature-18 {
    background-position: -720px -210px
}

.icon-feature-19 {
    background-position: -820px -210px
}

.icon-menu-1 {
    background-position: -310px -10px
}

.icon-menu-2 {
    background-position: -410px -10px
}

.icon-menu-3 {
    background-position: -510px -10px
}

.icon-menu-4 {
    background-position: -610px -10px
}

.img-thumbnail {
    border-radius: 4px;
    position: relative
}

.img-thumbnail img {
    border-radius: 4px
}

.img-thumbnail .zoom {
    background: #ccc;
    border-radius: 100%;
    bottom: 8px;
    color: #fff;
    display: block;
    height: 30px;
    padding: 6px;
    position: absolute;
    right: 8px;
    text-align: center;
    width: 30px
}

.img-thumbnail .zoom i {
    font-size: 10px;
    left: 0;
    position: relative;
    top: -6px
}

.img-thumbnail.img-thumbnail-hover-icon {
    position: relative
}

.img-thumbnail.img-thumbnail-hover-icon img {
    position: relative;
    z-index: 1
}

.img-thumbnail.img-thumbnail-hover-icon:before {
    transition: all .2s;
    background: rgba(0, 0, 0, .65);
    border-radius: 4px;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2
}

.img-thumbnail.img-thumbnail-hover-icon:after {
    transition: all .2s;
    font-family: 'font awesome 6 free';
    font-weight: 900;
    color: #fff;
    content: "+";
    font-size: 18px;
    height: 30px;
    position: absolute;
    top: 30%;
    margin-top: -15px;
    display: block;
    text-align: center;
    width: 100%;
    opacity: 0;
    z-index: 3
}

.img-thumbnail.img-thumbnail-hover-icon:hover:before {
    opacity: 1
}

.img-thumbnail.img-thumbnail-hover-icon:hover:after {
    top: 50%;
    opacity: 1
}

.img-thumbnail a.img-thumbnail-hover-icon {
    display: inline-block
}

.img-thumbnail.img-thumbnail-no-borders {
    border: none;
    padding: 0
}

ul.image-gallery {
    list-style: none;
    margin: 0;
    padding: 0
}

ul.image-gallery .image-gallery-item {
    margin-bottom: 35px
}

ul.thumbnail-gallery {
    list-style: none;
    margin: 10px 0;
    padding: 0
}

ul.thumbnail-gallery li {
    display: inline-block;
    margin: 10px 10px 0 0;
    padding: 0
}

@media(max-width:991px) {
    .thumbnail {
        border: 0;
        text-align: center
    }
}

.masonry .masonry-item:not(.no-default-style) {
    width: 25%
}

.masonry .masonry-item:not(.no-default-style).w2 {
    width: 50%
}

.masonry .masonry-item:not(.no-default-style) img {
    border-radius: 0 !important
}

.masonry-loader {
    height: auto;
    overflow: visible;
    position: relative
}

.masonry-loader:after {
    transition: all .5s;
    content: ' ';
    background: #fff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0
}

.masonry-loader .bounce-loader {
    display: none
}

.masonry-loader.masonry-loader-showing {
    height: 200px;
    overflow: hidden
}

.masonry-loader.masonry-loader-showing:after {
    opacity: 1
}

.masonry-loader.masonry-loader-showing .bounce-loader {
    display: block
}

.masonry-loader.masonry-loader-loaded:after {
    display: none
}

@media(min-width:992px) {
    .mockup-landing-page {
        margin-top: -120px
    }
}

.hover-effect-3d {
    transform-style: flat !important
}

.hover-effect-3d .thumb-info {
    will-change: box-shadow;
    transition: box-shadow 300ms
}

.hover-effect-3d .thumb-info:hover {
    box-shadow: 0 17px 20px -15px rgba(33, 37, 41, .6)
}

.hover-effect-3d .thumb-info .thumb-info-title {
    transition: ease-out opacity .2s !important
}

.hover-effect-3d .thumb-info .thumb-info-action {
    transition: ease-out opacity .2s !important
}

.hover-effect-3d .thumb-info .thumb-info-action .thumb-info-action-icon {
    pointer-events: none
}

.hover-effect-3d .thumb-info.thumb-info-centered-info .thumb-info-title {
    top: 47%
}

.profile-image-outer-container {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    position: relative;
    cursor: pointer
}

.profile-image-outer-container .profile-image-input {
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer
}

.profile-image-outer-container .profile-image-inner-container {
    border-radius: 50%;
    padding: 5px
}

.profile-image-outer-container .profile-image-inner-container img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 5px solid #fff
}

.profile-image-outer-container .profile-image-inner-container .profile-image-button {
    position: absolute;
    z-index: 2;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 38px;
    transition: ease all .1s
}

.profile-image-outer-container:hover .profile-image-button {
    transform: scale(1.1)
}

.lazy-bg {
    /* background-image: url(img/lazy.png) */
}

.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item {
    border: 2px solid #f7f7f7;
    transition: ease all 300ms
}

.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item:hover,
.thumb-gallery-wrapper .thumb-gallery-thumbs .owl-item.selected {
    border: 2px solid #212121
}

button.mfp-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 0;
    border-radius: 4px;
    background: #212529;
    width: 35px;
    height: 100px;
    color: #fff;
    margin: 0 !important;
    transform: translate3d(0, -50%, 0)
}

button.mfp-arrow:before {
    content: none
}

button.mfp-arrow:after {
    content: "<";
    font-family: 'font awesome 6 free';
    font-weight: 900;
    border: none !important
}

button.mfp-arrow.mfp-arrow-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

button.mfp-arrow.mfp-arrow-left:after {
    margin-left: 0;
    margin-top: 0;
    position: relative;
    top: auto;
    left: -2px;
    width: auto;
    height: auto
}

button.mfp-arrow.mfp-arrow-right {
    left: auto;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

button.mfp-arrow.mfp-arrow-right:after {
    content: ">";
    margin-left: 0;
    margin-top: 0;
    position: relative;
    top: auto;
    left: auto;
    right: -2px;
    width: auto;
    height: auto
}

.mfp-close,
.mfp-close-btn-in .mfp-close {
    font-family: poppins, Arial, sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: #838383
}

.mfp-no-margins img.mfp-img {
    padding: 0
}

.mfp-no-margins .mfp-figure:after {
    top: 0;
    bottom: 0
}

.mfp-no-margins .mfp-container {
    padding: 0
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: .001;
    transition: all .3s ease-out
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0
}

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    transition: all .2s ease-in-out;
    transform: scale(.8)
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    transform: scale(1)
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    transform: scale(.8);
    opacity: 0
}

.my-mfp-zoom-in.mfp-bg {
    opacity: .001;
    transition: opacity .3s ease-out
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    transition: all .2s ease-out;
    transform: translateY(-20px) perspective(600px) rotateX(10deg)
}

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    transform: translateY(0) perspective(600px) rotateX(0)
}

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    transform: translateY(-10px) perspective(600px) rotateX(10deg)
}

.my-mfp-slide-bottom.mfp-bg {
    opacity: .01;
    transition: opacity .3s ease-out
}

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: .8
}

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.dialog {
    background: #fff;
    padding: 20px 30px;
    text-align: left;
    margin: 40px auto;
    position: relative;
    max-width: 600px
}

.dialog.dialog-xs {
    max-width: 200px
}

.dialog.dialog-sm {
    max-width: 400px
}

.dialog.dialog-md {
    max-width: 600px
}

.dialog.dialog-lg {
    max-width: 900px
}

.dialog.mfp-close-out .mfp-close {
    top: -50px;
    right: -15px;
    color: #fff
}

.white-popup-block {
    background: #fff;
    padding: 20px 30px;
    text-align: left;
    max-width: 600px;
    margin: 40px auto;
    position: relative
}

.white-popup-block.white-popup-block-xs {
    max-width: 200px
}

.white-popup-block.white-popup-block-sm {
    max-width: 400px
}

.white-popup-block.white-popup-block-md {
    max-width: 600px
}

.white-popup-block.white-popup-block-lg {
    max-width: 900px
}

html.lightbox-opened.sticky-header-active #header .header-body {
    padding-right: 17px
}

.modal-open {
    padding-right: 0 !important
}

.list li {
    margin-bottom: 13px
}

.list.pull-left li {
    text-align: left
}

.list.list-icons {
    list-style: none;
    padding-left: 0;
    padding-right: 0
}

.list.list-icons li {
    position: relative;
    padding-left: 25px
}

.list.list-icons li>[class*=fa-]:first-child,
.list.list-icons li>.icons:first-child,
.list.list-icons li a:first-child>[class*=fa-]:first-child,
.list.list-icons li a:first-child>.icons:first-child {
    position: absolute;
    left: 0;
    top: 5px
}

.list.list-icons.list-icons-style-2 li {
    padding-top: 5px;
    padding-left: 36px
}

.list.list-icons.list-icons-style-2 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-2 li>.icons:first-child,
.list.list-icons.list-icons-style-2 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-2 li a:first-child>.icons:first-child {
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 4px;
    font-size: .8em;
    line-height: 1.3
}

.list.list-icons.list-icons-style-3 li {
    padding-top: 5px;
    padding-left: 36px
}

.list.list-icons.list-icons-style-3 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li>.icons:first-child,
.list.list-icons.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-style-3 li a:first-child>.icons:first-child {
    color: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    font-size: .8em;
    line-height: 1.3
}

.list.list-icons.list-icons-sm li {
    padding-left: 13px;
    margin-bottom: 5px
}

.list.list-icons.list-icons-sm li>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm li>.icons:first-child,
.list.list-icons.list-icons-sm li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm li a:first-child>.icons:first-child {
    font-size: .8em;
    top: 7px
}

.list.list-icons.list-icons-sm.list-icons-style-2 li {
    padding-left: 32px
}

.list.list-icons.list-icons-sm.list-icons-style-2 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li>.icons:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child>.icons:first-child {
    width: 20px;
    height: 20px;
    padding: 3px
}

.list.list-icons.list-icons-sm.list-icons-style-3 li {
    padding-left: 32px
}

.list.list-icons.list-icons-sm.list-icons-style-3 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li>.icons:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child>.icons:first-child {
    width: 20px;
    height: 20px;
    padding: 3px
}

.list.list-icons.list-icons-lg li {
    padding-top: 5px;
    padding-left: 27px
}

.list.list-icons.list-icons-lg li>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg li>.icons:first-child,
.list.list-icons.list-icons-lg li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg li a:first-child>.icons:first-child {
    font-size: 1.3em;
    top: 10px
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
    padding-top: 8px;
    padding-left: 42px
}

.list.list-icons.list-icons-lg.list-icons-style-2 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li>.icons:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child>.icons:first-child {
    width: 30px;
    height: 30px;
    padding: 4px
}

.list.list-icons.list-icons-lg.list-icons-style-3 li {
    padding-top: 8px;
    padding-left: 42px
}

.list.list-icons.list-icons-lg.list-icons-style-3 li>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li>.icons:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child>.icons:first-child {
    width: 30px;
    height: 30px;
    padding: 4px
}

.list.list-icons.list-icons-reverse li {
    text-align: right;
    padding-right: 25px;
    padding-left: 0
}

.list.list-icons.list-icons-reverse li>[class*=fa-]:first-child,
.list.list-icons.list-icons-reverse li>.icons:first-child,
.list.list-icons.list-icons-reverse li a:first-child>[class*=fa-]:first-child,
.list.list-icons.list-icons-reverse li a:first-child>.icons:first-child {
    position: absolute;
    left: auto;
    right: 0;
    top: 5px
}

.list.list-icons.list-icons-reverse.list-icons-style-2 li {
    padding-right: 36px
}

.list.list-icons.list-icons-reverse.list-icons-style-3 li {
    padding-right: 36px
}

.list.list-icons.list-icons-reverse.list-icons-sm li {
    padding-left: 0;
    padding-right: 13px
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
    padding-right: 32px
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
    padding-right: 32px
}

.list.list-icons.list-icons-reverse.list-icons-lg li {
    padding-left: 0;
    padding-right: 32px
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
    padding-right: 42px
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
    padding-right: 42px
}

.list.list-borders li {
    border-bottom: 1px solid #eaeaea;
    margin-top: 9px;
    margin-bottom: 0;
    padding-bottom: 9px
}

.list.list-borders li:last-child {
    border-bottom: 0
}

.list.list-side-borders {
    border-left: 1px solid #eaeaea;
    padding-left: 10px
}

.list.list-ordened {
    counter-reset: custom-counter;
    list-style: none
}

.list.list-ordened li {
    position: relative;
    padding-left: 22px
}

.list.list-ordened li:before {
    position: absolute;
    top: 0;
    left: 0;
    content: counter(custom-counter);
    counter-increment: custom-counter;
    font-weight: 700
}

.list.list-ordened.list-ordened-style-2 li,
.list.list-ordened.list-ordened-style-3 li {
    padding-left: 32px
}

.list.list-ordened.list-ordened-style-2 li:before,
.list.list-ordened.list-ordened-style-3 li:before {
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    min-width: 26px;
    padding: 0;
    text-align: center
}

.list.list-ordened.list-ordened-style-3 li:before {
    color: #fff;
    border: 0
}

.list.list-unstyled {
    padding-left: 0;
    padding-right: 0
}

.list-unstyled {
    padding-left: 0;
    padding-right: 0
}

dl dd {
    margin-bottom: 15px
}

ul.sitemap>li>a {
    font-weight: 700
}

ul.sitemap.list li {
    margin: 0 0 3px
}

ul.sitemap.list ul {
    margin-bottom: 5px;
    margin-top: 5px
}

.loading-overlay-showing {
    overflow: hidden
}

.loading-overlay-showing>.loading-overlay {
    opacity: 1;
    visibility: visible
}

.loading-overlay {
    transition: visibility 0s ease-in-out .5s, opacity .5s ease-in-out;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    background: #fff !important
}

body>.loading-overlay {
    position: fixed;
    z-index: 999999
}

.bounce-loader {
    transition: all .2s;
    margin: -9px 0 0 -35px;
    text-align: center;
    width: 70px;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 10000
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
    animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
    background-color: #ccc;
    border-radius: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    display: inline-block;
    height: 18px;
    width: 18px
}

.bounce-loader .bounce1 {
    animation-delay: -.32s
}

.bounce-loader .bounce2 {
    animation-delay: -.16s
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0)
    }

    40% {
        transform: scale(1)
    }
}

html.safari.iphone .bounce-loader .bounce1,
html.safari.iphone .bounce-loader .bounce2,
html.safari.iphone .bounce-loader .bounce3 {
    animation: 1.4s ease-in-out infinite bouncedelay
}

.loading-overlay.loading-overlay-percentage {
    display: flex;
    align-items: center;
    justify-content: center
}

.loading-overlay.loading-overlay-percentage .page-loader-progress-wrapper {
    display: flex;
    font-size: 65.6px;
    font-size: 4.1rem;
    font-weight: 700;
    letter-spacing: -1.5px;
    color: #212121
}

.loading-overlay.loading-overlay-percentage.loading-overlay-percentage-effect-2 .loading-overlay-background-layer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0;
    background: #f4f4f4;
    z-index: 0;
    transition: ease width 300ms
}

.loading-overlay.loading-overlay-percentage.loading-overlay-percentage-effect-2 .page-loader-progress-wrapper {
    position: relative;
    z-index: 1
}

.cssload-thecube {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: -20px;
    position: relative;
    transform: rotateZ(45deg)
}

.cssload-thecube .cssload-cube {
    position: relative;
    float: left;
    width: 50%;
    height: 50%;
    transform: rotateZ(45deg);
    transform: scale(1.1)
}

.cssload-thecube .cssload-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    animation: cssload-fold-thecube 1.68s infinite linear both;
    transform-origin: 100% 100%
}

.cssload-thecube .cssload-c2 {
    transform: scale(1.1) rotateZ(90deg)
}

.cssload-thecube .cssload-c2:before {
    animation-delay: .205s
}

.cssload-thecube .cssload-c3 {
    transform: scale(1.1) rotateZ(180deg)
}

.cssload-thecube .cssload-c3:before {
    animation-delay: .42s
}

.cssload-thecube .cssload-c4 {
    transform: scale(1.1) rotateZ(270deg)
}

.cssload-thecube .cssload-c4:before {
    animation-delay: .625s
}

@keyframes cssload-fold-thecube {

    0%,
    10% {
        transform: perspective(129px) rotateX(-180deg);
        opacity: 0
    }

    25%,
    75% {
        transform: perspective(129px) rotateX(0deg);
        opacity: 1
    }

    90%,
    100% {
        transform: perspective(129px) rotateY(180deg);
        opacity: 0
    }
}

.cssload-cube-progress {
    display: block;
    margin: 0 auto;
    width: 29px;
    height: 29px;
    position: relative;
    border: 4px solid #ccc;
    animation: cssload-cube-progress 2.3s infinite ease
}

.cssload-cube-progress-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #ccc;
    animation: cssload-cube-progress-inner 2.3s infinite ease-in
}

@keyframes cssload-cube-progress {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(180deg)
    }

    50% {
        transform: rotate(180deg)
    }

    75% {
        transform: rotate(360deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes cssload-cube-progress-inner {
    0% {
        height: 0%
    }

    25% {
        height: 0%
    }

    50% {
        height: 100%
    }

    75% {
        height: 100%
    }

    100% {
        height: 0%
    }
}

.cssload-float-rings-loader {
    position: relative;
    left: calc(50% - 15px);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    perspective: 380px
}

.cssload-float-rings-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%
}

.cssload-float-rings-inner.cssload-one {
    left: 0%;
    top: 0%;
    animation: cssload-rotate-one 1.15s linear infinite;
    border-bottom: 1px solid #ccc
}

.cssload-float-rings-inner.cssload-two {
    right: 0%;
    top: 0%;
    animation: cssload-rotate-two 1.15s linear infinite;
    border-right: 1px solid #ccc
}

.cssload-float-rings-inner.cssload-three {
    right: 0%;
    bottom: 0%;
    animation: cssload-rotate-three 1.15s linear infinite;
    border-top: 1px solid #ccc
}

@keyframes cssload-rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)
    }
}

@keyframes cssload-rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)
    }

    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)
    }
}

@keyframes cssload-rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)
    }

    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
    }
}

.cssload-float-bars-container {
    margin: -70px auto 0;
    max-width: 545px;
    transform: rotate(90deg)
}

.cssload-float-bars-container * {
    box-sizing: border-box
}

.cssload-float-bars-container ul li {
    list-style: none
}

.cssload-float-bars-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around
}

.cssload-float-bars-flex-container li {
    padding: 10px;
    height: 97px;
    width: 97px;
    margin: 29px 19px;
    position: relative;
    text-align: center
}

.cssload-float-bars-loading,
.cssload-float-bars-loading:after,
.cssload-float-bars-loading:before {
    display: inline-block;
    position: relative;
    width: 5px;
    height: 49px;
    background: #ccc;
    margin-top: 5px;
    border-radius: 975px;
    animation: cssload-upDown2 1.15s ease infinite;
    animation-direction: alternate;
    animation-delay: .29s
}

.cssload-float-bars-loading:after,
.cssload-float-bars-loading:before {
    position: absolute;
    content: '';
    animation: cssload-upDown 1.15s ease infinite;
    animation-direction: alternate
}

.cssload-float-bars-loading:before {
    left: -10px
}

.cssload-float-bars-loading:after {
    left: 10px;
    animation-delay: .58s
}

@keyframes cssload-upDown {
    from {
        transform: translateY(19px)
    }

    to {
        transform: translateY(-19px)
    }
}

@keyframes cssload-upDown2 {
    from {
        transform: translateY(29px)
    }

    to {
        transform: translateY(-19px)
    }
}

.cssload-speeding-wheel-container {
    width: 100%;
    height: 49px;
    text-align: center
}

.cssload-speeding-wheel {
    width: 49px;
    height: 49px;
    margin: 0 auto;
    border: 3px solid #ccc;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: cssload-spin 575ms infinite linear
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.cssload-zenith-container {
    width: 100%;
    height: 49px;
    text-align: center
}

.cssload-zenith {
    width: 49px;
    height: 49px;
    margin: 0 auto;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    box-shadow: 3px 3px 1px #ccc;
    animation: cssload-spin 690ms infinite linear
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.cssload-spinning-square-loading {
    position: relative;
    width: 30px;
    height: 30px;
    background: #ccc;
    display: block;
    margin: auto;
    animation: 1.15s cssload-spin infinite
}

.cssload-spinning-square-loading:before,
.cssload-spinning-square-loading:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: .58s infinite alternate linear
}

.cssload-spinning-square-loading:before {
    background: #999;
    animation-name: cssload-blur1
}

.cssload-spinning-square-loading:after {
    background: #fff;
    animation-name: cssload-blur2
}

@keyframes cssload-spin {
    to {
        transform: rotate(360deg)
    }
}

@keyframes cssload-blur1 {
    to {
        transform: rotate(-30deg)
    }
}

@keyframes cssload-blur2 {
    to {
        transform: rotate(-15deg)
    }
}

.wrapper-pulse {
    margin-top: -60px
}

.cssload-pulse-loader {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    margin: 3em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #000
}

.cssload-pulse-loader,
.cssload-pulse-loader:before,
.cssload-pulse-loader:after {
    animation: 1.15s infinite ease-in-out
}

.cssload-pulse-loader:before,
.cssload-pulse-loader:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0
}

.cssload-pulse-loader {
    animation-name: cssload-pulse-loader
}

@keyframes cssload-pulse-loader {
    from {
        transform: scale(0);
        opacity: 1
    }

    to {
        transform: scale(1);
        opacity: 0
    }
}

.google-map {
    background: #e5e3df;
    height: 400px;
    margin: -35px 0 30px;
    width: 100%
}

.google-map.small {
    height: 250px;
    border-radius: 6px;
    margin: 0;
    clear: both
}

.google-map img {
    max-width: 9999px
}

.google-map-borders {
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px
}

@keyframes mapPinPulse {
    from {
        transform: scale(1);
        opacity: .7
    }

    to {
        transform: scale(10);
        opacity: 0
    }
}

.map-info-window {
    position: absolute;
    bottom: 30px;
    left: 50%;
    font-size: 12.8px;
    font-size: .8rem;
    background: #fff;
    border-radius: 4px;
    padding: 15px 20px;
    white-space: nowrap;
    transform: translate3d(-50%, 0, 0)
}

.map-info-window:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 0;
    height: 0;
    border-top: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent
}

.map-info-window .map-info-window-close {
    position: absolute;
    top: 3px;
    right: 3px
}

.map-rounded-pin {
    width: 13px;
    height: 13px;
    background: #ccc;
    border-radius: 100%
}

.map-rounded-pin.map-rounded-pin-animated {
    position: relative
}

.map-rounded-pin.map-rounded-pin-animated:before,
.map-rounded-pin.map-rounded-pin-animated:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
    animation-name: mapPinPulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 1s
}

.map-rounded-pin.map-rounded-pin-animated:after {
    animation-delay: 1.25s
}

.google-map-footer {
    margin: 35px 0 -50px !important
}

html[dir=rtl] .gm-style .gm-style-iw-c {
    padding: 20px 20px 0 0 !important
}

.min-height-screen {
    min-height: 100vh
}

@media(min-width:576px) {
    .min-height-screen-sm {
        min-height: 100vh
    }
}

@media(min-width:768px) {
    .min-height-screen-md {
        min-height: 100vh
    }
}

@media(min-width:992px) {
    .min-height-screen-lg {
        min-height: 100vh
    }
}

@media(min-width:1200px) {
    .min-height-screen-xl {
        min-height: 100vh
    }
}

.hide-text {
    display: block;
    text-indent: -9999px;
    width: 0;
    height: 0
}

.box-shadow-1:before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: .33;
    content: '';
    box-shadow: 0 30px 90px #bbb;
    transition: all .2s ease-in-out
}

.box-shadow-1.box-shadow-1-hover:before {
    opacity: 0
}

.box-shadow-1.box-shadow-1-hover:hover:before {
    opacity: .33
}

.box-shadow-hover {
    transition: ease all 300ms
}

.box-shadow-2:not(.box-shadow-hover) {
    box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45)
}

.box-shadow-2.box-shadow-hover:hover {
    box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45)
}

.box-shadow-3:not(.box-shadow-hover) {
    box-shadow: 0 30px 60px -30px rgba(0, 0, 0, .45)
}

.box-shadow-3.box-shadow-hover:hover {
    box-shadow: 0 30px 60px -30px rgba(0, 0, 0, .45)
}

.box-shadow-4:not(.box-shadow-hover) {
    box-shadow: 0 0 21px -5px rgba(0, 0, 0, .2)
}

.box-shadow-4.box-shadow-hover:hover {
    box-shadow: 0 0 21px -5px rgba(0, 0, 0, .2)
}

.box-shadow-5:not(.box-shadow-hover) {
    box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45)
}

.box-shadow-5.box-shadow-hover:hover {
    box-shadow: 0 15px 60px -36px rgba(0, 0, 0, .45)
}

.box-shadow-6:not(.box-shadow-hover) {
    box-shadow: 0 12px 45px rgba(0, 0, 0, .08)
}

.box-shadow-6.box-shadow-hover:hover {
    box-shadow: 0 12px 45px rgba(0, 0, 0, .08)
}

.border-radius {
    border-radius: 4px !important
}

.border-radius.box-shadow-1:before {
    border-radius: 4px !important
}

.border-radius-2 {
    border-radius: 8px !important
}

.border-radius-2.box-shadow-1:before {
    border-radius: 8px !important
}

html body .rounded-circle {
    border-radius: 50% !important
}

.h-50px {
    height: 50px
}

.h-min-50px {
    min-height: 50px
}

.h-100px {
    height: 100px
}

.h-min-100px {
    min-height: 100px
}

.h-150px {
    height: 150px
}

.h-min-150px {
    min-height: 150px
}

.h-200px {
    height: 200px
}

.h-min-200px {
    min-height: 200px
}

.h-250px {
    height: 250px
}

.h-min-250px {
    min-height: 250px
}

.h-300px {
    height: 300px
}

.h-min-300px {
    min-height: 300px
}

.h-350px {
    height: 350px
}

.h-min-350px {
    min-height: 350px
}

.h-400px {
    height: 400px
}

.h-min-400px {
    min-height: 400px
}

.h-450px {
    height: 450px
}

.h-min-450px {
    min-height: 450px
}

.h-500px {
    height: 500px
}

.h-min-500px {
    min-height: 500px
}

.h-550px {
    height: 550px
}

.h-min-550px {
    min-height: 550px
}

.h-600px {
    height: 600px
}

.h-min-600px {
    min-height: 600px
}

.h-650px {
    height: 650px
}

.h-min-650px {
    min-height: 650px
}

.h-700px {
    height: 700px
}

.h-min-700px {
    min-height: 700px
}

.h-750px {
    height: 750px
}

.h-min-750px {
    min-height: 750px
}

.h-800px {
    height: 800px
}

.h-min-800px {
    min-height: 800px
}

.h-850px {
    height: 850px
}

.h-min-850px {
    min-height: 850px
}

.h-900px {
    height: 900px
}

.h-min-900px {
    min-height: 900px
}

.h-950px {
    height: 950px
}

.h-min-950px {
    min-height: 950px
}

.h-1000px {
    height: 1000px
}

.h-min-1000px {
    min-height: 1000px
}

@media(min-width:576px) {
    .h-sm-50px {
        height: 50px
    }

    .h-sm-min-50px {
        min-height: 50px
    }

    .h-sm-100px {
        height: 100px
    }

    .h-sm-min-100px {
        min-height: 100px
    }

    .h-sm-150px {
        height: 150px
    }

    .h-sm-min-150px {
        min-height: 150px
    }

    .h-sm-200px {
        height: 200px
    }

    .h-sm-min-200px {
        min-height: 200px
    }

    .h-sm-250px {
        height: 250px
    }

    .h-sm-min-250px {
        min-height: 250px
    }

    .h-sm-300px {
        height: 300px
    }

    .h-sm-min-300px {
        min-height: 300px
    }

    .h-sm-350px {
        height: 350px
    }

    .h-sm-min-350px {
        min-height: 350px
    }

    .h-sm-400px {
        height: 400px
    }

    .h-sm-min-400px {
        min-height: 400px
    }

    .h-sm-450px {
        height: 450px
    }

    .h-sm-min-450px {
        min-height: 450px
    }

    .h-sm-500px {
        height: 500px
    }

    .h-sm-min-500px {
        min-height: 500px
    }

    .h-sm-550px {
        height: 550px
    }

    .h-sm-min-550px {
        min-height: 550px
    }

    .h-sm-600px {
        height: 600px
    }

    .h-sm-min-600px {
        min-height: 600px
    }

    .h-sm-650px {
        height: 650px
    }

    .h-sm-min-650px {
        min-height: 650px
    }

    .h-sm-700px {
        height: 700px
    }

    .h-sm-min-700px {
        min-height: 700px
    }

    .h-sm-750px {
        height: 750px
    }

    .h-sm-min-750px {
        min-height: 750px
    }

    .h-sm-800px {
        height: 800px
    }

    .h-sm-min-800px {
        min-height: 800px
    }

    .h-sm-850px {
        height: 850px
    }

    .h-sm-min-850px {
        min-height: 850px
    }

    .h-sm-900px {
        height: 900px
    }

    .h-sm-min-900px {
        min-height: 900px
    }

    .h-sm-950px {
        height: 950px
    }

    .h-sm-min-950px {
        min-height: 950px
    }

    .h-sm-1000px {
        height: 1000px
    }

    .h-sm-min-1000px {
        min-height: 1000px
    }
}

@media(min-width:768px) {
    .h-md-50px {
        height: 50px
    }

    .h-md-min-50px {
        min-height: 50px
    }

    .h-md-100px {
        height: 100px
    }

    .h-md-min-100px {
        min-height: 100px
    }

    .h-md-150px {
        height: 150px
    }

    .h-md-min-150px {
        min-height: 150px
    }

    .h-md-200px {
        height: 200px
    }

    .h-md-min-200px {
        min-height: 200px
    }

    .h-md-250px {
        height: 250px
    }

    .h-md-min-250px {
        min-height: 250px
    }

    .h-md-300px {
        height: 300px
    }

    .h-md-min-300px {
        min-height: 300px
    }

    .h-md-350px {
        height: 350px
    }

    .h-md-min-350px {
        min-height: 350px
    }

    .h-md-400px {
        height: 400px
    }

    .h-md-min-400px {
        min-height: 400px
    }

    .h-md-450px {
        height: 450px
    }

    .h-md-min-450px {
        min-height: 450px
    }

    .h-md-500px {
        height: 500px
    }

    .h-md-min-500px {
        min-height: 500px
    }

    .h-md-550px {
        height: 550px
    }

    .h-md-min-550px {
        min-height: 550px
    }

    .h-md-600px {
        height: 600px
    }

    .h-md-min-600px {
        min-height: 600px
    }

    .h-md-650px {
        height: 650px
    }

    .h-md-min-650px {
        min-height: 650px
    }

    .h-md-700px {
        height: 700px
    }

    .h-md-min-700px {
        min-height: 700px
    }

    .h-md-750px {
        height: 750px
    }

    .h-md-min-750px {
        min-height: 750px
    }

    .h-md-800px {
        height: 800px
    }

    .h-md-min-800px {
        min-height: 800px
    }

    .h-md-850px {
        height: 850px
    }

    .h-md-min-850px {
        min-height: 850px
    }

    .h-md-900px {
        height: 900px
    }

    .h-md-min-900px {
        min-height: 900px
    }

    .h-md-950px {
        height: 950px
    }

    .h-md-min-950px {
        min-height: 950px
    }

    .h-md-1000px {
        height: 1000px
    }

    .h-md-min-1000px {
        min-height: 1000px
    }
}

@media(min-width:992px) {
    .h-lg-50px {
        height: 50px
    }

    .h-lg-min-50px {
        min-height: 50px
    }

    .h-lg-100px {
        height: 100px
    }

    .h-lg-min-100px {
        min-height: 100px
    }

    .h-lg-150px {
        height: 150px
    }

    .h-lg-min-150px {
        min-height: 150px
    }

    .h-lg-200px {
        height: 200px
    }

    .h-lg-min-200px {
        min-height: 200px
    }

    .h-lg-250px {
        height: 250px
    }

    .h-lg-min-250px {
        min-height: 250px
    }

    .h-lg-300px {
        height: 300px
    }

    .h-lg-min-300px {
        min-height: 300px
    }

    .h-lg-350px {
        height: 350px
    }

    .h-lg-min-350px {
        min-height: 350px
    }

    .h-lg-400px {
        height: 400px
    }

    .h-lg-min-400px {
        min-height: 400px
    }

    .h-lg-450px {
        height: 450px
    }

    .h-lg-min-450px {
        min-height: 450px
    }

    .h-lg-500px {
        height: 500px
    }

    .h-lg-min-500px {
        min-height: 500px
    }

    .h-lg-550px {
        height: 550px
    }

    .h-lg-min-550px {
        min-height: 550px
    }

    .h-lg-600px {
        height: 600px
    }

    .h-lg-min-600px {
        min-height: 600px
    }

    .h-lg-650px {
        height: 650px
    }

    .h-lg-min-650px {
        min-height: 650px
    }

    .h-lg-700px {
        height: 700px
    }

    .h-lg-min-700px {
        min-height: 700px
    }

    .h-lg-750px {
        height: 750px
    }

    .h-lg-min-750px {
        min-height: 750px
    }

    .h-lg-800px {
        height: 800px
    }

    .h-lg-min-800px {
        min-height: 800px
    }

    .h-lg-850px {
        height: 850px
    }

    .h-lg-min-850px {
        min-height: 850px
    }

    .h-lg-900px {
        height: 900px
    }

    .h-lg-min-900px {
        min-height: 900px
    }

    .h-lg-950px {
        height: 950px
    }

    .h-lg-min-950px {
        min-height: 950px
    }

    .h-lg-1000px {
        height: 1000px
    }

    .h-lg-min-1000px {
        min-height: 1000px
    }
}

@media(min-width:1200px) {
    .h-xl-50px {
        height: 50px
    }

    .h-xl-min-50px {
        min-height: 50px
    }

    .h-xl-100px {
        height: 100px
    }

    .h-xl-min-100px {
        min-height: 100px
    }

    .h-xl-150px {
        height: 150px
    }

    .h-xl-min-150px {
        min-height: 150px
    }

    .h-xl-200px {
        height: 200px
    }

    .h-xl-min-200px {
        min-height: 200px
    }

    .h-xl-250px {
        height: 250px
    }

    .h-xl-min-250px {
        min-height: 250px
    }

    .h-xl-300px {
        height: 300px
    }

    .h-xl-min-300px {
        min-height: 300px
    }

    .h-xl-350px {
        height: 350px
    }

    .h-xl-min-350px {
        min-height: 350px
    }

    .h-xl-400px {
        height: 400px
    }

    .h-xl-min-400px {
        min-height: 400px
    }

    .h-xl-450px {
        height: 450px
    }

    .h-xl-min-450px {
        min-height: 450px
    }

    .h-xl-500px {
        height: 500px
    }

    .h-xl-min-500px {
        min-height: 500px
    }

    .h-xl-550px {
        height: 550px
    }

    .h-xl-min-550px {
        min-height: 550px
    }

    .h-xl-600px {
        height: 600px
    }

    .h-xl-min-600px {
        min-height: 600px
    }

    .h-xl-650px {
        height: 650px
    }

    .h-xl-min-650px {
        min-height: 650px
    }

    .h-xl-700px {
        height: 700px
    }

    .h-xl-min-700px {
        min-height: 700px
    }

    .h-xl-750px {
        height: 750px
    }

    .h-xl-min-750px {
        min-height: 750px
    }

    .h-xl-800px {
        height: 800px
    }

    .h-xl-min-800px {
        min-height: 800px
    }

    .h-xl-850px {
        height: 850px
    }

    .h-xl-min-850px {
        min-height: 850px
    }

    .h-xl-900px {
        height: 900px
    }

    .h-xl-min-900px {
        min-height: 900px
    }

    .h-xl-950px {
        height: 950px
    }

    .h-xl-min-950px {
        min-height: 950px
    }

    .h-xl-1000px {
        height: 1000px
    }

    .h-xl-min-1000px {
        min-height: 1000px
    }
}

.pagination {
    position: relative;
    z-index: 1
}

.pagination>li>a,
.pagination>li>span,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    color: #ccc;
    box-shadow: none !important
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #ccc;
    border-color: #ccc;
    box-shadow: none !important
}

.pagination.pagination-rounded .page-item {
    margin-right: 8px;
    margin-right: .5rem
}

.pagination.pagination-rounded .page-item:last-child {
    margin-right: 0
}

.pagination.pagination-rounded .page-link {
    border-radius: 50% !important;
    padding: 0;
    width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px
}

.pagination.pagination-rounded.pagination-md .page-link {
    padding: 0;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-size: 1rem
}

.pagination.pagination-rounded.pagination-lg .page-link {
    padding: 0;
    width: 60px;
    text-align: center;
    height: 60px;
    line-height: 60px
}

.read-more,
.learn-more {
    display: inline-block;
    white-space: nowrap
}

.learn-more-hover-animate-icon>i {
    transition: ease transform 300ms
}

.learn-more-hover-animate-icon:hover>i {
    transform: translate3d(5px, 0, 0)
}

.offset-anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: -100px
}

@media(max-width:991px) {
    .offset-anchor {
        top: 0
    }
}

.show-grid [class*=col] .show-grid-block {
    background-color: #eee;
    line-height: 40px;
    min-height: 40px;
    text-align: center;
    border: 1px solid #fff;
    display: block
}

.alert [class*=fa-],
.alert .icons {
    margin-right: 8px;
    font-size: 1.1em
}

.alert ul {
    padding: 0;
    margin: 7px 0 0 40px
}

.alert ul li {
    font-size: .9em
}

.alert.alert-sm {
    padding: 5px 10px;
    font-size: .9em
}

.alert.alert-lg {
    padding: 20px;
    font-size: 1.2em
}

.alert.alert-default {
    background-color: #f2f2f2;
    border-color: #eaeaea;
    color: #737272
}

.alert.alert-default .alert-link {
    color: #4c4c4c
}

.alert.alert-dark {
    background-color: #383f45;
    border-color: #0a0c0d;
    color: #d3d8dc
}

.alert.alert-dark .alert-link {
    color: #fefefe
}

.ratio.ratio-soundcloud {
    padding-bottom: 19.25%
}

.ratio.ratio-borders {
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px
}

.ratio.ratio-borders>* {
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px)
}

@media(max-width:991px) {
    .col-md-3 .sidebar {
        clear: both
    }
}

.col-half-section {
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    max-width: 555px;
    margin-left: 30px;
    margin-right: 0
}

.col-half-section.col-half-section-right {
    margin-left: 0;
    margin-right: 30px
}

@media(max-width:1199px) {
    .col-half-section {
        max-width: 465px
    }
}

@media(max-width:991px) {
    .col-half-section {
        max-width: 720px;
        margin: 0 auto !important;
        padding-left: 30px !important;
        padding-right: 30px !important
    }
}

@media(max-width:767px) {
    .col-half-section {
        max-width: 540px
    }
}

@media(max-width:575px) {
    .col-half-section {
        max-width: 100%
    }
}

.content-grid {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.content-grid .content-grid-item {
    position: relative;
    padding: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.content-grid .content-grid-item:before,
.content-grid .content-grid-item:after {
    content: '';
    position: absolute
}

.content-grid .content-grid-item:before {
    height: 100%;
    top: 0;
    left: -1px;
    border-left: 1px solid #dadada
}

.content-grid .content-grid-item:after {
    width: 100%;
    height: 0;
    top: auto;
    left: 0;
    bottom: -1px;
    border-bottom: 1px solid #dadada
}

.content-grid .content-grid-item .img-fluid {
    display: inline-block;
    flex: 0 0 auto
}

.content-grid.content-grid-dashed .content-grid-item:before {
    border-left: 1px dashed #dadada
}

.content-grid.content-grid-dashed .content-grid-item:after {
    border-bottom: 1px dashed #dadada
}

.alert-admin {
    margin: 25px 0
}

.alert-admin img {
    margin: -50px 0 0
}

.alert-admin .btn {
    margin: 0 0 -15px
}

.alert-admin p {
    color: #444
}

.alert-admin h4 {
    color: #111;
    font-size: 1.2em;
    font-weight: 600;
    text-transform: uppercase
}

.alert-admin .warning {
    color: #b20000
}

.not-included {
    color: #b7b7b7;
    display: block;
    font-size: .8em;
    font-style: normal;
    margin: -4px 0;
    padding: 0
}

.tip {
    display: inline-block;
    padding: 0 5px;
    background: #fff;
    color: #111;
    text-shadow: none;
    border-radius: 3px;
    margin-left: 8px;
    position: relative;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700
}

.tip:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: #fff;
    border-width: 5px;
    margin-top: -5px
}

.tip.skin {
    color: #fff
}

.ajax-box {
    transition: all .2s;
    position: relative
}

.ajax-box .ajax-box-content {
    transition: all .2s;
    opacity: 1;
    overflow: hidden;
    height: auto
}

.ajax-box .bounce-loader {
    opacity: 0
}

.ajax-box.ajax-box-init {
    height: 0
}

.ajax-box.ajax-box-loading {
    height: 300px
}

.ajax-box.ajax-box-loading .ajax-box-content {
    opacity: 0;
    height: 300px
}

.ajax-box.ajax-box-loading .bounce-loader {
    opacity: 1
}

.notice-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #d01a34;
    z-index: -1
}

.notice-top-bar .notice-button {
    background: rgba(235, 34, 63, .7);
    color: #fff;
    border-radius: 35px;
    transition: ease background 300ms;
    text-decoration: none
}

.notice-top-bar .notice-button:hover {
    background: #eb223f
}

.notice-top-bar .notice-top-bar-close {
    position: absolute;
    top: 7px;
    right: 7px
}

.hover-effect-1:not(.portfolio-item) {
    box-shadow: 10px 10px 74px -15px transparent;
    transition: ease transform 300ms, ease box-shadow 300ms
}

.hover-effect-1:not(.portfolio-item):hover {
    box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, .4);
    transform: translate3d(0, -15px, 0)
}

.hover-effect-1.portfolio-item {
    transition: ease transform 300ms
}

.hover-effect-1.portfolio-item .thumb-info,
.hover-effect-1.portfolio-item .owl-carousel {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .06);
    transition: ease box-shadow 300ms
}

.hover-effect-1.portfolio-item:hover .thumb-info,
.hover-effect-1.portfolio-item:hover .owl-carousel,
.hover-effect-1.portfolio-item.show .thumb-info,
.hover-effect-1.portfolio-item.show .owl-carousel {
    box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, .4)
}

.hover-effect-1 a {
    transition: ease color 300ms
}

.hover-effect-2 {
    opacity: 1;
    transition: ease opacity 300ms
}

.hover-effect-2:hover {
    opacity: .85
}

.hover-effect-3 {
    opacity: .3;
    transition: ease opacity 300ms
}

.hover-effect-3:hover {
    opacity: 1
}

.sticky-wrapper {
    position: fixed;
    width: 100% !important;
    z-index: 11
}

.sticky-wrapper .sticky-body {
    display: flex;
    background: #fff;
    width: 100%;
    z-index: 10;
    transition: ease background 300ms
}

.sticky-wrapper.sticky-wrapper-transparent .sticky-body {
    background: 0 0
}

.sticky-wrapper.sticky-wrapper-effect-1 {
    position: absolute !important
}

.sticky-wrapper.sticky-wrapper-effect-1.sticky-effect-active .sticky-body {
    position: fixed;
    background: #fff;
    animation: headerReveal .4s ease-in
}

.sticky-wrapper.sticky-wrapper-effect-1.sticky-wrapper-effect-1-dark.sticky-effect-active .sticky-body {
    background: #212529
}

.sticky-wrapper.sticky-wrapper-border-bottom.sticky-effect-active .sticky-body {
    border-bottom: 1px solid rgba(0, 0, 0, .06)
}

@keyframes pencilAnimation {
    from {
        width: 0
    }

    to {
        width: 74px
    }
}

@keyframes pencilAnimation2 {
    from {
        width: 0
    }

    to {
        width: 115px
    }
}

.highlighted-word {
    font-family: shadows into light, cursive;
    font-weight: 500
}

.highlighted-word.highlighted-word-animation-1 {
    position: relative
}

.highlighted-word.highlighted-word-animation-1:after {
    content: '';
    position: absolute;
    right: calc(100% - 74px);
    bottom: -6px;
    /* background: url(../img/pencil-blue-line.png); */
    width: 0;
    height: 15px;
    animation-name: pencilAnimation;
    animation-duration: 500ms;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    overflow: hidden;
    transform: rotate(180deg)
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-end:after {
    right: 0 !important
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-light:after {
    filter: brightness(0) invert(1)
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-dark:after {
    /* background: url(../img/pencil-blue-line-dark.png) */
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-no-rotate:after {
    transform: rotate(0)
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-no-animate:after {
    animation-duration: 0ms;
    animation-delay: 0ms
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-2:after {
    animation-name: pencilAnimation2;
    right: calc(100% - 115px);
    /* background: url(../img/pencil-blue-line-2.png); */
    background-repeat: no-repeat
}

.highlighted-word.highlighted-word-animation-1.highlighted-word-animation-1-2-dark:after {
    /* background-image: url(../img/pencil-blue-line-2-dark.png) */
}

.highlighted-word.highlighted-word-rotate {
    transform: rotate(-15deg);
    transform-origin: 0 0
}

.curved-border {
    position: relative;
    min-height: 7.2vw
}

.curved-border:after {
    content: '';
    /* background-image: url(../img/curved-border.svg); */
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100vw;
    height: 7.2vw;
    z-index: 20
}

.curved-border.curved-border-top:after {
    top: -2px;
    bottom: auto;
    transform: rotateX(180deg)
}

.vertical-divider {
    display: inline;
    border-left: 1px solid rgba(255, 255, 255, .1);
    border-right: 1px solid transparent
}

.rating-container .filled-stars {
    text-shadow: none !important;
    -webkit-text-stroke: 0 !important
}

.rating-container .star {
    margin: 0 1px
}

.rating-invisible {
    height: 27px;
    visibility: hidden !important
}

.rating-xs {
    font-size: 12px
}

.image-hotspots {
    position: relative
}

.image-hotspot {
    cursor: pointer;
    position: absolute
}

.image-hotspot strong {
    color: #fff;
    z-index: 5;
    font-size: .75em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-weight: 600 !important
}

.image-hotspot .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 28px;
    height: 28px;
    margin: -.666em auto auto -.666em;
    background: #ccc;
    border-radius: 50%;
    opacity: .6;
    transform-origin: 50% 50%;
    transition: opacity .2s ease-in, transform .1s ease-out;
    color: #fff;
    font-size: 1.5em;
    padding: 0;
    text-align: center;
    line-height: 28px;
    overflow: hidden
}

.image-hotspot .ring {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    margin: -1em auto auto -1em;
    transform-origin: 50% 50%;
    border-radius: 50%;
    border: 1px solid rgba(204, 204, 204, .9);
    opacity: 0;
    animation: hotspot-pulsate 3s ease-out infinite
}

body[data-plugin-page-transition] {
    transition: ease opacity 300ms
}

body.page-transition-active {
    opacity: 0 !important
}

.cd-product-viewer-wrapper {
    text-align: center;
    padding: 2em 0
}

.cd-product-viewer-wrapper>div {
    display: inline-block
}

@media(max-width:1199px) {
    .cd-product-viewer-wrapper>div {
        width: 100% !important
    }
}

.cd-product-viewer-wrapper .product-viewer {
    position: relative;
    z-index: 1;
    display: inline-block;
    overflow: hidden
}

@media(max-width:1199px) {
    .cd-product-viewer-wrapper .product-viewer {
        width: 100% !important
    }
}

.cd-product-viewer-wrapper img {
    display: block;
    position: relative;
    width: 100%;
    z-index: 1
}

.cd-product-viewer-wrapper .product-sprite {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 1600%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: ease opacity 300ms
}

.cd-product-viewer-wrapper.loaded .product-sprite {
    opacity: 1;
    cursor: ew-resize
}

.cd-product-viewer-handle {
    position: relative;
    z-index: 2;
    width: 60%;
    max-width: 300px;
    border-radius: 50em;
    margin: 1em auto 3em;
    height: 4px
}

.cd-product-viewer-handle .fill {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background: #212529;
    transform: scaleX(0);
    transform-origin: left center;
    transition: ease transform 500ms
}

.no-csstransitions .cd-product-viewer-handle .fill {
    display: none
}

.loaded .cd-product-viewer-handle .fill {
    opacity: 1;
    background-image: none !important;
    transition: ease transform 300ms, ease opacity 200ms 300ms
}

.cd-product-viewer-handle .handle {
    position: absolute;
    z-index: 2;
    display: inline-block;
    height: 44px;
    width: 44px;
    left: 0;
    top: -20px;
    background: #212529;
    border-radius: 50%;
    border: 2px solid #212529;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: transparent;
    transform: translateX(-50%) scale(0);
    transition: ease box-shadow 200ms
}

.cd-product-viewer-handle .handle .bars {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 20px;
    background: #fff;
    transform: translate3d(-50%, -50%, 0)
}

.cd-product-viewer-handle .handle .bars:before,
.cd-product-viewer-handle .handle .bars:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 4px;
    height: 60%;
    background: #fff;
    transform: translate3d(0, -50%, 0)
}

.cd-product-viewer-handle .handle .bars:before {
    left: -8px
}

.cd-product-viewer-handle .handle .bars:after {
    right: -8px
}

.loaded .cd-product-viewer-handle .handle {
    transform: translateX(-50%) scale(1);
    animation: cd-bounce 300ms 300ms;
    animation-fill-mode: both;
    cursor: ew-resize
}

@keyframes cd-bounce {
    0% {
        transform: translateX(-50%) scale(0)
    }

    60% {
        transform: translateX(-50%) scale(1.1)
    }

    100% {
        transform: translateX(-50%) scale(1)
    }
}

.payment-cc {
    display: flex
}

.payment-cc>i {
    font-size: 32px;
    font-size: 2rem;
    transition: ease color 300ms
}

.payment-cc>i+i {
    margin-left: 10px
}

.payment-cc>i:hover {
    color: #fff
}

.payment-cc.payment-cc-no-hover-effect>i:hover {
    color: inherit
}

.zoomContainer {
    z-index: 1
}

.porto-big-title {
    letter-spacing: -22px;
    font-size: 144px;
    font-size: 9rem;
    line-height: .8
}

@media(max-width:575px) {
    .porto-big-title {
        letter-spacing: -18px;
        font-size: 7rem
    }
}

[data-plugin-animated-letters] {
    opacity: 0;
    visibility: hidden
}

[data-plugin-animated-letters].initialized {
    opacity: 1;
    visibility: visible
}

@keyframes typeWriterFlash {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.letters-wrapper+.typeWriter:last-child {
    position: relative;
    padding-right: 4px;
    opacity: .7
}

.letters-wrapper+.typeWriter:last-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: #212121;
    animation-name: typeWriterFlash;
    animation-iteration-count: infinite;
    animation-duration: 600ms
}

.readmore-rendered {
    position: relative;
    overflow: hidden
}

.readmore-rendered .readmore-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1
}

.readmore-rendered .readmore-button-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2
}

.style-switcher-open-loader {
    color: #fff !important;
    background-color: #151719;
    display: block;
    height: 40px;
    position: fixed;
    left: 0;
    top: 135px;
    width: 40px;
    font-size: 12px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 0 4px 4px 0;
    z-index: 9999
}

.style-switcher-open-loader.style-switcher-open-loader-loading>i {
    animation-name: basicRotation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.style-switcher-open-loader.style-switcher-open-loader-loading>i:before {
    content: "\f1ce"
}

.style-switcher-open-loader .style-switcher-tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: calc(100% + 20px);
    width: 200px;
    background: #282d31;
    text-align: left;
    padding: 22.4px;
    padding: 1.4rem;
    border-radius: 4px;
    box-shadow: 0 0 30px -6px rgba(0, 0, 0, .2);
    transform: translate3d(-20px, -50%, 0);
    transition: ease opacity 300ms, ease transform 300ms
}

.style-switcher-open-loader .style-switcher-tooltip:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #282d31;
    transform: translate3d(0, -50%, 0)
}

.style-switcher-open-loader .style-switcher-tooltip>strong {
    font-size: 13.6px;
    font-size: .85rem;
    font-family: open sans;
    line-height: 1.3;
    display: block;
    margin-bottom: 8px;
    margin-bottom: .5rem
}

.style-switcher-open-loader .style-switcher-tooltip>p {
    color: #e2e2e2;
    font-size: 12.8px;
    font-size: .8rem;
    line-height: 1.5;
    margin-bottom: 0;
    opacity: .8
}

.style-switcher-open-loader .style-switcher-tooltip.active {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, -50%, 0)
}

@media(max-width:767px) {
    .style-switcher-open-loader {
        display: none
    }
}

.envato-buy-redirect,
.demos-redirect {
    color: #fff !important;
    background-color: #151719;
    display: block;
    height: 40px;
    position: fixed;
    left: 0;
    top: 176px;
    width: 40px;
    font-size: 12px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 0 4px 4px 0;
    z-index: 9998
}

.demos-redirect {
    top: 217px
}

.demos-redirect img {
    max-width: 16px;
    max-height: 16px
}

@media(max-width:767px) {

    .envato-buy-redirect,
    .demos-redirect {
        display: none
    }
}

[data-plugin-video] {
    position: relative
}

[data-plugin-video]>img {
    position: relative;
    z-index: 1
}

[data-plugin-video]>video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0
}

[data-plugin-video]>video.active {
    z-index: 1
}

.cursor-outer {
    position: fixed;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 100%;
    pointer-events: none;
    z-index: 9998;
    transition: ease-out all .08s .01s
}

.cursor-outer.cursor-outer-fit {
    left: 0 !important;
    top: 0 !important
}

.cursor-outer.cursor-color-light {
    border-color: #fff !important
}

.cursor-outer.cursor-color-dark {
    border-color: #212121 !important
}

.cursor-inner {
    position: fixed;
    left: 17px;
    top: 17px;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 100%;
    pointer-events: none;
    z-index: 9999;
    transition: all .08s ease-out
}

.cursor-inner.cursor-inner-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important
}

.cursor-inner.cursor-inner-plus:before {
    content: '+';
    color: #fff;
    font-size: 27.2px;
    font-size: 1.7rem
}

.cursor-inner.cursor-color-light:not(.cursor-inner-plus) {
    background-color: #fff !important
}

.cursor-inner.cursor-color-light.cursor-inner-plus:before {
    color: #fff !important
}

.cursor-inner.cursor-color-dark:not(.cursor-inner-plus) {
    background-color: #212121 !important
}

.cursor-inner.cursor-color-dark.cursor-inner-plus:before {
    color: #212121 !important
}

.hide-mouse-cursor {
    cursor: none
}

.hide-mouse-cursor a,
.hide-mouse-cursor button,
.hide-mouse-cursor input,
.hide-mouse-cursor textarea,
.hide-mouse-cursor .mfp-auto-cursor .mfp-content {
    cursor: none !important
}

.cursor-effect-size-small .cursor-outer {
    width: 20px;
    height: 20px;
    left: 10px;
    top: 9px
}

.cursor-effect-size-small .cursor-inner {
    width: 4px;
    height: 4px;
    left: 18px
}

.cursor-effect-size-small .cursor-inner.cursor-inner-plus:before {
    font-size: 17.6px;
    font-size: 1.1rem
}

.cursor-effect-size-big .cursor-outer {
    width: 60px;
    height: 60px;
    left: -9px;
    top: -10px
}

.cursor-effect-size-big .cursor-inner {
    width: 8px;
    height: 8px
}

.cursor-effect-size-big .cursor-inner.cursor-inner-plus:before {
    font-size: 41.6px;
    font-size: 2.6rem
}

.cursor-effect-style-square .cursor-outer {
    border-radius: 0
}

.cursor-effect-style-square .cursor-inner {
    border-radius: 0
}

.copy-to-clipboard-wrapper .btn {
    position: relative
}

.copy-to-clipboard-wrapper .btn:before {
    content: 'Copy to Clipboard';
    position: absolute;
    top: -41px;
    left: 50%;
    background: #212121;
    border-radius: 4px;
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
    display: none;
    transition: ease opacity 300ms;
    white-space: nowrap;
    padding: 6px 14px;
    font-size: 12px;
    font-size: .75rem;
    z-index: 10
}

.copy-to-clipboard-wrapper .btn:after {
    content: '';
    position: absolute;
    top: -12px;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 7px solid #212121;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
    display: none;
    transition: ease opacity 300ms
}

.copy-to-clipboard-wrapper .btn:hover:before,
.copy-to-clipboard-wrapper .btn:hover:after,
.copy-to-clipboard-wrapper .btn.copied:before,
.copy-to-clipboard-wrapper .btn.copied:after {
    display: block;
    opacity: 1
}

.copy-to-clipboard-wrapper .btn.copied:before {
    content: 'Copied!'
}

.particles-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.particles-wrapper div {
    height: 100%
}

.particle {
    position: absolute
}

.particle.particle-dots {
    /* -webkit-mask-image: url(../img/particle-dot.svg);
    mask-image: url(../img/particle-dot.svg); */
    z-index: 0;
    -webkit-mask-size: 18px
}

.particle.particle-repeating-lines {
    background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #0088cc 1.6px, #0088cc 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px)
}

.particle.particle-repeating-lines.bg-light {
    background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #FFF 1.6px, #FFF 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px) !important
}

.particle.particle-repeating-lines.bg-dark {
    background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #212529 1.6px, #212529 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px) !important
}

.patterns svg {
    position: absolute
}

.progress-reading {
    z-index: 99999;
    height: 8px
}

.content-switcher-wrapper {
    position: relative
}

.content-switcher {
    transition: all .2s ease-in-out;
    opacity: 0;
    top: 0
}

.content-switcher.active {
    position: absolute;
    opacity: 1
}

.content-switcher.initialized {
    position: absolute
}

.popup-inline-content,
.mfp-content .ajax-container {
    background: none repeat scroll 0 0 #fff;
    margin: 40px auto;
    max-width: 1170px;
    padding: 20px 30px;
    position: relative;
    text-align: left;
    display: none
}

.mfp-content .ajax-container {
    display: block
}

.mfp-wrap .popup-inline-content {
    display: block
}

.modal-header .btn-close {
    text-indent: -99999px
}

.modal-footer>:not(:first-child) {
    margin-left: 4px;
    margin-left: .25rem;
    margin-right: 0;
    margin-right: 0
}

.modal-footer>:not(:last-child) {
    margin-right: 4px;
    margin-right: .25rem;
    margin-left: 0;
    margin-left: 0
}

.nav>li>a {
    text-decoration: none
}

.nav>li>a:hover,
.nav>li>a:focus {
    background: 0 0;
    text-decoration: none
}

ul.nav-list li {
    margin: 0;
    padding: 0;
    display: block;
    position: relative
}

ul.nav-list li a {
    transition: background .1s;
    border-bottom: 1px solid rgba(0, 0, 0, .06);
    padding: 8px 0 8px 22px;
    display: block;
    color: #666;
    font-size: .9em;
    text-decoration: none;
    line-height: 20px
}

ul.nav-list li a:before {
    font-family: 'font awesome 6 free';
    content: "\f054";
    font-weight: 900;
    position: absolute;
    top: 8px;
    font-size: 7.2px;
    font-size: .45rem;
    opacity: .7;
    transform: translate3d(-1px, 0, 0);
    left: 12px
}

ul.nav-list li a:hover {
    text-decoration: none
}

ul.nav-list li a:hover:before,
ul.nav-list li a:hover i[class*=fa-chevron] {
    animation-name: arrowLeftRight;
    animation-duration: 500ms;
    animation-iteration-count: infinite
}

ul.nav-list li a i[class*=fa-chevron] {
    position: relative;
    top: -1px;
    font-size: 8px;
    font-size: .5rem;
    opacity: .8
}

ul.nav-list li>a.active {
    font-weight: 700
}

ul.nav-list li ul {
    list-style: none;
    margin: 0 0 0 25px;
    padding: 0
}

ul.nav-list li:last-child a {
    border-bottom: 0
}

ul.nav-list.hide-not-active li ul {
    display: none
}

ul.nav-list.hide-not-active li.active ul {
    display: block
}

ul.nav-list.show-bg-active .active>a,
ul.nav-list.show-bg-active a.active {
    background-color: #f5f5f5
}

ul.nav-list.show-bg-active .active>a:hover,
ul.nav-list.show-bg-active a.active:hover {
    background-color: #eee
}

ul.nav-list.no-arrows li a:before {
    content: none
}

ul.nav-list-simple li {
    margin: 0;
    padding: 0;
    display: block;
    position: relative
}

ul.nav-list-simple li a {
    transition: background .1s;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    padding: 8px 0;
    display: block;
    color: #666;
    font-size: .9em;
    text-decoration: none;
    line-height: 20px;
    transition: ease padding 300ms
}

ul.nav-list-simple li>a.active {
    font-weight: 700
}

ul.nav-list-simple li:hover a {
    padding-left: 4px
}

ul.nav-list-simple li ul {
    list-style: none;
    margin: 0 0 0 25px;
    padding: 0
}

ul.narrow li a {
    padding: 4px 0
}

ul.nav-pills>li>a.active {
    color: #fff;
    background-color: #ccc
}

ul.nav-pills>li>a.active:hover,
ul.nav-pills>li>a.active:focus {
    color: #fff;
    background-color: #ccc
}

.nav-pills-center {
    text-align: center
}

.nav-pills-center>li {
    display: inline-block;
    float: none
}

.nav-pills-sm>li>a {
    padding: 7px 12px
}

.nav-pills-xs>li>a {
    padding: 5px 10px;
    font-size: .9em
}

.nav-pills .nav-link.active {
    background-color: transparent
}

.show>.nav-pills .nav-link {
    background-color: transparent
}

.nav-light>li>a {
    color: #fff
}

.nav-active-style-1 {
    min-height: 100px;
    align-items: center
}

.nav-active-style-1>li {
    align-self: stretch
}

.nav-active-style-1>li>a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 !important;
    margin: 0 15px;
    border-bottom: 2px solid transparent;
    transition: ease border-color 300ms
}

.nav-active-style-1>li>a:hover,
.nav-active-style-1>li>a:focus,
.nav-active-style-1>li>a.active {
    border-bottom-color: #ccc
}

@media(max-width:575px) {
    .nav-active-style-1>li>a {
        min-height: 45px;
        justify-content: center
    }
}

.nav-secondary {
    background: #fafafa;
    margin: 0;
    padding: 20px 0;
    text-align: center;
    z-index: 99;
    width: 100% !important
}

.nav-secondary .nav-pills>li {
    float: none;
    display: inline-block
}

.nav-secondary .nav-pills>li a {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 13px;
    text-transform: uppercase
}

.nav-secondary.sticky-active {
    border-bottom-color: #e9e9e9;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1)
}

html.boxed .nav-secondary.sticky-active {
    max-width: 1200px;
    left: auto !important
}

.section-scroll-dots-navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: 30px;
    background: #fff;
    border-radius: 35px;
    padding: 9.6px;
    padding: .6rem;
    z-index: 10;
    transform: translate3d(0, -50%, 0)
}

.section-scroll-dots-navigation>ul {
    margin: 8px 0;
    margin: .5rem 0
}

.section-scroll-dots-navigation>ul:hover>li.active:hover>a>span {
    opacity: 1;
    visibility: visible
}

.section-scroll-dots-navigation>ul:hover>li.active>a>span {
    opacity: 0;
    visibility: hidden
}

.section-scroll-dots-navigation>ul>li {
    position: relative;
    margin-bottom: 4.8px;
    margin-bottom: .3rem
}

.section-scroll-dots-navigation>ul>li:last-child {
    margin-bottom: 0
}

.section-scroll-dots-navigation>ul>li:hover {
    background: 0 0
}

.section-scroll-dots-navigation>ul>li:hover>a>span {
    opacity: 1;
    visibility: visible
}

.section-scroll-dots-navigation>ul>li.active>a:before {
    opacity: 1
}

.section-scroll-dots-navigation>ul>li>a {
    position: relative;
    width: 15px;
    height: 15px;
    display: block;
    padding: 4.8px;
    padding: .3rem
}

.section-scroll-dots-navigation>ul>li>a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    opacity: .3;
    background: #ccc;
    transition: ease opacity 300ms
}

.section-scroll-dots-navigation>ul>li>a>span {
    position: absolute;
    top: 50%;
    right: calc(100% + 20px);
    background: #fff;
    color: #212529;
    font-size: 12.8px;
    font-size: .8rem;
    font-weight: 800;
    padding: 3.2px 16px;
    padding: .2rem 1rem;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -50%, 0);
    transition: ease opacity 300ms
}

.section-scroll-dots-navigation>ul>li>a>span:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #f7f7f7;
    transform: translate3d(0, -50%, 0)
}

.section-scroll-dots-navigation.section-scroll-dots-navigation-style-2 {
    background: 0 0
}

.section-scroll-dots-navigation.section-scroll-dots-navigation-light>ul>li>a:before {
    background: #fff
}

.section-scroll-dots-navigation.section-scroll-dots-navigation-light>ul>li>a>span {
    right: calc(100% + 10px)
}

@media(max-width:767px) {
    .section-scroll-dots-navigation {
        right: 15px
    }
}

.overlay {
    position: relative
}

.overlay:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #212529;
    opacity: 0;
    pointer-events: none;
    transition: ease opacity 300ms 100ms
}

.overlay:hover:before,
.overlay.overlay-show:before,
.overlay.show:before {
    opacity: .8
}

.overlay.overlay-op-1:hover:before,
.overlay.overlay-op-1.overlay-show:before,
.overlay.overlay-op-1.show:before {
    opacity: .1
}

.overlay.overlay-op-2:hover:before,
.overlay.overlay-op-2.overlay-show:before,
.overlay.overlay-op-2.show:before {
    opacity: .2
}

.overlay.overlay-op-3:hover:before,
.overlay.overlay-op-3.overlay-show:before,
.overlay.overlay-op-3.show:before {
    opacity: .3
}

.overlay.overlay-op-4:hover:before,
.overlay.overlay-op-4.overlay-show:before,
.overlay.overlay-op-4.show:before {
    opacity: .4
}

.overlay.overlay-op-5:hover:before,
.overlay.overlay-op-5.overlay-show:before,
.overlay.overlay-op-5.show:before {
    opacity: .5
}

.overlay.overlay-op-6:hover:before,
.overlay.overlay-op-6.overlay-show:before,
.overlay.overlay-op-6.show:before {
    opacity: .6
}

.overlay.overlay-op-7:hover:before,
.overlay.overlay-op-7.overlay-show:before,
.overlay.overlay-op-7.show:before {
    opacity: .7
}

.overlay.overlay-op-8:hover:before,
.overlay.overlay-op-8.overlay-show:before,
.overlay.overlay-op-8.show:before {
    opacity: .8
}

.overlay.overlay-op-9:hover:before,
.overlay.overlay-op-9.overlay-show:before,
.overlay.overlay-op-9.show:before {
    opacity: .9
}

.overlay.overlay-backward:hover:before,
.overlay.overlay-backward.overlay-show:before,
.overlay.overlay-backward.show:before {
    z-index: 0
}

.overlay .container,
.overlay .container-fluid {
    position: relative;
    z-index: 3
}

.overlay-light:before {
    background-color: #fff !important
}

.overlay-dark:before {
    background-color: #212529 !important
}

.overlay-primary:before {
    background: var(--primary) !important
}

.overlay-secondary:before {
    background: var(--secondary) !important
}

.overlay-tertiary:before {
    background: var(--tertiary) !important
}

.overlay-quaternary:before {
    background: var(--quaternary) !important
}

.overlay-gradient-bottom-content:before {
    opacity: 1 !important;
    background: transparent;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, #212529 100%)
}

.overlay-gradient-top-content:before {
    opacity: 1 !important;
    background: transparent;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 30%, #212529 100%)
}

.overlay-gradient:before {
    background-color: #6c6ef1;
    background-image: linear-gradient(to right, #6c6ef1 0%, #e2498e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1)
}

.overlay-gradient.overlay-gradient-animated:before {
    background-size: 200% 200%;
    animation-name: gradientTransition;
    animation-duration: 20000ms;
    animation-iteration-count: infinite
}

.parallax {
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: repeat;
    background-attachment: fixed;
    position: relative;
    clear: both
}

.parallax.parallax-mouse {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.parallax.parallax-mouse .parallax-mouse-object {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: contain;
    object-fit: contain
}

.parallax:not(.page-header):not(.match-height):not([class*=section-height-]) {
    margin: 70px 0;
    padding: 50px 0
}

.parallax blockquote {
    border: 0;
    padding: 0 10%
}

.parallax blockquote i.fa-quote-left {
    font-size: 34px;
    position: relative;
    left: -5px
}

.parallax blockquote p {
    font-size: 24px;
    line-height: 30px
}

.parallax blockquote span {
    font-size: 16px;
    line-height: 20px;
    color: #999;
    position: relative;
    left: -5px
}

.parallax-disabled .parallax-background {
    background-attachment: scroll !important;
    background-position: 50% 50% !important
}

.parallax-background+.container {
    position: relative
}

.scrollable-parallax-wrapper {
    position: relative;
    z-index: 1
}

.pricing-table {
    display: flex;
    flex-wrap: wrap
}

.pricing-table .plan {
    background: #fff;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 15px
}

.pricing-table .plan .plan-price,
.pricing-table .plan .plan-features,
.pricing-table .plan .plan-footer {
    border: 1px solid #ededed
}

.pricing-table .plan .plan-header {
    background: #212529;
    padding: 15.2px 16px;
    padding: .95rem 1rem
}

.pricing-table .plan .plan-header h3 {
    color: #fff;
    font-size: 12.8px;
    font-size: .8rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0
}

.pricing-table .plan .plan-price {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-top: 0;
    border-bottom: 0;
    padding: 32px 12.8px;
    padding: 2rem .8rem;
    text-align: center
}

.pricing-table .plan .plan-price .price {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 43.2px;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 1;
    color: #212529
}

.pricing-table .plan .plan-price .price .price-unit {
    font-size: 50%;
    line-height: 1.4
}

.pricing-table .plan .plan-price .price-label {
    text-transform: uppercase;
    color: #777;
    font-size: 11.2px;
    font-size: .7rem;
    line-height: 1;
    margin-bottom: 0
}

.pricing-table .plan .plan-features {
    border-top: 0;
    border-bottom: 0;
    padding: 12.8px;
    padding: .8rem;
    text-align: center
}

.pricing-table .plan .plan-features ul {
    padding: 0 8px;
    padding: 0 .5rem;
    margin-bottom: 0
}

.pricing-table .plan .plan-features ul li {
    border-bottom: 1px solid #ededed;
    line-height: 2.9;
    list-style: none;
    font-size: .9em
}

.pricing-table .plan .plan-features ul li:last-child {
    border-bottom: 0
}

.pricing-table .plan .plan-footer {
    border-top: 0;
    padding: 24px 12.8px 40px;
    padding: 1.5rem .8rem 2.5rem;
    text-align: center
}

.pricing-table .plan.plan-featured {
    position: relative;
    box-shadow: 0 0 40px rgba(200, 200, 200, .3);
    transform: scale(1.15);
    z-index: 1
}

.pricing-table .plan.plan-featured .plan-header h3,
.pricing-table .plan.plan-featured .plan-features ul li {
    font-size: 12.8px;
    font-size: .8rem
}

@media(max-width:767px) {
    .pricing-table .plan.plan-featured {
        transform: scale(1)
    }

    .pricing-table .plan.plan-featured .plan-header h3,
    .pricing-table .plan.plan-featured .plan-features ul li {
        font-size: .9rem
    }
}

.pricing-block>.row {
    margin-right: 0;
    margin-left: 0
}

.pricing-block>.row>div:nth-child(1) {
    padding: 27.2px;
    padding: 1.7rem
}

.pricing-block>.row>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 27.2px;
    padding: 1.7rem;
    background: #f7f7f7;
    text-align: center;
    border-left: 1px solid #dee2e6;
    border-radius: 0 4px 4px 0
}

.pricing-block .plan-price {
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    border-top: 0;
    border-bottom: 0;
    text-align: center
}

.pricing-block .plan-price .price {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 43.2px;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 1;
    color: #212529
}

.pricing-block .plan-price .price .price-unit {
    font-size: 50%;
    line-height: 1.4
}

.pricing-block .plan-price .price-label {
    text-transform: uppercase;
    color: #777;
    font-size: 11.2px;
    font-size: .7rem;
    line-height: 1;
    margin-bottom: 0
}

@media(max-width:991px) {
    .pricing-block>.row>div:nth-child(2) {
        border-left: none;
        border-top: 1px solid #dee2e6;
        border-radius: 0 0 4px 4px
    }
}

.price-menu-item .price-menu-item-details {
    display: flex;
    justify-content: space-between;
    position: relative
}

.price-menu-item .price-menu-item-details .price-menu-item-title {
    position: relative;
    padding-bottom: 10px
}

.price-menu-item .price-menu-item-details .price-menu-item-price {
    position: relative;
    top: -5px
}

.price-menu-item .price-menu-item-details .price-menu-item-price strong {
    font-size: 20.8px;
    font-size: 1.3rem
}

.price-menu-item .price-menu-item-details .price-menu-item-price strong>span {
    font-size: .6em
}

.price-menu-item .price-menu-item-details .price-menu-item-line {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: dashed 1px #777
}

.price-menu-item .price-menu-item-img img {
    border-radius: 50%;
    max-width: 60px;
    height: auto
}

.price-menu-item .price-menu-item-desc {
    position: relative;
    margin-top: 5px
}

.price-menu-item+.price-menu-item {
    margin-top: 25px
}

.process {
    text-align: center
}

.process .process-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative
}

.process .process-step-circle {
    display: flex;
    justify-content: center;
    height: 90px;
    text-align: center;
    width: 90px;
    border: 2px solid #ccc;
    border-radius: 50%;
    align-items: center;
    position: relative;
    background-color: transparent;
    transition: all .2s ease-in-out
}

.process .process-step-content {
    padding-top: 20px
}

.process .process-step-circle-content {
    font-weight: 700;
    color: #212529;
    font-size: 1.6em;
    letter-spacing: -1px;
    transition: all .2s ease-in-out
}

.process .process-step:hover .process-step-circle {
    background-color: #ccc
}

.process .process-step:hover .process-step-circle .process-step-circle-content {
    color: #fff
}

.process .process-step:hover .process-step-circle .process-step-circle-content .icons {
    color: #fff !important
}

.process.process-shapes .process-step-circle {
    position: relative;
    width: auto;
    height: auto;
    border: 0;
    margin-bottom: 19.2px;
    margin-bottom: 1.2rem
}

.process.process-shapes .process-step-circle .process-step-circle-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #ccc;
    border-radius: 100%;
    color: #fff;
    z-index: 3
}

.process.process-shapes .process-step-circle:before,
.process.process-shapes .process-step-circle:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #777;
    border-radius: 100%;
    opacity: .6;
    transform: translate3d(-50%, -50%, 0) scale(1.5);
    transition: ease transform 1s;
    z-index: 2
}

.process.process-shapes .process-step-circle:after {
    background-color: #999;
    opacity: .3;
    transform: translate3d(-50%, -50%, 0) scale(2);
    z-index: 1
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:before {
    animation-name: processShapesScaleOne;
    animation-delay: 300ms
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:after {
    animation-name: processShapesScaleTwo
}

.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:before,
.process.process-shapes.process-shapes-hover-animate .process-step:hover .process-step-circle:after {
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:before {
    animation-name: processShapesScaleOne;
    animation-delay: 300ms
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:after {
    animation-name: processShapesScaleTwo
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:before,
.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle:after {
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle.process-shapes-always-animate-delay:before {
    animation-name: processShapesScaleOne;
    animation-delay: 1000ms
}

.process.process-shapes.process-shapes-always-animate .process-step .process-step-circle.process-shapes-always-animate-delay:after {
    animation-name: processShapesScaleTwo;
    animation-delay: 700ms
}

@keyframes processShapesScaleOne {
    0% {
        opacity: .6;
        transform: translate3d(-50%, -50%, 0) scale(1.5)
    }

    25% {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(1.8)
    }

    50% {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(1)
    }

    100% {
        opacity: .6;
        transform: translate3d(-50%, -50%, 0) scale(1.5)
    }
}

@keyframes processShapesScaleTwo {
    0% {
        opacity: .3;
        transform: translate3d(-50%, -50%, 0) scale(2)
    }

    25% {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(2.3)
    }

    50% {
        opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(1)
    }

    100% {
        opacity: .3;
        transform: translate3d(-50%, -50%, 0) scale(2)
    }
}

@media(min-width:992px) {
    .process .process-step:before {
        content: '';
        height: 2px;
        display: block;
        background-color: #ededed;
        position: absolute;
        width: 50%;
        top: 45px;
        left: 75%
    }

    .process .process-step:last-child:before {
        display: none
    }

    .process.process-shapes .process-step:before {
        top: 23px
    }
}

.process-vertical {
    text-align: left
}

.process-vertical .process-step {
    width: 100%;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    position: relative;
    padding-bottom: 60px
}

.process-vertical .process-step:before {
    display: none
}

.process-vertical .process-step:after {
    width: 2px;
    display: block;
    background-color: #ededed;
    position: absolute;
    content: '';
    height: calc(100% - 80px);
    top: 70px;
    left: 30px
}

.process-vertical .process-step:last-child:after {
    display: none
}

.process-vertical .process-step-circle {
    height: 60px;
    width: 60px;
    margin: 0 15px 0 0;
    min-height: 60px;
    min-width: 60px
}

.process-vertical .process-step-content {
    padding-top: 0
}

.process-connecting-line {
    position: relative;
    text-align: left
}

.process-connecting-line .connecting-line {
    content: '';
    position: absolute;
    top: 25px;
    left: 15px;
    right: 15px;
    height: 3px;
    background: #ccc
}

.process-connecting-line .process-step {
    align-items: flex-start;
    padding-right: 48px;
    padding-right: 3rem;
    padding-left: 48px;
    padding-left: 3rem
}

.process-connecting-line .process-step:before {
    content: none
}

.process-connecting-line .process-step-circle {
    background: #fff;
    width: 50px;
    height: 50px;
    border: 3px solid #ccc
}

html .scroll-to-top {
    transition: opacity .3s;
    background: #404040;
    border-radius: 4px 4px 0 0;
    bottom: 0;
    color: #fff;
    display: block;
    height: 9px;
    opacity: 0;
    padding: 10px 10px 35px;
    position: fixed;
    right: 10px;
    text-align: center;
    text-decoration: none;
    min-width: 50px;
    z-index: 1040;
    font-size: 1.5em
}

html .scroll-to-top:hover {
    opacity: 1
}

html .scroll-to-top.visible {
    opacity: .75
}

html .scroll-to-top span {
    display: inline-block;
    padding: 0 5px
}

html.lightbox-opened .scroll-to-top {
    right: 27px
}

@media(max-width:991px) {
    html .scroll-to-top.hidden-mobile {
        display: none !important
    }
}

.scrollable {
    overflow: hidden;
    position: relative;
    width: 100%
}

.scrollable .scrollable-content {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 37px 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    outline: none
}

.scrollable .scrollable-content::-webkit-scrollbar {
    visibility: hidden
}

.scrollable .scrollable-pane {
    bottom: 0;
    opacity: .01;
    position: absolute;
    right: 5px;
    top: 0;
    transition: all .2s ease 0s;
    width: 4px
}

.scrollable .scrollable-slider {
    border-radius: 5px;
    background: none repeat scroll 0 0 #ccc;
    margin: 0;
    position: relative;
    transition: opacity .2s ease 0s;
    opacity: 0
}

.scrollable.scrollable-padding .scrollable-content {
    padding: 10px 24px 10px 10px
}

.scrollable:hover .scrollable-slider,
.scrollable.visible-slider .scrollable-slider {
    opacity: 1
}

section.section {
    background: #f7f7f7;
    border-top: 5px solid #f1f1f1;
    margin: 30px 0;
    padding: 50px 0
}

section.section>.container {
    position: relative
}

section.section.section-height-1 {
    padding: 12.307px 0;
    padding: .7692307692rem 0
}

section.section.section-height-2 {
    padding: 36.923px 0;
    padding: 2.3076923077rem 0
}

section.section.section-height-3 {
    padding: 73.846px 0;
    padding: 4.6153846154rem 0
}

section.section.section-height-4 {
    padding: 110.769px 0;
    padding: 6.9230769231rem 0
}

section.section.section-height-5 {
    padding: 147.692px 0;
    padding: 9.2307692308rem 0
}

section.section.section-bg-strong-grey {
    background: #ededed
}

section.section.section-no-background {
    background: 0 0;
    border-top-color: transparent
}

section.section.section-center {
    text-align: center
}

section.section.section-text-light h1,
section.section.section-text-light h2,
section.section.section-text-light h3,
section.section.section-text-light h4,
section.section.section-text-light h5,
section.section.section-text-light h6 {
    color: #fff
}

section.section.section-text-light p {
    color: #e6e5e5
}

section.section.section-background {
    background-repeat: repeat;
    border: 0
}

section.section.section-background-change-anim {
    animation-name: colorTransition;
    animation-duration: 25s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

section.section.section-funnel {
    position: relative
}

section.section.section-funnel .section-funnel-layer-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 96px 0;
    padding: 6rem 0;
    transform: translate3d(0, -50%, 0)
}

section.section.section-funnel .section-funnel-layer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 96px 0;
    padding: 6rem 0;
    transform: translate3d(0, 50%, 0)
}

section.section.section-funnel .section-funnel-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff
}

section.section.section-funnel .section-funnel-layer:nth-child(1) {
    transform: skewY(-4.7deg)
}

section.section.section-funnel .section-funnel-layer:nth-child(2) {
    transform: skewY(4.7deg)
}

section.section.section-angled {
    position: relative;
    margin: 0;
    overflow: hidden
}

section.section.section-angled .section-angled-layer-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 48px 0;
    padding: 3rem 0;
    transform: skewY(-0.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled .section-angled-layer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 48px 0;
    padding: 3rem 0;
    transform: skewY(0.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled .section-angled-layer-top+.section-angled-content,
section.section.section-angled .section-angled-layer-top+.section-angled-layer-bottom+.section-angled-content {
    margin-top: 40px;
    margin-top: 2.5rem
}

section.section.section-angled .section-angled-layer-bottom+.section-angled-content,
section.section.section-angled .section-angled-layer-bottom+.section-angled-layer-top+.section-angled-content {
    margin-bottom: 40px;
    margin-bottom: 2.5rem
}

section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-top {
    transform: skewY(-1.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled .section-angled-layer-increase-angle.section-angled-layer-bottom {
    transform: skewY(1.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-top {
    padding: 112px 0;
    padding: 7rem 0;
    transform: skewY(-4.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
    padding: 112px 0;
    padding: 7rem 0;
    transform: skewY(4.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-top {
    transform: skewY(0.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-bottom {
    transform: skewY(-0.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-top {
    transform: skewY(1.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle.section-angled-layer-bottom {
    transform: skewY(-1.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-top {
    padding: 112px 0;
    padding: 7rem 0;
    transform: skewY(4.7deg) translate3d(0, -50%, 0)
}

section.section.section-angled.section-angled-reverse .section-angled-layer-increase-angle-2.section-angled-layer-bottom {
    padding: 112px 0;
    padding: 7rem 0;
    transform: skewY(-4.7deg) translate3d(0, 50%, 0)
}

section.section.section-angled+.section-angled {
    padding-top: 0
}

section.section.overlay .section-angled-layer-top,
section.section.overlay .section-angled-layer-bottom {
    z-index: 99 !important
}

section.section.section-overlay {
    position: relative
}

section.section.section-overlay:before {
    content: "";
    display: block;
    background: rgba(33, 37, 41, .8);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

section.section.section-overlay .section-angled-layer-top,
section.section.section-overlay .section-angled-layer-bottom {
    z-index: 99 !important
}

section.section.section-overlay-dot:before {
    /* background: url(../img/overlay-dot.png) repeat scroll 0 0 transparent */
}

section.section.section-overlay-opacity {
    position: relative
}

section.section.section-overlay-opacity:before {
    content: "";
    display: block;
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
    background: rgba(33, 37, 41, .1)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
    background: rgba(33, 37, 41, .2)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
    background: rgba(33, 37, 41, .3)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
    background: rgba(33, 37, 41, .4)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
    background: rgba(33, 37, 41, .5)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
    background: rgba(33, 37, 41, .6)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
    background: rgba(33, 37, 41, .7)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
    background: rgba(33, 37, 41, .8)
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
    background: rgba(33, 37, 41, .9)
}

section.section.section-overlay-opacity-light:before {
    background: rgba(255, 255, 255, .5)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
    background: rgba(255, 255, 255, .1)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
    background: rgba(255, 255, 255, .2)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
    background: rgba(255, 255, 255, .3)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
    background: rgba(255, 255, 255, .4)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
    background: rgba(255, 255, 255, .5)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
    background: rgba(255, 255, 255, .6)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
    background: rgba(255, 255, 255, .7)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
    background: rgba(255, 255, 255, .8)
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
    background: rgba(255, 255, 255, .9)
}

section.section.section-overlay-opacity-gradient:before {
    opacity: .85
}

section.section.section-video {
    background: 0 0 !important;
    border: 0
}

section.section.section-parallax {
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: repeat;
    background-attachment: fixed;
    border: 0
}

section.section.section-parallax.parallax-disabled {
    background-attachment: scroll !important;
    background-position: 50% 50% !important
}

section.section.section-no-border {
    border: 0
}

section.section.section-with-divider {
    margin: 56px 0 35px
}

section.section.section-with-divider .divider {
    margin: -56px 0 44px
}

section.section.section-footer {
    margin-bottom: -50px
}

section.section.section-with-mockup {
    margin-top: 120px
}

section.section.section-front {
    position: relative;
    z-index: 1
}

@media(max-width:991px) {
    .container-fluid>.row>.p-0 section.section {
        margin-bottom: 0;
        margin-top: 0
    }

    .container-fluid>.row>.p-0:first-child section.section {
        margin-bottom: 0;
        margin-top: 30px
    }

    .container-fluid>.row>.p-0:last-child section.section {
        margin-bottom: 30px;
        margin-top: 0
    }
}

@keyframes shapeDividerToLeft {
    from {
        transform: translate3d(0, 0, 0)
    }

    to {
        transform: translate3d(-200%, 0, 0)
    }
}

@keyframes shapeDividerToRight {
    from {
        transform: translate3d(0, 0, 0)
    }

    to {
        transform: translate3d(200%, 0, 0)
    }
}

.section.section-with-shape-divider {
    position: relative;
    overflow: hidden
}

.section.section-with-shape-divider .shape-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1
}

.section.section-with-shape-divider .shape-divider svg {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%, 0, 0)
}

@media(min-width:1950px) {
    .section.section-with-shape-divider .shape-divider svg {
        width: 100%
    }
}

.section.section-with-shape-divider .shape-divider.shape-divider-bottom {
    top: auto;
    bottom: 0
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y {
    transform: rotateY(180deg)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x {
    transform: rotateX(180deg)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy {
    transform: rotateY(180deg) rotateX(180deg)
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    animation-duration: 45s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation>svg {
    width: 100vw;
    left: 0;
    transform: none
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
    animation-name: shapeDividerToRight
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(2) {
    transform: rotateY(180deg) translate3d(99.9%, 0, 0)
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(3) {
    transform: translate3d(-199.8%, 0, 0)
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
    animation-name: shapeDividerToLeft
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(2) {
    transform: rotateY(180deg) translate3d(-99.9%, 0, 0)
}

.section.section-with-shape-divider .shape-divider .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(3) {
    transform: translate3d(199.8%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start,
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
    animation-name: shapeDividerToLeft
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(2),
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(2) {
    transform: rotateY(180deg) translate3d(-99.9%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(3),
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right>svg:nth-child(3) {
    transform: translate3d(199.8%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start,
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
    animation-name: shapeDividerToRight
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(2),
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(2) {
    transform: rotateY(180deg) translate3d(99.9%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(3),
.section.section-with-shape-divider .shape-divider.shape-divider-reverse-xy .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(3) {
    transform: translate3d(-199.8%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-right.start {
    animation-name: shapeDividerToRight
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left.start {
    animation-name: shapeDividerToLeft
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(2) {
    transform: rotateY(180deg) translate3d(-99.9%, 0, 0)
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x .shape-divider-horizontal-animation.shape-divider-horizontal-animation-to-left>svg:nth-child(3) {
    transform: translate3d(199.8%, 0, 0)
}

.section.section-with-shape-divider.overlay:before {
    z-index: 1
}

aside ul.nav-list>li>a {
    color: #666;
    font-size: .9em;
    padding: 8px 0 8px 22px
}

aside .twitter [class*=fa-],
aside .twitter .icons {
    clear: both;
    font-size: 1.5em;
    position: relative;
    top: 3px;
    margin-right: -22px;
    left: -30px;
    color: #212529
}

aside .twitter .meta {
    display: block;
    font-size: .9em;
    padding-top: 3px
}

aside .twitter ul {
    list-style: none;
    margin: 0;
    padding: 0
}

aside .twitter ul li {
    padding-bottom: 20px;
    padding-left: 30px
}

aside .twitter ul li:last-child {
    padding-bottom: 0
}

aside .twitter-account {
    display: block;
    font-size: .9em;
    margin: -15px 0 5px;
    opacity: .55
}

aside .twitter-account:hover {
    opacity: 1
}

html.side-panel .body:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(33, 37, 41, .5);
    opacity: 0;
    visibility: hidden;
    z-index: 9998;
    transition: ease opacity 300ms
}

html.side-panel .side-panel-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background: #fff;
    padding: 32px;
    padding: 2rem;
    box-shadow: 0 0 30px -13px rgba(0, 0, 0, .2);
    overflow-y: auto;
    transform: translate3d(-100%, 0, 0);
    transition: ease transform 300ms;
    z-index: 9999
}

html.side-panel .side-panel-wrapper .side-panel-close {
    position: absolute;
    top: 0;
    right: 0
}

html.side-panel.side-panel-push .body {
    transform: translate3d(0, 0, 0);
    transition: ease transform 300ms
}

html.side-panel.side-panel-right .side-panel-wrapper {
    left: auto;
    right: 0;
    transform: translate3d(100%, 0, 0)
}

html.side-panel.side-panel-without-overlay .body:before {
    content: none
}

html.side-panel.side-panel-open .body:before {
    opacity: 1;
    visibility: visible
}

html.side-panel.side-panel-open .side-panel-wrapper {
    transform: translate3d(0, 0, 0)
}

html.side-panel.side-panel-open.side-panel-push .body {
    transform: translate3d(300px, 0, 0)
}

html.side-panel.side-panel-open.side-panel-push.side-panel-right .body {
    transform: translate3d(-300px, 0, 0)
}

.slider-container {
    background: #151719;
    height: 500px;
    overflow: hidden;
    width: 100%;
    direction: ltr
}

.slider-container .tparrows {
    border-radius: 6px
}

.slider-container .slider-single-slide .tparrows {
    display: none
}

.slider-container .top-label {
    color: #fff;
    font-size: 24px;
    font-weight: 300
}

.slider-container .main-label {
    color: #fff;
    font-size: 62px;
    line-height: 62px;
    font-weight: 800;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .15)
}

.slider-container .featured-label {
    color: #fff;
    font-size: 52px;
    line-height: 52px;
    font-weight: 800;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .15)
}

.slider-container .bottom-label {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    font-weight: 300
}

.slider-container .blackboard-text {
    color: #bab9be;
    font-size: 46px;
    line-height: 46px;
    font-family: shadows into light, cursive;
    text-transform: uppercase
}

.slider-container .btn.btn-lg.btn-slider-action {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    padding: 19.2px 28.8px;
    padding: 1.2rem 1.8rem
}

.slider-container .top-label-dark,
.slider-container .main-label-dark,
.slider-container .bottom-label-dark {
    color: #333
}

.slider-container .tp-caption-dark a {
    color: #333
}

.slider-container .tp-caption-dark a:hover {
    color: #333
}

.slider-container .tp-caption-overlay {
    background: #000;
    padding: 10px
}

.slider-container .tp-caption-overlay-opacity {
    background: rgba(0, 0, 0, .4);
    padding: 10px
}

.slider-container .tp-opacity-overlay {
    background: #000;
    opacity: .75
}

.slider-container .tp-opacity-overlay-light {
    background: #fff;
    opacity: .75
}

.slider-container.transparent {
    background-color: transparent
}

.slider-container.light {
    background-color: #eaeaea
}

.slider-container.light .tp-bannershadow {
    opacity: .05;
    top: 0;
    bottom: auto;
    z-index: 1000
}

.slider-container.light .featured-label,
.slider-container.light .top-label,
.slider-container.light .main-label,
.slider-container.light .bottom-label {
    color: #333
}

.slider-container.light .tp-caption a {
    color: #333
}

.slider-container.light .tp-caption a:hover {
    color: #333
}

.tp-caption[data-typewriter]:after {
    transform: translateX(-9px)
}

.rev_slider li.slide-overlay .slotholder:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: .9;
    background: #212529;
    z-index: 2
}

.rev_slider li.slide-overlay.slide-overlay-light .slotholder:after {
    background: #fff
}

.rev_slider li.slide-overlay.slide-overlay-gradient .slotholder:after {
    background-color: #6c6ef1;
    background-image: linear-gradient(to right, #6c6ef1 0%, #e2498e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6c6ef1', endColorstr='#e2498e', GradientType=1)
}

.rev_slider li.slide-overlay.slide-overlay-gradient.slide-overlay-gradient-animated .slotholder:after {
    background-size: 200% 200%;
    animation-name: gradientTransition;
    animation-duration: 20000ms;
    animation-iteration-count: infinite
}

.rev_slider li.slide-overlay.slide-overlay-level-0 .slotholder:after {
    opacity: 0
}

.rev_slider li.slide-overlay.slide-overlay-level-1 .slotholder:after {
    opacity: .1
}

.rev_slider li.slide-overlay.slide-overlay-level-2 .slotholder:after {
    opacity: .2
}

.rev_slider li.slide-overlay.slide-overlay-level-3 .slotholder:after {
    opacity: .3
}

.rev_slider li.slide-overlay.slide-overlay-level-4 .slotholder:after {
    opacity: .4
}

.rev_slider li.slide-overlay.slide-overlay-level-5 .slotholder:after {
    opacity: .5
}

.rev_slider li.slide-overlay.slide-overlay-level-6 .slotholder:after {
    opacity: .6
}

.rev_slider li.slide-overlay.slide-overlay-level-7 .slotholder:after {
    opacity: .7
}

.rev_slider li.slide-overlay.slide-overlay-level-8 .slotholder:after {
    opacity: .8
}

.rev_slider li.slide-overlay.slide-overlay-level-9 .slotholder:after {
    opacity: .9
}

.rev_slider li.slide-overlay.slide-overlay-level-10 .slotholder:after {
    opacity: .1
}

.slider-scroll-button {
    position: relative;
    width: 57px;
    height: 57px;
    border-radius: 100%;
    text-indent: -9999px
}

.slider-scroll-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 49%;
    width: 23px;
    height: 34px;
    border: 1px solid #fff;
    border-radius: 15px;
    transform: translate3d(-50%, -50%, 0)
}

.slider-scroll-button:after {
    content: '';
    width: 6px;
    height: 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    position: absolute;
    top: 19px;
    left: 49%;
    transform: translate3d(-50%, 0, 0);
    animation-name: sliderScrollButton;
    animation-duration: 2s;
    animation-iteration-count: infinite
}

.slider-scroll-button.slider-scroll-button-dark:before {
    border-color: #212529
}

.slider-scroll-button.slider-scroll-button-dark:after {
    border-color: #212529
}

.tparrows.tparrows-carousel {
    display: inline-block;
    position: absolute;
    width: 30px;
    height: auto;
    padding: 20px 0
}

.tparrows.tparrows-carousel.tp-leftarrow {
    right: auto !important;
    left: -30px !important;
    border-radius: 0 4px 4px 0
}

.tparrows.tparrows-carousel.tp-leftarrow:before {
    font-family: 'font awesome 6 free';
    font-weight: 900;
    content: "\f053";
    position: relative;
    left: -1px;
    top: 1px
}

.tparrows.tparrows-carousel.tp-rightarrow {
    right: -60px !important;
    left: auto !important;
    border-radius: 4px 0 0 4px
}

.tparrows.tparrows-carousel.tp-rightarrow:before {
    font-family: 'font awesome 6 free';
    font-weight: 900;
    content: "\f054";
    position: relative;
    left: 1px;
    top: 1px
}

.tparrows.arrows-style-1 {
    background: 0 0 !important
}

.tparrows.arrows-style-2 {
    background: 0 0 !important
}

.tparrows.arrows-style-2.tp-leftarrow:before,
.tparrows.arrows-style-2.tp-rightarrow:before {
    content: '';
    display: block;
    position: absolute;
    left: 1.5px;
    top: 50%;
    width: .8em;
    height: .8em;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    transition: ease opacity 400ms;
    font-size: inherit;
    opacity: .7
}

.tparrows.arrows-style-2.tp-leftarrow:after,
.tparrows.arrows-style-2.tp-rightarrow:after {
    content: '';
    display: block;
    border-top: 1px solid #fff;
    transition: ease opacity 400ms;
    width: 1.5em;
    position: absolute;
    top: 50%;
    font-size: inherit;
    transform: translate3d(0, -50%, 0);
    opacity: .7
}

.tparrows.arrows-style-2.tp-leftarrow:hover:before,
.tparrows.arrows-style-2.tp-rightarrow:hover:before {
    opacity: 1
}

.tparrows.arrows-style-2.tp-leftarrow:hover:after,
.tparrows.arrows-style-2.tp-rightarrow:hover:after {
    opacity: 1
}

.tparrows.arrows-style-2.tp-leftarrow:before {
    left: .5px;
    transform: translate3d(0, -50%, 0) rotate(-45deg)
}

.tparrows.arrows-style-2.tp-rightarrow:before {
    left: 9.5px;
    transform: translate3d(0, -50%, 0) rotate(135deg)
}

@media(max-width:768px) {
    .tparrows.arrows-style-2 {
        display: none
    }
}

.tparrows.arrows-dark:before {
    color: #111 !important
}

.tparrows.arrows-big:before {
    font-size: 25px
}

.tparrows.arrows-rounded {
    border-radius: 100%
}

.tp-bullets.bullets-style-1 .tp-bullet {
    width: 8px;
    height: 8px;
    border-radius: 100%
}

.tp-bullets.bullets-style-2 .tp-bullet {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #78888d
}

.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
    border: 0 !important
}

.hesperiden-custom {
    padding: 10px 0 !important
}

.hesperiden-custom .tp-tab {
    border: 0 !important
}

.slider-with-overlay {
    position: relative
}

.slider-with-overlay .slider-container {
    z-index: auto
}

.slider-contact-form {
    margin: 45px 0
}

.slider-contact-form .featured-box {
    text-align: left
}

.slider-contact-form label.error {
    display: none !important
}

.slider-contact-form .alert {
    padding: 6px;
    text-align: center
}

.slider-contact-form textarea {
    resize: none
}

@media(min-width:992px) {
    .slider-contact-form {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        width: 100%
    }
}

html.boxed .slider-container {
    width: 100% !important;
    left: auto !important
}

@media(max-width:991px) {

    html.side-header .slider-container.fullscreen-slider,
    html.side-header .slider-container.fullscreen-slider+.tp-fullwidth-forcer {
        height: calc(100vh - 84px) !important
    }
}

.social-icons {
    margin: 0;
    padding: 0;
    width: auto
}

.social-icons li {
    display: inline-block;
    margin: -1px 1px 0 0;
    padding: 0;
    border-radius: 100%;
    overflow: visible
}

.social-icons li a {
    transition: all .2s ease;
    border-radius: 100%;
    display: block;
    height: 28px;
    line-height: 28px;
    width: 28px;
    text-align: center;
    color: #333 !important;
    text-decoration: none;
    font-size: 12.8px;
    font-size: .8rem
}

.social-icons li:hover a {
    background: #151719;
    color: #fff !important
}

.social-icons li:hover.social-icons-twitter a {
    background: #1aa9e1 !important
}

.social-icons li:hover.social-icons-facebook a {
    background: #3b5a9a !important
}

.social-icons li:hover.social-icons-linkedin a {
    background: #0073b2 !important
}

.social-icons li:hover.social-icons-rss a {
    background: #ff8201 !important
}

.social-icons li:hover.social-icons-googleplus a {
    background: #dd4b39 !important
}

.social-icons li:hover.social-icons-pinterest a {
    background: #cc2127 !important
}

.social-icons li:hover.social-icons-youtube a {
    background: #c3191e !important
}

.social-icons li:hover.social-icons-instagram a {
    background: #7c4a3a !important
}

.social-icons li:hover.social-icons-skype a {
    background: #00b0f6 !important
}

.social-icons li:hover.social-icons-email a {
    background: #dd4b39 !important
}

.social-icons li:hover.social-icons-vk a {
    background: #6383a8 !important
}

.social-icons li:hover.social-icons-xing a {
    background: #1a7576 !important
}

.social-icons li:hover.social-icons-tumblr a {
    background: #304e6c !important
}

.social-icons li:hover.social-icons-reddit a {
    background: #ff4107 !important
}

.social-icons li:hover.social-icons-whatsapp a {
    background: #25d366 !important
}

.social-icons.social-icons-dark li a {
    background: #181818
}

.social-icons.social-icons-dark li a i {
    color: #fff
}

.social-icons.social-icons-dark-2 li a {
    background: #272a2e
}

.social-icons.social-icons-dark-2 li a i {
    color: #fff
}

.social-icons.social-icons-transparent li {
    box-shadow: none
}

.social-icons.social-icons-transparent li a {
    background: 0 0
}

.social-icons.social-icons-icon-gray li a {
    color: #fff !important
}

.social-icons.social-icons-icon-gray li:hover a {
    color: #fff !important
}

.social-icons.social-icons-icon-light li a i {
    color: #fff
}

.social-icons.social-icons-icon-light.social-icons-clean li a i {
    color: #fff !important
}

.social-icons.social-icons-medium li a,
.social-icons.social-icons-md li a {
    height: 33px;
    line-height: 33px;
    width: 33px;
    font-size: 14.4px;
    font-size: .9rem
}

.social-icons.social-icons-big li a,
.social-icons.social-icons-lg li a {
    height: 48px;
    line-height: 50px;
    width: 48px
}

@media(max-width:400px) {

    .social-icons.social-icons-big li a,
    .social-icons.social-icons-lg li a {
        height: 38px;
        line-height: 40px;
        width: 38px
    }
}

.social-icons.social-icons-opacity-light li a {
    background: rgba(255, 255, 255, .04)
}

.social-icons.social-icons-clean-with-border li a {
    background: 0 0;
    color: #fff !important;
    border: 1px solid rgba(255, 255, 255, .1)
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-border-grey li a {
    border: 1px solid #e7e7e7
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-icon-dark li a {
    color: #212121 !important
}

.social-icons.social-icons-clean-with-border.social-icons-clean-with-border-icon-dark li:hover a {
    color: #fff !important
}

.social-icons:not(.social-icons-opacity-light):not(.social-icons-transparent):not(.social-icons-clean):not(.social-icons-clean-with-border):not(.social-icons-dark):not(.social-icons-dark-2):not(.custom-social-icons) li {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2)
}

.social-icons:not(.social-icons-opacity-light):not(.social-icons-transparent):not(.social-icons-clean):not(.social-icons-clean-with-border):not(.social-icons-dark):not(.social-icons-dark-2):not(.custom-social-icons) li a {
    background: #fff
}

.social-icons-dark.social-icons-clean li a,
.social-icons-dark-2.social-icons-clean li a {
    background: 0 0;
    color: #fff !important
}

.sort-source-wrapper {
    padding: 8px 0;
    position: relative
}

.sort-source-wrapper .nav {
    margin: -100px 0 5px
}

.sort-source-wrapper .nav>li>a {
    color: #fff;
    background: 0 0
}

.sort-source-wrapper .nav>li>a:hover,
.sort-source-wrapper .nav>li>a:focus {
    background: 0 0
}

.sort-source-wrapper .nav>li.active>a {
    background: 0 0
}

.sort-source-wrapper .nav>li.active>a:hover,
.sort-source-wrapper .nav>li.active>a:focus {
    background: 0 0
}

.sort-source {
    clear: both
}

.sort-source.d-none {
    display: block !important;
    width: 0;
    height: 0;
    overflow: hidden
}

.sort-source>li>a {
    padding: 6px 12px;
    text-decoration: none
}

.sort-source.sort-source-style-2 {
    margin: -10px auto
}

.sort-source.sort-source-style-2>li {
    float: none;
    display: inline-block
}

.sort-source.sort-source-style-2>li>a,
.sort-source.sort-source-style-2>li>a:focus,
.sort-source.sort-source-style-2>li>a:hover {
    background: 0 0;
    color: #fff;
    display: inline-block;
    border-radius: 0;
    margin: 0;
    padding: 18px 28px;
    position: relative;
    text-decoration: none
}

.sort-source.sort-source-style-2>li>a:after {
    transition: margin .3s;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 15px solid #ccc;
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    margin-top: 0;
    opacity: 0
}

.sort-source.sort-source-style-2>li.active>a,
.sort-source.sort-source-style-2>li.active>a:focus,
.sort-source.sort-source-style-2>li.active>a:hover {
    background: 0 0
}

.sort-source.sort-source-style-2>li.active>a:after {
    opacity: 1;
    margin-top: 18px
}

@media(max-width:991px) {
    .sort-source.sort-source-style-2>li {
        display: block
    }

    .sort-source.sort-source-style-2>li.active>a:after {
        display: none !important
    }
}

.sort-source.sort-source-style-3>li>a {
    color: #777
}

.sort-source.sort-source-style-3>li.active>a {
    background: 0 0 !important;
    border-bottom: 3px solid #ccc;
    border-radius: 0
}

.sort-destination {
    overflow: hidden
}

.sort-destination .isotope-item {
    min-width: 1px
}

.sort-destination.full-width {
    position: relative
}

.sort-destination.full-width .isotope-item {
    width: 20%;
    float: left
}

.sort-destination.full-width .isotope-item.w2 {
    width: 40%
}

.sort-destination.full-width .isotope-item .img-thumbnail,
.sort-destination.full-width .isotope-item a,
.sort-destination.full-width .isotope-item .thumb-info img {
    border-radius: 0
}

.sort-destination.full-width-spaced {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px
}

.sort-destination.full-width-spaced .portfolio-item {
    margin-left: 20px;
    margin-right: 20px
}

.sort-destination.sort-destination-items-hardware-acc .isotope-item {
    transform: translate3d(0, 0, 0)
}

html.gecko .sort-destination.sort-destination-margin>[class*=col-] {
    margin: -1px
}

.sort-source-wrapper+.sort-destination.full-width {
    margin: 0 0 -81px;
    top: -51px
}

.sort-source-wrapper+.sort-destination-loader .full-width {
    margin: 0 0 -81px;
    top: -51px
}

.sort-destination-loader {
    height: auto;
    overflow: hidden;
    position: relative
}

.sort-destination-loader:after {
    transition: opacity .3s;
    content: ' ';
    background: #fff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0
}

.sort-destination-loader .bounce-loader {
    display: none
}

.sort-destination-loader.sort-destination-loader-showing {
    max-height: 200px
}

.sort-destination-loader.sort-destination-loader-showing:after {
    opacity: 1
}

.sort-destination-loader.sort-destination-loader-showing .bounce-loader {
    display: block
}

.sort-destination-loader.loader-showing-dark:after {
    background: #181818
}

.sort-destination-loader.sort-destination-loader-loaded {
    overflow: visible
}

.sort-destination-loader.sort-destination-loader-loaded:after {
    display: none
}

.sort-destination-loader-full-width {
    overflow: visible
}

.sort-destination-loader-full-width:after {
    bottom: -81px;
    top: -51px
}

.sort-destination-loader-full-width.sort-destination-loader-showing {
    overflow: hidden;
    height: 600px
}

.sort-destination-loader-lg.sort-destination-loader-showing {
    height: 600px
}

.portfolio-load-more-loader.portfolio-load-more-loader-showing .bounce-loader {
    display: block !important
}

@media(min-width:992px) {
    .col-md-6.isotope-item:nth-child(2n+1) {
        clear: both
    }

    .col-md-4.isotope-item:nth-child(3n+1) {
        clear: both
    }

    .col-md-3.isotope-item:nth-child(4n+1) {
        clear: both
    }

    .col-md-1-5.isotope-item:nth-child(5n+1) {
        clear: both
    }

    .col-md-2.isotope-item:nth-child(6n+1) {
        clear: both
    }

    .sort-destination.full-width .isotope-item:nth-child(5n+1) {
        clear: both
    }
}

@media(max-width:991px) {
    .sort-destination.isotope {
        overflow: visible !important;
        height: auto !important
    }

    .sort-destination.isotope.full-width {
        overflow: hidden !important
    }

    .isotope-hidden {
        display: none !important
    }
}

@media(max-width:767px) {
    .isotope-item {
        position: static !important;
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        transform: none !important
    }

    .isotope-item.product {
        position: relative !important
    }

    .sort-source-wrapper {
        background: #151719;
        clear: both;
        margin-top: -35px
    }

    .sort-source-wrapper .nav {
        margin: 0
    }

    .sort-destination {
        height: auto !important
    }

    .sort-destination.full-width,
    .sort-source-wrapper+.sort-destination.full-width {
        max-width: none;
        margin: 0 0 -30px;
        top: 0
    }

    .sort-destination.full-width .isotope-item,
    .sort-source-wrapper+.sort-destination.full-width .isotope-item {
        max-width: 100%
    }

    .sort-source-wrapper+.sort-destination-loader .full-width {
        margin: 0 0 -30px;
        top: 0
    }
}

@media(max-width:575px) {

    .sort-destination.full-width .isotope-item,
    .sort-source-wrapper+.sort-destination.full-width .isotope-item {
        position: static !important;
        clear: both;
        float: none;
        width: auto
    }
}

.clip-svg {
    width: 0;
    height: 0
}

.svg-fill-color-light {
    fill: #fff !important
}

.svg-fill-color-light svg path,
.svg-fill-color-light svg rect {
    fill: #fff !important
}

.svg-fill-color-hover-light:hover {
    fill: #fff !important
}

.svg-fill-color-hover-light svg:hover path,
.svg-fill-color-hover-light svg:hover rect {
    fill: #fff !important
}

.svg-stroke-color-light {
    stroke: #fff !important
}

.svg-stroke-color-light svg path,
.svg-stroke-color-light svg rect {
    stroke: #fff !important
}

.svg-stroke-color-hover-light:hover {
    stroke: #fff !important
}

.svg-stroke-color-hover-light svg:hover path,
.svg-stroke-color-hover-light svg:hover rect {
    stroke: #fff !important
}

.svg-fill-color-dark {
    fill: #212529 !important
}

.svg-fill-color-dark svg path,
.svg-fill-color-dark svg rect {
    fill: #212529 !important
}

.svg-fill-color-hover-dark:hover {
    fill: #212529 !important
}

.svg-fill-color-hover-dark svg:hover path,
.svg-fill-color-hover-dark svg:hover rect {
    fill: #212529 !important
}

.svg-stroke-color-dark {
    stroke: #212529 !important
}

.svg-stroke-color-dark svg path,
.svg-stroke-color-dark svg rect {
    stroke: #212529 !important
}

.svg-stroke-color-hover-dark:hover {
    stroke: #212529 !important
}

.svg-stroke-color-hover-dark svg:hover path,
.svg-stroke-color-hover-dark svg:hover rect {
    stroke: #212529 !important
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-dark:hover svg rect {
    stroke: #212529
}

.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg path,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polygon,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg polyline,
.svg-animation-effect-1-hover.svg-animation-effect-1-hover-light:hover svg rect {
    stroke: #fff
}

.table td,
.table th {
    border-color: rgba(0, 0, 0, .06)
}

.table>:not(:first-child) {
    border-top-color: rgba(0, 0, 0, .06)
}

.table>thead>tr>td.primary,
.table>tbody>tr>td.primary,
.table>tfoot>tr>td.primary,
.table>thead>tr>th.primary,
.table>tbody>tr>th.primary,
.table>tfoot>tr>th.primary,
.table>thead>tr.primary>td,
.table>tbody>tr.primary>td,
.table>tfoot>tr.primary>td,
.table>thead>tr.primary>th,
.table>tbody>tr.primary>th,
.table>tfoot>tr.primary>th {
    background-color: #ccc;
    color: #fff
}

.table>thead>tr>td.success,
.table>tbody>tr>td.success,
.table>tfoot>tr>td.success,
.table>thead>tr>th.success,
.table>tbody>tr>th.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>tbody>tr.success>td,
.table>tfoot>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr.success>th,
.table>tfoot>tr.success>th {
    background-color: #28a745;
    color: #fff
}

.table>thead>tr>td.warning,
.table>tbody>tr>td.warning,
.table>tfoot>tr>td.warning,
.table>thead>tr>th.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>tbody>tr.warning>td,
.table>tfoot>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr.warning>th,
.table>tfoot>tr.warning>th {
    background-color: #ffc107;
    color: #fff
}

.table>thead>tr>td.danger,
.table>tbody>tr>td.danger,
.table>tfoot>tr>td.danger,
.table>thead>tr>th.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>tbody>tr.danger>td,
.table>tfoot>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr.danger>th,
.table>tfoot>tr.danger>th {
    background-color: #dc3545;
    color: #fff
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
    background-color: #17a2b8;
    color: #fff
}

.table>thead>tr>td.dark,
.table>tbody>tr>td.dark,
.table>tfoot>tr>td.dark,
.table>thead>tr>th.dark,
.table>tbody>tr>th.dark,
.table>tfoot>tr>th.dark,
.table>thead>tr.dark>td,
.table>tbody>tr.dark>td,
.table>tfoot>tr.dark>td,
.table>thead>tr.dark>th,
.table>tbody>tr.dark>th,
.table>tfoot>tr.dark>th {
    background-color: #212529;
    color: #fff
}

.tabs {
    border-radius: 4px;
    margin-bottom: 20px
}

.nav-tabs {
    margin: 0;
    padding: 0;
    border-bottom-color: #eee
}

.nav-tabs li:last-child .nav-link {
    margin-right: 0
}

.nav-tabs li .nav-link {
    border-radius: 5px 5px 0 0;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -.2px;
    padding: 11.2px 24px;
    padding: .7rem 1.5rem;
    transition: all .2s;
    margin-right: 1px
}

.nav-tabs li .nav-link,
.nav-tabs li .nav-link:hover {
    background: #f7f7f7;
    border-bottom: none;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 3px solid #eee;
    color: #ccc
}

.nav-tabs li .nav-link:hover {
    border-bottom-color: transparent;
    border-top: 3px solid #ccc;
    box-shadow: none
}

.nav-tabs li .nav-link:active,
.nav-tabs li .nav-link:hover,
.nav-tabs li .nav-link:focus {
    background: #fff;
    border-left-color: #eee;
    border-right-color: #eee;
    border-top: 3px solid #ccc;
    color: #ccc
}

.tab-content {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .04);
    background-color: #fff;
    border: 1px solid #eee;
    border-top: 0;
    padding: 15px
}

.nav-tabs.nav-right {
    text-align: right
}

.tabs.tabs-bottom {
    margin: 0 0 20px;
    padding: 0
}

.tabs.tabs-bottom .tab-content {
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
    border-top: 1px solid #eee
}

.tabs.tabs-bottom .nav-tabs {
    border-bottom: none;
    border-top: 1px solid #eee
}

.tabs.tabs-bottom .nav-tabs li {
    margin-bottom: 0;
    margin-top: -1px
}

.tabs.tabs-bottom .nav-tabs li:last-child .nav-link {
    margin-right: 0
}

.tabs.tabs-bottom .nav-tabs li .nav-link {
    border-radius: 0 0 5px 5px;
    font-size: 13px;
    margin-right: 1px;
    border-bottom: 3px solid #ccc;
    border-top-color: transparent !important
}

.tabs.tabs-bottom .nav-tabs li .nav-link.active,
.tabs.tabs-bottom .nav-tabs li .nav-link.active:hover,
.tabs.tabs-bottom .nav-tabs li .nav-link.active:focus {
    border-bottom: 3px solid #ccc;
    border-top-color: transparent !important
}

.tabs.tabs-bottom .nav-tabs li .nav-link,
.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
    border-bottom: 3px solid #eee;
    border-top: 1px solid #eee
}

.tabs.tabs-bottom .nav-tabs li .nav-link:hover {
    border-bottom: 3px solid #ccc;
    border-top: 1px solid #eee
}

.tabs-vertical {
    display: table;
    width: 100%;
    padding: 0;
    border-top: 1px solid #eee
}

.tabs-vertical .tab-content {
    display: table-cell;
    vertical-align: top
}

.tabs-vertical .nav-tabs {
    border-bottom: none;
    display: table-cell;
    height: 100%;
    float: none;
    padding: 0;
    vertical-align: top;
    width: 25%
}

.tabs-vertical .nav-tabs>li {
    display: block
}

.tabs-vertical .nav-tabs>li .nav-link {
    border-radius: 0;
    display: block;
    padding-top: 10px
}

.tabs-vertical .nav-tabs>li .nav-link,
.tabs-vertical .nav-tabs>li .nav-link:hover,
.tabs-vertical .nav-tabs>li .nav-link:focus {
    border-bottom: none;
    border-top: none
}

.tabs-vertical .nav-tabs>li .nav-link.active,
.tabs-vertical .nav-tabs>li .nav-link.active:hover,
.tabs-vertical .nav-tabs>li .nav-link.active:focus {
    border-top: none
}

.tabs-left {
    padding: 0
}

.tabs-left .tab-content {
    border-radius: 0 5px 5px 5px;
    border-left: none
}

.tabs-left .nav-tabs>li {
    margin-right: -1px
}

.tabs-left .nav-tabs>li:first-child .nav-link {
    border-radius: 5px 0 0 0
}

.tabs-left .nav-tabs>li:last-child .nav-link {
    border-radius: 0 0 0 5px;
    border-bottom: 1px solid #eee
}

.tabs-left .nav-tabs>li .nav-link {
    border-right: 1px solid #eee;
    border-left: 3px solid #eee;
    margin-right: 1px;
    margin-left: -3px
}

.tabs-left .nav-tabs>li .nav-link:hover {
    border-left-color: #ccc
}

.tabs-left .nav-tabs>li .nav-link.active,
.tabs-left .nav-tabs>li .nav-link.active:hover,
.tabs-left .nav-tabs>li .nav-link.active:focus {
    border-left: 3px solid #ccc;
    border-right-color: #fff
}

.tabs-right {
    padding: 0
}

.tabs-right .tab-content {
    border-radius: 5px 0 5px 5px;
    border-right: none
}

.tabs-right .nav-tabs>li {
    margin-left: -1px
}

.tabs-right .nav-tabs>li:first-child .nav-link {
    border-radius: 0 5px 0 0
}

.tabs-right .nav-tabs>li:last-child .nav-link {
    border-radius: 0 0 5px 0;
    border-bottom: 1px solid #eee
}

.tabs-right .nav-tabs>li .nav-link {
    border-right: 3px solid #eee;
    border-left: 1px solid #eee;
    margin-right: 1px;
    margin-left: 1px
}

.tabs-right .nav-tabs>li .nav-link:hover {
    border-right-color: #ccc
}

.tabs-right .nav-tabs>li .nav-link.active,
.tabs-right .nav-tabs>li .nav-link.active:hover,
.tabs-right .nav-tabs>li .nav-link.active:focus {
    border-right: 3px solid #ccc;
    border-left: 1px solid #fff
}

.nav-tabs.nav-justified {
    padding: 0;
    margin-bottom: -1px
}

.nav-tabs.nav-justified li {
    margin-bottom: 0
}

.nav-tabs.nav-justified li:first-child .nav-link,
.nav-tabs.nav-justified li:first-child .nav-link:hover {
    border-radius: 5px 0 0 0
}

.nav-tabs.nav-justified li:last-child .nav-link,
.nav-tabs.nav-justified li:last-child .nav-link:hover {
    border-radius: 0 5px 0 0
}

.nav-tabs.nav-justified li .nav-link {
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    margin-right: 0
}

.nav-tabs.nav-justified li .nav-link:hover,
.nav-tabs.nav-justified li .nav-link:focus {
    border-bottom: 1px solid #ddd
}

.nav-tabs.nav-justified li .nav-link.active {
    border-bottom: 1px solid #fff
}

.nav-tabs.nav-justified li .nav-link.active,
.nav-tabs.nav-justified li .nav-link.active:hover,
.nav-tabs.nav-justified li .nav-link.active:focus {
    background: #fff;
    border-left-color: #eee;
    border-right-color: #eee;
    border-top-width: 3px
}

.nav-tabs.nav-justified li .nav-link.active,
.nav-tabs.nav-justified li .nav-link.active:hover,
.nav-tabs.nav-justified li .nav-link.active:focus {
    border-top-width: 3px
}

.nav-tabs.nav-justified li .nav-link.active:hover {
    border-bottom: 1px solid #fff
}

.tabs.tabs-bottom {
    padding: 0
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
    border-top: none
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
    margin-right: 0;
    border-top-color: transparent
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link.active,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link.active:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link.active:focus {
    border-top-color: transparent
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link {
    border-radius: 0 0 0 5px
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link {
    margin-right: 0;
    border-radius: 0 0 5px 0
}

.tabs-center .nav-tabs {
    margin: 0 auto;
    text-align: center
}

.tabs-navigation {
    border-radius: 5px
}

.tabs-navigation .nav-tabs>li {
    margin-bottom: 1px
}

.tabs-navigation .nav-tabs>li .nav-link {
    color: #777;
    padding: 18px;
    line-height: 100%;
    position: relative
}

.tabs-navigation .nav-tabs>li .nav-link:before {
    font-family: 'font awesome 6 free';
    font-weight: 900;
    font-size: 10px;
    content: "\f054";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%)
}

.tabs-navigation .nav-tabs>li .nav-link [class*=fa-],
.tabs-navigation .nav-tabs>li .nav-link .icons {
    margin-right: 4px
}

.tabs-navigation .nav-tabs li:first-child .nav-link {
    border-radius: 5px 5px 0 0
}

.tabs-navigation .nav-tabs li .nav-link.active,
.tabs-navigation .nav-tabs li .nav-link.active:hover,
.tabs-navigation .nav-tabs li .nav-link.active:focus {
    border-right-color: #eee
}

.tabs-navigation .nav-tabs li:last-child .nav-link {
    border-radius: 0 0 5px 5px
}

.tabs-navigation-simple {
    border: 0 !important;
    border-radius: 0 !important
}

.tabs-navigation-simple .nav-tabs {
    border: 0 !important;
    border-radius: 0 !important
}

.tabs-navigation-simple .nav-tabs .nav-link,
.tabs-navigation-simple .nav-tabs .nav-link:hover {
    background: 0 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, .06) !important;
    border-left: 0 !important;
    padding-left: 0 !important
}

.tabs-navigation-simple .nav-tabs li:last-child .nav-link,
.tabs-navigation-simple .nav-tabs li:last-child .nav-link:hover {
    border-bottom: 0 !important
}

.tab-pane-navigation {
    display: none
}

.tab-pane-navigation.active {
    display: block
}

.tabs.tabs-simple .nav-tabs {
    justify-content: center;
    border: 0;
    margin-bottom: 10px
}

.tabs.tabs-simple .nav-tabs>li {
    margin-left: 0;
    margin-bottom: 20px
}

.tabs.tabs-simple .nav-tabs>li .nav-link,
.tabs.tabs-simple .nav-tabs>li .nav-link:hover,
.tabs.tabs-simple .nav-tabs>li .nav-link:focus {
    padding: 15px 30px;
    background: 0 0;
    border: 0;
    border-bottom: 3px solid #eee;
    border-radius: 0;
    color: #777;
    font-size: 16px
}

.tabs.tabs-simple .tab-content {
    background: 0 0;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none
}

.tabs.tabs-simple.tabs-simple-full-width-line .nav-tabs {
    border-bottom: 3px solid #eee
}

.tabs.tabs-simple.tabs-simple-full-width-line .nav-tabs>li {
    margin-bottom: -3px
}

@media(max-width:575px) {
    .tabs .nav.nav-tabs.nav-justified li {
        display: block;
        margin-bottom: -5px
    }

    .tabs .nav.nav-tabs.nav-justified li .nav-link {
        border-top-width: 3px !important;
        border-bottom-width: 0 !important
    }

    .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link,
    .tabs .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
        border-radius: 5px 5px 0 0
    }

    .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link,
    .tabs .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
        border-radius: 0
    }

    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
        margin-bottom: 0;
        margin-top: -5px
    }

    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li .nav-link {
        border-bottom-width: 3px !important;
        border-top-width: 0 !important
    }

    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link,
    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child .nav-link:hover {
        border-radius: 0
    }

    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link,
    .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child .nav-link:hover {
        border-radius: 0 0 5px 5px
    }
}

.responsive-tabs-container .tab-pane {
    margin-bottom: 15px
}

.responsive-tabs-container .accordion-link {
    display: none;
    margin-bottom: 10px;
    padding: 10px 15px;
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #ebebeb
}

@media(max-width:767px) {
    .responsive-tabs-container.accordion-xs .nav-tabs {
        display: none
    }

    .responsive-tabs-container.accordion-xs .accordion-link {
        display: block
    }
}

@media(min-width:768px) and (max-width:991px) {
    .responsive-tabs-container.accordion-sm .nav-tabs {
        display: none
    }

    .responsive-tabs-container.accordion-sm .accordion-link {
        display: block
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .responsive-tabs-container.accordion-md .nav-tabs {
        display: none
    }

    .responsive-tabs-container.accordion-md .accordion-link {
        display: block
    }
}

@media(min-width:1200px) {
    .responsive-tabs-container.accordion-lg .nav-tabs {
        display: none
    }

    .responsive-tabs-container.accordion-lg .accordion-link {
        display: block
    }
}

.testimonial {
    margin-bottom: 20px
}

.testimonial blockquote {
    background: #ccc;
    border-radius: 5px;
    border: 0;
    color: #666;
    font-family: playfair display, serif;
    margin: 0;
    padding: 40px 64px 40px 72px;
    padding: 2.5rem 4rem 2.5rem 4.5rem;
    position: relative
}

.testimonial blockquote:before {
    left: 22px;
    top: 21px;
    color: #fff;
    content: "“";
    font-size: 85px;
    font-style: normal;
    line-height: 1;
    position: absolute
}

.testimonial blockquote p {
    color: #fff;
    font-family: playfair display, serif;
    font-size: 1.2em
}

.testimonial .testimonial-arrow-down {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #ccc;
    height: 0;
    margin: 0 0 0 45px;
    width: 0
}

.testimonial .testimonial-author {
    display: flex;
    margin: 12px 0 0
}

.testimonial .testimonial-author .testimonial-author-thumbnail {
    width: auto
}

.testimonial .testimonial-author img {
    max-width: 25px
}

.testimonial .testimonial-author strong {
    color: #111;
    display: block;
    margin-bottom: 2px;
    font-size: 16px;
    font-size: 1rem
}

.testimonial .testimonial-author span {
    color: #666;
    display: block;
    font-size: 12.8px;
    font-size: .8rem;
    font-weight: 600
}

.testimonial .testimonial-author p {
    color: #999;
    text-align: left;
    line-height: 1.2;
    margin-left: 9.6px;
    margin-left: .6rem
}

.testimonial.testimonial-style-2 {
    text-align: center
}

.testimonial.testimonial-style-2 blockquote {
    background: 0 0 !important;
    border-radius: 4px;
    padding: 22px 20px
}

.testimonial.testimonial-style-2 blockquote:before,
.testimonial.testimonial-style-2 blockquote:after {
    display: none
}

.testimonial.testimonial-style-2 blockquote p {
    color: #777
}

.testimonial.testimonial-style-2 .testimonial-arrow-down {
    display: none
}

.testimonial.testimonial-style-2 .testimonial-author {
    padding: 0;
    margin: 6px 0 0;
    flex-direction: column
}

.testimonial.testimonial-style-2 .testimonial-author img {
    margin: 0 auto 15px;
    max-width: 60px;
    max-height: 60px
}

.testimonial.testimonial-style-2 .testimonial-author p {
    text-align: center;
    padding: 0;
    margin: 0
}

.testimonial.testimonial-style-3 blockquote {
    background: #f2f2f2 !important;
    border-radius: 4px;
    padding: 12px 30px
}

.testimonial.testimonial-style-3 blockquote:before {
    top: 5px;
    left: 9px;
    color: #777;
    font-size: 35px
}

.testimonial.testimonial-style-3 blockquote:after {
    color: #777;
    font-size: 35px;
    bottom: -5px
}

.testimonial.testimonial-style-3 blockquote p {
    color: #777;
    font-family: poppins, Arial, sans-serif;
    font-size: 1em;
    font-style: normal
}

.testimonial.testimonial-style-3 .testimonial-author {
    margin-left: 8px;
    align-items: center
}

.testimonial.testimonial-style-3 .testimonial-author img {
    max-width: 55px
}

.testimonial.testimonial-style-3 .testimonial-author p {
    margin-bottom: 0
}

.testimonial.testimonial-style-3 .testimonial-arrow-down {
    margin-left: 20px;
    border-top: 10px solid #f2f2f2 !important
}

.testimonial.testimonial-style-3.testimonial-style-3-light blockquote {
    background: #fff !important
}

.testimonial.testimonial-style-3.testimonial-style-3-light .testimonial-arrow-down {
    border-top: 10px solid #fff !important
}

.testimonial.testimonial-style-4 {
    border-radius: 6px;
    padding: 8px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04)
}

.testimonial.testimonial-style-4 blockquote {
    background: 0 0 !important;
    border-radius: 4px;
    padding: 12px 30px
}

.testimonial.testimonial-style-4 blockquote:before {
    top: 5px;
    left: 9px;
    color: #777;
    font-size: 35px
}

.testimonial.testimonial-style-4 blockquote:after {
    color: #777;
    font-size: 35px;
    bottom: -5px
}

.testimonial.testimonial-style-4 blockquote p {
    color: #777;
    font-family: poppins, Arial, sans-serif;
    font-size: 1em;
    font-style: normal
}

.testimonial.testimonial-style-4 .testimonial-arrow-down {
    display: none
}

.testimonial.testimonial-style-4 .testimonial-author {
    margin-left: 30px;
    align-items: center
}

.testimonial.testimonial-style-4 .testimonial-author img {
    max-width: 55px
}

.testimonial.testimonial-style-4 .testimonial-author p {
    margin-bottom: 0
}

.testimonial.testimonial-style-4.testimonial-no-borders {
    border: 0;
    box-shadow: none
}

.testimonial.testimonial-style-5 {
    text-align: center
}

.testimonial.testimonial-style-5 blockquote {
    background: 0 0 !important;
    border-radius: 4px;
    padding: 6px 20px
}

.testimonial.testimonial-style-5 blockquote:before,
.testimonial.testimonial-style-5 blockquote:after {
    display: none
}

.testimonial.testimonial-style-5 blockquote p {
    color: #777;
    font-family: poppins, Arial, sans-serif;
    font-size: 1.2em;
    font-style: normal
}

.testimonial.testimonial-style-5 .testimonial-arrow-down {
    display: none
}

.testimonial.testimonial-style-5 .testimonial-author {
    display: block;
    padding: 6px 0 0;
    margin: 12px 0 0;
    border-top: 1px solid #f2f2f2;
    justify-content: center
}

.testimonial.testimonial-style-5 .testimonial-author img {
    margin: 0 auto 10px;
    max-width: 55px
}

.testimonial.testimonial-style-5 .testimonial-author p {
    text-align: center;
    padding: 0;
    margin: 0
}

.testimonial.testimonial-style-6 {
    text-align: center
}

.testimonial.testimonial-style-6 blockquote {
    background: 0 0 !important;
    border-radius: 4px;
    border: 0;
    padding: 0 10%
}

.testimonial.testimonial-style-6 blockquote:before,
.testimonial.testimonial-style-6 blockquote:after {
    display: none
}

.testimonial.testimonial-style-6 blockquote i.fa-quote-left {
    font-size: 34px;
    position: relative;
    left: -5px
}

.testimonial.testimonial-style-6 blockquote p {
    color: #777;
    font-size: 24px;
    line-height: 30px
}

.testimonial.testimonial-style-6 blockquote span {
    font-size: 16px;
    line-height: 20px;
    color: #999;
    position: relative;
    left: -5px
}

.testimonial.testimonial-style-6 .testimonial-arrow-down {
    display: none
}

.testimonial.testimonial-style-6 .testimonial-author {
    padding: 0;
    margin: 6px 0 0;
    justify-content: center
}

.testimonial.testimonial-style-6 .testimonial-author img {
    margin: 0 auto 10px
}

.testimonial.testimonial-style-6 .testimonial-author p {
    text-align: center;
    padding: 0;
    margin: 0
}

.testimonial.testimonial-style-7 {
    text-align: center;
    background-color: #ccc;
    padding: 20px
}

.testimonial.testimonial-style-7 blockquote {
    padding: 30px
}

.testimonial.testimonial-style-7 blockquote:before {
    color: #fff;
    display: block !important;
    left: 50%;
    top: -10px;
    font-size: 80px;
    font-style: normal;
    line-height: 1;
    position: absolute;
    transform: translate3d(-50%, 0, 0)
}

.testimonial.testimonial-style-7 blockquote:after {
    display: none
}

.testimonial.testimonial-style-7 .testimonial-arrow-down {
    display: none
}

.testimonial.testimonial-style-7 .testimonial-author {
    padding: 0;
    margin: 6px 0 0;
    justify-content: center
}

.testimonial.testimonial-style-7 .testimonial-author img {
    margin: 0 auto 10px
}

.testimonial.testimonial-style-7 .testimonial-author p {
    text-align: center;
    padding: 0;
    margin: 0
}

.testimonial.testimonial-with-quotes blockquote:before {
    color: #777;
    display: block !important;
    left: -10px;
    top: -16px;
    content: "“";
    font-size: 80px;
    font-style: normal;
    line-height: 1;
    position: absolute
}

.testimonial.testimonial-with-quotes blockquote:after {
    color: #777;
    display: block !important;
    right: 10px;
    font-size: 80px;
    font-style: normal;
    line-height: 1;
    position: absolute;
    bottom: -.5em;
    content: "”"
}

.testimonial.testimonial-with-quotes blockquote p {
    padding: 0 40px
}

.testimonial.testimonial-with-quotes.testimonial-remove-left-quote blockquote:before {
    content: none
}

.testimonial.testimonial-with-quotes.testimonial-remove-right-quote blockquote:after {
    content: none
}

.testimonial.testimonial-with-quotes.testimonial-quotes-light blockquote:before,
.testimonial.testimonial-with-quotes.testimonial-quotes-light blockquote:after {
    color: #fff !important
}

.testimonial.testimonial-with-quotes.testimonial-quotes-dark blockquote:before,
.testimonial.testimonial-with-quotes.testimonial-quotes-dark blockquote:after {
    color: #212529 !important
}

@media(max-width:767px) {

    .testimonial.testimonial-with-quotes.testimonial-with-quotes-mobile-remove blockquote:before,
    .testimonial.testimonial-with-quotes.testimonial-with-quotes-mobile-remove blockquote:after {
        content: none
    }
}

.testimonial.testimonial-trasnparent-background blockquote {
    background: 0 0 !important
}

.testimonial.testimonial-alternarive-font blockquote p {
    font-family: playfair display, serif;
    font-style: italic;
    font-size: 1.2em
}

.testimonial.testimonial-light blockquote:before,
.testimonial.testimonial-light blockquote:after,
.testimonial.testimonial-light blockquote p {
    color: #fff
}

.testimonial.testimonial-light blockquote.blockquote-default:before,
.testimonial.testimonial-light blockquote.blockquote-default:after,
.testimonial.testimonial-light blockquote.blockquote-default p {
    color: #777 !important
}

.testimonial.testimonial-light .testimonial-author span,
.testimonial.testimonial-light .testimonial-author strong {
    color: #fff
}

.section-text-light .testimonial.testimonial blockquote:before,
.section-text-light .testimonial.testimonial blockquote:after,
.section-text-light .testimonial.testimonial blockquote p {
    opacity: .9;
    color: #fff
}

.section-text-light .testimonial.testimonial .testimonial-author span,
.section-text-light .testimonial.testimonial .testimonial-author strong {
    opacity: .6;
    color: #fff
}

.thumb-info {
    display: block;
    position: relative;
    text-decoration: none;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0)
}

.thumb-info .thumb-info-wrapper {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
    margin: 0;
    overflow: hidden;
    display: block;
    position: relative
}

.thumb-info .thumb-info-wrapper.thumb-info-wrapper-demos {
    max-height: 330px
}

.thumb-info .thumb-info-wrapper:after,
.thumb-info .thumb-info-wrapper .thumb-info-wrapper-overlay:before {
    content: "";
    background: rgba(33, 37, 41, .8);
    transition: all .3s;
    border-radius: 4px;
    bottom: -4px;
    color: #fff;
    left: -4px;
    position: absolute;
    right: -4px;
    top: -4px;
    display: block;
    opacity: 0;
    z-index: 1
}

.thumb-info .thumb-info-wrapper .thumb-info-wrapper-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: auto;
    top: auto
}

.thumb-info .thumb-info-wrapper .thumb-info-wrapper-overlay:before {
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0
}

.thumb-info .thumb-info-wrapper.thumb-info-wrapper-link {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.thumb-info .thumb-info-wrapper.thumb-info-wrapper-link>a {
    display: block;
    padding-top: 72%;
    text-indent: -9999px
}

.thumb-info.thumb-info-wrapper-direction-aware .thumb-info-wrapper:after {
    display: none !important
}

.thumb-info .thumb-info-wrapper-opacity-0:after {
    opacity: 0
}

.thumb-info .thumb-info-wrapper-opacity-1:after {
    opacity: .1
}

.thumb-info .thumb-info-wrapper-opacity-2:after {
    opacity: .2
}

.thumb-info .thumb-info-wrapper-opacity-3:after {
    opacity: .3
}

.thumb-info .thumb-info-wrapper-opacity-4:after {
    opacity: .4
}

.thumb-info .thumb-info-wrapper-opacity-5:after {
    opacity: .5
}

.thumb-info .thumb-info-wrapper-opacity-6:after {
    opacity: .6
}

.thumb-info .thumb-info-wrapper-opacity-7:after {
    opacity: .7
}

.thumb-info .thumb-info-wrapper-opacity-8:after {
    opacity: .8
}

.thumb-info .thumb-info-wrapper-opacity-9:after {
    opacity: .9
}

.thumb-info .thumb-info-action-icon {
    transition: all .3s;
    background: #ccc;
    border-radius: 0 0 0 4px;
    display: inline-block;
    font-size: 25px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    right: -100px;
    text-align: center;
    top: -100px;
    width: 50px;
    z-index: 2
}

.thumb-info .thumb-info-action-icon i {
    font-size: 14px;
    right: 50%;
    position: absolute;
    top: 50%;
    color: #fff;
    transform: translate(50%, -50%);
    margin: -2px -1px 0 0
}

.thumb-info .thumb-info-inner {
    transition: all .3s;
    display: block
}

.thumb-info .thumb-info-inner em {
    display: block;
    font-size: .8em;
    font-style: normal;
    font-weight: 400
}

.thumb-info .thumb-info-title {
    transition: all .3s;
    background: rgba(33, 37, 41, .8);
    color: #fff;
    font-weight: 600;
    left: 0;
    letter-spacing: -.05em;
    position: absolute;
    z-index: 2;
    max-width: 90%;
    font-size: 17px;
    padding: 13px 21px 2px;
    bottom: 13%
}

.thumb-info .thumb-info-title.thumb-info-title-vert-align {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%)
}

.thumb-info .thumb-info-title.thumb-info-title-top {
    bottom: auto;
    top: 13%
}

.thumb-info .thumb-info-type {
    background-color: #ccc;
    border-radius: 2px;
    display: inline-block;
    float: left;
    font-size: .6em;
    font-weight: 600;
    letter-spacing: 0;
    margin: 8px -2px -15px 0;
    padding: 2px 12px;
    text-transform: uppercase;
    z-index: 2;
    line-height: 2.3
}

.thumb-info .owl-carousel {
    z-index: auto
}

.thumb-info .owl-carousel .owl-dots {
    z-index: 2
}

.thumb-info img {
    transition: all .3s ease;
    border-radius: 3px;
    position: relative;
    width: 100%
}

.thumb-info:hover .thumb-info-wrapper:after {
    opacity: 1
}

.thumb-info:hover .thumb-info-action-icon {
    right: 0;
    top: 0
}

.thumb-info:hover .thumb-info-title {
    background: rgba(33, 37, 41, .9)
}

.thumb-info:hover img {
    transform: scale(1.1, 1.1)
}

.thumb-info.thumb-info-no-zoom img {
    transition: none
}

.thumb-info.thumb-info-no-zoom:hover img {
    transform: scale(1, 1)
}

.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
    background: rgba(23, 23, 23, .25)
}

.thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
    display: none
}

.thumb-info.thumb-info-centered-icons .thumb-info-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 45px;
    text-align: center;
    opacity: 0;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transform: translate3d(0, -70%, 0);
    z-index: 2
}

.thumb-info.thumb-info-centered-icons .thumb-info-action a {
    text-decoration: none
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 35px;
    height: 35px;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 5px;
    transition: all .1s ease;
    z-index: 2
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
    margin: 0
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
    transform: scale(1.15, 1.15)
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
    opacity: 1;
    transform: translate3d(0, -50%, 0)
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
    position: relative
}

.thumb-info.thumb-info-centered-info .thumb-info-title {
    background: 0 0;
    left: 5%;
    width: 90%;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transform: translate(0%, -50%);
    top: 50%;
    bottom: auto;
    opacity: 0;
    text-align: center;
    padding: 0;
    position: absolute
}

.thumb-info.thumb-info-centered-info .thumb-info-type {
    float: none
}

.thumb-info.thumb-info-centered-info:hover .thumb-info-title {
    opacity: 1;
    transform: translate(0%, -50%)
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
    background: #fff;
    left: 0;
    width: 100%;
    max-width: 100%;
    transition: transform .2s ease-out, opacity .2s ease-out;
    transform: translate3d(0, 100%, 0);
    top: auto;
    bottom: 0;
    opacity: 0;
    padding: 15px;
    text-shadow: none;
    color: #777
}

.thumb-info.thumb-info-bottom-info .thumb-info-type {
    float: none;
    background: 0 0;
    padding: 0;
    margin: 0
}

.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.thumb-info.thumb-info-bottom-info:hover img {
    transform: scale(1.1)
}

.thumb-info.thumb-info-show-button-hover .btn {
    opacity: 0
}

.thumb-info.thumb-info-show-button-hover .btn,
.thumb-info.thumb-info-show-button-hover .thumb-info-type {
    transition: opacity .3s ease-out
}

.thumb-info.thumb-info-show-button-hover .thumb-info-title {
    transform: translate3d(0, 35px, 0);
    transition: transform .3s ease-out;
    bottom: 0
}

.thumb-info.thumb-info-show-button-hover:hover .btn {
    opacity: 1
}

.thumb-info.thumb-info-show-button-hover:hover .thumb-info-type {
    opacity: 0
}

.thumb-info.thumb-info-show-button-hover:hover .thumb-info-title {
    transform: translate3d(0, -20px, 0)
}

.thumb-info.thumb-info-bottom-info-linear .thumb-info-title {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(255, 255, 255, 0)
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
    background: #212529;
    color: #fff
}

.thumb-info.thumb-info-bottom-info-dark-linear .thumb-info-title {
    background: linear-gradient(to top, rgba(33, 37, 41, 0.8) 0, transparent 100%) repeat scroll 0 0 rgba(0, 0, 0, 0)
}

.thumb-info.thumb-info-bottom-info-show-more img {
    transform: none !important
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
    transform: translate3d(0, 0, 0);
    opacity: 1
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-show-more-content {
    transition: ease max-height 800ms;
    overflow: hidden;
    max-height: 0;
    display: block
}

.thumb-info.thumb-info-bottom-info-show-more:hover .thumb-info-show-more-content {
    max-height: 300px
}

.thumb-info.thumb-info-push-hor img {
    transform: translatex(-18px);
    width: calc(100% + 19px);
    max-width: none
}

.thumb-info.thumb-info-push-hor:hover img {
    transform: translatex(0)
}

.thumb-info.thumb-info-block .thumb-info-action-icon {
    top: 15px;
    right: 15px;
    opacity: 0;
    transition: all .2s ease-in-out
}

.thumb-info.thumb-info-block .thumb-info-wrapper:before {
    content: "";
    background: rgba(255, 255, 255, .9);
    transition: all .3s;
    border-radius: 0;
    bottom: 16px;
    bottom: 1rem;
    color: #fff;
    left: 16px;
    left: 1rem;
    position: absolute;
    right: 16px;
    right: 1rem;
    top: 16px;
    top: 1rem;
    display: block;
    opacity: 0;
    z-index: 2
}

.thumb-info.thumb-info-block:hover .thumb-info-wrapper:before {
    opacity: 1
}

.thumb-info.thumb-info-block:hover .thumb-info-action-icon {
    opacity: 1
}

.thumb-info.thumb-info-block.thumb-info-block-dark .thumb-info-wrapper:before {
    background: rgba(33, 37, 41, .9)
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
    opacity: .65
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-title {
    opacity: 1;
    top: 50%
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-type {
    float: none
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
    opacity: .1
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
    opacity: 0
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper:after {
    opacity: .65
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-wrapper-no-opacity:after {
    opacity: 1
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-title {
    opacity: 1;
    top: 0;
    height: 100%;
    top: 0;
    transform: none !important
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-type {
    float: none
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-1,
.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
    transition: cubic-bezier(.55, 0, .1, 1) top 500ms;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translate(0%, -50%)
}

.thumb-info.thumb-info-slide-info-hover .thumb-info-slide-info-hover-2 {
    top: 150%
}

.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-1 {
    top: -50%
}

.thumb-info.thumb-info-slide-info-hover:hover .thumb-info-slide-info-hover-2 {
    top: 50%
}

.thumb-info.thumb-info-show-image-hover img {
    opacity: 0
}

.thumb-info.thumb-info-show-image-hover .thumb-info-title {
    background: 0 0;
    color: #333
}

.thumb-info.thumb-info-show-image-hover .thumb-info-inner {
    transition: cubic-bezier(.55, 0, .1, 1) top 500ms
}

.thumb-info.thumb-info-show-image-hover:hover img {
    opacity: 1
}

.thumb-info.thumb-info-show-image-hover:hover .thumb-info-title {
    color: #fff
}

.thumb-info.thumb-info-no-borders,
.thumb-info.thumb-info-no-borders img {
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
    border-radius: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0
}

.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded img,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper,
.thumb-info.thumb-info-no-borders.thumb-info-no-borders-rounded .thumb-info-wrapper:after {
    border-radius: 4px
}

.thumb-info.thumb-info-preview .thumb-info-wrapper:after {
    display: none
}

.thumb-info.thumb-info-preview .thumb-info-image {
    min-height: 232px;
    display: block;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
    transition: background-position .8s linear 0s
}

.thumb-info.thumb-info-preview:hover .thumb-info-image {
    transition: background-position 2.5s linear 0s;
    background-position: center bottom
}

.thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
    transition: background-position 1s linear 0s
}

.thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
    transition: background-position 5s linear 0s
}

.thumb-info.thumb-info-slow-image-zoom-hover img {
    transition: transform 2s;
    will-change: transform;
    transform: scale(1, 1)
}

.thumb-info.thumb-info-slow-image-zoom-hover:hover img {
    transform: scale(1.5, 1.5)
}

.thumb-info.thumb-info-no-overlay .thumb-info-wrapper:after {
    content: none
}

.thumb-info.thumb-info-no-bg {
    background: 0 0
}

.thumb-info.thumb-info-swap-content .thumb-info-swap-content-wrapper>* {
    transition: cubic-bezier(.55, 0, .1, 1) all 300ms
}

.thumb-info.thumb-info-swap-content .thumb-info-swap-content-wrapper>:nth-child(1) {
    max-width: 100%;
    text-align: center;
    bottom: 0%;
    margin: 0;
    position: absolute;
    width: 100%;
    left: 0
}

.thumb-info.thumb-info-swap-content .thumb-info-swap-content-wrapper>:nth-child(2) {
    display: inline-block;
    transform: translateY(120%);
    opacity: 0
}

.thumb-info.thumb-info-swap-content:hover .thumb-info-swap-content-wrapper>:nth-child(1) {
    transform: translateY(-120%);
    opacity: 0
}

.thumb-info.thumb-info-swap-content:hover .thumb-info-swap-content-wrapper>:nth-child(2) {
    transform: none;
    opacity: 1
}

.thumb-info-ribbon {
    background: #999;
    position: absolute;
    margin: -16px 0 0;
    padding: 5px 13px 6px;
    right: 15px;
    z-index: 1
}

.thumb-info-ribbon:before {
    border-right: 10px solid #646464;
    border-top: 16px solid transparent;
    content: "";
    display: block;
    height: 0;
    left: -10px;
    position: absolute;
    top: 0;
    width: 7px
}

.thumb-info-ribbon span {
    color: #fff;
    font-size: 1.1em;
    font-family: shadows into light, cursive
}

.full-width .thumb-info img {
    border-radius: 0 !important
}

.thumb-info-caption {
    padding: 10px 0
}

.thumb-info-caption .thumb-info-caption-text,
.thumb-info-caption p {
    font-size: .9em;
    margin: 0;
    padding: 15px 0;
    display: block
}

.thumb-info-side-image {
    display: flex
}

.thumb-info-side-image .thumb-info-side-image-wrapper {
    padding: 0;
    margin-right: 20px
}

.thumb-info-side-image-right {
    display: flex
}

.thumb-info-side-image-right .thumb-info-side-image-wrapper {
    margin-left: 20px;
    margin-right: 0
}

.thumb-info-social-icons {
    margin: 0;
    padding: 0;
    display: block
}

.thumb-info-social-icons a {
    background: #ccc;
    border-radius: 25px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px
}

.thumb-info-social-icons a:hover {
    text-decoration: none
}

.thumb-info-social-icons a span {
    display: none
}

.thumb-info-social-icons a i {
    color: #fff;
    font-size: .9em;
    font-weight: 400
}

.thumb-info-floating-caption-title {
    background: rgba(33, 37, 41, .8);
    color: #fff;
    font-weight: 600;
    letter-spacing: -.05em;
    font-size: 17px;
    padding: 13px 21px
}

.thumb-info-floating-caption-title .thumb-info-floating-caption-type {
    position: absolute;
    background-color: #ccc;
    border-radius: 2px;
    display: block;
    font-size: .6em;
    font-weight: 600;
    letter-spacing: 0;
    margin: 8px -2px -15px 0;
    padding: 2px 12px;
    text-transform: uppercase;
    line-height: 2.3
}

@media(max-width:991px) {
    .thumb-info .thumb-info-title {
        font-size: 14px
    }

    .thumb-info .thumb-info-more {
        font-size: 11px
    }
}

.thumb-info-container-full-img {
    overflow: hidden;
    position: relative
}

.thumb-info-container-full-img .thumb-info-container-full-img-large {
    opacity: 0;
    transform: scale(1.025);
    transition: all .85s cubic-bezier(.23, 1, .32, 1);
    transition-property: opacity, transform;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 0
}

.thumb-info-container-full-img .thumb-info-container-full-img-large.active {
    opacity: 1 !important
}

.thumb-info-container-full-img .thumb-info-wrapper img {
    transition: all .85s cubic-bezier(.23, 1, .32, 1);
    transition-property: opacity, transform
}

.thumb-info-container-full-img .owl-stage-outer:hover .thumb-info-wrapper img {
    opacity: 0
}

section.timeline {
    width: 100%;
    margin: 50px 0 0;
    position: relative;
    float: left;
    padding-bottom: 120px
}

section.timeline:before {
    background: #505050;
    background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: -60px;
    width: 3px;
    z-index: 0;
    filter: alpha(opacity=35);
    opacity: .35
}

section.timeline .timeline-body {
    position: relative;
    z-index: 1 !important
}

section.timeline .timeline-date {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .04);
    position: relative;
    display: block;
    clear: both;
    width: 200px;
    height: 45px;
    padding: 5px;
    border: 1px solid #e5e5e5;
    margin: 0 auto 10px;
    text-shadow: 0 1px 1px #fff;
    border-radius: 4px;
    background: #fff;
    z-index: 1
}

section.timeline .timeline-date h3 {
    display: block;
    text-align: center;
    color: #757575;
    font-size: .9em;
    line-height: 32px
}

section.timeline .timeline-title {
    background: #f7f7f7;
    padding: 12px
}

section.timeline .timeline-title h4 {
    padding: 0;
    margin: 0;
    color: #151719;
    font-size: 1.4em
}

section.timeline .timeline-title a {
    color: #151719
}

section.timeline .timeline-title .timeline-title-tags {
    color: #b1b1b1;
    font-size: .9em
}

section.timeline .timeline-title .timeline-title-tags a {
    color: #b1b1b1;
    font-size: .9em
}

section.timeline .timeline-box {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .04);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin: 20px 10px 10px;
    padding: 10px;
    width: 45.7%;
    float: left;
    z-index: 1;
    margin-bottom: 30px;
    background: #fff
}

section.timeline .timeline-box.left {
    clear: both
}

section.timeline .timeline-box.left:before {
    background: none repeat scroll 0 0 #e5e5e5;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #e5e5e5;
    content: "";
    display: block;
    height: 7px;
    margin-right: -5px;
    position: absolute;
    left: 50%;
    margin-top: 22px;
    width: 8px;
    transform: translate3d(-50%, 0, 0);
    z-index: 1
}

section.timeline .timeline-box.left .timeline-box-arrow {
    transform: rotate(45deg);
    content: '';
    display: block;
    background: #fff;
    border-right: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    float: right;
    height: 14px;
    margin-top: 17px;
    margin-right: -18px;
    width: 14px;
    z-index: 0
}

section.timeline .timeline-box.right {
    clear: right;
    float: right;
    right: -1px;
    margin-top: 40px
}

section.timeline .timeline-box.right:before {
    background: none repeat scroll 0 0 #e5e5e5;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #e5e5e5;
    content: "";
    display: block;
    height: 7px;
    position: absolute;
    left: 50%;
    margin-top: 32px;
    width: 8px;
    transform: translate3d(-50%, 0, 0);
    z-index: 1
}

section.timeline .timeline-box.right .timeline-box-arrow {
    transform: rotate(45deg);
    content: '';
    display: block;
    background: #fff;
    border-left: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    float: left;
    height: 14px;
    margin-top: 28px;
    margin-left: -18px;
    width: 14px;
    z-index: 0
}

section.timeline .timeline-box .owl-carousel {
    border-radius: 0;
    padding: 0;
    border: 0;
    box-shadow: none
}

@media(max-width:991px) {
    section.timeline .timeline-box {
        float: none;
        clear: both;
        right: auto;
        left: auto;
        margin: 25px auto;
        max-width: 70%;
        width: auto
    }

    section.timeline .timeline-box.right {
        float: none;
        clear: both;
        right: auto;
        left: auto;
        margin: 25px auto;
        max-width: 70%;
        width: auto
    }

    section.timeline .timeline-box.left:after,
    section.timeline .timeline-box.right:after,
    section.timeline .timeline-box.left:before,
    section.timeline .timeline-box.right:before {
        display: none
    }
}

@media(max-width:767px) {
    section.timeline {
        width: 100%;
        margin: 0
    }
}

@media(max-width:575px) {
    section.timeline {
        width: auto;
        float: none;
        margin-left: 0;
        margin-right: 0
    }

    section.timeline .timeline-box {
        margin: 15px auto;
        max-width: 90%
    }

    section.timeline .timeline-box.right {
        margin: 15px auto;
        max-width: 90%
    }

    article.post-large {
        margin-left: 0
    }

    article.post-large .post-image,
    article.post-large .post-date {
        margin-left: 0
    }
}

.toggle {
    margin: 10px 0 20px;
    position: relative;
    clear: both
}

.toggle .toggle {
    margin: 10px 0 0
}

.toggle>input {
    cursor: pointer;
    filter: alpha(opacity=0);
    height: 45px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 2
}

.toggle>label,
.toggle>.toggle-title {
    transition: all .15s ease-out;
    background: #f7f7f7;
    border-left: 3px solid #ccc;
    border-radius: 5px;
    color: #ccc;
    display: block;
    min-height: 20px;
    padding: 12px 20px 12px 15px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -.2px;
    text-decoration: none !important
}

.toggle>label:-moz-selection,
.toggle>.toggle-title:-moz-selection {
    background: 0 0
}

.toggle>label i.fa-minus,
.toggle>.toggle-title i.fa-minus {
    display: none
}

.toggle>label i.fa-plus,
.toggle>.toggle-title i.fa-plus {
    display: inline
}

.toggle>label:before,
.toggle>.toggle-title:before {
    content: " ";
    position: absolute;
    top: calc(50% + 1px);
    right: 14px;
    border-color: #ccc;
    border-top: 1px solid;
    border-right: 1px solid;
    width: 8px;
    height: 8px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .3s, top .3s;
    transform: rotate(-45deg) translate3d(0, -50%, 0);
    transform-origin: 35%
}

.toggle>label:hover,
.toggle>.toggle-title:hover {
    background: #f5f5f5
}

.toggle>label+p,
.toggle>.toggle-title+p {
    color: #999;
    display: block;
    overflow: hidden;
    padding-left: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 25px
}

.toggle>label i,
.toggle>.toggle-title i {
    font-size: .7em;
    margin-right: 8px;
    position: relative;
    top: -1px
}

.toggle>.toggle-content {
    display: none
}

.toggle>.toggle-content>p {
    margin-bottom: 0;
    padding: 10px 0
}

.toggle.active i.fa-minus {
    display: inline
}

.toggle.active i.fa-plus {
    display: none
}

.toggle.active>label,
.toggle.active>.toggle-title {
    background: #f7f7f7;
    border-color: #ccc
}

.toggle.active>label:before,
.toggle.active>.toggle-title:before {
    top: calc(50% - 8px);
    transform: rotate(135deg)
}

.toggle.active>p {
    white-space: normal
}

.toggle>p.preview-active {
    height: auto;
    white-space: normal
}

.toggle.toggle-sm .toggle>label,
.toggle.toggle-sm .toggle>.toggle-title {
    font-size: .9em;
    padding: 6px 20px 6px 10px
}

.toggle.toggle-sm .toggle>.toggle-content>p {
    font-size: .9em
}

.toggle.toggle-lg .toggle>label,
.toggle.toggle-lg .toggle>.toggle-title {
    font-size: 1.1em;
    padding: 16px 20px
}

.toggle.toggle-lg .toggle>.toggle-content>p {
    font-size: 1.1em
}

.toggle.toggle-simple .toggle>label,
.toggle.toggle-simple .toggle>.toggle-title {
    border-radius: 0;
    border: 0;
    background: 0 0;
    color: #777;
    padding-left: 32px;
    margin-bottom: -10px;
    font-size: 1em;
    font-weight: 600;
    color: #212529
}

.toggle.toggle-simple .toggle>label:after,
.toggle.toggle-simple .toggle>.toggle-title:after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    background: #ccc;
    top: 25px;
    margin-top: -12px;
    border-radius: 3px;
    z-index: 1
}

.toggle.toggle-simple .toggle>label:before,
.toggle.toggle-simple .toggle>.toggle-title:before {
    border: 0;
    left: 9px;
    right: auto;
    margin-top: 0;
    top: 12px;
    font-family: 'font awesome 6 free';
    font-weight: 900;
    content: "\f067";
    z-index: 2;
    color: #fff;
    font-size: 7px;
    width: auto;
    height: auto;
    transform: none
}

.toggle.toggle-simple .toggle.active>label,
.toggle.toggle-simple .toggle.active>.toggle-title {
    background: 0 0;
    color: #212529
}

.toggle.toggle-simple .toggle.active>label:before,
.toggle.toggle-simple .toggle.active>.toggle-title:before {
    content: "\f068"
}

.toggle.toggle-minimal .toggle {
    margin: 0
}

.toggle.toggle-minimal .toggle>label,
.toggle.toggle-minimal .toggle>.toggle-title {
    background: 0 0;
    color: #777;
    border-left: 0;
    border-bottom: 1px solid #cccc;
    border-radius: 0;
    margin: 0;
    padding: 12px 20px 12px 0;
    font-weight: 600
}

.toggle.toggle-minimal .toggle.active>label,
.toggle.toggle-minimal .toggle.active>.toggle-title {
    background: 0 0;
    border-bottom-color: #ccc
}

blockquote {
    border-left: 5px solid #eee;
    margin: 0 0 16px;
    margin: 0 0 1rem;
    padding: 8px 16px;
    padding: .5rem 1rem;
    font-size: 1em
}

blockquote.with-borders {
    border-radius: 6px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04);
    padding: 20.8px;
    padding: 1.3rem
}

blockquote.blockquote-reverse {
    text-align: right;
    border-right: 5px solid #eee;
    border-left: 0 !important
}

small,
.small {
    font-size: 70%
}

p {
    color: #777;
    line-height: 26px;
    margin: 0 0 20px
}

p.featured {
    font-size: 1.6em;
    line-height: 1.5em
}

p.lead {
    line-height: 28.8px;
    line-height: 1.8rem
}

p .alternative-font {
    display: inline-block;
    margin-top: -15px;
    position: relative;
    top: 3px;
    margin-bottom: -6px
}

.lead {
    letter-spacing: -.05em;
    font-size: 19.2px;
    font-size: 1.2rem
}

.lead-2 {
    font-size: 17.92px;
    font-size: 1.12rem
}

a,
a:hover,
a:focus {
    color: #ccc
}

p.drop-caps:first-letter {
    float: left;
    font-size: 75px;
    line-height: 60px;
    padding: 4px;
    margin-right: 5px;
    margin-top: 5px;
    font-family: Georgia
}

p.drop-caps.drop-caps-style-2:first-letter {
    background-color: #ccc;
    color: #fff;
    padding: 6px;
    margin-right: 5px;
    border-radius: 4px
}

.default-font {
    font-family: poppins, Arial, sans-serif !important
}

.alternative-font {
    color: #ccc;
    font-family: shadows into light, cursive !important;
    font-size: 1.6em
}

.alternative-font-2 {
    font-family: oswald, sans-serif !important
}

.alternative-font-3 {
    font-family: segoe script, zapfino, savoye let !important
}

.alternative-font-4 {
    font-family: poppins, Arial, sans-serif !important
}

.alternative-font-5 {
    font-family: open sans, Arial, sans-serif !important
}

.alternative-font-6 {
    font-family: montserrat, Arial, sans-serif !important
}

.alternative-font-7 {
    font-family: jost, sans-serif !important
}

.alternative-font-8 {
    font-family: cormorant, serif !important
}

.gradient-text-color {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important
}

.no-backgroundcliptext .gradient-text-color {
    background: 0 0 !important
}

.sup-text-small {
    font-size: .5em;
    font-weight: 500;
    top: -.7em
}

.text-default,
.text-color-default {
    color: #777 !important
}

.marquee {
    overflow: hidden;
    white-space: nowrap
}

.mark.mark-gradient {
    position: relative;
    z-index: 0
}

.mark.mark-gradient:before {
    content: '';
    height: 30%;
    width: 100%;
    bottom: 15%;
    position: absolute;
    left: -2%;
    z-index: -1
}

section.video {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
    width: 100%
}

section.video .container {
    position: relative;
    z-index: 3
}

html.boxed section.video>div:first-child {
    z-index: 0 !important
}

html.boxed section.video>div:first-child video {
    z-index: 0 !important
}

.word-rotator i,
.word-rotator em,
.word-rotator b {
    font-style: normal;
    font-weight: inherit
}

.word-rotator-words {
    display: inline-flex !important;
    position: relative;
    text-align: left
}

.word-rotator-words b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0
}

.word-rotator-words b.is-visible {
    position: relative
}

.word-rotator-words[class*=bg-] {
    background: #ccc
}

.word-rotator-words[class*=bg-] b {
    color: #fff;
    padding: 1.6px 8px;
    padding: .1rem .5rem
}

.word-rotator.rotate-1 .word-rotator-words {
    perspective: 300px
}

.word-rotator.rotate-1 b {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: rotateX(180deg)
}

.word-rotator.rotate-1 b.is-visible {
    opacity: 1;
    transform: rotateX(0deg);
    animation: word-rotator-rotate-1-in 1.2s
}

.word-rotator.rotate-1 b.is-hidden {
    transform: rotateX(180deg);
    animation: word-rotator-rotate-1-out 1.2s
}

@keyframes word-rotator-rotate-1-in {
    0% {
        transform: rotateX(180deg);
        opacity: 0
    }

    35% {
        transform: rotateX(120deg);
        opacity: 0
    }

    65% {
        opacity: 0
    }

    100% {
        transform: rotateX(360deg);
        opacity: 1
    }
}

@keyframes word-rotator-rotate-1-out {
    0% {
        transform: rotateX(0deg);
        opacity: 1
    }

    35% {
        transform: rotateX(-40deg);
        opacity: 1
    }

    65% {
        opacity: 0
    }

    100% {
        transform: rotateX(180deg);
        opacity: 0
    }
}

.word-rotator.type .word-rotator-words {
    vertical-align: top;
    overflow: hidden;
    position: relative;
    padding-right: 3px
}

.word-rotator.type .word-rotator-words::after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 5%;
    height: 90%;
    width: 2px;
    display: block;
    background-color: transparent
}

.word-rotator.type .word-rotator-words.waiting::after {
    animation: 1s word-rotator-pulse step-end infinite
}

.word-rotator.type .word-rotator-words.selected {
    background-color: #212529 !important
}

.word-rotator.type .word-rotator-words.selected::after {
    visibility: hidden
}

.word-rotator.type .word-rotator-words.selected b {
    color: #fff !important
}

.word-rotator.type b {
    visibility: hidden
}

.word-rotator.type b.is-visible {
    visibility: visible
}

.word-rotator.type i {
    position: absolute;
    visibility: hidden
}

.word-rotator.type i.in {
    position: relative;
    visibility: visible
}

.word-rotator.type.type-clean-light .word-rotator-words:after {
    width: 8px
}

.word-rotator.type.type-clean-light .word-rotator-words.waiting::after {
    animation: 500ms word-rotator-pulse-light step-end infinite
}

.word-rotator.type.type-clean-light .word-rotator-words.selected {
    background-color: transparent !important
}

@keyframes word-rotator-pulse {

    from,
    to {
        background-color: transparent
    }

    50% {
        background-color: #000
    }
}

@keyframes word-rotator-pulse-light {

    from,
    to {
        background-color: transparent
    }

    50% {
        background-color: #fff
    }
}

.word-rotator.rotate-2 .word-rotator-words {
    perspective: 300px
}

.word-rotator.rotate-2 i,
.word-rotator.rotate-2 em {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.word-rotator.rotate-2 b {
    opacity: 0
}

.word-rotator.rotate-2 i {
    transform-style: preserve-3d;
    transform: translateZ(-20px) rotateX(90deg);
    opacity: 0
}

.is-visible .word-rotator.rotate-2 i {
    opacity: 1
}

.word-rotator.rotate-2 i.in {
    animation: word-rotator-rotate-2-in .4s forwards
}

.word-rotator.rotate-2 i.out {
    animation: word-rotator-rotate-2-out .4s forwards
}

.word-rotator.rotate-2 em {
    transform: translateZ(20px)
}

.no-csstransitions .word-rotator.rotate-2 i {
    transform: rotateX(0deg);
    opacity: 0
}

.no-csstransitions .word-rotator.rotate-2 i em {
    transform: scale(1)
}

.no-csstransitions .word-rotator.rotate-2 .is-visible i {
    opacity: 1
}

@keyframes word-rotator-rotate-2-in {
    0% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(90deg)
    }

    60% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(-10deg)
    }

    100% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0deg)
    }
}

@keyframes word-rotator-rotate-2-out {
    0% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0)
    }

    60% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-100deg)
    }

    100% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-90deg)
    }
}

.word-rotator.loading-bar span {
    display: inline-block;
    padding: .2em 0
}

.word-rotator.loading-bar .word-rotator-words {
    overflow: hidden;
    vertical-align: top
}

.word-rotator.loading-bar .word-rotator-words::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: #212529;
    z-index: 2;
    transition: width .3s -.1s
}

.word-rotator.loading-bar .word-rotator-words.is-loading::after {
    width: 100%;
    transition: width 3s
}

.word-rotator.loading-bar .word-rotator-words.bg-light::after {
    background-color: #212529 !important
}

.word-rotator.loading-bar .word-rotator-words[class*=bg-]:not(.bg-light)::after {
    background-color: #fff !important
}

.word-rotator.loading-bar b {
    top: .2em;
    opacity: 0;
    transition: opacity .3s
}

.word-rotator.loading-bar b.is-visible {
    opacity: 1;
    top: 0
}

.word-rotator.slide span {
    display: inline-block;
    padding: .2em 0
}

.word-rotator.slide .word-rotator-words {
    overflow: hidden;
    vertical-align: top
}

.word-rotator.slide b {
    opacity: 0;
    top: .2em
}

.word-rotator.slide b.is-visible {
    top: 0;
    opacity: 1;
    animation: slide-in .6s
}

.word-rotator.slide b.is-hidden {
    animation: slide-out .6s
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }

    60% {
        opacity: 1;
        transform: translateY(20%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    60% {
        opacity: 0;
        transform: translateY(120%)
    }

    100% {
        opacity: 0;
        transform: translateY(100%)
    }
}

.word-rotator.clip span {
    display: inline-block;
    padding: .2em 0
}

.word-rotator.clip .word-rotator-words {
    overflow: hidden;
    vertical-align: top
}

.word-rotator.clip .word-rotator-words::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #212529
}

.word-rotator.clip b {
    opacity: 0
}

.word-rotator.clip b.is-visible {
    opacity: 1
}

.word-rotator.zoom .word-rotator-words {
    perspective: 300px
}

.word-rotator.zoom b {
    opacity: 0
}

.word-rotator.zoom b.is-visible {
    opacity: 1;
    animation: zoom-in .8s
}

.word-rotator.zoom b.is-hidden {
    animation: zoom-out .8s
}

@keyframes zoom-in {
    0% {
        opacity: 0;
        transform: translateZ(100px)
    }

    100% {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes zoom-out {
    0% {
        opacity: 1;
        transform: translateZ(0)
    }

    100% {
        opacity: 0;
        transform: translateZ(-100px)
    }
}

.word-rotator.rotate-3 .word-rotator-words {
    perspective: 300px
}

.word-rotator.rotate-3 b {
    opacity: 0
}

.word-rotator.rotate-3 i {
    display: inline-block;
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.is-visible .word-rotator.rotate-3 i {
    transform: rotateY(0deg)
}

.word-rotator.rotate-3 i.in {
    animation: word-rotator-rotate-3-in .6s forwards
}

.word-rotator.rotate-3 i.out {
    animation: word-rotator-rotate-3-out .6s forwards
}

.no-csstransitions .word-rotator.rotate-3 i {
    transform: rotateY(0deg);
    opacity: 0
}

.no-csstransitions .word-rotator.rotate-3 .is-visible i {
    opacity: 1
}

@keyframes word-rotator-rotate-3-in {
    0% {
        transform: rotateY(180deg)
    }

    100% {
        transform: rotateY(0deg)
    }
}

@keyframes word-rotator-rotate-3-out {
    0% {
        transform: rotateY(0)
    }

    100% {
        transform: rotateY(-180deg)
    }
}

.word-rotator.scale b {
    opacity: 0
}

.word-rotator.scale i {
    display: inline-block;
    opacity: 0;
    transform: scale(0)
}

.is-visible .word-rotator.scale i {
    opacity: 1
}

.word-rotator.scale i.in {
    animation: scale-up .6s forwards
}

.word-rotator.scale i.out {
    animation: scale-down .6s forwards
}

.no-csstransitions .word-rotator.scale i {
    transform: scale(1);
    opacity: 0
}

.no-csstransitions .word-rotator.scale .is-visible i {
    opacity: 1
}

@keyframes scale-up {
    0% {
        transform: scale(0);
        opacity: 0
    }

    60% {
        transform: scale(1.2);
        opacity: 1
    }

    100% {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes scale-down {
    0% {
        transform: scale(1);
        opacity: 1
    }

    60% {
        transform: scale(0);
        opacity: 0
    }
}

.word-rotator.push b {
    opacity: 0
}

.word-rotator.push b.is-visible {
    opacity: 1;
    animation: push-in .6s
}

.word-rotator.push b.is-hidden {
    animation: push-out .6s
}

@keyframes push-in {
    0% {
        opacity: 0;
        transform: translateX(-100%)
    }

    60% {
        opacity: 1;
        transform: translateX(10%)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes push-out {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    60% {
        opacity: 0;
        transform: translateX(110%)
    }

    100% {
        opacity: 0;
        transform: translateX(100%)
    }
}

section.section-custom-map {
    /* background: transparent url(../img/map.png) center 0 no-repeat; */
    padding: 129px 0 0;
    margin: 20px 0 0;
    border: 0
}

section.section-custom-map section.section {
    border-top: 0;
    background: rgba(247, 247, 247, .5)
}

.home-intro {
    background-color: #212529;
    margin-bottom: 60px;
    overflow: hidden;
    padding: 38.4px 0;
    padding: 2.4rem 0;
    position: relative;
    text-align: left;
    letter-spacing: -.5px
}

.home-intro p {
    color: #fff;
    display: inline-block;
    font-size: 1.4em;
    font-weight: 600;
    margin-bottom: 0
}

.home-intro p span:not(.highlighted-word) {
    color: #fff;
    display: block;
    font-size: .8em;
    font-weight: 300;
    padding-top: 5px;
    opacity: .7
}

.home-intro p .highlighted-word {
    font-size: 1.6em
}

.home-intro .get-started {
    position: relative
}

.home-intro .get-started .btn {
    position: relative;
    z-index: 1
}

.home-intro .get-started .btn i {
    font-size: 1.1em;
    font-weight: 400;
    margin-left: 5px
}

.home-intro .get-started a {
    color: #fff
}

.home-intro .get-started a:not(.btn) {
    color: #fff;
    font-weight: 600
}

.home-intro .learn-more {
    color: #fff;
    margin-left: 15px;
    font-weight: 300
}

.home-intro.light {
    background-color: #ededed
}

.home-intro.light.secondary {
    background-color: #e0e1e4
}

.home-intro.light p,
.home-intro.light .learn-more,
.home-intro.light p span:not(.highlighted-word),
.home-intro.light .get-started a:not(.btn) {
    color: #333
}

.home-intro-compact {
    border-radius: 0 0 .3rem .3rem;
    margin-top: -10px;
    margin: -10px auto 60px;
    max-width: 1116px
}

.home-intro-compact p {
    padding-left: 20px
}

.home-intro-compact .get-started {
    padding-right: 20px
}

@media(max-width:991px) {
    .home-intro p {
        margin-bottom: 2rem
    }

    .home-intro-compact .get-started {
        padding-left: 20px
    }
}

@media(max-width:767px) {
    .home-intro-compact {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0
    }
}

@keyframes animatedLineBg {
    from {
        stroke-dasharray: 1000
    }

    to {
        stroke-dasharray: 1360
    }
}

@keyframes animatedLineSmallCircle {
    from {
        stroke-dasharray: 1000
    }

    to {
        stroke-dasharray: 1120
    }
}

@keyframes animatedLineBigCircle {
    from {
        stroke-dasharray: 1000
    }

    to {
        stroke-dasharray: 1266
    }
}

@keyframes animatedInitialPin {
    0% {
        transform: translate(2px, 69px) scale(.23)
    }

    33% {
        transform: translate(2px, 79px) scale(.23)
    }

    66% {
        transform: translate(2px, 74px) scale(.23)
    }

    100% {
        transform: translate(2px, 79px) scale(.23)
    }
}

@keyframes animatedFinalPin {
    0% {
        transform: translate(325px, -19px) scale(.23) rotate(36deg)
    }

    33% {
        transform: translate(325px, -13px) scale(.23) rotate(36deg)
    }

    66% {
        transform: translate(325px, -16px) scale(.23) rotate(36deg)
    }

    100% {
        transform: translate(325px, -13px) scale(.23) rotate(36deg)
    }
}

@keyframes animatedFinalPinFirefox {
    0% {
        transform: translate(193px, -16px) scale(.23) rotate(36deg)
    }

    33% {
        transform: translate(193px, -10px) scale(.23) rotate(36deg)
    }

    66% {
        transform: translate(193px, -13px) scale(.23) rotate(36deg)
    }

    100% {
        transform: translate(193px, -10px) scale(.23) rotate(36deg)
    }
}

.home-concept {
    /* background: transparent url(../img/home/home-concept.png) no-repeat center 0; */
    width: 100%;
    overflow: hidden
}

.home-concept strong {
    display: block;
    font-family: shadows into light, cursive;
    font-size: 1.8em;
    font-weight: 400 !important;
    position: relative;
    margin-top: 30px
}

.home-concept .row {
    position: relative
}

.home-concept .process-image {
    /* background: transparent url(../img/home/home-concept-item.png) no-repeat 0 0; */
    width: 160px;
    margin: 120px auto 0;
    padding-bottom: 50px;
    position: relative;
    z-index: 1
}

.home-concept .process-image img {
    border-radius: 150px;
    margin: 7px 8px;
    width: auto;
    height: auto;
    max-width: 145px;
    max-height: 145px
}

@media(min-width:992px) {
    .home-concept .process-image.process-image-on-middle {
        margin-top: 95px
    }
}

.home-concept .our-work {
    margin-top: 52px;
    font-size: 2.2em
}

.home-concept .project-image {
    /* background: transparent url(../img/home/home-concept-item.png) no-repeat 100% 0; */
    width: 350px;
    margin: 20px 0 0 -30px;
    padding-bottom: 45px;
    position: relative;
    z-index: 1
}

.home-concept .sun {
    /* background: transparent url(../img/home/home-concept-icons.png) no-repeat 0 0; */
    width: 60px;
    height: 56px;
    display: block;
    position: absolute;
    left: 0;
    top: 65px
}

.home-concept .cloud {
    /* background: transparent url(../img/home/home-concept-icons.png) no-repeat 100% 0; */
    width: 116px;
    height: 56px;
    display: block;
    position: absolute;
    left: 53%;
    top: 35px
}

@media(max-width:991px) {
    .home-concept {
        background-image: none
    }

    .home-concept .project-image {
        margin: 20px auto 0
    }

    .home-concept .process-image {
        margin-top: 0;
        padding-bottom: 25px
    }
}

@media(max-width:479px) {
    .home-concept .project-image {
        background-position: 0 0;
        margin-left: auto;
        max-width: 160px
    }

    .home-concept .fc-slideshow {
        max-width: 145px;
        height: 145px
    }

    .home-concept .fc-slideshow img {
        max-height: 145px;
        max-width: 145px
    }
}

.portfolio-title {
    margin-bottom: -10px
}

.portfolio-title a {
    display: inline-block
}

.portfolio-title [class*=fa-],
.portfolio-title .icons {
    font-size: 1.7em
}

.portfolio-title .portfolio-nav {
    margin-top: 11px
}

.portfolio-title .portfolio-nav-all {
    margin-top: 9px
}

.portfolio-title .portfolio-nav {
    text-align: right
}

.portfolio-title .portfolio-nav a {
    width: 40%
}

.portfolio-title .portfolio-nav-all .fa,
.portfolio-title .portfolio-nav-all .icons {
    font-size: 2em
}

.portfolio-title .fa-angle-right {
    text-align: right
}

.portfolio-title .portfolio-nav-prev {
    text-align: left
}

.portfolio-info {
    margin: 2px 0 10px
}

.portfolio-info ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.portfolio-info ul li {
    display: inline-block;
    border-right: 1px solid #e6e6e6;
    color: #b1b1b1;
    padding-right: 8px;
    margin-right: 8px;
    font-size: .9em
}

.portfolio-info ul li:last-child {
    border-right: 0;
    padding-right: 0;
    margin-right: 0
}

.portfolio-info ul li a {
    text-decoration: none;
    color: #b1b1b1
}

.portfolio-info ul li a:hover .fa-heart {
    color: #e53f51
}

.portfolio-info ul li [class*=fa-],
.portfolio-info ul li .icons {
    margin-right: 4px
}

.portfolio-list {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0
}

.portfolio-list:not(.row) {
    margin: 0
}

.portfolio-list .portfolio-item {
    position: relative;
    margin-bottom: 35px
}

.portfolio-list .portfolio-item.portfolio-item-updated:after,
.portfolio-list .portfolio-item.portfolio-item-new:after {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    background: #ccc;
    content: "NEW";
    display: block;
    padding: 0 8px;
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border-radius: 2px;
    line-height: 22px
}

.portfolio-list .portfolio-item.portfolio-item-updated:after {
    content: "UPDATED";
    background: #36c57a
}

.portfolio-details {
    list-style: none;
    padding: 0;
    margin: 45px 0 0 5px;
    font-size: .9em
}

.portfolio-details>li {
    padding: 0;
    margin: 0 0 20px
}

.portfolio-details p {
    padding: 0;
    margin: 0
}

.portfolio-details blockquote {
    padding: 0 0 0 10px;
    margin: 15px 0 0;
    color: #999
}

.portfolio-details .list-icons {
    margin-left: 0
}

.portfolio-details .list-icons>li {
    padding-left: 18px;
    margin-right: 10px
}

.portfolio-details .list-icons>li [class*=fa-],
.portfolio-details .list-icons>li .icons {
    top: 7px
}

.portfolio-modal .mfp-arrow,
.portfolio-ajax-modal .mfp-arrow {
    display: none
}

.portfolio-load-more-loader {
    display: none;
    position: relative;
    height: 49px
}

.btn-portfolio-infinite-scroll {
    opacity: 0
}

.portfolio-next:hover [class*=fa-],
.portfolio-next:hover .icons,
.portfolio-prev:hover [class*=fa-],
.portfolio-prev:hover .icons {
    animation: navItemArrow 600ms ease;
    animation-iteration-count: infinite
}

@media(max-width:991px) {

    .portfolio-nav,
    .portfolio-nav-all {
        display: none
    }
}

ul.team-list {
    list-style: none;
    margin: 0;
    padding: 0
}

ul.team-list .team-item {
    margin-bottom: 35px
}

ul.team-list .team-item .thumb-info-social-icons {
    margin: 0 10px
}

@media(max-width:991px) {
    ul.team-list .team-item {
        margin: 0 auto 35px;
        max-width: 270px
    }
}

.search-results {
    min-height: 300px
}

.http-error {
    padding: 50px 0
}

.http-error h2 {
    font-size: 140px;
    font-weight: 700;
    line-height: 140px
}

.http-error h4 {
    color: #777
}

.http-error p {
    font-size: 1.4em;
    line-height: 36px
}

@media(max-width:575px) {
    .http-error {
        padding: 0
    }

    .http-error h2 {
        font-size: 100px;
        letter-spacing: 0;
        line-height: 100px
    }
}